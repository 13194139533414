// Built-in
import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { Breadcrumb } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import moment from 'moment';

// Component
import SaleDetailsContainer from 'shared/containers/sale-details';

// Type
import { Sale } from 'types/sale';

// Store
import UIStore from 'stores/ui';
import useStores from 'hooks/use-stores';

// Service
import { getSaleById } from 'services/sale';
import { getPurchaseById } from 'services/purchase';
import { getImportById } from 'services/import';
import { getExportById } from 'services/export';
import { getDeliveryById } from 'services/delivery';
import { getProductById } from 'services/product';

// Hook
import { useQueryParams } from 'hooks/use-query-params';
import { v1 } from 'uuid';
import { removeKeys } from 'utils';
import { Import } from 'types/import';
import { Export } from 'types/export';
import SessionStore from 'stores/session';

const CreateSaleScreen = () => {
  const navigate = useNavigate();
  const uiStore: UIStore = useStores().uiStore;
  const sessionStore: SessionStore = useStores().sessionStore;
  const query = useQueryParams();
  const [initialValues, setInitialValues] = useState<Partial<Sale> | undefined>(undefined);
  const [duplicateId, setDuplicateId] = useState<number | undefined>(undefined);
  const [purchaseId, setPurchaseId] = useState<number | undefined>(undefined);

  const [target, setTarget] = useState<
    { targetType: 'IMPORT' | 'EXPORT' | 'DELIVERY'; targetId: number } | undefined
  >(undefined);
  const [loaded, setLoaded] = useState(false);

  useQuery<Partial<Sale>>(['sale', duplicateId], () => getSaleById(duplicateId as number), {
    enabled: !!duplicateId,
    onSettled: () => uiStore.hideLoading(),
    onSuccess: (data) => {
      const newSale = JSON.parse(JSON.stringify(data));
      newSale.sale_details = data.sale_details?.map((old: any) => {
        const newSaleDetail = JSON.parse(JSON.stringify(old));
        newSaleDetail.id = v1();
        removeKeys(newSaleDetail, ['sale_id', 'updated_at', 'created_at', 'deleted_at']);
        return newSaleDetail;
      });

      newSale.complete = 0;
      newSale.complete_text = '未完了';
      newSale.input_date = moment(new Date()).format('YYYY/MM/DD');

      removeKeys(newSale, [
        'updated_at',
        'id',
        'created_at',
        'deleted_at',
        'target_type',
        'target_id',
        'delivery',
        'purchase',
        'deliveries',
        'import',
        'export',
        'sale_code',
        'user_confirm_id',
        'user_confirm_person',
        'sale_date',
        'aggregation_date',
      ]);

      setInitialValues(newSale);
    },
  });

  // convert a purchase to a sale (the purchase will be removed afterward)
  // old "purchase date" -> new "sale date"
  // ignore "store", "created_at", "deleted_at", "updated_at", "purchase_id"
  // "purchase_unit_price" multiple with "-1"
  // sale type = "7" (disposal)
  useQuery(['purchase', purchaseId], () => getPurchaseById(purchaseId as number), {
    enabled: !!purchaseId,
    onSettled: () => uiStore.hideLoading(),
    onSuccess: (data) => {
      const sale_details = data.purchase_details.map((purchaseDetail: any) => {
        const newSaleDetail = JSON.parse(JSON.stringify(purchaseDetail));
        newSaleDetail.purchase_unit_price *= -1;
        newSaleDetail.id = v1();
        removeKeys(newSaleDetail, [
          'purchase_id',
          'updated_at',
          'store',
          'created_at',
          'deleted_at',
          'purchase_detail_note',
        ]);
        return newSaleDetail;
      });

      const newSale = {
        ...data,
        sale_type: 6,
        target_type: 'PURCHASE',
        target_id: purchaseId,
        sale_date: data.purchase_date,
        sale_detail: data.purchase_detail,
        sale_memo: data.purchase_memo,
        sale_details,
        deliveries: data.deliveries,
        import: data.imports[0],
      };

      newSale.complete = 0;
      newSale.complete_text = '未完了';

      removeKeys(newSale, [
        'id',
        // 'imports',
        // 'deliveries',
        'updated_at',
        'created_at',
        'deleted_at',
        'quotation_id',
        'purchase_code',
        'purchase_date',
        'purchase_detail',
        'purchase_memo',
        'purchase_details',
        'purchase_type',
      ]);

      setInitialValues(newSale);
    },
  });

  useEffect(() => {
    if (initialValues) setLoaded(true);
  }, [initialValues]);

  useEffect(() => {
    let params: any = {};
    for (let value of query.keys()) {
      params[value] = query.get(value);
    }
    switch (true) {
      // create a new sale from an existing one
      case params.id !== undefined:
        if (!!parseInt(params.id) === true) setDuplicateId(params.id);
        break;
      // create a new sale from purchase
      case params.purchase_id !== undefined:
        if (!!parseInt(params.purchase_id) === true) {
          setPurchaseId(params.purchase_id);
        }
        break;
      // create a new sale from one of these: delivery, import, export
      case JSON.stringify(params) !== '{}':
        let target = {
          targetType: params.target_type,
          targetId: params.target_id,
        };
        setTarget(target);
        break;
      // a completely new sale
      default:
        setLoaded(true);
        break;
    }
  }, []);

  const getTargetDetails = async () => {
    if (target) {
      let func;
      switch (target.targetType) {
        case 'IMPORT':
          func = getImportById;
          break;
        case 'EXPORT':
          func = getExportById;
          break;
        case 'DELIVERY':
          func = getDeliveryById;
          break;
      }
      try {
        uiStore.showLoading();
        const res = await func(target.targetId);
        let products: Array<any> = [];
        if (res.pickup_fee != null) {
          const productRes = await getProductById(2);
          products.push({
            product: productRes,
            device_type_code: productRes.device_type_code,
            product_id: 2,
            product_quantity: res.pickup_fee_count ? res.pickup_fee_count : 1,
            purchase_unit_price: res.pickup_fee_unit_price ? res.pickup_fee_unit_price : res.pickup_fee ,
          });
        }
        if (target.targetType === 'IMPORT' && res.labor_cost != null && res.labor_cost > 0) {
          const productRes = await getProductById(3);
          products.push({
            product: productRes,
            device_type_code: productRes.device_type_code,
            product_id: 3,
            product_quantity: 1,
            purchase_unit_price: res.labor_cost,
          });
        }
        if (target.targetType === 'EXPORT' && res.labor_cost != null && res.labor_cost > 0) {
          const productRes = await getProductById(7);
          products.push({
            product: productRes,
            device_type_code: productRes.device_type_code,
            product_id: 7,
            product_quantity: 1,
            purchase_unit_price: res.labor_cost,
          });
        }
        /*  入庫手数料は自動追加不要になったのでコメントアウト
        if (res.import_fee != null) {
          const productRes = await getProductById(4);
          products.push({
            product: productRes,
            device_type_code: productRes.device_type_code,
            product_id: 4,
            product_quantity: res.import_fee_count ? res.import_fee_count : 1,
            purchase_unit_price: res.import_fee_unit_price ? res.import_fee_unit_price : res.import_fee,
          });
        }
        */
        if (res.other_fee != null && res.other_fee > 0) {
          const productRes = await getProductById(5);
          products.push({
            product: productRes,
            device_type_code: productRes.device_type_code,
            product_id: 5,
            product_quantity: 1,
            purchase_unit_price: res.other_fee,
          });
        }
        if (res.total_body_cell_price != null && res.total_body_cell_price > 0) {
          const productRes = await getProductById(6);
          products.push({
            product: productRes,
            device_type_code: productRes.device_type_code,
            product_id: 6,
            product_quantity: 1,
            purchase_unit_price: res.total_body_cell_price,
          });
        }
        if (res.export_fee != null) {
          const productRes = await getProductById(8);
          products.push({
            product: productRes,
            device_type_code: productRes.device_type_code,
            product_id: 8,
            product_quantity: 1,
            purchase_unit_price: res.export_fee,
          });
        }
        if (res.deliveries) {
          let amountFee = 0;
          let amountFeeExtra = 0;
          res.deliveries.forEach((delivery: any) => {
            amountFee = amountFee + delivery.amount_fee;
            amountFeeExtra = amountFeeExtra + delivery.amount_fee_extra;
          });
          let productRes = await getProductById(9);
          products.push({
            product: { ...productRes },
            device_type_code: productRes.device_type_code,
            product_id: 9,
            product_quantity: 1,
            purchase_unit_price: amountFee,
          });
          productRes = await getProductById(10);
          if (amountFeeExtra > 0) {
            products.push({
              product: { ...productRes },
              device_type_code: productRes.device_type_code,
              product_id: 10,
              product_quantity: 1,
              purchase_unit_price: amountFeeExtra,
            });
          }
        }
        if (res.amount_fee != null && res.amount_fee_extra != null) {
          let productRes = await getProductById(9);
          products.push({
            product: { ...productRes },
            device_type_code: productRes.device_type_code,
            product_id: 9,
            product_quantity: 1,
            purchase_unit_price: res.amount_fee,
          });
          if (res.amount_fee_extra > 0) {
            productRes = await getProductById(10);
            products.push({
              product: { ...productRes },
              device_type_code: productRes.device_type_code,
              product_id: 10,
              product_quantity: 1,
              purchase_unit_price: res.amount_fee_extra,
            });
          }
        }

        const initial: { [key: string]: any } = { sale_details: products };
        if (target.targetType === 'IMPORT') {
          // creating new sale from import will require a bunch of import's info
          // to fill into sale.
          createInitialSaleFromImport(initial, res);
        } else if (target.targetType === 'EXPORT') {
          createInitialSaleFromExport(initial, res);
        } else if (target.targetType === 'DELIVERY') {
          initial.input_date = moment(new Date()).format('YYYY/MM/DD');
          initial.sale_date = res.delivery_date;
          initial.aggregation_date = res.delivery_date;
          initial.user_input_id = sessionStore.profile?.id;
          initial.customer_id = res.customer_id;
        }

        setInitialValues(initial);
      } catch (err: any) {
        uiStore.showAlertBox({
          title: 'エラー',
          content: err.message,
          onClose: () => navigate(-1),
        });
      } finally {
        uiStore.hideLoading();
      }
    }
  };

  const createInitialSaleFromImport = (initial: any, importInfo: Import) => {
    initial.input_date = moment(new Date()).format('YYYY/MM/DD');
    initial.sale_type = importInfo.deliveries.length > 0 ? 4 : 3;
    initial.customer_id = importInfo.customer_id;
    initial.user_input_id = importInfo.user_input_id;
    initial.import = {
      complete: importInfo.complete,
      id: importInfo.id,
      import_code: importInfo.import_code,
      import_date: importInfo.import_date,
      import_fee: importInfo.import_fee,
      import_type: importInfo.import_type,
      input_date: importInfo.input_date,
      labor_cost: importInfo.labor_cost,
      pickup_fee: importInfo.pickup_fee,
      stock_register: importInfo.stock_register,
    };
  };

  const createInitialSaleFromExport = (initial: any, exportInfo: Export) => {
    initial.input_date = moment(new Date()).format('YYYY/MM/DD');
    initial.sale_type = exportInfo.deliveries.length > 0 ? 4 : 3;
    initial.user_input_id = exportInfo.user_input_id;
    initial.customer_id = exportInfo.customer_id;

    // only add these additional sale_details data if this is the first sale
    // created from this export
    if (exportInfo.sales.length > 0) {
      initial.sale_details = [];
    }
  };

  useEffect(() => {
    getTargetDetails();
  }, [target]);

  return (
    <div id="sale-details-screen">
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/home' }}>
          TOP
        </Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/sales' }}>
          売上
        </Breadcrumb.Item>
        <Breadcrumb.Item active>売上詳細</Breadcrumb.Item>
      </Breadcrumb>

      {loaded && (
        <SaleDetailsContainer
          initialValues={initialValues}
          purchaseId={purchaseId}
          target={target}
        />
      )}
      <div id="dynamic-dropdown-menu-portal"></div>
    </div>
  );
};

export default CreateSaleScreen;
