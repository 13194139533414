// Built-ins
import { Control, Controller, useWatch } from 'react-hook-form';
import moment from 'moment';

// Components
import FilterItem from '../filter-item';
import ReactDatePickerWrapper from '../datepicker-wrapper';

// Styles
import styles from './styles.module.scss';
import './styles.scss';

interface IProps {
  label: string;
  keys: [string, string];
  control: Control<any, any>;
}

const TimeRangeFilter = ({ label, keys, control }: IProps) => {
  const from = useWatch({ control, name: keys[0] });
  const to = useWatch({ control, name: keys[1] });
  return (
    <div className={styles.container}>
      <FilterItem label={label} />
      <div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
        <Controller
          name={keys[0]}
          control={control}
          render={({ field }) => (
            <ReactDatePickerWrapper
              isClearable
              selected={
                ['', undefined, null].includes(from) ? null : moment(from, 'YYYY-MM-DD').toDate()
              }
              onChange={(date) => field.onChange(date ? moment(date).format('YYYY-MM-DD') : null)}
              className={styles.datePicker}
              dateFormat="yyyy/MM/dd"
              maxDate={
                ['', undefined, null].includes(to) ? undefined : moment(to, 'YYYY-MM-DD').toDate()
              }
            />
          )}
        />
        <Controller
          name={keys[1]}
          control={control}
          render={({ field }) => (
            <ReactDatePickerWrapper
              isClearable
              selected={
                ['', undefined, null].includes(to) ? null : moment(to, 'YYYY-MM-DD').toDate()
              }
              onChange={(date) => field.onChange(date ? moment(date).format('YYYY-MM-DD') : null)}
              className={styles.datePicker}
              dateFormat="yyyy/MM/dd"
              minDate={
                ['', undefined, null].includes(from) ? undefined : moment(from, 'YYYY-MM-DD').toDate()
              }
            />
          )}
        />
      </div>
    </div>
  );
};

export default TimeRangeFilter;
