// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_container__5qhHP {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background-color: #008060;\n  color: #fff;\n  font-weight: bold;\n  font-size: 1rem;\n  min-width: 75px;\n  height: 30px;\n}\n.styles_container__5qhHP svg {\n  height: 1.5rem;\n  width: 1.5rem;\n  color: #fff;\n}\n\n@media all and (min-width: 1600px) {\n  .styles_container__5qhHP {\n    min-width: 85px;\n  }\n}\n@media all and (min-width: 1920px) {\n  .styles_container__5qhHP {\n    min-width: 100px;\n  }\n}", "",{"version":3,"sources":["webpack://./src/shared/components/filter-item/styles.module.scss","webpack://./src/assets/styles/_variables.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,yBCNa;EDOb,WCEW;EDDX,iBAAA;EACA,eAAA;EACA,eAAA;EACA,YAAA;AADF;AAGE;EACE,cAAA;EACA,aAAA;EACA,WCPS;ADMb;;AAKA;EACE;IACE,eAAA;EAFF;AACF;AAKA;EACE;IACE,gBAAA;EAHF;AACF","sourcesContent":["@import '../../../assets/styles/variables';\n\n.container {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background-color: $primaryColor;\n  color: $whiteColor;\n  font-weight: bold;\n  font-size: 1rem;\n  min-width: 75px;\n  height: 30px;\n\n  svg {\n    height: 1.5rem;\n    width: 1.5rem;\n    color: $whiteColor;\n  }\n}\n\n@media all and (min-width: 1600px) {\n  .container {\n    min-width: 85px;\n  }\n}\n\n@media all and (min-width: 1920px) {\n  .container {\n    min-width: 100px;\n  }\n}\n","$primaryColor: #008060;\n$lightGreenColor: #d2ecd0;\n$darkGreenColor: #02644c;\n$lightMagentaColor: #d0cee2;\n$grayColor: #444;\n$lightBlueColor: #d3d8de;\n$lightGrayColor: lightgray;\n$deleteButtonColor: #e55451;\n\n$whiteColor: #fff;\n$blackColor: #000;\n$blueColor: #0d6efd;\n\n$importColColor: #ffff88;\n$exportColColor: #cce5ff;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "styles_container__5qhHP"
};
export default ___CSS_LOADER_EXPORT___;
