export async function sleep(delay: number) {
  return new Promise(function (resolve) {
    setTimeout(resolve, delay);
  });
}

export function makeUuid(length: number) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export const convertFileToBase64 = (file: File, callback: (result: any) => void) => {
  let reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    callback(reader.result);
  };
  reader.onerror = function (error) {
    console.log('Error: ', error);
  };
};

export const getDirtyObject = (dirtyFields: { [x: string]: any }, values: { [x: string]: any }) => {
  let temp: any = {};
  for (let key of Object.keys(values)) {
    if (dirtyFields[key]) {
      if (values[key] === undefined) {
        temp[key] = null;
      } else {
        temp[key] = values[key];
      }
    }
  }
  return temp;
};

export const removeKeys = (obj: { [key: string]: any }, keys: Array<string>) => {
  keys.forEach((key) => {
    delete obj[key];
  });
};

export const openInNewTab = (url: string) => {
  window.open(url, '_blank', 'noreferrer');
};