// Built-in
import { useEffect, useState } from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { MONTHS, currentMonthOption, currentYearOption, YEARS } from 'consts';
import { observer } from 'mobx-react';

// Component
import YearMonthSelect from 'shared/components/year-month-select';
// Store
import UIStore from 'stores/ui';

// Service
import { exportForm } from 'services/export-form';
import useStores from 'hooks/use-stores';

// Style
import styles from './styles.module.scss';
import SessionStore from 'stores/session';

const FormReportScreen = observer(() => {
  const navigate = useNavigate();
  const uiStore: UIStore = useStores().uiStore;
  const sessionStore: SessionStore = useStores().sessionStore;
  const [month, setMonth] = useState<{ [key: string]: any }>(currentMonthOption);
  const [year, setYear] = useState<{ [key: string]: any }>(currentYearOption);

  const onNavigate = (dest: string) => {
    const date = { month: month.value, year: year.value };
    const query = new URLSearchParams(date).toString();

    navigate(`/${dest}?${query}`);
  };

  const exportPurchaseSaleDetailToExcel = async () => {
    try {
      uiStore.showLoading();
      const payload = {
        year: year.value,
        month: month.value,
        form_type: 'R2201',
      };
      await exportForm(`reports/export`, payload);
    } catch (error: any) {
      uiStore.showAlertBox({
        title: 'エラー',
        type: 'error',
        content: error.message,
      });
    } finally {
      uiStore.hideLoading();
    }
  };

  const onMonthChange = (newValue: any) => {
    setMonth(newValue);
    sessionStore.setFormReportDate({
      month: newValue.value,
      year: year.value,
    });
  };

  const onYearChange = (newValue: any) => {
    setYear(newValue);
    sessionStore.setFormReportDate({
      month: month.value,
      year: newValue.value,
    });
  };

  useEffect(() => {
    if (sessionStore.formReportDate === null) {
      setMonth(currentMonthOption);
      setYear(currentYearOption);
    } else {
      const monthOption = {
        label: sessionStore.formReportDate.month,
        value: sessionStore.formReportDate.month,
      };
      const yearOption = {
        label: sessionStore.formReportDate.year,
        value: sessionStore.formReportDate.year,
      };
      setMonth(monthOption);
      setYear(yearOption);
    }
  }, []);

  return (
    <div className={`${styles.container} list-screen`}>
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/home' }}>
          TOP
        </Breadcrumb.Item>
        <Breadcrumb.Item active>集計</Breadcrumb.Item>
        <Breadcrumb.Item active>帳票出力</Breadcrumb.Item>
      </Breadcrumb>

      <div className={styles.selectWrapper}>
        <YearMonthSelect
          yearOptions={YEARS}
          monthOptions={MONTHS}
          yearValue={year}
          monthValue={month}
          dropdownStyle={styles.dropdown}
          label="出力年月"
          maxMenuHeight={{ month: 500 }}
          onMonthChange={onMonthChange}
          onYearChange={onYearChange}
        />
      </div>

      <div className={styles.btnContainer}>
        <button
          aria-label="azukari-report"
          className="action-btn"
          onClick={() => onNavigate('azukari-report')}
        >
          預かり報告書
        </button>
        <button
          aria-label="purchase-sale-detail"
          className="action-btn"
          onClick={exportPurchaseSaleDetailToExcel}
        >
          買取売上明細
        </button>
        <button aria-label="invoice" className="action-btn" onClick={() => onNavigate('invoice')}>
          請求書
        </button>
        <button aria-label="payment-slip" className="action-btn" onClick={() => onNavigate('payment-slip')}>
          支払伝票
        </button>
      </div>
    </div>
  );
});

export default FormReportScreen;
