import { CustomerGroup } from './customer-group';

export type Customer = {
  id: number;
  customer_code: string;
  customer_name: string;
  customer_kana: string;
  customer_note?: string;
  customer_memo?: string;
  hall_name: string;
  hall_kana: string;
  groups: Array<CustomerGroup>;
  address?: string;
  billing_id?: number;
  billing_by?: any;
  deadline_day: number;
  fax?: string;
  pay_info?: string;
  pic_name?: string;
  postal_code?: string;
  tel?: string;
  status: 0 | 1;
};

export interface ICustomerFilter {
  customer_code: string | null;
  group_id: number | null;
  customer_name_kana: string | null;
  customer_kana_row: string | null;
  customer_hall_name_kana: string | null;
  customer_hall_kana_row: string | null;
  status: 0 | 1 | null;
  sort_type: 'asc' | 'desc';
  sort: string | null;
}

// used in group master only
export type GroupCustomer = Omit<Customer, 'id'> & { id: number | string | undefined; key: string };

export const DEFAULT_CUSTOMER_FILTER: ICustomerFilter = {
  customer_code: null,
  group_id: null,
  customer_kana_row: null,
  customer_hall_kana_row: null,
  customer_hall_name_kana: null,
  customer_name_kana: null,
  status: null,
  sort: 'customer_code',
  sort_type: 'asc',
};
