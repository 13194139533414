import { useState, useEffect, useMemo, useRef } from 'react';
import { Form } from 'react-bootstrap';
import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/ai';
import { BsPlusCircle } from 'react-icons/bs';
import { IoCloseCircleOutline } from 'react-icons/io5';
import { Control, UseFormSetValue, useFormState, useWatch } from 'react-hook-form';
import { useQuery } from 'react-query';
import { v1 } from 'uuid';

import ProductSelectModal from '../product-select-modal';
import DetailTable from 'shared/components/detail-table';
import ValidatingInput from 'shared/components/validating-text-input';
import CustomerSelectModal from '../customer-select-modal';
import { Product } from 'types/product';
import { SaleDetail } from 'types/sale';
import useEditableTable from 'hooks/use-editable-table';
import { getTaxByDate } from 'services/consumption-tax';
import { getProductById } from 'services/product';
import { getCustomerByCode, getCustomerDetails } from 'services/customer';
import { DropdownDataProvider } from 'shared/data-providers/dropdown-data-provider';
import DynamicDropdown from 'shared/components/dynamic-dropdown';
import CustomerTooltip from 'shared/components/customer-tooltip';
import InputWrapper from 'shared/components/input-wrapper';

import styles from './styles.module.scss';

interface IProps {
  initialRows: Array<SaleDetail>;
  control: Control<any>;
  setValue: UseFormSetValue<any>;
  billingBy?: any;
}

const SaleDetailsTable = ({ initialRows, control, setValue, billingBy }: IProps) => {
  const [productModal, setProductModal] = useState(false);
  const [customerModal, setCustomerModal] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const [productCount, setProductCount] = useState(0);
  const [price, setPrice] = useState(0);
  const [taxRate, setTaxRate] = useState(0);

  const { rows, setRows, swap, removeRow, updateRow, changeSource, rowsRef } = useEditableTable(initialRows);

  const completed = useWatch({ control, name: 'complete' });
  const customerId = useWatch({ control, name: 'customer_id' });
  const customer = useWatch({ control, name: 'customer' });
  const saleDate = useWatch({ control, name: 'sale_date' });
  const { dirtyFields } = useFormState({ control });

  const timeoutRef = useRef<any>();

  useQuery(['tax', saleDate], () => getTaxByDate(saleDate), {
    enabled: !!saleDate,
    onSuccess: (data) => setTaxRate(data.tax_rate),
    onError: () => setTaxRate(0),
  });

  const deviceTypeDataProvider = useRef(
    new DropdownDataProvider(
      'device-types/dropdown',
      (item) => ({
        label: item.device_type_code + "　　" + item.device_type_name,
        value: item.device_type_code,
      }),
      { type: 1 }
    )
  ).current;

  const renderHeaders = useMemo(() => {
    const setAllRowsCustomerId = async () => {
      if (!customerId) return;
      changeSource.current = 'in';
      try {
        const customer = await getCustomerDetails(customerId);
        const newRows = rows.map((row) => {
          const newRow: SaleDetail = JSON.parse(JSON.stringify(row));
          return {
            ...newRow,
            customer_id: billingBy ? billingBy.id : customerId,
            customer: billingBy ? billingBy : customer,
          };
        });
        setRows(newRows);
      } catch (err: any) {}
    };

    const AddNewRow = (
      <button
        onClick={() =>
          {
            setRows([
              ...rows,
              {
                id: v1(),
                customer_id: billingBy ? billingBy.id : customerId,
                customer: billingBy ? billingBy : customer,
                tax_rate: taxRate,
              },
            ])

            rowsRef.current = [...rowsRef.current, {
              id: v1(),
              customer_id: billingBy ? billingBy.id : customerId,
              customer: billingBy ? billingBy : customer,
              tax_rate: taxRate,
            }]
            changeSource.current = 'in';
          }
        }
        disabled={completed}
      >
        <BsPlusCircle size={20} style={{ color: 'white' }} />
      </button>
    );

    const CustomerIdBtn = (
      <div className={styles.customerIdHeader}>
        <p>明細顧客</p>
        <button className="secondary-btn" onClick={setAllRowsCustomerId}>
          更新
        </button>
      </div>
    );

    const headers = [
      { label: '' },
      { label: '種別' },
      { label: '商品CD' },
      { label: '' },
      { label: 'メーカー' },
      { label: '品名' },
      { label: 'スペック' },
      { label: '数量' },
      { label: '参照基準' },
      { label: '単価' },
      { label: '合計' },
      { label: '税込' },
      { label: '備考' },
      { label: CustomerIdBtn },
      { label: AddNewRow },
    ];

    return headers;
  }, [completed, rows, setRows, customerId, customer]);

  const renderRow = (item: SaleDetail, index: number) => {
    const onCustomerChange = async (code: string) => {
      let payload: any = {};
      try {
        const customer = await getCustomerByCode(code);
        payload = {
          customer_id: customer.id,
          customer,
        };
        updateRow(index, payload);
      } catch (err: any) {
        payload = {
          customer_id: undefined,
          customer: undefined,
        };
      } finally {
        updateRow(index, payload);
      }
    };
    const onDeviceTypeChange = (newValue: any) => {
      updateRow(index, {
        device_type_code: newValue ? newValue.toString() : undefined,
      });
    };
    const openProductModal = () => {
      setSelectedIndex(index);
      setProductModal(true);
    };
    const onProductIdChange = async (value: string, showError: (valid: boolean) => void) => {
      let payload: any = {};
      let hasError = false;
      if (value === '') {
        payload.product_id = undefined;
        payload.product = undefined;
      } else {
        try {
          const res = await getProductById(parseInt(value));
          payload.product_id = res.id;
          payload.device_type_code = res.device_type_code;
          payload.product = res;
        } catch (err) {
          payload.product_id = parseInt(value);
          payload.product = undefined;
          hasError = true;
        }
      }
      updateRow(index, payload);
      showError(hasError);
    };
    const onQuantityChange = (value: any) => {
      updateRow(index, { product_quantity: value });
    };
    const onUnitPriceChange = (value: any) => {
      updateRow(index, { purchase_unit_price: value });
    };

    /********** Rows **********/
    const isVAT = item.product_id === 1;
    const upDownArrow = !completed && (
      <div className="d-flex flex-column align-items-center justify-content-center">
        <AiFillCaretUp className="arrow-icon" onClick={() => swap(index, 'up')} />
        <AiFillCaretDown className="arrow-icon" onClick={() => swap(index, 'down')} />
      </div>
    );
    const deviceTypeCode = (
      <div>
        <DynamicDropdown
          dataProvider={deviceTypeDataProvider}
          value={item.device_type_code}
          onChange={onDeviceTypeChange}
          isClearable={false}
        />
      </div>
    );
    const productId = (
      <ValidatingInput
        allowFullWidth
        type="number"
        value={item.product_id?.toString()}
        validate={onProductIdChange}
        disabled={completed}
      />
    );
    const productModalBtn = (
      <button onClick={openProductModal} disabled={completed}>
        参
      </button>
    );
    const makerName = item.product?.maker?.maker_name;
    const productName = item.product?.product_name;
    const spec = item.product?.spec;
    const productQuantity = (
      <InputWrapper
        allowFullWidth
        type="number"
        isFloat
        defaultValue={item.product_quantity}
        onChange={onQuantityChange}
        disabled={completed || isVAT}
      />
    );
    const baseUnitPrice = (
      (item.product?.lcd1?.base_unit_price || 0) +
      (item.product?.lcd2?.base_unit_price || 0) +
      (item.product?.lcd3?.base_unit_price || 0)
    ).toLocaleString('en-US');
    const purchaseUnitPrice = (
      <InputWrapper
        allowFullWidth
        defaultValue={item.purchase_unit_price}
        type="number"
        isFloat
        onChange={onUnitPriceChange}
        disabled={completed || isVAT}
      />
    );
    const totalPrice = Math.round(
      (item.purchase_unit_price || 0) * (item.product_quantity || 0)
    ).toLocaleString('en-US');
    const taxIncluded = (
      <Form.Check
        className="custom-checkbox"
        disabled={completed || item.device_type_code === 'ZZX'}
        checked={!!item.tax_included}
        onChange={(event: any) => updateRow(index, { tax_included: event.target.checked ? 1 : 0 })}
      />
    );
    const detailNote = (
      <InputWrapper
        defaultValue={item.sale_detail_note || ''}
        onChange={(value: any) => {
          updateRow(index, { sale_detail_note: value });
        }}
        readOnly={completed}
      />
    );
    const customerCodeInput = (
      <div className={styles.customerId}>
        <CustomerTooltip
          onUpdate={onCustomerChange}
          customer={item.customer}
          disabled={completed}
        />

        {!completed && (
          <button
            onClick={() => {
              setSelectedIndex(index);
              setCustomerModal(true);
            }}
          >
            参
          </button>
        )}
      </div>
    );
    const removeIcon = !completed && (
      <IoCloseCircleOutline size={25} className="delete-icon" onClick={() => removeRow(index)} />
    );

    const arr = [
      upDownArrow,
      deviceTypeCode,
      productId,
      productModalBtn,
      makerName,
      productName,
      spec,
      productQuantity,
      baseUnitPrice,
      purchaseUnitPrice,
      totalPrice,
      taxIncluded,
      detailNote,
      customerCodeInput,
      removeIcon,
    ];

    return arr;
  };

  const calculateVAT = async () => {
    changeSource.current = 'in';

    const hasVat = rows.some((row) => row.product_id === 1);
    const tempRows = JSON.parse(JSON.stringify(rows));
    const rowsWithoutVAT = tempRows.filter((row: SaleDetail) => row.product_id !== 1);
    const totalTax = rowsWithoutVAT.reduce((acc: number, row: SaleDetail) => {
      let tax = 0;
      if (!row.tax_included) {
        let originalPrice = (row.product_quantity || 0) * (row.purchase_unit_price || 0);
        tax = (originalPrice / 100) * taxRate;
      }
      return acc + tax;
    }, 0);

    if (hasVat) {
      tempRows.forEach((row: SaleDetail) => {
        if (row.product_id === 1) {
          row.product_quantity = 1;
          row.purchase_unit_price = Math.round(totalTax);
          row.tax_included = 1;
          row.tax_rate = taxRate;
          if (!row.customer) {
            row.customer_id = billingBy ? billingBy.id: customerId;
            row.customer = billingBy ? billingBy: customer;
          }
        }
      });
      setRows(tempRows);
    } else {
      const product: Product = await getProductById(1);

      const saleDetailVAT = {
        id: v1(),
        device_type_code: product.device_type_code,
        product_id: product.id,
        product_quantity: 1,
        purchase_unit_price: Math.round(totalTax),
        customer_id: billingBy ? billingBy.id: customerId,
        customer: billingBy ? billingBy: customer,
        tax_included: 1,
        tax_rate: taxRate,
        product: product,
      };
      setRows([...tempRows, saleDetailVAT]);
    }
  };

  const renderTotalRow = () => (
    <>
      <td colSpan={7} align="right">
        合計
      </td>
      <td style={{ textAlign: 'right', width: '4em', maxWidth: '4em', padding: '0 0.5em' }}>
        {productCount}
      </td>
      <td style={{ width: '4em', maxWidth: '4em' }}></td>
      <td style={{ width: '4em', maxWidth: '4em' }}></td>
      <td colSpan={5} style={{ textAlign: 'left', padding: '0 0.5em' }}>
        {price.toLocaleString('en-US')}
      </td>
    </>
  );

  useEffect(() => {
    if (changeSource.current === 'in') setValue('sale_details', rows, { shouldDirty: true });

    let count = 0;
    let price = 0;

    timeoutRef.current = setTimeout(() => {
      for (let i = 0; i < rows.length; i++) {
        const row = rows[i];

        if (row.product?.count_target === 1) {
          count += row.product_quantity || 0;
        }
        price += Math.round((row.purchase_unit_price || 0) * (row.product_quantity || 0));
      }

      setProductCount(count);
      setPrice(price);
      timeoutRef.current = null;
    }, 500);
  }, [rows]);

  useEffect(() => {
    const temp: any = [];
    rows.forEach((row) => {
      temp.push({ ...row, tax_rate: taxRate });
    });
    if (dirtyFields.sale_date) {
      changeSource.current = 'in';
      setRows(temp);
    }
  }, [taxRate]);

  return (
    <div>
      {!completed && (
        <div className={styles.calculateBtnContainer}>
          <button className="secondary-btn" disabled={!saleDate} onClick={calculateVAT}>
            計算
          </button>
        </div>
      )}

      <div className={`${styles.detailTableWrapper} sale-table__override`}>
        <DetailTable
          headers={renderHeaders}
          data={rows}
          renderRow={renderRow}
          renderTotalRow={renderTotalRow}
        />
      </div>

      {productModal && (
        <ProductSelectModal
          onClose={() => setProductModal(false)}
          onSelect={(product) => {
            const alreadyIncludedVAT = rows.some((row) => row.product_id === 1);
            if (product.id === 1 && alreadyIncludedVAT) return;
            const data = {
              product_id: product.id,
              product_quantity: 0,
              purchase_unit_price: 0,
              device_type_code: product.device_type_code,
              product: product as Product,
              sale_detail_note: '',
              tax_included: product.id === 1 ? 1 : 0,
            };

            updateRow(selectedIndex as number, data);
            setProductModal(false);
          }}
        />
      )}

      {customerModal && (
        <CustomerSelectModal
          onClose={() => setCustomerModal(false)}
          onSelect={(customer) => {
            updateRow(selectedIndex as number, { customer_id: customer.id, customer });
            setCustomerModal(false);
          }}
        />
      )}
    </div>
  );
};

export default SaleDetailsTable;
