import { del, get, post, postFormData, put } from 'services';
import { Import } from 'types/import';

export const getImportById = (id: number) => get(`imports/${id}`, {});

export const createImport = (data: Partial<Import>, purchaseId?: number) =>
  post(`imports${purchaseId ? `/${purchaseId}` : ''}`, data);

export const updateImport = (id: number, data: Partial<Import>) => put(`imports/${id}`, data);

export const completeImport = (id: number) => post(`imports/completion/${id}`, {});

export const revertImportCompletion = (id: number) => post(`imports/revert/${id}`, {});

export const deleteImport = (id: number) => del(`imports/${id}`);

export const uploadQRFile = (id: number, file: File) => {
  const formData = new FormData();
  formData.append('file_excel', file);
  return postFormData(`imports/qr/${id}`, formData);
};

export const getImportDetailsFromExcel = (file: File) => {
  const formData = new FormData();
  formData.append('file_excel', file);

  return postFormData(`imports/import-details/from-file`, formData);
};

export const rematchQRFile = (id: number, importProducts: Array<any>, qrProducts: Array<any>) =>
  put(`imports/qr/re-check/${id}`, {
    import_details: importProducts,
    import_qr_details: qrProducts,
  });

export const updateMatchingData = (
  id: number,
  matchedRows: Array<any>,
  unmatchedRows: Array<any>
) => put(`imports/qr/${id}`, { import_details: matchedRows, import_qr_details: unmatchedRows });

export const countProductImportDetails = (id: number | string) =>
  get(`imports/count/device-type/${id}`, {});

export const createStocksFromImport = (id: number) => post(`imports/regist/${id}`, {});

export const checkImportCode = (code: string) => get(`imports/target-check/${code}`, {});

export const getImportDetailsGroupByProduct = (id: number) => get(`imports/import-details/group-by-product/${id}`, {});
