// Built-in
import { Breadcrumb } from 'react-bootstrap';
import { useParams, Link } from 'react-router-dom';

// Component
import QuotationDetailContainer from 'shared/containers/quotation-details';

const QuotationDetailScreen = () => {
  const params = useParams();
  const id = params.id ? parseInt(params.id) : undefined;

  return (
    <div id="quotation-details-screen">
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/home' }}>
          TOP
        </Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/quotations' }}>
          見積
        </Breadcrumb.Item>
        <Breadcrumb.Item active>見積詳細</Breadcrumb.Item>
      </Breadcrumb>
      <QuotationDetailContainer id={id} />
      <div id="dynamic-dropdown-menu-portal"></div>
    </div>
  );
};

export default QuotationDetailScreen;
