import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { Modal, Table } from 'react-bootstrap';
import { AiOutlineClose } from 'react-icons/ai';
import { BsPlusCircle } from 'react-icons/bs';
import { IoCloseCircleOutline } from 'react-icons/io5';
import { NumericFormat } from 'react-number-format';
import { useQuery } from 'react-query';
import Select from 'react-select';
import { v4 } from 'uuid';

import useStores from 'hooks/use-stores';
import { getCustomerByCode } from 'services/customer';
import { getDepositInfo } from 'services/deposit';
import ReactDatePickerWrapper from 'shared/components/datepicker-wrapper';
import CustomerSelectModal from 'shared/containers/customer-select-modal';
import UIStore from 'stores/ui';
import { Deposit, depositTypes } from 'types/deposit';
import { popupConfirmBack, popupConfirmDelete } from 'utils/modals';

import styles from './styles.module.scss';

interface IProps {
  date: {
    year: number;
    month: number;
  };
  onClose: any;
  onUpdate: (payload: any) => void;
  completed: boolean;
}

const EditAllModal = ({ date, onClose, onUpdate, completed }: IProps) => {
  const uiStore: UIStore = useStores().uiStore;

  const [customerCode, setCustomerCode] = useState('');
  const [customerName, setCustomerName] = useState('');
  const [customerId, setCustomerId] = useState<number>();
  const [customerModal, setCustomerModal] = useState(false);
  const [depositList, setDepositList] = useState<Array<Partial<Deposit>>>([]);

  const customerQuery = useQuery(
    ['customer', customerCode],
    () => getCustomerByCode(customerCode),
    {
      enabled: customerCode.length > 0,
      onSuccess: (res) => {
        setCustomerName(res.customer_name);
        setCustomerId(res.id);
      },
      onError: () => {
        setCustomerName('');
        setCustomerId(undefined);
      },
    }
  );

  const depositQuery = useQuery(
    ['deposits', date.month, date.year, customerId],
    () => getDepositInfo({ year: date.year, month: date.month, customerId }),
    {
      enabled: !!customerId,
      onSuccess: (res) => {
        setDepositList(res.deposits);
        initialValues.current = res.deposits;
      },
    }
  );

  // useEffect(() => {
  //   setDepositList([]);
  // }, [customerId]);

  const formattedDate =
    date.year + '/' + (`${date.month}`.length === 1 ? `0${date.month}` : `${date.month}`);

  const updateDeposit = (index: number, payload: any) => {
    const newList = [...depositList];
    newList.splice(index, 1, { ...depositList[index], ...payload });

    setDepositList(newList);
  };
  const initialValues = useRef<any>([]);

  const removeDeposit = (index: number) => {
    const newList = [...depositList];
    newList.splice(index, 1);

    setDepositList(newList);
  };

  const onRegisterClick = (_event: any) => {
    let formattedList = depositList.map((withdrawal) => {
      if (typeof withdrawal.id === 'string') {
        return {
          ...withdrawal,
          id: undefined,
        };
      }
      withdrawal.in_amount = withdrawal.in_amount ? withdrawal.in_amount : 0;
      return withdrawal;
    });
    let isMissingDate = formattedList.some((deposit) => !deposit.deposit_date);

    // const depositSum = formattedList.reduce((acc, curr) => {
    //   return acc + (curr.in_amount || 0);
    // }, 0);

    // if (depositSum > depositCustomer.last_request_balance) {
    //   uiStore.showAlertBox({
    //     title: 'エラー',
    //     content: '入金額が前回請求額より高くすることはできません。',
    //   });
    //   return;
    // }

    if (isMissingDate) {
      uiStore.showAlertBox({
        title: 'エラー',
        content: '入金日は、必ず指定してください。',
      });
      return;
    }

    let payload = {
      month: date.month,
      year: date.year,
      customerId: customerId,
      data: {
        deposits: formattedList,
      },
    };
    onUpdate(payload);
  };

  const onBackClick = (_event: any) => {
    let isDirty = JSON.stringify(initialValues.current) !== JSON.stringify(depositList);

    popupConfirmBack(isDirty, onClose);
  };

  const renderCustomerInfoTable = () => (
    <Table bordered className={styles.customerInfoTable}>
      <thead>
        <tr>
          <th>処理年月</th>
          <th>顧客CD</th>
          <th>顧客</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <span>{formattedDate}</span>
          </td>
          <td>
            <input
              value={customerCode}
              onChange={(e) => {
                setCustomerCode(e.target.value);
              }}
            />
          </td>
          <td style={{ textAlign: 'left' }}>
            <p>{customerName}</p>
          </td>
          <td>
            <button onClick={() => setCustomerModal((customerModal) => !customerModal)}>参照</button>
          </td>
        </tr>
      </tbody>
    </Table>
  );

  return (
    <Modal show centered onBackdropClick={onClose} dialogClassName={styles.dialog}>
      <div className={styles.content}>
        <div className={styles.head}>
          <h3>入金登録モーダル</h3>
          <button onClick={onBackClick}>
            <AiOutlineClose />
          </button>
        </div>
        {renderCustomerInfoTable()}
        <Table bordered className={styles.withdrawalTable}>
          <thead>
            <tr>
              <th>入金日</th>
              <th>入金方法</th>
              <th>入金額</th>
              {!completed && (
                <th>
                  <button
                    onClick={(_event: any) => {
                      setDepositList([
                        ...depositList,
                        {
                          deposit_date: '',
                          deposit_type: 1,
                          in_amount: 0,
                          id: v4(),
                        },
                      ]);
                    }}
                  >
                    <BsPlusCircle />
                  </button>
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {depositList.map((deposit, index) => (
              <tr key={deposit.id}>
                <td>
                  <ReactDatePickerWrapper
                    className={styles.dateInput}
                    selected={
                      deposit.deposit_date
                        ? moment(deposit.deposit_date, 'YYYY-MM-DD').toDate()
                        : null
                    }
                    dateFormat="yyyy/MM/dd"
                    onChange={(date) => {
                      updateDeposit(index, {
                        deposit_date: moment(date).format('YYYY-MM-DD'),
                      });
                    }}
                    disabled={completed}
                  />
                </td>
                <td>
                  <Select
                    className={styles.dropdown}
                    value={depositTypes.find((type) => type.value === deposit.deposit_type)}
                    options={depositTypes}
                    isClearable={false}
                    isSearchable={false}
                    onChange={(newValue) => {
                      updateDeposit(index, {
                        deposit_type: newValue.value,
                      });
                    }}
                    isDisabled={completed}
                  />
                </td>
                <td>
                  <NumericFormat
                    type="text"
                    value={deposit.in_amount}
                    decimalScale={0}
                    thousandSeparator=","
                    allowNegative={true}
                    allowLeadingZeros={false}
                    allowedDecimalSeparators={[]}
                    onValueChange={(values, sourceInfo) => {
                      updateDeposit(index, {
                        in_amount: values.floatValue,
                      });
                    }}
                    disabled={completed}
                  />
                </td>
                {!completed && (
                  <td>
                    <div className={styles.deleteBtnWrapper}>
                      <IoCloseCircleOutline
                        size={20}
                        aria-label="remove-row"
                        className="delete-icon"
                        onClick={(_event: any) => popupConfirmDelete(() => removeDeposit(index))}
                      />
                    </div>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </Table>

        {!completed && (
          <button aria-label="register" className="secondary-btn" onClick={onRegisterClick}>
            登録
          </button>
        )}
      </div>
      {customerModal && (
        <CustomerSelectModal
          onClose={() => setCustomerModal(false)}
          onSelect={(item) => {
            setCustomerCode(item.customer_code);
            setCustomerName(item.customer_name);
            setCustomerModal(false);
          }}
        />
      )}
    </Modal>
  );
};

export default EditAllModal;
