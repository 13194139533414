import useStores from 'hooks/use-stores';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import { MdClose } from 'react-icons/md';
import { useQuery } from 'react-query';

import { getCustomerDetails } from 'services/customer';
import UIStore from 'stores/ui';
import { Customer } from 'types/customer';
import { popupConfirmBack } from 'utils/modals';
import EditForm from './form';

interface IProps {
  customerId?: number;
  onClose: () => void;
  onCreate: (data: any) => void;
  onSave: (data: any) => void;
  onDelete: () => void;
}

const EditModal = observer(({ customerId, onClose, onCreate, onSave, onDelete }: IProps) => {
  const [isDirty, setIsDirty] = useState<boolean | null>(null);
  const { data, isLoading, isFetching } = useQuery<Customer>(
    ['customer-details', customerId],
    () => getCustomerDetails(customerId as number),
    {
      keepPreviousData: true,
      enabled: !!customerId,
    }
  );
  const uiStore: UIStore = useStores().uiStore;
  const modalTitle = !customerId ? '顧客作成モーダル' : '顧客編集モーダル';

  return (
    <Modal show backdrop="static" centered size="lg">
      <div className="edit-modal">
        <div className="edit-modal--title">
          {modalTitle}
          <button
            onClick={() => {
              popupConfirmBack(!!isDirty, () => onClose());
            }}
          >
            <MdClose />
          </button>
        </div>

        {isLoading || isFetching ? (
          <div className="text-center">
            <Spinner animation="border" />
          </div>
        ) : (
          <>
            <EditForm
              initialValues={data}
              onCreate={onCreate}
              onSave={onSave}
              onDelete={onDelete}
              setIsDirty={setIsDirty}
            />
          </>
        )}
      </div>
      {isDirty && uiStore.confirmBox.visible && <div className="overlappedBackdrop"></div>}
    </Modal>
  );
});

export default EditModal;
