import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import styles from './styles.module.scss';

interface IProps {
  title?: string;
  options: Array<{
    label: string;
    value: any;
  }>;
  actions?: Array<{
    label: string | number;
    callback: any;
  }>;
  radioGroupStyle?: any;
}

const PopupPrint = ({ title, options, actions, radioGroupStyle }: IProps) => {
  const [printOption, setPrintOption] = useState<string | number>(options[0].value);

  return (
    <Modal centered backdrop show dialogClassName={styles.dialog}>
      <div className={styles.content}>
        {title && <h2>{title}</h2>}
        <div className={`${radioGroupStyle ? radioGroupStyle : styles.optionsContainer}`}>
          {options.map((option) => (
            <div className={styles.check}>
              <label htmlFor={option.label}>
                {option.label}
                <input
                  id={option.label}
                  type="radio"
                  name="group"
                  value={option.value}
                  onChange={(event) => {
                    setPrintOption(event.target.value);
                  }}
                  defaultChecked={option.value === printOption}
                />
                <span></span>
              </label>
            </div>
          ))}
        </div>

        <div className={styles.actions}>
          {actions?.map((action, index) => (
            <button
              className="secondary-btn"
              type="button"
              onClick={(_event: any) => {
                if (index === 0) {
                  action.callback();
                } else {
                  action.callback(printOption);
                }
              }}
            >
              {action.label}
            </button>
          ))}
        </div>
      </div>
    </Modal>
  );
};

export default PopupPrint;
