import { User } from 'types/user';
import { del, post, put, get } from '.';

export const updateUser = (id: number, data: User) => put(`users/${id}`, data);

export const createUser = (data: User) => post('users', data);

export const deleteUser = (id: number) => del(`users/${id}`);

export const getUserInfo = (id: number) => get(`users/fill/${id}`, {});
