// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_form__aNspD .styles_inputGroup__pFlad {\n  display: flex;\n  border: none;\n  outline: none;\n}\n.styles_form__aNspD .styles_inputGroup__pFlad input {\n  padding: 0 0.5em;\n}\n\n.styles_dropdown__Sqg32 {\n  border: 1px solid lightgray;\n  border-radius: 0.25rem;\n  width: 20vw;\n}\n\n.styles_selectGroup__LNLRD {\n  display: flex;\n  align-items: center;\n  margin: 0.5em 0;\n}\n.styles_selectGroup__LNLRD button {\n  margin-left: 3em;\n}", "",{"version":3,"sources":["webpack://./src/screens/monthly-report-azukari/styles.module.scss"],"names":[],"mappings":"AAGE;EACE,aAAA;EACA,YAAA;EACA,aAAA;AAFJ;AAII;EACE,gBAAA;AAFN;;AAOA;EACE,2BAAA;EACA,sBAAA;EACA,WAAA;AAJF;;AAOA;EACE,aAAA;EACA,mBAAA;EACA,eAAA;AAJF;AAME;EACE,gBAAA;AAJJ","sourcesContent":["@use '../../assets/styles/variables' as *;\n\n.form {\n  .inputGroup {\n    display: flex;\n    border: none;\n    outline: none;\n\n    input {\n      padding: 0 0.5em;\n    }\n  }\n}\n\n.dropdown {\n  border: 1px solid lightgray;\n  border-radius: 0.25rem;\n  width: 20vw;\n}\n\n.selectGroup {\n  display: flex;\n  align-items: center;\n  margin: 0.5em 0;\n\n  button {\n    margin-left: 3em;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "styles_form__aNspD",
	"inputGroup": "styles_inputGroup__pFlad",
	"dropdown": "styles_dropdown__Sqg32",
	"selectGroup": "styles_selectGroup__LNLRD"
};
export default ___CSS_LOADER_EXPORT___;
