import { useCallback, useEffect, useState } from 'react';
import { AsyncPaginate } from 'react-select-async-paginate';
import { get } from 'services';
import FilterItem from 'shared/components/filter-item';
import styles from './styles.module.scss';

interface IProps {
  label?: string;
  targetType: string;
  disabled?: boolean;
  setInfo: (...arg: any[]) => void;
  openTargetDetailScreen: ({ id, target }: { id: number; target: string }) => void;
  onChange: (data: any) => void;
  targetId?: number | null;
  baseDate?: string | null;
  settingBtnDisabled?: boolean;
}

const DropdownSelectOptions = ({
  label,
  targetType,
  setInfo,
  openTargetDetailScreen,
  onChange,
  disabled = false,
  targetId,
  baseDate,
  settingBtnDisabled,
}: IProps) => {
  const target = targetType === 'IMPORT_EXPORT' ? 'import' : targetType.toLowerCase();
  const [data, setData] = useState<any>(undefined);
  const loadOptions = async (_search: string, prevOptions: any) => {
    try {
      if (!baseDate) return { options: [] };
      const response = await get(`${target}s/target/delivery?delivery_date=${baseDate}`, {});
      const options = response.map((item: any) => {
        return {
          label: item[`${target}_code`] + ': ' + item[`${target}_date`].substr( 5, 5 ).replace('-','/') + ': ' + (item.customer?.customer_name? item.customer?.customer_name: '') + ('　'+(item.customer?.hall_name? item.customer?.hall_name: '')),
          value: item,
        };
      });

      return { options };
    } catch (error) {
      return prevOptions;
    }
  };

  const loadingMessage = useCallback(() => <div>Loading...</div>, []);
  const noOptionMessage = useCallback(() => <div>日付を変更してもう一度試してください。</div>, []);

  const onSelect = (event: any) => {
    setData(event);
    onChange(event ? event.value : null); // set target_id
  };

  useEffect(() => {
    const getTarget = async () => {
      try {
        const response = await get(`${target}s/${targetId}`, {});
        setData({
          label: response[`${target}_code`] + ': ' + response[`${target}_date`].substr( 5, 5 ).replace('-','/') + ': ' + (response.customer?.customer_name? response.customer?.customer_name: '') + ('　'+(response.customer?.hall_name? response.customer?.hall_name: '')),
          value: response,
        });
      } catch (error) {}
    };
    if (targetId) getTarget();
  }, []);

  useEffect(() => {
    if (!targetId) setData(null);
  }, [targetId]);

  useEffect(() => {
    setData(null);
  }, [targetType]);

  return (
    <div className={styles.container}>
      {label && <FilterItem label={label} />}
      <AsyncPaginate
        key={targetType + baseDate} // clear cache on state change.
        className={styles.dropdown}
        loadOptions={loadOptions}
        onChange={onSelect}
        isSearchable={false}
        isClearable={true}
        placeholder="選択"
        isDisabled={disabled}
        value={data}
        loadingMessage={loadingMessage}
        noOptionsMessage={noOptionMessage}
      />
      <button
        className="secondary-btn ms-2"
        onClick={(_event: any) => {
          if (data) {
            setInfo(data.value, target);
          } else {
            setInfo(data);
          }
        }}
        disabled={settingBtnDisabled}
      >
        設定
      </button>
      <button
        className="secondary-btn ms-2"
        onClick={(_event: any) => {
          if (data) openTargetDetailScreen({ id: data.value.id, target });
        }}
      >
        詳細
      </button>
    </div>
  );
};

export default DropdownSelectOptions;
