import { ToastContainer } from 'react-toastify';

const ToastWrapper = () => {
  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop
        draggable={false}
        pauseOnHover={false}
      />
    </>
  );
};

export default ToastWrapper;
