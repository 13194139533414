import { useEffect, useState } from 'react';
import { Control, Controller, UseFormSetValue, useWatch } from 'react-hook-form';
import { AsyncPaginate } from 'react-select-async-paginate';
import { observer } from 'mobx-react';

import FilterItem from '../filter-item';
import { IDropdownDataProvider } from 'shared/data-providers/dropdown-data-provider';

import styles from './styles.module.scss';

interface IProps {
  label: string;
  keys: [string, string] | [string];
  control: Control<any, any>;
  dataProvider: IDropdownDataProvider<{ label: string; value: string | number }>;
  setValue?: UseFormSetValue<any>;
  isClearable?: boolean;
  fallbackValue?: { label: string; value: string | number };
  required?: boolean;
  placeholder?: string;
  disabled?: boolean;
  effect?: () => any;
}

const DynamicDropdownFilter = observer(
  ({
    label,
    keys,
    control,
    dataProvider,
    setValue,
    isClearable = true,
    fallbackValue,
    required = false,
    placeholder = '選択',
    disabled = false,
    effect,
  }: IProps) => {
    const [option, setOption] = useState<any>(null);

    const watcher = useWatch({ control, name: keys[0] });
    useEffect(() => {
      if (watcher) {
        const filtered = dataProvider.data.filter((item: any) => item.value === watcher);
        setOption(filtered.length > 0 ? filtered[0] : fallbackValue);
      } else setOption(null);
    }, [watcher, dataProvider.data]);

    return (
      <div className={`d-flex align-items-center w-100 ${styles.container}`}>
        <FilterItem label={label} required={required} />
        <Controller
          name={keys[0]}
          control={control}
          rules={{ required }}
          render={({ field: { onChange, onBlur } }) => (
            <AsyncPaginate
              className={styles.dropdown}
              loadingMessage={() => <div>Loading</div>}
              noOptionsMessage={() => 'オプションなし'}
              isSearchable={false}
              isClearable={isClearable}
              placeholder={placeholder}
              defaultOptions
              isDisabled={disabled}
              loadOptions={() => dataProvider.load()}
              onBlur={onBlur}
              onChange={(newValue: any) => {
                onChange(newValue ? newValue.value : null);
                effect && effect();
              }}
              value={option}
            />
          )}
        />
      </div>
    );
  }
);

export default DynamicDropdownFilter;
