import { useEffect, useRef } from 'react';
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { MdClose } from 'react-icons/md';

import DataTable from 'shared/components/data-table';
import DropdownFilter from 'shared/components/dropdown-filter';
import TextFilter from 'shared/components/text-filter';
import { DataProvider } from 'shared/data-providers/table-data-provider';
import { DropdownDataProvider } from 'shared/data-providers/dropdown-data-provider';
import { Customer } from 'types/customer';
import { KANA_ROWS } from 'consts';

import styles from './styles.module.scss';

const groupDataProvider = new DropdownDataProvider('groups/dropdown', (item) => ({
  label: item.group_name,
  value: item.id,
}));

interface IProps {
  onClose: () => void;
  onSelect: (item: Customer) => void;
  groupId?: number;
}

const CustomerSelectModal = ({ onClose, onSelect, groupId }: IProps) => {
  const { control, handleSubmit, reset } = useForm({ defaultValues: { group_id: groupId } });
  const dataProvider = useRef(
    new DataProvider<any>({
      dataSource: 'customers',
      defaultFilters: { group_id: groupId, status: 1, sort: 'customer_code', sort_type: 'asc' },
    })
  );

  useEffect(() => {
    return () => {
      dataProvider.current.clearFilters();
    };
  }, []);

  return (
    <Modal show centered size="xl" className={styles.modal}>
      <div className="list-screen" style={{ padding: '1.5rem 2.5rem', backgroundColor: '#f8f8fb' }}>
        <div className={styles.title}>
          顧客選択モーダル
          <button className={styles.close} onClick={onClose}>
            <MdClose />
          </button>
        </div>

        <div className={styles.filterContainer}>
          <div className={styles.customerCode}>
            <TextFilter label="顧客CD" control={control} keys={['customer_code']} />
          </div>

          <div className={styles.groupId}>
            <DropdownFilter
              label="グループ"
              control={control}
              keys={['group_id']}
              dataProvider={groupDataProvider}
            />
          </div>

          <div className={styles.buttonGroup}>
            <button
              className="action-btn"
              onClick={handleSubmit((values) =>
                dataProvider.current.setFilters({ ...values, status: 1 })
              )}
            >
              検索
            </button>
            <button
              className="action-btn"
              onClick={() => {
                reset();
                dataProvider.current.clearFilters();
              }}
            >
              クリア
            </button>
          </div>

          <div className={styles.customerNameKana}>
            <TextFilter label="顧客名・カナ" control={control} keys={['customer_name_kana']} />
          </div>

          <div className={styles.customerHallName}>
            <TextFilter
              label="ホール名・カナ"
              control={control}
              keys={['customer_hall_name_kana']}
            />
          </div>

          <div className={styles.customerKanaRow}>
            <DropdownFilter
              label="顧客カナ"
              control={control}
              keys={['customer_kana_row']}
              options={KANA_ROWS}
            />
          </div>

          <div className={styles.customerHallKanaRow}>
            <DropdownFilter
              label="ホールカナ"
              control={control}
              keys={['customer_hall_kana_row']}
              options={KANA_ROWS}
            />
          </div>
        </div>

        <DataTable
          dataProvider={dataProvider.current}
          headers={[
            { label: '' },
            { label: '顧客CD', key: 'customer_code' },
            { label: '顧客名', key: 'customer_name' },
            { label: 'ホール名', key: 'hall_name' },
            { label: '締日' },
            { label: '備考' },
            { label: 'メモ' },
          ]}
          delegate={(item) => [
            <button
              className="edit"
              onClick={() => onSelect(item)}
              style={{ width: '3.5em', padding: '0' }}
            >
              選択
            </button>,
            item.customer_code,
            item.customer_name,
            item.hall_name,
            item.deadline_day ? item.deadline_day : '末',
            item.customer_note,
            item.customer_memo,
          ]}
          scrollable={true}
        />
      </div>
    </Modal>
  );
};

export default CustomerSelectModal;
