// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_dropdown__xhQFG {\n  flex: 1 1;\n  border: 1px solid lightgray;\n  border-radius: 0.25rem;\n  min-width: max-content;\n}\n\n.styles_group__ipRn7 {\n  display: flex;\n  align-items: center;\n  margin: 0.5em 0;\n}\n.styles_group__ipRn7 input {\n  width: 85%;\n  margin: 0 0 0 auto;\n}\n.styles_group__ipRn7 textarea {\n  width: 85%;\n  margin: 0 0 0 auto;\n}\n\n.styles_billingInput__FZcat {\n  border-radius: 0.25em;\n}", "",{"version":3,"sources":["webpack://./src/screens/customers/edit-modal/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,SAAA;EACA,2BAAA;EACA,sBAAA;EACA,sBAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,eAAA;AACF;AACE;EACE,UAAA;EACA,kBAAA;AACJ;AAEE;EACE,UAAA;EACA,kBAAA;AAAJ;;AAIA;EACE,qBAAA;AADF","sourcesContent":[".dropdown {\n  flex: 1;\n  border: 1px solid lightgray;\n  border-radius: 0.25rem;\n  min-width: max-content;\n}\n\n.group {\n  display: flex;\n  align-items: center;\n  margin: 0.5em 0;\n\n  input {\n    width: 85%;\n    margin: 0 0 0 auto;\n  }\n\n  textarea {\n    width: 85%;\n    margin: 0 0 0 auto;\n  }\n}\n\n.billingInput {\n  border-radius: 0.25em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropdown": "styles_dropdown__xhQFG",
	"group": "styles_group__ipRn7",
	"billingInput": "styles_billingInput__FZcat"
};
export default ___CSS_LOADER_EXPORT___;
