// Built-in
import { useQueryParams } from 'hooks/use-query-params';
import { useState } from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';

// Component
import ExportDetailsContainer from 'shared/containers/export-details';

// Const
import { EXPORT_TYPE_TEXT } from 'consts';

const ExportDetailScreen = () => {
  const params = useParams();
  const id = params.id ? parseInt(params.id) : undefined;
  const [key, setKey] = useState<number>(0);

  const query = useQueryParams();
  const exportTypeStr = query.get('type');
  const exportType = exportTypeStr ? parseInt(exportTypeStr) : 1;

  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/home' }}>
          TOP
        </Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/exports' }}>
          出庫
        </Breadcrumb.Item>
        <Breadcrumb.Item active>出庫詳細（{EXPORT_TYPE_TEXT[exportType]}）</Breadcrumb.Item>
      </Breadcrumb>

      <ExportDetailsContainer key={key} setKey={setKey} id={id} exportType={exportType as 1 | 2} />
    </div>
  );
};

export default ExportDetailScreen;
