import { useRef, useMemo } from 'react';
import { Modal, Form } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { MdClose } from 'react-icons/md';
import moment from 'moment';

import DataTable from 'shared/components/data-table';
import DropdownFilter from 'shared/components/dropdown-filter';
import TextFilter from 'shared/components/text-filter';
import { DataProvider } from 'shared/data-providers/table-data-provider';
import { DropdownDataProvider } from 'shared/data-providers/dropdown-data-provider';
import DateFilter from 'shared/components/date-filter';
import { DeviceType } from 'types/device-type';
import { Maker } from 'types/maker';
import { Product } from 'types/product';
import { calculateProductPrice } from 'utils/product';

import styles from './styles.module.scss';

interface IProps {
  onClose: () => void;
  onSelect: (item: Product) => void;
}

const ProductSelectModal = ({ onClose, onSelect }: IProps) => {
  const { control, handleSubmit, reset, setValue } = useForm({
    defaultValues: { before: 0, after: 1 },
  });
  const dataProvider = useRef(
    new DataProvider<any>({ dataSource: 'products', defaultFilters: { status: 1, sort: 'id', sort_type: 'asc' } })
  );
  dataProvider.current.setLimit(100);

  const deviceTypeDataProvider = useRef(
    new DropdownDataProvider('device-types/dropdown', (item: DeviceType) => ({
      label: item.device_type_code,
      value: item.device_type_code,
    }))
  ).current;
  const makerDataProvider = useRef(
    new DropdownDataProvider('makers/dropdown', (item: Maker) => ({
      label: `${item.maker_code} | ${item.maker_name}`,
      value: item.id,
    }))
  ).current;

  const headers = useMemo(() => {
    return [
      { label: '' },
      { label: '商品CD', key: 'id' },
      { label: '種別' },
      { label: 'メーカー' },
      { label: '品名', key: 'product_kana' },
      { label: '型式名' },
      { label: 'スペック' },
      { label: 'フリガナ' },
      { label: '導入開始日', key: 'intro_start_date' },
      { label: '参照基準' },
      { label: 'メモ' },
    ];
  }, []);

  const delegate = (item: Product) => [
    <button className="edit" onClick={() => onSelect(item)}>
      選択
    </button>,
    item.id,
    item.device_type_code,
    `${item.maker?.maker_code || ''} ${item.maker?.maker_name || ''}`,
    item.product_name,
    item.model_name,
    item.spec,
    item.product_kana,
    item.intro_start_date ? moment(item.intro_start_date).format('YYYY/MM/DD') : '',
    calculateProductPrice(item).toLocaleString(),
    item.product_memo,
  ];

  return (
    <Modal
      show
      centered
      dialogClassName={styles.modalDialog}
      contentClassName={styles.modalContent}
    >
      <div className="list-screen" style={{ padding: '1.5rem 2.5rem' }}>
        <div className={styles.title}>
          商品選択モーダル
          <button className={styles.close} onClick={onClose}>
            <MdClose />
          </button>
        </div>
        <form onSubmit={handleSubmit((values) =>
                dataProvider.current.setFilters({ ...values, status: 1 })
              )}>
          <div className={styles.filterContainer}>
            <div>
              <TextFilter label="商品CD" control={control} keys={['product_id']} />
            </div>

            <div>
              <TextFilter label="フリガナ" control={control} keys={['product_kana']} />
            </div>

            <div>
              <TextFilter label="スペック" control={control} keys={['spec']} />
            </div>

            <div>
              <TextFilter label="品名・型式名" control={control} keys={['product_name']} />
            </div>

            <div>
              <DropdownFilter
                label="種別"
                control={control}
                keys={['device_type_code']}
                dataProvider={deviceTypeDataProvider}
              />
            </div>

            <div>
              <DropdownFilter
                label="メーカー"
                dataProvider={makerDataProvider}
                control={control}
                keys={['maker_id']}
              />
            </div>

            <div>
              <DateFilter label="導入開始" control={control} keys={['intro_start_date']} />
            </div>

            <div className={styles.checkbox}>
              <div
                className="d-flex align-items-center"
                style={{ minWidth: 'max-content', marginRight: '1rem' }}
              >
                <Controller
                  control={control}
                  name="before"
                  render={({ field: { value, onChange } }) => (
                    <Form.Check
                      className="custom-checkbox"
                      checked={!!value}
                      defaultChecked={false}
                      onChange={(event) => {
                        onChange(event.target.checked ? 1 : 0);
                        setValue('after', 0);
                      }}
                    />
                  )}
                />
                <div style={{ marginLeft: '0.25rem' }}>以前</div>
              </div>
              <div className="d-flex align-items-center" style={{ minWidth: 'max-content' }}>
                <Controller
                  control={control}
                  name="after"
                  render={({ field: { value, onChange } }) => (
                    <Form.Check
                      className="custom-checkbox"
                      checked={!!value}
                      defaultChecked={true}
                      onChange={(event) => {
                        onChange(event.target.checked ? 1 : 0);
                        setValue('before', 0);
                      }}
                    />
                  )}
                />
                <div style={{ marginLeft: '0.25rem' }}>以降</div>
              </div>
            </div>
          </div>

          <div className={styles.buttonGroup}>
            <button
              className="secondary-btn"
              onClick={handleSubmit((values) =>
                dataProvider.current.setFilters({ ...values, status: 1 })
              )}
            >
              検索
            </button>
            <button
              className="secondary-btn"
              onClick={() => {
                reset();
                dataProvider.current.clearFilters();
              }}
            >
              クリア
            </button>
          </div>
        </form>

        <div className={styles.tableWrapper}>
          <DataTable
            dataProvider={dataProvider.current}
            headers={headers}
            delegate={delegate}
            scrollable={true}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ProductSelectModal;
