// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_container__EDp8a {\n  display: flex;\n}\n\n.styles_selectGroup__jYdOb {\n  margin: 0 1em 0 0;\n  display: flex;\n  align-items: center;\n}\n\n.styles_text__Nrj7r {\n  margin: 0 0 0 0.25em;\n}\n\n.styles_defaultDropdownStyle__rM-fo {\n  width: 10em;\n  border: 1px solid lightgray;\n}\n\n@media all and (min-width: 1920px) {\n  .styles_defaultDropdownStyle__rM-fo {\n    width: 8em;\n  }\n}", "",{"version":3,"sources":["webpack://./src/shared/components/year-month-select/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;;AAEA;EACE,iBAAA;EACA,aAAA;EACA,mBAAA;AACF;;AAEA;EACE,oBAAA;AACF;;AAEA;EACE,WAAA;EACA,2BAAA;AACF;;AAEA;EACE;IACE,UAAA;EACF;AACF","sourcesContent":[".container {\n  display: flex;\n}\n\n.selectGroup {\n  margin: 0 1em 0 0;\n  display: flex;\n  align-items: center;\n}\n\n.text {\n  margin: 0 0 0 0.25em;\n}\n\n.defaultDropdownStyle {\n  width: 10em;\n  border: 1px solid lightgray;\n}\n\n@media all and (min-width: 1920px) {\n  .defaultDropdownStyle {\n    width: 8em;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "styles_container__EDp8a",
	"selectGroup": "styles_selectGroup__jYdOb",
	"text": "styles_text__Nrj7r",
	"defaultDropdownStyle": "styles_defaultDropdownStyle__rM-fo"
};
export default ___CSS_LOADER_EXPORT___;
