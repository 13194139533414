import { useState } from 'react';
import { Form, Modal } from 'react-bootstrap';

import styles from './styles.module.scss';

interface IProps {
  onClose: () => void;
  onDone: (type: 1 | 2, saleCode?: string) => void;
}

const SaleConfirmModal = ({ onClose, onDone }: IProps) => {
  const [type, setType] = useState<1 | 2>(1);
  const [saleCode, setSaleCode] = useState('');

  return (
    <Modal show size="sm">
      <div className={styles.container}>
        <div style={{ fontWeight: 'bold', textAlign: 'center', marginBottom: '1rem' }}>
          売上設定モーダル
        </div>
        <div>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
            <Form.Check
              className="custom-checkbox"
              style={{ marginRight: '0.5rem' }}
              checked={type === 1}
              onChange={(event) => {
                event.target.checked && setType(1);
              }}
            />
            新規作成する
          </div>
          <div style={{ display: 'flex', alignItems: 'flex-start' }}>
            <Form.Check
              className="custom-checkbox"
              style={{ marginRight: '0.5rem', marginTop: '0.3rem' }}
              checked={type === 2}
              onChange={(event) => {
                event.target.checked && setType(2);
              }}
            />
            <div>
              既存設定する
              <div style={{ display: 'flex', marginTop: '1rem' }}>
                <div
                  style={{
                    backgroundColor: '#008060',
                    color: 'white',
                    padding: '0.25rem 0.75rem',
                  }}
                >
                  売上番号
                </div>
                <input
                  type="text"
                  style={{ border: '1px solid #d3d8de', padding: '0 0.5rem', flex: 1, minWidth: 0 }}
                  onChange={(event) => {
                    setSaleCode(event.target.value);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '2rem',
          }}
        >
          <button
            style={{
              backgroundColor: '#d3d8de',
              border: '1px solid #d3d8de',
              padding: '0.25rem 0.75rem',
              fontSize: '1rem',
              borderRadius: '0.25rem',
              marginRight: '1rem',
            }}
            onClick={onClose}
          >
            閉じる
          </button>
          <button
            style={{
              backgroundColor: '#008060',
              border: '1px solid #008060',
              color: 'white',
              padding: '0.25rem 0.75rem',
              fontSize: '1rem',
              borderRadius: '0.25rem',
            }}
            onClick={() => onDone(type, saleCode)}
          >
            登錄
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default SaleConfirmModal;
