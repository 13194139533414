// Built-in
import { useEffect, useRef, useState } from 'react';
import { Breadcrumb, Col, Row, Table } from 'react-bootstrap';
import { useForm, useWatch } from 'react-hook-form';
import { IoCloseCircleOutline } from 'react-icons/io5';
import { useMutation, useQuery } from 'react-query';
import { Link, useNavigate, useParams } from 'react-router-dom';

// Component
import TextFilter from 'shared/components/text-filter';
import ProductSelectModal from 'shared/containers/product-select-modal';
import DynamicDropdown from 'shared/components/dynamic-dropdown';
import ValidatingInput from 'shared/components/validating-text-input';

// Store
import { DropdownDataProvider } from 'shared/data-providers/dropdown-data-provider';
import UIStore from 'stores/ui';
import useStores from 'hooks/use-stores';

// Service
import { getImportById, rematchQRFile, updateMatchingData, uploadQRFile } from 'services/import';
import { getProductById } from 'services/product';

// Hooks
import { useCallbackPrompt } from 'hooks/use-callback-prompt';

// Style
import styles from './styles.module.scss';
import { toast } from 'react-toastify';

const deviceTypeDataProvider = new DropdownDataProvider('device-types/dropdown', (item) => ({
  label: item.device_type_code,
  value: item.device_type_code,
}));

const ImportQRFileScreen = () => {
  const navigate = useNavigate();

  const uiStore: UIStore = useStores().uiStore;

  const params = useParams();
  const id = params.id ? (isNaN(parseInt(params.id)) ? undefined : parseInt(params.id)) : undefined;

  const [productModal, setProductModal] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const [leftProducts, setLeftProducts] = useState<Array<any>>([]);
  const [rightProducts, setRightProducts] = useState<Array<any>>([]);
  const [isDirty, setIsDirty] = useState(false);
  const loadedRef = useRef(false);

  const inputRef = useRef<HTMLInputElement | null>(null);

  const { control, reset } = useForm();
  const completed = useWatch({ control, name: 'complete' });

  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(isDirty);

  const { refetch: _ } = useQuery(['import', id], () => getImportById(id as number), {
    enabled: !!id,
    onSettled: () => uiStore.hideLoading(),
    onSuccess: (data) => {
      reset(data);
      const tempRight = data.import_details
        .filter((item: any) => item.qr === 1)
        .map((item: any) => item.import_qr_detail);
      setLeftProducts(data.import_details.filter((item: any) => item.qr === 1) || []);
      setRightProducts(tempRight);
      setTimeout(() => (loadedRef.current = true), 100);
    },
  });

  const uploadQRMutation = useMutation((file: File) => uploadQRFile(id as number, file), {
    onMutate: () => uiStore.showLoading(),
    onSettled: () => uiStore.hideLoading(),
    onSuccess: (data) => {
      const tempLeft = JSON.parse(JSON.stringify(data.import_details));
      const tempRight = data.import_details.map((item: any) => item.import_qr_detail);
      if (data.import_qr_details) {
        tempRight.push(...data.import_qr_details);
        data.import_qr_details.forEach(() => tempLeft.push(null));
      }
      setLeftProducts(tempLeft);
      setRightProducts(tempRight);
    },
  });

  const rematchMutation = useMutation(
    ({ importProducts, qrProducts }: any) => rematchQRFile(id!, importProducts, qrProducts),
    {
      onMutate: () => uiStore.showLoading(),
      onSettled: () => uiStore.hideLoading(),
      onSuccess: (data) => {
        const tempLeft = data.import_details;
        const tempRight = data.import_details.map((item: any) => item.import_qr_detail);
        if (data.import_qr_details) {
          tempRight.push(...data.import_qr_details);
          data.import_qr_details.forEach(() => tempLeft.push(null));
        }
        setLeftProducts(tempLeft);
        setRightProducts(tempRight);
      },
    }
  );

  const saveMutation = useMutation(
    ({ matchedRows, unmatchedRows }: any) =>
      updateMatchingData(id as number, matchedRows, unmatchedRows),
    {
      onMutate: () => uiStore.showLoading(),
      onSettled: () => uiStore.hideLoading(),
      onSuccess: (_data) => {
        // refetch();
        // cancel block navigation after mutating data.
        setIsDirty(false);
        // loadedRef.current = false;
        toast.success('登録しました');
        setTimeout(() => {
          navigate(-1);
        }, 20);
      },
    }
  );

  useEffect(() => {
    if (!id) navigate(-1);
    else uiStore.showLoading();
  }, []);

  useEffect(() => {
    if (loadedRef.current) {
      setIsDirty(true);
    }
  }, [leftProducts, rightProducts]);

  useEffect(() => {
    if (showPrompt) {
      uiStore.showConfirmBox({
        title: '変更内容を保存せず、終了しますか？',
        confirmText: 'OK',
        cancelText: ' キャンセル',
        onConfirm: () => {
          uiStore.hideConfirmBox();
          confirmNavigation();
        },
        onCancel: () => cancelNavigation(),
      });
    }
  }, [showPrompt]);

  const checkFieldMatch = (item: any, key: string) => {
    if (item?.check_match?.[key]) return styles.matched;
    else return styles.unmatched;
  };

  // TODO: There is something wrong with this code
  const updateLeftRow = (index: number, data: any) => {
    const temp = [...leftProducts];
    temp.splice(index, 1, {
      ...leftProducts[index],
      ...data,
    });
    setLeftProducts(temp);
  };

  const removeRightRow = (index: number) => {
    const temp = [...rightProducts];
    temp.splice(index, 1, null);
    setRightProducts(temp);
  };

  const headers = [
    '種別',
    '商品CD',
    '',
    '型式名',
    'セル番号',
    '枠番号',
    '基盤番号',
    '種別',
    '商品CD',
    '型式名',
    'セル番号',
    '枠番号',
    '基盤番号',
    'セルコード',
    '枠コード',
    '基盤コード',
    '在庫場所',
  ];
  if (!completed) headers.push('');

  const uploadFile = (event: any) => {
    if (event.target.files && event.target.files?.length > 0) {
      const file = event.target.files[0];
      uploadQRMutation.mutate(file);
      event.target.value = '';
    }
  };

  const saveChanges = () => {
    let matchedRows = leftProducts;
    for (let i = 0; i < leftProducts.length; i++) {
      // TODO: bug here if leftProducts' s items is not null
      if (matchedRows[i]) {
        matchedRows[i].import_qr_detail = rightProducts[i];
      }
    }
    const unmatchedIndexes: Array<number> = [];
    matchedRows.forEach((item, index) => !item && unmatchedIndexes.push(index));
    matchedRows = matchedRows.filter((item) => !!item);
    let unmatchedRows: Array<any> = [];
    rightProducts.forEach(
      (item, index) => unmatchedIndexes.includes(index) && unmatchedRows.push(item)
    );
    unmatchedRows = unmatchedRows.filter((item) => !!item);
    saveMutation.mutate({ matchedRows, unmatchedRows });
  };

  const rematch = () => {
    const importProducts = leftProducts.filter((item) => !!item);
    const qrProducts = rightProducts.filter((item) => !!item);

    rematchMutation.mutate({ importProducts, qrProducts });
  };

  return (
    <div>
      <div>
        <Breadcrumb>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/home' }}>
            TOP
          </Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/imports' }}>
            入庫
          </Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/imports/${id}` }}>
            入庫詳細
          </Breadcrumb.Item>
          <Breadcrumb.Item active>入庫QRファイル</Breadcrumb.Item>
        </Breadcrumb>

        <Row className="mb-4">
          <Col className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <TextFilter control={control} label="入庫番号" keys={['import_code']} disabled />
              {!completed && (
                <button className="primary-btn ms-2" onClick={() => inputRef.current?.click()}>
                  QRファイル
                </button>
              )}
              <input
                type="file"
                accept=".csv, .xls, .xlsx"
                className="d-none"
                ref={inputRef}
                onChange={uploadFile}
              />
            </div>
            {!completed && (
              <button aria-label="save" className="primary-btn" onClick={saveChanges}>
                登録
              </button>
            )}
          </Col>
        </Row>

        <div style={{ overflowX: 'scroll' }}>
          <Table bordered hover className={`${styles.tableContainer} import-qr-table__override`}>
            <thead>
              <tr>
                <th colSpan={7} className={styles.orange}>
                  入庫詳細
                </th>
                <th colSpan={10} className={styles.purple}>
                  <div className="d-flex align-items-center justify-content-center position-relative">
                    QRファイル
                    {!completed && (
                      <button
                        className="secondary-btn"
                        style={{ position: 'absolute', right: 0 }}
                        onClick={rematch}
                        disabled={!leftProducts.length && !rightProducts.length}
                      >
                        再チェック
                      </button>
                    )}
                  </div>
                </th>
                <th colSpan={2} />
              </tr>
              <tr>
                {headers.map((item, index) => (
                  <th key={index}>
                    <div>{item}</div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {leftProducts.map((item: any, index: number) => (
                <tr key={index}>
                  <td>
                    <div>
                      {completed ? (
                        item?.device_type_code
                      ) : (
                        <DynamicDropdown
                          isClearable={false}
                          dataProvider={deviceTypeDataProvider}
                          value={item?.device_type_code || ''}
                          onChange={(value) => updateLeftRow(index, { device_type_code: value })}
                        />
                      )}
                    </div>
                  </td>
                  <td>
                    {completed ? (
                      item?.product_id
                    ) : (
                      <ValidatingInput
                        type="number"
                        value={item?.product_id?.toString()}
                        validate={async (value, showError) => {
                          let payload: any = {};
                          let hasError = false;
                          if (value === '') {
                            payload.product_id = undefined;
                            payload.product = undefined;
                          } else {
                            try {
                              const res = await getProductById(parseInt(value));
                              payload.product_id = res.id;
                              payload.product = res;
                            } catch (err) {
                              payload.product_id = parseInt(value);
                              payload.product = undefined;
                              hasError = true;
                            }
                          }
                          updateLeftRow(index, payload);
                          showError(hasError);
                        }}
                      />
                    )}
                  </td>
                  <td>
                    <button
                      disabled={completed}
                      onClick={() => {
                        setSelectedIndex(index);
                        setProductModal(true);
                      }}
                    >
                      参
                    </button>
                  </td>
                  <td style={{ minWidth: '7rem' }}>{item?.product?.model_name}</td>
                  <td>
                    {completed ? (
                      item?.cell_number
                    ) : (
                      <input
                        value={item?.cell_number || ''}
                        onChange={(event) =>
                          updateLeftRow(index, { cell_number: event.target.value })
                        }
                      />
                    )}
                  </td>
                  <td>
                    {completed ? (
                      item?.frame_number
                    ) : (
                      <input
                        value={item?.frame_number || ''}
                        onChange={(event) =>
                          updateLeftRow(index, { frame_number: event.target.value })
                        }
                      />
                    )}
                  </td>

                  <td>
                    {completed ? (
                      item?.base_number
                    ) : (
                      <input
                        value={item?.base_number || ''}
                        onChange={(event) =>
                          updateLeftRow(index, { base_number: event.target.value })
                        }
                      />
                    )}
                  </td>
                  <td className={checkFieldMatch(item, 'device_type_code')}>
                    {rightProducts[index]?.device_type_code}
                  </td>
                  <td className={checkFieldMatch(item, 'product_id')}>
                    {rightProducts[index]?.product_id}
                  </td>
                  <td className={styles.disabled}>{rightProducts[index]?.model_name}</td>
                  <td className={checkFieldMatch(item, 'cell_number')}>
                    <div style={{ width: 'max-content' }}>{rightProducts[index]?.cell_number}</div>
                  </td>
                  <td className={checkFieldMatch(item, 'frame_number')}>
                    <div style={{ width: 'max-content' }}>{rightProducts[index]?.frame_number}</div>
                  </td>
                  <td className={checkFieldMatch(item, 'base_number')}>
                    <div style={{ width: 'max-content' }}>{rightProducts[index]?.base_number}</div>
                  </td>
                  <td className={styles.disabled}>
                    <div style={{ width: 'max-content' }}>{rightProducts[index]?.cell_code}</div>
                  </td>

                  <td className={styles.disabled}>
                    <div style={{ width: 'max-content' }}>{rightProducts[index]?.frame_code}</div>
                  </td>

                  <td className={styles.disabled}>
                    <div style={{ width: 'max-content' }}>{rightProducts[index]?.base_code}</div>
                  </td>
                  <td className={styles.disabled}>{rightProducts[index]?.pallet_code}</td>
                  {!completed && (
                    <td>
                      <IoCloseCircleOutline
                        size={25}
                        className="delete-icon"
                        onClick={() => removeRightRow(index)}
                      />
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>

        <div className="d-flex justify-content-end mt-2">
          <button className="primary-btn" onClick={() => navigate(-1)}>
            キャンセル
          </button>
        </div>
      </div>
      {productModal && (
        <ProductSelectModal
          onClose={() => setProductModal(false)}
          onSelect={(item) => {
            const temp = [...leftProducts];
            temp.splice(selectedIndex!, 1, {
              ...leftProducts[selectedIndex!],
              product_id: item.id,
              product: item,
              device_type_code:
                leftProducts[selectedIndex!]?.device_type_code || item.device_type_code,
            });
            setLeftProducts(temp);
            setProductModal(false);
          }}
        />
      )}
    </div>
  );
};

export default ImportQRFileScreen;
