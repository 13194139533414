// Built-in
import { useEffect, useState } from 'react';
import { Navigate, Outlet, Route, Routes, useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { observer } from 'mobx-react';

// Route
import Login from 'screens/login';
import DepositScreen from 'screens/deposits';
import WithdrawalScreen from 'screens/withdrawals';
import CustomerScreen from 'screens/customers';
import DeviceTypeScreen from 'screens/device-types';
import DriverScreen from 'screens/drivers';
import GroupScreen from 'screens/customer-groups';
import LCDScreen from 'screens/lcds';
import MakerScreen from 'screens/makers';
import ProductScreen from 'screens/products';
import TruckScreen from 'screens/vehicles';
import UserScreen from 'screens/users';
import VATScreen from 'screens/consumption-tax';
import AzukariReportScreen from 'screens/monthly-report-azukari';
import InvoiceScreen from 'screens/invoices';
import PaymentSlipScreen from 'screens/payment-slips';
import FormReportScreen from 'screens/monthly-report';
import DeliveryListScreen from 'screens/delivery-list';
import DeliveryDetailScreen from 'screens/delivery-details';
import VehicleCostScreen from 'screens/vehicle-cost';
import ExportScreen from 'screens/export-list';
import ExportDetailScreen from 'screens/export-details';
import ExportQRFileScreen from 'screens/export-qr-file';
import ImportScreen from 'screens/import-list';
import ImportDetailScreen from 'screens/import-details';
import ImportQRFileScreen from 'screens/import-qr-file';
import StockScreen from 'screens/inventory-list';
import StockDetailScreen from 'screens/inventory-item';
import TransshipmentQRFileScreen from 'screens/transshipment-qr-files';
import Home from 'screens/home';
import PurchaseScreen from 'screens/purchase-list';
import PurchaseDetailScreen from 'screens/purchase-details';
import CreatePurchaseScreen from 'screens/create-purchase';
import QuotationScreen from 'screens/quotation-list';
import QuotationDetailScreen from 'screens/quotation-details';
import CreateQuotationScreen from 'screens/create-quotation';
import SaleScreen from 'screens/sale-list';
import SaleDetailScreen from 'screens/sale-details';
import CreateSaleScreen from 'screens/sale-create';
import CreateDeliveryScreen from 'screens/delivery-create';
import CreateImportScreen from 'screens/create-import';
import SettlementScreen from 'screens/settlement';
import CreateInventoryItemScreen from 'screens/create-inventory-item';
import CreateExportScreen from 'screens/create-export';

// Store
import SessionStore from 'stores/session';
import UIStore from 'stores/ui';
import useStores from 'hooks/use-stores';

// Service
import { getMyProfile } from 'services/auth';

// Component
import { LayoutWithSideBar } from 'shared/containers/layouts/default';

const CustomRoute = observer(({ name }: { name: string }) => {
  const navigate = useNavigate();

  const sessionStore: SessionStore = useStores().sessionStore;
  const uiStore: UIStore = useStores().uiStore;

  const [authorized, setAuthorized] = useState(false);

  useEffect(() => {
    if (sessionStore.profile) {
      if (!sessionStore.profile.is_admin && sessionStore.profile.user_authorities[name] !== 1) {
        uiStore.showAlertBox({
          title: 'エラー',
          content: 'アクセス権限がありません。',
          onClose: () => {
            uiStore.hideAlertBox();
            navigate('/home');
          },
        });
      } else {
        setAuthorized(true);
      }
    }
  }, [sessionStore.profile]);

  return authorized ? <Outlet /> : null;
});

const ProtectedRoute = () => {
  const navigate = useNavigate();

  const uiStore: UIStore = useStores().uiStore;
  const sessionStore: SessionStore = useStores().sessionStore;

  const profileQuery = useQuery('get-profile', () => getMyProfile(), {
    enabled: false,
    onSettled: () => uiStore.hideLoading(),
    onSuccess: (data) => {
      sessionStore.setProfile(data);
      sessionStore.setToken(localStorage.getItem('access_token'));
    },
    onError: (err: any) => {
      uiStore.showAlertBox({
        title: 'エラー',
        content: err.message,
        onClose: () => uiStore.hideAlertBox(),
      });
      navigate('/login');
    },
  });

  useEffect(() => {
    const accessToken = localStorage.getItem('access_token');
    if (accessToken === null) {
      navigate('/login');
    } else {
      if (sessionStore.profile === undefined) profileQuery.refetch();
    }
  }, []);

  return <Outlet />;
};

const RouteList = () => (
  <Routes>
    <Route path="/" element={<Navigate to="/login" />} />
    <Route path="/login" element={<Login />} />
    <Route path="/" element={<ProtectedRoute />}>
      <Route path="/" element={<LayoutWithSideBar />}>
        <Route path="/home" element={<Home />} />
        <Route path="/" element={<CustomRoute name="QUOTATION" />}>
          <Route path="/quotations" element={<QuotationScreen />} />
          <Route path="/quotations/create" element={<CreateQuotationScreen />} />
          <Route path="/quotations/:id" element={<QuotationDetailScreen />} />
        </Route>
        <Route path="/" element={<CustomRoute name="PURCHASE" />}>
          <Route path="/purchases" element={<PurchaseScreen />} />
          <Route path="/purchases/:id" element={<PurchaseDetailScreen />} />
          <Route path="/purchases/create" element={<CreatePurchaseScreen />} />
        </Route>
        <Route path="/" element={<CustomRoute name="SALE" />}>
          <Route path="/sales" element={<SaleScreen />} />
          <Route path="/sales/create" element={<CreateSaleScreen />} />
          <Route path="/sales/:id" element={<SaleDetailScreen />} />
        </Route>
        <Route path="/" element={<CustomRoute name="IMPORT" />}>
          <Route path="/imports" element={<ImportScreen />} />
          <Route path="/imports/create" element={<CreateImportScreen />} />
          <Route path="/imports/:id" element={<ImportDetailScreen />} />
          <Route path="/imports/:id/qr" element={<ImportQRFileScreen />} />
        </Route>
        <Route path="/" element={<CustomRoute name="EXPORT" />}>
          <Route path="/exports" element={<ExportScreen />} />
          <Route path="/exports/create" element={<CreateExportScreen />} />
          <Route path="/exports/:id" element={<ExportDetailScreen />} />
          <Route path="/exports/:id/qr" element={<ExportQRFileScreen />} />
        </Route>
        <Route path="/" element={<CustomRoute name="STOCK" />}>
          <Route path="/inventories" element={<StockScreen />} />
          <Route path="/inventories/:id" element={<StockDetailScreen />} />
          <Route path="/inventories/create" element={<CreateInventoryItemScreen />} />
          <Route path="/inventories/qr" element={<TransshipmentQRFileScreen />} />
        </Route>
        <Route path="/" element={<CustomRoute name="DELIVERY" />}>
          <Route path="/deliveries" element={<DeliveryListScreen />} />
          <Route path="/deliveries/:id" element={<DeliveryDetailScreen />} />
          <Route path="/deliveries/create" element={<CreateDeliveryScreen />} />
        </Route>
        <Route path="/" element={<CustomRoute name="DEPOSIT_WITHDRAWAL" />}>
          <Route path="/deposit" element={<DepositScreen />} />
          <Route path="/withdrawal" element={<WithdrawalScreen />} />
        </Route>
        <Route path="/" element={<CustomRoute name="VEHICLE" />}>
          <Route path="/vehicle-cost" element={<VehicleCostScreen />} />
        </Route>
        <Route path="/" element={<CustomRoute name="REPORT" />}>
          <Route path="/form-report" element={<FormReportScreen />} />
          <Route path="/azukari-report" element={<AzukariReportScreen />} />
          <Route path="/invoice" element={<InvoiceScreen />} />
          <Route path="/settlement" element={<SettlementScreen />} />
          <Route path="/payment-slip" element={<PaymentSlipScreen />} />
        </Route>
        <Route path="/" element={<CustomRoute name="MASTER" />}>
          <Route path="/master/customers" element={<CustomerScreen />} />
          <Route path="/master/customer-groups" element={<GroupScreen />} />
          <Route path="/master/lcds" element={<LCDScreen />} />
          <Route path="/master/makers" element={<MakerScreen />} />
          <Route path="/master/products" element={<ProductScreen />} />
          <Route path="/master/vehicles" element={<TruckScreen />} />
          <Route path="/master/drivers" element={<DriverScreen />} />
          <Route path="/master/device-types" element={<DeviceTypeScreen />} />
          <Route path="/master/consumption-taxes" element={<VATScreen />} />
        </Route>
        <Route path="/" element={<CustomRoute name="USER" />}>
          <Route path="/users" element={<UserScreen />} />
        </Route>
      </Route>
    </Route>
  </Routes>
);

export default RouteList;
