// Built-in
import { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { Breadcrumb } from 'react-bootstrap';
import { useMutation, useQuery } from 'react-query';
import { Link } from 'react-router-dom';

// Component
import DataTable from 'shared/components/data-table';
import FilterItem from 'shared/components/filter-item';
import YearMonthSelect from 'shared/components/year-month-select';

// Type
import { LedgerInfo } from 'types/ledger';

// Store
import useStores from 'hooks/use-stores';
import UIStore from 'stores/ui';
import { DataProvider } from 'shared/data-providers/table-data-provider';

// Service
import { exportForm } from 'services/export-form';
import { aggregateLedger, confirmLedger, getLedgerInfo, revertLedger } from 'services/report';

// Style
import styles from './styles.module.scss';

// Const
import { MONTHS, currentMonthOption, currentYearOption, YEARS } from 'consts';
import SessionStore from 'stores/session';

const SettlementScreen = observer(() => {
  const [didMount, setDidMount] = useState<boolean>(false);
  const [date, setDate] = useState<string>('');
  const [year, setYear] = useState<any>(currentYearOption);
  const [month, setMonth] = useState<any>(currentMonthOption);
  const [complete, setComplete] = useState<0 | 1 | -1 | null>(null);
  const uiStore: UIStore = useStores().uiStore;
  const sessionStore: SessionStore = useStores().sessionStore;

  const dataProvider = useRef(
    new DataProvider({ dataSource: 'ledgers', localStorageKey: 'ledger_filter' })
  );

  const headers = [
    { label: '顧客CD' }, // customer code
    { label: '顧客' }, // name
    { label: '前回請求額' }, // sale last request balance
    { label: '入金額' }, // sale deposit amount
    { label: '調整額' }, // sale adjust amount
    { label: '売上金額' }, // sale amount
    { label: '今回請求額' }, // sale request balance
    { label: '前回買掛額' }, // purchase last request balance
    { label: '支払額' }, // purchase withdrawal amount
    { label: '調整額' }, // purchase adjust amount
    { label: '仕入金額' }, // purchase amount
    { label: '支払残高' }, // purchase request balance
  ];

  const delegate = (item: any) => {
    let name = `${item.customer_name} ${item.hall_name || ''}`;

    return [
      item.customer_code,
      name,
      item.sale_ledger.last_total_amount_request.toLocaleString('en-US'),
      item.sale_ledger.last_deposit_amount.toLocaleString('en-US'),
      item.sale_ledger.last_adjust_amount.toLocaleString('en-US'),
      item.sale_ledger.sale_amount.toLocaleString('en-US'),
      item.sale_ledger.total_amount_request.toLocaleString('en-US'),
      item.purchase_ledger.last_total_amount_request.toLocaleString('en-US'),
      item.purchase_ledger.last_withdrawal_amount.toLocaleString('en-US'),
      item.purchase_ledger.last_adjust_amount.toLocaleString('en-US'),
      item.purchase_ledger.purchase_amount.toLocaleString('en-US'),
      item.purchase_ledger.total_amount_request.toLocaleString('en-US'),
    ];
  };

  const aggregateMutation = useMutation((payload: any) => aggregateLedger(payload), {
    onMutate: () => uiStore.showLoading(),
    onSettled: () => uiStore.hideLoading(),
    onSuccess: (_data: any, error, variables) => {
      if(_data.length > 0) {
        uiStore.showAlertBox({
          title: '精算処理が完了しました',
          type: 'success',
          content: '明細顧客が間違っている可能性のある買取・売上が見つかりましたので確認してください\n\n'+_data.join('\n')
        });
      }
      setComplete(0);
      const currentLimit = dataProvider.current.limit;
      dataProvider.current.setLimit(currentLimit);
    },
  });

  const completeMutation = useMutation((payload: any) => confirmLedger(payload), {
    onMutate: () => uiStore.showLoading(),
    onSettled: () => uiStore.hideLoading(),
    onSuccess: (_data: any) => {
      setComplete(1);
    },
  });

  const revertMutation = useMutation((payload: any) => revertLedger(payload), {
    onMutate: () => uiStore.showLoading(),
    onSettled: () => uiStore.hideLoading(),
    onSuccess: (_data: any) => {
      setComplete(0);
    },
  });

  const reportInfoQueryData = useQuery<LedgerInfo>(
    ['ledger', month, year],
    () =>
      getLedgerInfo({
        month: month.value,
        year: year.value,
      }),
    {
      enabled: false,
      onSuccess: (data) => setComplete(data.complete),
      onError: (_error) => setComplete(null),
    }
  );

  const filter = () => {
    dataProvider.current.setFilters({
      month: month.value,
      year: year.value,
    });
    reportInfoQueryData.refetch();
    setDidMount(true);
  };

  const completeOrCancelLedger = () => {
    const payload = {
      month: month.value,
      year: year.value,
    };

    complete === 1 ? revertMutation.mutate(payload) : completeMutation.mutate(payload);
  };

  const calculateLedger = () => {
    aggregateMutation.mutate({
      month: month.value,
      year: year.value,
    });
  };

  const exportSettlementForm = async () => {
    try {
      uiStore.showLoading();
      const payload = { year: year.value, month: month.value, form_type: 'R2202' };
      await exportForm(`reports/export`, payload);
    } catch (error: any) {
      uiStore.showAlertBox({
        title: 'エラー',
        type: 'error',
        content: error.message,
      });
    } finally {
      uiStore.hideLoading();
    }
  };

  const onMonthChange = (monthOption: any) => {
    setMonth(monthOption);
    sessionStore.setSettlement({ year: year.value, month: monthOption.value });
    dataProvider.current.setFilters({
      month: monthOption.value,
      year: year.value,
    });
    reportInfoQueryData.refetch();
  };

  const onYearChange = (yearOption: any) => {
    setYear(yearOption);
    sessionStore.setSettlement({ month: month.value, year: yearOption.value });
    dataProvider.current.setFilters({
      month: month.value,
      year: yearOption.value,
    });
    reportInfoQueryData.refetch();
  };

  useEffect(() => {
    let filterOption: any = {};

    if (sessionStore.settlement) {
      const yearValue = sessionStore.settlement.year;
      const monthValue = sessionStore.settlement.month;

      const year = {
        label: yearValue,
        value: yearValue,
      };
      const month = {
        label: monthValue,
        value: monthValue,
      };
      setMonth(month);
      setYear(year);

      filterOption = {
        month: sessionStore.settlement.month,
        year: sessionStore.settlement.year,
      };
    } else {
      filterOption = {
        month: currentMonthOption.value,
        year: currentYearOption.value,
      };
    }

    dataProvider.current.setFilters(filterOption);
    setDidMount(true);
  }, []);

  useEffect(() => {
    if (year && month) setDate(`${year.label}/${month.label}`);
    reportInfoQueryData.refetch();
  }, [year, month]);

  return (
    <div className={styles.container}>
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/home' }}>
          TOP
        </Breadcrumb.Item>
        <Breadcrumb.Item active>集計</Breadcrumb.Item>
        <Breadcrumb.Item active>精算</Breadcrumb.Item>
      </Breadcrumb>

      <div className={styles.selectWrapper}>
        <YearMonthSelect
          yearOptions={YEARS}
          monthOptions={MONTHS}
          yearValue={year}
          monthValue={month}
          label="精算年月"
          onMonthChange={onMonthChange}
          onYearChange={onYearChange}
          maxMenuHeight={{ month: 500, year: undefined }}
        />
        {/* <button className="secondary-btn" aria-label="filter" onClick={filter}>
          検索
        </button> */}
      </div>

      {didMount && (
        <div className={styles.actionWrapper}>
          <FilterItem label="精算年月" />
          <input type="text" readOnly value={date} />
          <input
            type="text"
            readOnly
            value={complete === 1 ? '本精算' : complete === 0 ? '仮精算' : '未精算'}
          />

          {(complete === 0 || complete === 1 ) && (
            <button
              aria-label="complete-cancel"
              className={`primary-btn ${complete === null && styles.hidden}`}
              onClick={completeOrCancelLedger}
            >
            {complete === 0 ? '確定' : '解除'}
            </button>
          )}
          {(complete === 0 || complete === 1 ) && (
            <button onClick={exportSettlementForm} className="primary-btn">
              印刷
            </button>
          )}

          <button
            aria-label="calculate"
            className={`primary-btn ${complete === 1 && styles.hidden}`}
            onClick={calculateLedger}
          >
            精算処理
          </button>
        </div>
      )}

      <div className={styles.tableWrapper}>
        {didMount && (
          <DataTable
            headers={headers}
            delegate={delegate}
            dataProvider={dataProvider.current}
            showPagination={{ top: false, bottom: true }}
          />
        )}
      </div>
    </div>
  );
});

export default SettlementScreen;
