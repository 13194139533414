// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_title__PaUX3 {\n  text-align: center;\n  padding: 4rem 0;\n}\n\n.styles_formContainer__vJI6- {\n  display: flex;\n  justify-content: center;\n}\n.styles_formContainer__vJI6- button {\n  margin-left: 1rem;\n  font-size: 1rem;\n  background-color: #008060;\n  padding: 0.5rem 1rem;\n  color: #fff;\n  border: none;\n  border-radius: 5px;\n}\n.styles_formContainer__vJI6- button:hover {\n  background-color: #02644c;\n}\n.styles_formContainer__vJI6- button:disabled {\n  background-color: darkgray;\n}", "",{"version":3,"sources":["webpack://./src/screens/login/styles.module.scss","webpack://./src/assets/styles/_variables.scss"],"names":[],"mappings":"AAEA;EACE,kBAAA;EACA,eAAA;AADF;;AAIA;EACE,aAAA;EACA,uBAAA;AADF;AAGE;EACE,iBAAA;EACA,eAAA;EACA,yBCdW;EDeX,oBAAA;EACA,WCPS;EDQT,YAAA;EACA,kBAAA;AADJ;AAGI;EACE,yBCnBW;ADkBjB;AAII;EACE,0BAAA;AAFN","sourcesContent":["@import \"../../assets/styles/variables\";\n\n.title {\n  text-align: center;\n  padding: 4rem 0;\n}\n\n.formContainer {\n  display: flex;\n  justify-content: center;\n\n  button {\n    margin-left: 1rem;\n    font-size: 1rem;\n    background-color: $primaryColor;\n    padding: 0.5rem 1rem;\n    color: $whiteColor;\n    border: none;\n    border-radius: 5px;\n\n    &:hover {\n      background-color: $darkGreenColor;\n    }\n\n    &:disabled {\n      background-color: darkgray;\n    }\n  }\n}\n","$primaryColor: #008060;\n$lightGreenColor: #d2ecd0;\n$darkGreenColor: #02644c;\n$lightMagentaColor: #d0cee2;\n$grayColor: #444;\n$lightBlueColor: #d3d8de;\n$lightGrayColor: lightgray;\n$deleteButtonColor: #e55451;\n\n$whiteColor: #fff;\n$blackColor: #000;\n$blueColor: #0d6efd;\n\n$importColColor: #ffff88;\n$exportColColor: #cce5ff;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "styles_title__PaUX3",
	"formContainer": "styles_formContainer__vJI6-"
};
export default ___CSS_LOADER_EXPORT___;
