import { useMemo } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { useForm, useWatch } from 'react-hook-form';
import { MdClose } from 'react-icons/md';

import UIStore from 'stores/ui';
import useStores from 'hooks/use-stores';

import styles from './styles.module.scss';
import { observer } from 'mobx-react';
import { popupConfirmBack } from 'utils/modals';
import { convertToHalfWidth } from 'utils/validator';

interface IProps {
  initialValues?: any;
  onClose: () => void;
  onSave: (data: any) => void;
  onCreate: (data: any) => void;
  onDelete: () => void;
}

const EditModal = observer(({ initialValues, onClose, onSave, onCreate, onDelete }: IProps) => {
  const uiStore: UIStore = useStores().uiStore;

  const initialFormValues = useMemo(() => {
    if (initialValues) return initialValues;

    return {
      base_unit_price: 0,
      lcd_inch: 0,
    };
  }, []);

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { isDirty, isValid },
  } = useForm({
    defaultValues: initialFormValues,
    mode: 'onChange',
  });

  const baseUnitPrice = useWatch({ control, name: 'base_unit_price' });
  const LCDWeight = useWatch({ control, name: 'lcd_weight' });
  const LCDInch = useWatch({ control, name: 'lcd_inch' });
  const LCDModel = useWatch({ control, name: 'lcd_model' });

  const modalTitle = useMemo(() => {
    return !initialValues ? '液晶作成モーダル' : '液晶編集モーダル';
  }, [initialValues]);

  const onSubmit = (data: any) => {
    const payload = JSON.parse(JSON.stringify(data));

    // ex: "12."
    if (typeof payload.lcd_inch === 'string') {
      payload.lcd_inch = parseInt(payload.lcd_inch);
    }

    if (initialValues) onSave(payload);
    else onCreate(payload);
  };

  const onErrors = (errors: any) => {
    console.log('Errors: ', errors);
  };

  const onModalClose = (event: any) => {
    event.preventDefault();
    popupConfirmBack(isDirty, () => onClose());
  };

  const deleteLCD = (event: any) => {
    event.preventDefault();
    onDelete();
  };

  return (
    <Modal show backdrop="static" centered>
      <div className="edit-modal">
        <div className="edit-modal--title">
          {modalTitle}
          <button onClick={onModalClose}>
            <MdClose />
          </button>
        </div>
        <Form onSubmit={handleSubmit(onSubmit, onErrors)}>
          <Form.Group className={styles.group}>
            <Form.Label className="required">液晶CD</Form.Label>
            <Form.Control
              disabled={!!initialValues?.lcd_code}
              {...register('lcd_code', { required: true })}
            />
          </Form.Group>

          <Form.Group className={styles.group}>
            <Form.Label>液晶メーカー</Form.Label>
            <Form.Control {...register('maker_name')} />
          </Form.Group>

          <Form.Group className={styles.group}>
            <Form.Label>インチ</Form.Label>
            <Form.Control
              value={LCDInch}
              onChange={(event) => {
                let val = event.target.value;

                switch (true) {
                  case val === '':
                    setValue('lcd_inch', 0, { shouldDirty: true });
                    break;
                  case /^[０-９0-9]+$/.test(val) === true:
                    setValue('lcd_inch', parseInt(convertToHalfWidth(val)), { shouldDirty: true });
                    break;
                  case /^[０-９0-9]+\.$/.test(val) === true:
                    setValue('lcd_inch', convertToHalfWidth(val), { shouldDirty: true });
                    break;
                  case /^[０-９0-9]+\.[０-９0-9]+$/.test(val) === true:
                    setValue('lcd_inch', parseFloat(convertToHalfWidth(val)), {
                      shouldDirty: true,
                    });
                    break;
                  default:
                    break;
                }
              }}
            />
          </Form.Group>

          <Form.Group className={styles.group}>
            <Form.Label>液晶型番</Form.Label>
            <Form.Control
              value={LCDModel}
              onChange={(event) => {
                setValue('lcd_model', convertToHalfWidth(event.target.value), {
                  shouldDirty: true,
                });
              }}
            />
          </Form.Group>

          <Form.Group className={styles.group}>
            <Form.Label>形態</Form.Label>
            <Form.Control {...register('lcd_form')} />
          </Form.Group>

          <Form.Group className={styles.group}>
            <Form.Label>参照基準価格</Form.Label>
            <Form.Control
              value={baseUnitPrice}
              onChange={(event) => {
                let val = event.target.value;
                switch (true) {
                  case val === '':
                    setValue('base_unit_price', 0, { shouldDirty: true });
                    break;
                  case /^[０-９0-9]+$/.test(val) === true:
                    setValue('base_unit_price', parseInt(convertToHalfWidth(val)), {
                      shouldDirty: true,
                    });
                    break;
                  default:
                    break;
                }
              }}
            />
          </Form.Group>

          <Form.Group className={styles.group}>
            <Form.Label>備考</Form.Label>
            <Form.Control {...register('lcd_note')} />
          </Form.Group>

          <Form.Group className={styles.group}>
            <Form.Label>重量(g)</Form.Label>
            <Form.Control
              value={LCDWeight}
              onChange={(event) => {
                let val = event.target.value;
                switch (true) {
                  case val === '':
                    setValue('lcd_weight', null, { shouldDirty: true });
                    break;
                  case /^[０-９0-9]+$/.test(val) === true:
                    setValue('lcd_weight', parseInt(convertToHalfWidth(val)), {
                      shouldDirty: true,
                    });
                    break;
                  default:
                    break;
                }
              }}
            />
          </Form.Group>

          <div className="d-flex justify-content-end">
            {initialValues && (
              <button className="edit-modal--delete" type="button" onClick={deleteLCD}>
                削除
              </button>
            )}
            <button className="edit-modal--save" type="submit" disabled={!isValid}>
              登録
            </button>
          </div>
        </Form>
      </div>

      {isDirty && uiStore.confirmBox.visible && <div className="overlappedBackdrop"></div>}
    </Modal>
  );
});

export default EditModal;
