import FilterItem from '../filter-item';
import styles from './styles.module.scss';

interface IProps {
  label?: string;
  rows?: number;
  onChange: (event: any) => void;
  value?: any;
  disabled?: boolean;
}

const TextAreaFilter = ({ label, rows, onChange, value, disabled = false }: IProps) => {
  return (
    <div className={styles.container}>
      {label && <FilterItem label={label} />}
      <textarea rows={rows ? rows : 8} onChange={onChange} value={value} disabled={disabled} />
    </div>
  );
};

export default TextAreaFilter;
