import moment from 'moment';
import { Customer } from './customer';

type User = {
  id: number;
  username: string;
  name: string;
};

export const DEVICE_TYPE_CODES = ['P', 'PC', 'PW', 'S', 'PP', 'PH', 'PT','ST'];

export type volume = {
  device_type_code: string;
  import: number;
  export: number;
};

export const DEFAULT_DELIVERY_VOLUMES: volume[] = [
  {
    device_type_code: 'P',
    import: 0,
    export: 0,
  },
  {
    device_type_code: 'PC',
    import: 0,
    export: 0,
  },
  {
    device_type_code: 'PW',
    import: 0,
    export: 0,
  },
  {
    device_type_code: 'S',
    import: 0,
    export: 0,
  },
  {
    device_type_code: 'PP',
    import: 0,
    export: 0,
  },
  {
    device_type_code: 'PH',
    import: 0,
    export: 0,
  },
  {
    device_type_code: 'PT',
    import: 0,
    export: 0,
  },
  {
    device_type_code: 'ST',
    import: 0,
    export: 0,
  },
];

export type Delivery = {
  id: number;
  delivery_code: string;
  delivery_type: number;
  target_id: number | null;
  target2_id: number | null;
  target_type: string;
  delivery_date: string | null;
  amount_fee: number | null;
  amount_fee_extra: number | null;
  complete: 0 | 1;
  hour_from: string | null;
  hour_to: string | null;
  delivery_time_other: string | null;
  customer_id: number | null;
  user_pic_id: number | null;
  user_input_id: number | null;
  user_confirm_id: number | null;
  truck_id: number | null;
  driver_id: number | null;
  assistant1_id: number | null;
  assistant2_id: number | null;
  delivery_detail: string | null;
  business_contact: string | null;
  delivery_memo: string | null;
  delivery_volume: volume[];
  delivery_volume_note: string | null;
  journey_start: number | null;
  journey_end: number | null;
  journey_fuel: number;
  journey_distance: number;
  journey_adblue: number;
  journey_enter_later: number;
  expway_charge: number | null;
  expway_section: string | null;
  sort: number | null;
  create_at: string;
  updated_at: string | null;
  delete_at: string | null;
  customer: Partial<Customer>;
  driver: {
    id: number;
    driver_name: string;
  };
  truck: {
    id: number;
    truck_name: string;
  };
  assistant1: {
    id: number;
    driver_name: string;
  };
  assistant2: {
    id: number;
    driver_name: string;
  };
  purchase: any;
  import: any;
  export: any;
  sale: any;
  sales: Array<any>;
  user_person_in_charge: User;
  user_input_person: User;
  user_confirm_person: User;
};

export interface IDeliveryFilter {
  sort: 'delivery_code' | 'delivery_date' | 'delivery_type' | 'hour_from_hour_to' | null;
  sort_type: 'asc' | 'desc' | null;
  delivery_date_from: string | null;
  delivery_date_to: string | null;
  delivery_code: string | null;
  customer_id: number | null;
  complete: 0 | 1 | null;
  sale_status: 0 | 1 | null;
  journey_enter_later: 0 | 1 | null;
}

const from = new Date();
const to = new Date();

export const DEFAULT_DELIVERY_FILTER: IDeliveryFilter = {
  delivery_date_from: moment(from).format('YYYY/MM/DD'),
  delivery_date_to: moment(to).format('YYYY/MM/DD'),
  delivery_code: null,
  customer_id: null,
  complete: null,
  sale_status: null,
  journey_enter_later: null,
  sort: 'delivery_date',
  sort_type: 'asc',
};

export const DELIVERY_TYPES = [
  { label: '常用', value: 1 }, // standard
  { label: '午前', value: 2 }, // morning
  { label: '午後', value: 3 }, // afternoon
  { label: '夜間', value: 4 }, // evening
  { label: 'リサイクル', value: 5 }, // recycle
  { label: '来社', value: 6 }, // company
  { label: 'その他', value: 7 }, // other
];

export const DELIVERY_TARGET_TYPES = [
  { label: 'その他', value: 'OTHER' },
  { label: '買取', value: 'PURCHASE' },
  { label: '売上 ', value: 'SALE' },
  { label: '入庫 ', value: 'IMPORT' },
  { label: '出庫 ', value: 'EXPORT' },
  { label: '入出庫', value: 'IMPORT_EXPORT' },
];
