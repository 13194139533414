import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Form, InputGroup } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';

import useStores from 'hooks/use-stores';
import { getMyProfile, login } from 'services/auth';
import UIStore from 'stores/ui';
import SessionStore from 'stores/session';

import styles from './styles.module.scss';

const Login = () => {
  const uiStore: UIStore = useStores().uiStore;
  const sessionStore: SessionStore = useStores().sessionStore;
  const navigate = useNavigate();

  const profileQuery = useQuery('get-profile', () => getMyProfile(), {
    enabled: false,
    onSettled: () => uiStore.hideLoading(),
    onSuccess: (data) => {
      sessionStore.setProfile(data);
      sessionStore.setToken(localStorage.getItem('access_token'));
      navigate('/home');
    },
  });

  const loginMutation = useMutation<any, unknown, { username: string; password: string }>(
    ({ username, password }) => login(username, password),
    {
      onMutate: () => uiStore.showLoading(),
      onSettled: () => uiStore.hideLoading(),
      onSuccess: (data) => {
        localStorage.setItem('access_token', data.token);
        sessionStore.setToken(data.token);
        navigate('/home');
      },
    }
  );
  const {
    register,
    handleSubmit,
    formState: { isValid },
  } = useForm<{ username: string; password: string }>({
    defaultValues: { username: '', password: '' },
    mode: 'onChange',
  });

  useEffect(() => {
    const accessToken = localStorage.getItem('access_token');
    if (accessToken) {
      uiStore.showLoading();
      profileQuery.refetch();
    }
  }, []);

  const onLogin = async (data: any) => {
    const accessToken = localStorage.getItem('access_token');
    if (accessToken) {
      uiStore.showAlertBox({
        title: 'エラー',
        content: '別のタブでログインが検知されました。',
        type: 'error',
      });
      navigate(`/home`);
    } else {
      loginMutation.mutate({ username: data.username, password: data.password });
    }
  };

  return (
    <div>
      <h2 className={styles.title}>昌栄業務システム</h2>
      <Form onSubmit={handleSubmit(onLogin)} className={styles.formContainer}>
        <div className="d-flex align-items-end" style={{ width: 600 }}>
          <div style={{ flex: 1 }}>
            <InputGroup className="mb-2">
              <InputGroup.Text> ログインID</InputGroup.Text>
              <Form.Control
                aria-label="Default"
                placeholder="Login_id"
                {...register('username', { required: true })}
              />
            </InputGroup>
            <InputGroup>
              <InputGroup.Text>パスワード</InputGroup.Text>
              <Form.Control
                aria-label="Default"
                type="password"
                placeholder="Password"
                {...register('password', { required: true })}
              />
            </InputGroup>
          </div>
          <button className={styles.btn} type="submit" disabled={!isValid}>
            ログイン
          </button>
        </div>
      </Form>
    </div>
  );
};

export default Login;
