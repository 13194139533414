import { observer } from 'mobx-react';
import { Modal } from 'react-bootstrap';
import { BsCheckCircleFill } from 'react-icons/bs';

import useStores from 'hooks/use-stores';
import error from 'assets/images/error.svg';
import UIStore from 'stores/ui';

import styles from './styles.module.scss';

const AlertBox = observer(() => {
  const uiStore: UIStore = useStores().uiStore;

  return (
    <Modal
      show={uiStore.alertBox.visible}
      onHide={() => uiStore.hideAlertBox()}
      backdrop="static"
      keyboard={false}
      contentClassName={styles.modalContent}
    >
      <div className={styles.titleContainer}>
        {uiStore.alertBox.type === 'error' && (
          <img src={error} className={styles.warningImg} alt="" />
        )}
        {uiStore.alertBox.type === 'success' && (
          <BsCheckCircleFill
            style={{ width: '2rem', height: '2rem', marginRight: '1rem', color: 'rgb(0, 128, 96)' }}
          />
        )}
        <div style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>{uiStore.alertBox.title}</div>
      </div>
      <div className={styles.content}>{uiStore.alertBox.content}</div>
      <div className={styles.footer}>
        <button
          onClick={() => uiStore.alertBox.onClose && uiStore.alertBox.onClose()}
          style={{
            border: 'none',
            backgroundColor: '#008060',
            color: 'white',
            fontSize: '1rem',
            padding: '0.25rem 0.75rem',
            borderRadius: '0.25rem',
          }}
        >
          {uiStore.alertBox.closeText}
        </button>
      </div>
    </Modal>
  );
});

export default AlertBox;
