// Built-in
import { Breadcrumb } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';

// Component
import ImportDetailsContainer from 'shared/containers/import-details';

// Style
import styles from './styles.module.scss';

const ImportDetailScreen = () => {
  const params = useParams();
  const id = params.id ? parseInt(params.id) : undefined;

  return (
    <div className={styles.container}>
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/home' }}>
          TOP
        </Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/imports' }}>
          入庫
        </Breadcrumb.Item>
        <Breadcrumb.Item active>入庫詳細</Breadcrumb.Item>
      </Breadcrumb>
      <ImportDetailsContainer id={id} />
    </div>
  );
};

export default ImportDetailScreen;
