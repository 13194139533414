import { useState, useEffect, useCallback, useRef } from 'react';
import { Control, UseFormSetValue, useWatch } from 'react-hook-form';
import { BsPlusCircle } from 'react-icons/bs';
import { IoCloseCircleOutline } from 'react-icons/io5';
import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/ai';
import { v1 } from 'uuid';

import DynamicDropdown from 'shared/components/dynamic-dropdown';
import DetailTable from 'shared/components/detail-table';
import ValidatingInput from 'shared/components/validating-text-input';
import { DropdownDataProvider } from 'shared/data-providers/dropdown-data-provider';
import { calculateProductPrice, getProductLcds } from 'utils/product';
import { Purchase, PurchaseProduct } from 'types/purchase';
import useEditableTable from 'hooks/use-editable-table';
import CustomerSelectModal from '../customer-select-modal';
import ProductSelectModal from '../product-select-modal';
import { getProductById } from 'services/product';

import styles from './styles.module.scss';
import { getCustomerByCode, getCustomerDetails } from 'services/customer';
import { getImportDetailsGroupByProduct } from 'services/import';
import CustomerTooltip from 'shared/components/customer-tooltip';
import InputWrapper from 'shared/components/input-wrapper';

import { Product } from 'types/product';
import UIStore from '../../../stores/ui';
import useStores from '../../../hooks/use-stores';

interface IProps {
  initialRows: Array<PurchaseProduct>;
  setValue: UseFormSetValue<Partial<Purchase>>;
  editable: boolean;
  customerId?: number;
  control: Control<any>;
  billingBy?: any;
  importId?: number;
}

const PurchaseDetailsTable = ({
  initialRows,
  setValue,
  editable,
  customerId,
  control,
  billingBy,
  importId,
}: IProps) => {
  const [index, setIndex] = useState<number | null>(null);
  const [productModal, setProductModal] = useState(false);
  const [customerModal, setCustomerModal] = useState(false);
  const [productCount, setProductCount] = useState(0);
  const [price, setPrice] = useState(0);
  const uiStore:UIStore = useStores().uiStore;

  const timeoutRef = useRef<any>();

  const { rows, setRows, swap, updateRow, removeRow, changeSource, rowsRef } =
    useEditableTable<PurchaseProduct>(initialRows);

  const customer = useWatch({ control, name: 'customer' });

  const importImportDetail = async () => {
    let importDetails: any;
    if (importId) {
      uiStore.showLoading();
      try {
        uiStore.showLoading();
        importDetails = await getImportDetailsGroupByProduct(importId);
      } catch (err: any) {
        uiStore.showAlertBox({
          title: 'エラー',
          content: err.message,
          onClose: () => uiStore.hideAlertBox(),
        });
      } finally {
        uiStore.hideLoading();
      }

    }
  
    const tempRows = JSON.parse(JSON.stringify(rowsRef.current));
    let purchaseDetails = [];
    for (let importDetail of importDetails) {
      try {
        uiStore.showLoading();
        const product = await getProductById(importDetail.product_id);
        const purchaseDetail = {
          id: v1(),
          device_type_code: importDetail.device_type_code,
          product_id: importDetail.product_id,
          product_quantity: importDetail.product_quantity,
          customer_id: billingBy ? billingBy.id : customerId,
          customer: billingBy ? billingBy : customer,
          product: product,
        };
        purchaseDetails.push(purchaseDetail);
      } catch (err: any) {
        uiStore.showAlertBox({
          title: 'エラー',
          content: err.message,
          onClose: () => uiStore.hideAlertBox(),
        });
      } finally {
        uiStore.hideLoading();
      }
    }
    setRows([...tempRows, ...purchaseDetails]);
  };

  useEffect(() => {
    rowsRef.current = [...rows];
    // prevent rows setting back values to initialRows on the first render.
    // changeSource is "in" if swap, updateRow or removeRow is used.
    if (changeSource.current === 'in') {
      setValue('purchase_details', rows, { shouldDirty: true });
    }

    timeoutRef.current = setTimeout(() => {
      let count = 0;
      let total = 0;
      rows.forEach((row) => {
        if (row.product?.count_target === 1) count = count + (row.product_quantity || 0);
        total = total + Math.round((row.purchase_unit_price || 0) * (row.product_quantity || 0));
      });
      setProductCount(count);
      setPrice(total);
      timeoutRef.current = null;
    }, 500);
  }, [rows]);

  const deviceTypeDropdownDataProvider = useRef(
    new DropdownDataProvider(
      'device-types/dropdown',
      (item) => ({
        label: item.device_type_code + "　　" + item.device_type_name,
        value: item.device_type_code,
      }),
      { type: 1 }
    )
  ).current;

  const renderHeaders = useCallback(() => {
    const setAllRowsCustomerId = async () => {
      if (!customerId) return;
      changeSource.current = 'in';
      try {
        const customer = await getCustomerDetails(customerId);
        const newRows = rows.map((row) => {
          const newRow: PurchaseProduct = JSON.parse(JSON.stringify(row));
          return {
            ...newRow,
            customer_id: billingBy ? billingBy.id : customerId,
            customer: billingBy ? billingBy : customer,
          };
        });
        setRows(newRows);
      } catch (err: any) {}
    };

    const AddNewRow = (
      <button
        onClick={() => {
          setRows([
            ...rows,
            {
              id: v1(),
              customer_id: billingBy ? billingBy.id : customerId,
              customer: billingBy ? billingBy : customer,
            },
          ]);
          rowsRef.current = [...rowsRef.current, {
            id: v1(),
            customer_id: billingBy ? billingBy.id : customerId,
            customer: billingBy ? billingBy : customer,
          }];
          changeSource.current = 'in';
        }
        }
      >
        <BsPlusCircle size={20} style={{ color: 'white' }} />
      </button>
    );

    const CustomerIdBtn = (
      <div className={styles.customerIdHeader}>
        <p>明細顧客</p>
        <button className="secondary-btn" onClick={setAllRowsCustomerId}>
          更新
        </button>
      </div>
    );

    const headers: Array<any> = [
      { label: '' },
      { label: '種別' },
      { label: '商品CD' },
      { label: '' },
      { label: 'メーカー' },
      { label: '品名' },
      { label: 'スペック' },
      { label: '液晶' },
      { label: '数量' },
      { label: '参照基準' },
      { label: '買取単価' },
      { label: '買取合計' },
      { label: '備考' },
      { label: '店舗' },
      { label: CustomerIdBtn },
      { label: AddNewRow },
    ];

    return headers;
  }, [customerId, rows, changeSource, setRows]);

  const renderRow = useCallback(
    (item: Partial<PurchaseProduct>, index: number) => {
      const upDownArrow = editable && (
        <div className="d-flex flex-column align-items-center justify-content-center">
          <AiFillCaretUp className="arrow-icon" onClick={() => swap(index, 'up')} />
          <AiFillCaretDown className="arrow-icon" onClick={() => swap(index, 'down')} />
        </div>
      );
      const deviceTypeCode = (
        <div>
          <DynamicDropdown
            dataProvider={deviceTypeDropdownDataProvider}
            value={item.device_type_code}
            onChange={(newValue) => {
              updateRow(index, {
                device_type_code: newValue ? newValue.toString() : undefined,
              });
            }}
            isClearable={false}
            disabled={!editable}
          />
        </div>
      );
      const productId = (
        <ValidatingInput
          type="number"
          allowFullWidth
          value={item.product_id?.toString()}
          validate={async (value, showError) => {
            let payload: any = {};
            let hasError = false;
            if (value === '') {
              payload.product_id = undefined;
              payload.product = undefined;
            } else {
              try {
                const res = await getProductById(parseInt(value));
                payload.product_id = res.id;
                payload.device_type_code = res.device_type_code;
                payload.product = res;
              } catch (err) {
                payload.product_id = parseInt(value);
                payload.product = undefined;
                hasError = true;
              }
            }
            updateRow(index, payload);
            showError(hasError);
          }}
          disabled={!editable}
        />
      );
      const productModalBtn = (
        <button
          onClick={() => {
            setIndex(index);
            setProductModal(true);
          }}
          disabled={!editable}
        >
          参
        </button>
      );
      const makerName = item.product?.maker?.maker_name;
      const productName = item.product?.product_name;
      const spec = item.product?.spec;
      const productLCDs = item.product && getProductLcds(item.product);
      const productQuantity = (
        <InputWrapper
          type="number"
          isFloat
          allowFullWidth
          defaultValue={item.product_quantity}
          onChange={(value: any) => {
            updateRow(index, { product_quantity: parseFloat(value as string) });
          }}
          disabled={!editable}
        />
      );
      const productPrice =
        item.product &&
        calculateProductPrice(item.product, item.device_type_code).toLocaleString('en-US');
      const purchaseUnitPrice = (
        <InputWrapper
          type="number"
          isFloat
          allowFullWidth
          defaultValue={item.purchase_unit_price}
          disabled={!editable}
          onChange={(value: any) => {
            updateRow(index, {
              purchase_unit_price: parseFloat(value as string),
            });
          }}
        />
      );
      const totalPrice = Math.round(
        (item.product_quantity || 0) * (item.purchase_unit_price || 0)
      ).toLocaleString('en-US');
      const detailNote = (
        <InputWrapper
          defaultValue={item.purchase_detail_note}
          onChange={(value: any) => {
            updateRow(index, { purchase_detail_note: value });
          }}
          readOnly={!editable}
        />
      );
      const store = (
        <InputWrapper
          defaultValue={item.store || ''}
          onChange={(value: any) => {
            updateRow(index, { store: value });
          }}
          disabled={!editable}
        />
      );
      const customerCodeInput = (
        <div className={styles.customerId} aria-label="customer-id">
          <CustomerTooltip
            disabled={!editable}
            customer={item.customer}
            onUpdate={async (code: string) => {
              let payload: any = {};
              try {
                const customer = await getCustomerByCode(code);
                payload = {
                  customer_id: customer.id,
                  customer,
                };
                updateRow(index, payload);
              } catch (err: any) {
                payload = {
                  customer_id: undefined,
                  customer: undefined,
                };
              } finally {
                updateRow(index, payload);
              }
            }}
          />

          {editable && (
            <button
              onClick={() => {
                setIndex(index);
                setCustomerModal(true);
              }}
            >
              参
            </button>
          )}
        </div>
      );
      const removeIcon = editable && (
        <IoCloseCircleOutline size={25} className="delete-icon" onClick={() => removeRow(index)} />
      );

      const arr = [
        upDownArrow,
        deviceTypeCode,
        productId,
        productModalBtn,
        makerName,
        productName,
        spec,
        productLCDs,
        productQuantity,
        productPrice,
        purchaseUnitPrice,
        totalPrice,
        detailNote,
        store,
        customerCodeInput,
        removeIcon,
      ];

      return arr;
    },
    [editable, swap, removeRow, updateRow, customerId]
  );

  const renderTotalRow = () => (
    <>
      <td colSpan={8} align="right">
        合計
      </td>
      <td style={{ textAlign: 'right', width: '5em', padding: '0 0.25em' }}>{productCount}</td>
      <td style={{ width: '5em' }} />
      <td style={{ width: '5em' }} />
      <td colSpan={4} style={{ textAlign: 'left', padding: '0 0.25em' }}>
        {price.toLocaleString('en-US')}
      </td>
    </>
  );

  return (
    <div>
      {importId && editable && (
        <div className={styles.importImportDetailBtnContainer}>
          <button className="secondary-btn" onClick={importImportDetail}>
            入庫取得
          </button>
        </div>
      )}
      <div className={`${styles.detailTableWrapper} purchase-table__override`}>
        <DetailTable
          headers={renderHeaders()}
          data={rows}
          renderRow={renderRow}
          renderTotalRow={renderTotalRow}
        />
      </div>

      {customerModal && (
        <CustomerSelectModal
          onClose={() => setCustomerModal(false)}
          onSelect={(customer) => {
            const payload: any = {
              customer_id: customer.id,
              customer,
            };

            updateRow(index as number, payload);
            setCustomerModal(false);
          }}
        />
      )}
      {productModal && (
        <ProductSelectModal
          onClose={() => setProductModal(false)}
          onSelect={(product) => {
            const payload = {
              product_id: product.id,
              device_type_code: product.device_type_code,
              product,
            };
            updateRow(index as number, payload);
            setProductModal(false);
          }}
        />
      )}
    </div>
  );
};

export default PurchaseDetailsTable;
