// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_popoverContent__x1eU9 {\n  padding: 0.5em;\n  border-radius: 0.25em;\n  font-size: 1.2rem;\n}", "",{"version":3,"sources":["webpack://./src/shared/components/tooltip-trigger/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,qBAAA;EACA,iBAAA;AACF","sourcesContent":[".popoverContent {\n  padding: 0.5em;\n  border-radius: 0.25em;\n  font-size: 1.2rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popoverContent": "styles_popoverContent__x1eU9"
};
export default ___CSS_LOADER_EXPORT___;
