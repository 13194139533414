import useStores from 'hooks/use-stores';
import { observer } from 'mobx-react';
import { useMemo } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { MdClose } from 'react-icons/md';
import UIStore from 'stores/ui';
import { popupConfirmBack } from 'utils/modals';
import { convertToHalfWidth } from 'utils/validator';
import styles from './styles.module.scss';

interface IProps {
  initialValues?: any;
  onClose: () => void;
  onSave: (data: any) => void;
  onDelete: () => void;
}

const EditModal = observer(({ initialValues, onClose, onSave, onDelete }: IProps) => {
  const initialFormValues = useMemo(() => {
    return initialValues ? initialValues : { status: 1 };
  }, []);

  const {
    setValue,
    register,
    handleSubmit,
    control,
    formState: { isDirty, isValid },
  } = useForm({
    defaultValues: initialFormValues,
    mode: 'onChange',
  });

  const makerCode = useWatch({ control, name: 'make_code' });

  const uiStore: UIStore = useStores().uiStore;
  const modalTitle = useMemo(() => {
    return !initialValues ? 'メーカー作成モーダル' : 'メーカー編集モーダル';
  }, [initialValues]);

  const onSubmit = (data: any) => {
    onSave(data);
  };

  const onErrors = (errors: any) => {
    console.log('Errors: ', errors);
  };

  return (
    <Modal show backdrop="static" centered>
      <div className="edit-modal">
        <div className="edit-modal--title">
          {modalTitle}
          <button
            onClick={(event: any) => {
              event.preventDefault();
              popupConfirmBack(isDirty, () => onClose());
            }}
          >
            <MdClose />
          </button>
        </div>
        <Form onSubmit={handleSubmit(onSubmit, onErrors)}>
          <Form.Group className={styles.group}>
            <Form.Label className="required">メーカーCD</Form.Label>
            <Form.Control
              disabled={!!initialValues?.maker_code}
              {...register('maker_code', {
                required: true,
                onChange(event) {
                  setValue('maker_code', convertToHalfWidth(event.target.value), {
                    shouldDirty: true,
                  });
                },
              })}
            />
          </Form.Group>

          <Form.Group className={styles.group}>
            <Form.Label className="required">メーカー名</Form.Label>
            <Form.Control {...register('maker_name', { required: true, maxLength: 100 })} />
          </Form.Group>

          <Form.Group className={styles.group}>
            <Form.Label>メーカーフリガナ</Form.Label>
            <Form.Control {...register('maker_kana', { maxLength: 100 })} />
          </Form.Group>

          <Form.Group className="d-flex align-items-center">
            <Form.Label style={{ width: '35%', margin: 0 }}>使用中止</Form.Label>
            <Controller
              control={control}
              name="status"
              render={({ field: { value, onChange } }) => (
                <Form.Check
                  className="custom-checkbox"
                  // unchecked = active, checked = inactive
                  defaultChecked={!value}
                  onChange={(event) => onChange(event.target.checked ? 0 : 1)}
                />
              )}
            />
          </Form.Group>

          <div className="d-flex justify-content-end">
            {initialValues && (
              <button
                className="edit-modal--delete"
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  onDelete();
                }}
              >
                削除
              </button>
            )}

            <button className="edit-modal--save" type="submit" disabled={!isValid}>
              登録
            </button>
          </div>
        </Form>
      </div>

      {isDirty && uiStore.confirmBox.visible && <div className="overlappedBackdrop"></div>}
    </Modal>
  );
});

export default EditModal;
