import moment from 'moment';
import { useRef, useState } from 'react';
import { Modal, Table } from 'react-bootstrap';
import { AiOutlineClose } from 'react-icons/ai';
import { BsPlusCircle } from 'react-icons/bs';
import { IoCloseCircleOutline } from 'react-icons/io5';
import { NumericFormat } from 'react-number-format';
import { useQuery } from 'react-query';
import Select from 'react-select';
import { v4 } from 'uuid';

import useStores from 'hooks/use-stores';
import { getWithdrawalInfo } from 'services/withdrawal';
import ReactDatePickerWrapper from 'shared/components/datepicker-wrapper';
import UIStore from 'stores/ui';
import { Withdrawal, WithdrawalCustomer, withdrawalTypes } from 'types/withdrawal';
import { popupConfirmBack, popupConfirmDelete } from 'utils/modals';

import styles from './styles.module.scss';
import { getCustomerByCode } from 'services/customer';
import CustomerSelectModal from 'shared/containers/customer-select-modal';

interface IProps {
  // withdrawalCustomer: WithdrawalCustomer;
  date: {
    year: number;
    month: number;
  };
  onClose: any;
  onUpdate: (payload: any) => void;
  completed: boolean;
}

const EditAllModal = ({ date, onClose, onUpdate, completed }: IProps) => {
  const uiStore: UIStore = useStores().uiStore;

  const [withdrawalList, setWithdrawalList] = useState<Array<Partial<Withdrawal>>>([]);
  const [customerCode, setCustomerCode] = useState('');
  const [customerName, setCustomerName] = useState('');
  const [customerId, setCustomerId] = useState<number>();
  const [customerModal, setCustomerModal] = useState(false);

  const initialValues = useRef<any>([]);

  const customerQuery = useQuery(
    ['customer', customerCode],
    () => getCustomerByCode(customerCode),
    {
      enabled: customerCode.length > 0,
      onSuccess: (res) => {
        setCustomerName(res.customer_name);
        setCustomerId(res.id);
      },
      onError: () => {
        setCustomerName('');
        setCustomerId(undefined);
      },
    }
  );

  const withdrawalQuery = useQuery(
    ['withdrawal', date.month, date.year, customerId],
    () => getWithdrawalInfo({ year: date.year, month: date.month, customerId }),
    {
      enabled: !!customerId,
      onSuccess: (data: any) => {
        setWithdrawalList(data.withdrawals);
        initialValues.current = data.withdrawals;
      },
    }
  );

  const formattedDate =
    date.year + '/' + (`${date.month}`.length === 1 ? `0${date.month}` : `${date.month}`);

  const updateWithdrawal = (index: number, payload: any) => {
    const newList = [...withdrawalList];
    newList.splice(index, 1, { ...withdrawalList[index], ...payload });

    setWithdrawalList(newList);
  };

  const removeWithdrawal = (index: number) => {
    const newList = [...withdrawalList];
    newList.splice(index, 1);

    setWithdrawalList(newList);
  };

  const onRegisterClick = (_event: any) => {
    let formattedList = withdrawalList.map((withdrawal) => {
      if (typeof withdrawal.id === 'string') {
        return {
          ...withdrawal,
          id: undefined,
        };
      }

      return withdrawal;
    });
    const isMissingDate = formattedList.some((withdrawal) => !withdrawal.withdrawal_date);
    const withdrawalSum = formattedList.reduce((acc, curr) => {
      return acc + (curr.out_amount || 0);
    }, 0);

    // if (withdrawalSum > withdrawalCustomer.last_request_balance) {
    //   uiStore.showAlertBox({
    //     title: 'エラー',
    //     content: '出金額が前回買掛額より高くすることはできません。',
    //   });
    //   return;
    // }
    if (isMissingDate) {
      uiStore.showAlertBox({
        title: 'エラー',
        content: ' 出金日は、必ず指定してください。',
      });
      return;
    }

    let payload = {
      month: date.month,
      year: date.year,
      customerId,
      data: {
        withdrawals: formattedList,
      },
    };
    onUpdate(payload);
  };

  const onBackClick = (_event: any) => {
    let isDirty = JSON.stringify(initialValues.current) !== JSON.stringify(withdrawalList);

    popupConfirmBack(isDirty, onClose);
  };

  const renderCustomerInfoTable = () => (
    <Table bordered className={styles.customerInfoTable}>
      <thead>
        <tr>
          <th>処理年月</th>
          <th>顧客CD</th>
          <th>顧客</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <span>{formattedDate}</span>
          </td>
          <td>
            <input
              value={customerCode}
              onChange={(e) => {
                setCustomerCode(e.target.value);
              }}
            />
          </td>
          <td style={{ textAlign: 'left' }}>
            <p>{customerName}</p>
          </td>
          <td>
            <button onClick={() => setCustomerModal((customerModal) => !customerModal)}>参照</button>
          </td>
        </tr>
      </tbody>
    </Table>
  );

  // const queryData = useQuery(
  //   ['withdrawal', date.month, date.year, withdrawalCustomer.customer.id],
  //   () =>
  //     getWithdrawalInfo({
  //       year: date.year,
  //       month: date.month,
  //       customerId: withdrawalCustomer.customer.id,
  //     }),
  //   {
  //     onSuccess: (data: any) => {
  //       setWithdrawalList(data.withdrawals);
  //       initialValues.current = data.withdrawals;
  //     },
  //   }
  // );

  return (
    <Modal show centered onBackdropClick={onClose} dialogClassName={styles.dialog}>
      <div className={styles.content}>
        <div className={styles.head}>
          <h3>出金登録モーダル</h3>
          <button onClick={onBackClick}>
            <AiOutlineClose />
          </button>
        </div>
        {renderCustomerInfoTable()}
        <Table bordered className={styles.withdrawalTable}>
          <thead>
            <tr>
              <th>出金日</th>
              <th>出金方法</th>
              <th>出金額</th>
              {!completed && (
                <th>
                  <button
                    onClick={(_event: any) => {
                      setWithdrawalList([
                        ...withdrawalList,
                        {
                          withdrawal_date: '',
                          withdrawal_type: 1,
                          out_amount: 0,
                          id: v4(),
                        },
                      ]);
                    }}
                  >
                    <BsPlusCircle />
                  </button>
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {withdrawalList.map((withdrawal, index) => (
              <tr key={withdrawal.id}>
                <td>
                  <ReactDatePickerWrapper
                    className={styles.dateInput}
                    selected={
                      withdrawal.withdrawal_date
                        ? moment(withdrawal.withdrawal_date, 'YYYY-MM-DD').toDate()
                        : null
                    }
                    dateFormat="yyyy/MM/dd"
                    onChange={(date) => {
                      updateWithdrawal(index, {
                        withdrawal_date: moment(date).format('YYYY-MM-DD'),
                      });
                    }}
                    disabled={completed}
                  />
                </td>
                <td>
                  <Select
                    className={styles.dropdown}
                    value={withdrawalTypes[(withdrawal.withdrawal_type as number) - 1]}
                    options={withdrawalTypes}
                    isClearable={false}
                    isSearchable={false}
                    onChange={(newValue) => {
                      updateWithdrawal(index, {
                        withdrawal_type: newValue.value,
                      });
                    }}
                    isDisabled={completed}
                  />
                </td>
                <td>
                  <NumericFormat
                    type="text"
                    value={withdrawal.out_amount}
                    decimalScale={0}
                    thousandSeparator=","
                    allowNegative={true}
                    allowLeadingZeros={false}
                    allowedDecimalSeparators={[]}
                    onValueChange={(values, sourceInfo) => {
                      updateWithdrawal(index, {
                        out_amount: values.floatValue,
                      });
                    }}
                    disabled={completed}
                  />
                </td>
                {!completed && (
                  <td>
                    <div className={styles.deleteBtnWrapper}>
                      <IoCloseCircleOutline
                        size={20}
                        aria-label="remove-row"
                        className="delete-icon"
                        onClick={(_event: any) => popupConfirmDelete(() => removeWithdrawal(index))}
                      />
                    </div>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </Table>

        {!completed && (
          <button aria-label="register" className="secondary-btn" onClick={onRegisterClick}>
            登録
          </button>
        )}
      </div>

      {customerModal && (
        <CustomerSelectModal
          onClose={() => setCustomerModal(false)}
          onSelect={(item) => {
            setCustomerCode(item.customer_code);
            setCustomerName(item.customer_name);
            setCustomerModal(false);
          }}
        />
      )}
    </Modal>
  );
};

export default EditAllModal;
