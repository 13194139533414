import moment from 'moment';

import { Customer } from './customer';
import { CustomerGroup } from './customer-group';
import { Product } from './product';
import { Purchase } from './purchase';
import { User } from './user';

export const importType: {
  [key: string]: string;
} = {
  '1': '預かり', // azukari
  '2': '廃棄',  // waste
};

export type Import = {
  id: number;
  import_code: string;
  import_type: 1 | 2;
  updated_at: string;
  customer_id: number;
  input_date: string;
  import_date: string;
  count_deliveries: number;
  deliveries: Array<any>;
  sales: Array<any>;
  stocks: Array<any>;
  complete: 0 | 1;
  stock_register: 0 | 1;
  import_detail: string;
  group_id: number;
  user_pic_id: number;
  user_input_id: number;
  user_confirm_id: number;
  business_contact: string;
  import_memo: string;
  purchase_id?: number;
  pickup_fee: number;
  labor_cost: number;
  import_fee: number;
  import_details: Array<ImportProduct>;

  customer: Customer;
  purchase?: Purchase;
};

export type ImportProduct = {
  id: number;
  device_type_code: string;
  product_id: number;
  qr: 0 | 1;
  frame_number: string;
  cell_number: string;
  base_number: string;
  frame_color: string;
  destination: string;
  import_detail_note: string;
  import_detail_detail: string;
  import_detail_memo: string;
  check_match?: { [key: string]: boolean };
  import_qr_detail?: { [key: string]: any };
  is_duplicate: 0 | 1;

  product: Partial<Product>;
};

export interface IImportFilter {
  input_date_from: string;
  input_date_to: string;
  import_date_from: string | null;
  import_date_to: string | null;
  import_code: string | null;
  import_type: 1 | 2 | null;
  customer_id: number | null;
  user_input_id: number | null;
  created_delivery: 0 | 1 | null;
  created_sale: 0 | 1 | null;
  complete: 0 | 1 | null;
  group_id: number | null;
  sort: string | null;
  sort_type: 'asc' | 'desc';
}

const now = new Date();
const date = now.getDate();
const month = now.getMonth();
const year = now.getFullYear();

export const DEFAULT_IMPORT_FILTER: IImportFilter = {
  input_date_from: moment(new Date(year, month - 1, date)).format('YYYY-MM-DD'),
  input_date_to: moment(now).format('YYYY-MM-DD'),
  import_date_from: null,
  import_date_to: null,
  import_code: null,
  import_type: null,
  customer_id: null,
  user_input_id: null,
  created_delivery: null,
  created_sale: null,
  complete: null,
  group_id: null,
  sort: 'import_code',
  sort_type: 'asc',
};
