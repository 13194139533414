import useStores from 'hooks/use-stores';
import { useEffect, useRef, useState } from 'react';
import { Form, Spinner } from 'react-bootstrap';
import { IoCloseCircleOutline } from 'react-icons/io5';
import { MdClose, MdError } from 'react-icons/md';
import { useQuery } from 'react-query';

import { getCustomerByCode } from 'services/customer';
import CustomerSelectModal from 'shared/containers/customer-select-modal';
import UIStore from 'stores/ui';
import { GroupCustomer } from 'types/customer';

interface IProps {
  item: Partial<GroupCustomer>;
  onRemove: () => void;
  setRow: (customer: Partial<GroupCustomer>) => void;
  rows?: Readonly<Partial<GroupCustomer>>[];
}

const TableRow = ({ item, onRemove, setRow, rows }: IProps) => {
  const [customerCode, setCustomerCode] = useState('');
  const [customer, setCustomer] = useState<Partial<GroupCustomer> | null>(null);
  const [error, setError] = useState<any>(null);
  const [showCustomerModal, setShowCustomerModal] = useState<boolean>(false);
  const uiStore: UIStore = useStores().uiStore;

  const { isLoading, refetch } = useQuery(
    ['customer', customerCode],
    () => getCustomerByCode(customerCode),
    {
      enabled: false,
      onSuccess: (cus) => {
        setError(null);
        setRow({ ...cus, key: customer!.key });
        setCustomer(cus);
      },
      onError: (_err: any) => {
        setError('error');
        setRow({ customer_code: customerCode, key: customer!.key });
        setCustomer({ key: customer!.key });
      },
    }
  );

  // debouncer
  const timeoutFunc = useRef<any>(null);

  const openCustomerModal = () => {
    setShowCustomerModal(true);
  };

  const closeCustomerModal = () => {
    setShowCustomerModal(false);
  };

  const onInputChange = (event: any) => {
    if (timeoutFunc.current !== null) clearTimeout(timeoutFunc.current);

    const val = event.target.value;
    if (val === '') {
      setRow({ key: customer!.key });
      setError(null);
    } else {
      timeoutFunc.current = setTimeout(() => {
        refetch();
        timeoutFunc.current = null;
      }, 300);
    }

    setCustomerCode(val);
  };

  useEffect(() => {
    setCustomerCode(item.customer_code || '');
    setCustomer(item);
  }, []);

  useEffect(() => {
    // if the current row is empty, don't bother checking duplication.
    if (item === undefined || JSON.stringify(item) === '{}' || item.id === undefined) return;

    if (rows) {
      let count = rows.reduce((acc, curr) => {
        // skip any row that is empty
        if (curr === undefined || JSON.stringify(curr) === '{}') {
          return acc;
        }
        return acc + (curr.id === item.id ? 1 : 0);
      }, 0);

      if (count > 1) setError('error');
      else setError(null);
    }
  }, [rows, item]);

  return (
    <>
      <tr>
        <td>
          <div className="d-flex align-items-center">
            <Form.Control value={customerCode} onChange={onInputChange} />
            {isLoading && <Spinner animation="border" size="sm" />}
            {error && <MdError style={{ width: '1.5rem', height: '1.5rem', color: 'red' }} />}
          </div>
        </td>
        <td>
          <button onClick={openCustomerModal}>参</button>
        </td>
        <td>
          <Form.Control value={customer?.customer_name || ''} disabled />
        </td>
        <td>
          <Form.Control value={customer?.hall_name || ''} disabled />
        </td>
        <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
          {customer?.status === 0 && <MdClose />}
        </td>
        <td style={{ verticalAlign: 'middle' }}>
          <IoCloseCircleOutline className="delete-icon w-100" onClick={onRemove} />
        </td>
      </tr>
      {showCustomerModal && (
        <CustomerSelectModal
          onClose={closeCustomerModal}
          onSelect={(selectedCustomer) => {
            if (selectedCustomer.customer_code === customerCode) {
              closeCustomerModal();
              return;
            }

            const count = rows!.reduce((acc, curr) => {
              if (selectedCustomer.id === curr.id) {
                return acc + 1;
              }
              return acc;
            }, 0);

            if (count > 0) {
              uiStore.showAlertBox({
                title: 'エラー',
                content: `この顧客が既にグループに追加されました。`,
              });
            } else {
              setRow({ ...selectedCustomer, key: customer!.key });
              setCustomerCode(selectedCustomer.customer_code);
              setCustomer({ ...selectedCustomer, key: customer!.key });
              closeCustomerModal();
            }
          }}
        />
      )}
    </>
  );
};

export default TableRow;
