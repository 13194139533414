// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@charset \"UTF-8\";\n.styles_container__tyIPO {\n  display: flex;\n  -webkit-user-select: none;\n          user-select: none;\n}\n.styles_container__tyIPO input {\n  border-radius: 0 !important;\n}\n.styles_container__tyIPO input:checked {\n  background-color: #000;\n  background-image: none !important;\n  outline: none;\n  border: none;\n}\n.styles_container__tyIPO input:focus {\n  outline: none !important;\n  box-shadow: none !important;\n}\n\n.styles_checkbox__CrjOF {\n  display: flex;\n  align-items: center;\n  width: 100%;\n  padding: 0 0.75rem;\n  height: 30px;\n  background-color: white;\n  border: 1px solid lightgray;\n}\n.styles_checkbox__CrjOF span::before {\n  content: \"  \";\n}", "",{"version":3,"sources":["webpack://./src/shared/components/checkbox/styles.module.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAEhB;EACE,aAAA;EACA,yBAAA;UAAA,iBAAA;AAAF;AAEE;EACE,2BAAA;AAAJ;AAEI;EACE,sBAAA;EACA,iCAAA;EACA,aAAA;EACA,YAAA;AAAN;AAGI;EACE,wBAAA;EACA,2BAAA;AADN;;AAMA;EACE,aAAA;EACA,mBAAA;EACA,WAAA;EACA,kBAAA;EACA,YAAA;EACA,uBAAA;EACA,2BAAA;AAHF;AAKE;EACE,aAAA;AAHJ","sourcesContent":["@import '../../../assets/styles/variables';\n\n.container {\n  display: flex;\n  user-select: none;\n\n  input {\n    border-radius: 0 !important;\n\n    &:checked {\n      background-color: $blackColor;\n      background-image: none !important;\n      outline: none;\n      border: none;\n    }\n\n    &:focus {\n      outline: none !important;\n      box-shadow: none !important;\n    }\n  }\n}\n\n.checkbox {\n  display: flex;\n  align-items: center;\n  width: 100%;\n  padding: 0 0.75rem;\n  height: 30px;\n  background-color: white;\n  border: 1px solid lightgray;\n\n  span::before {\n    content: '\\00a0\\00a0';\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "styles_container__tyIPO",
	"checkbox": "styles_checkbox__CrjOF"
};
export default ___CSS_LOADER_EXPORT___;
