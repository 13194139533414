import moment from 'moment';

import { Group } from './group';
import { Customer } from './customer';
import { Product } from './product';

export type InventoryItem = {
  id: number;
  stock_code: string;
  stock_type: number;
  stock_date: string;
  stock_note: string;
  stock_detail: string;
  stock_memo: string;
  frame_number: string;
  frame_code: string;
  frame_color: string;
  frame_export?: {
    id: number;
    export_stock_type: 'FRAME' | 'BASE_CELL' | 'FULL';
    export_code: string;
    export_date: string;
    customer_id: number;
    customer: Customer;
    export_id: number;
    export?: any;
  };
  cell_number: string;
  cell_code: string;
  base_number: string;
  base_code: string;
  base_cell_export?: {
    id: number;
    export_stock_type: 'FRAME' | 'BASE_CELL' | 'FULL';
    export_code: string;
    export_date: string;
    customer_id: number;
    customer: Customer;
    export_id: number;
    export?: any;
  };
  pallet_code: string | null;
  destination: string;
  qr: 0 | 1;
  updated_at: string;
  export_flag: 1 | 2 | 3 | 9;
  group_id: number;
  import_id?: number | null;
  import?: any;
  import_detail_id: number | null;
  import_device_type_code: string;
  current_device_type_code: string;
  customer_id: number;
  product_id: number;

  group?: Group;
  customer?: Customer;
  product?: Product;

  export_details: Array<any>;
};

export interface IInventoryFilter {
  export_flag: 0 | 1 | 2;
  group_id?: number | null;
  stock_date_from?: string | null;
  stock_date_to?: string | null;
  export_date_from?: string | null;
  export_date_to?: string | null;
  customer_id?: string | null;
  export_customer_id?: string | null;
  frame_cell_base_number?: string | null;
  qr?: number | null;
  pallet_code?: string | null;
  import_code?: string | null;
  export_code?: string | null;
  stock_type?: 1 | 2 | null;
  maker_id?: number | null;
  model_name?: string | null;
  stock_note?: string | null;
  stock_detail?: string | null;
  destination?: string | null;
  stock_memo?: string | null;
  frame_color?: string | null;
  sort: string | null;
  sort_type: 'asc' | 'desc';
}

// const now = new Date();
// const date = now.getDate();
// const month = now.getMonth();
// const year = now.getFullYear();
export const DEFAULT_INVENTORY_FILTER: IInventoryFilter = {
  export_flag: 1,
  group_id: null,
  stock_date_from: null,
  stock_date_to: null,
  export_date_from: null,
  export_date_to: null,
  customer_id: null,
  export_customer_id: null,
  frame_cell_base_number: null,
  qr: null,
  pallet_code: null,
  import_code: null,
  export_code: null,
  stock_type: null,
  maker_id: null,
  model_name: null,
  stock_note: null,
  stock_detail: null,
  destination: null,
  stock_memo: null,
  frame_color: null,
  sort: 'stock_date',
  sort_type: 'desc',
};
