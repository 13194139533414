import { useEffect, useState } from 'react';
import { AsyncPaginate } from 'react-select-async-paginate';
import { get } from 'services';
import FilterItem from '../filter-item';
import styles from './styles.module.scss';

interface IProps {
  label?: string;
  target: string;
  disabled?: boolean;
  onChange?: (...arg: any[]) => void;
  defaultValue?: any;
  query?: { [key: string]: any };
  formatter: (item: any) => {
    label: string | number;
    value: any;
  };
  placeholder?: string;
  dropdownStyle?: any;
  isClearable?: boolean;
  autoDefaultValue?: boolean;
}

const UniversalDropdown = ({
  disabled = false,
  onChange,
  defaultValue,
  target,
  formatter,
  label,
  placeholder,
  dropdownStyle,
  isClearable = true,
  autoDefaultValue,
}: IProps) => {
  const [option, setOption] = useState<any>(null);

  useEffect(() => {
    if (defaultValue || autoDefaultValue) {
      loadOptions('', []);
    }
  }, []);

  const loadOptions = async (_search: string, prevOptions: any) => {
    try {
      const response = await get(`${target}/dropdown`, {});
      const options: Array<any> = response.map((item: any, _index: number) => {
        return formatter(item);
      });

      if (defaultValue) {
        if (typeof defaultValue === 'object') {
          setOption(defaultValue);
        } else {
          // if defaultValue is just the value, it has to be the same data type
          // as the value return from the formatter.
          let temp: any = null;
          options.forEach((option) => {
            if (option.value === defaultValue) {
              temp = option;
            }
          });
          if (temp !== null) setOption(temp);
        }
      } else {
        if (autoDefaultValue) {
          setOption(options[0]);
        }
      }
      return { options };
    } catch (error) {
      return { options: prevOptions };
    }
  };

  useEffect(() => {
    onChange && onChange(option ? option.value : null);
  }, [option]);

  return (
    <div className={styles.container}>
      {label && <FilterItem label={label} />}
      <AsyncPaginate
        className={dropdownStyle}
        loadOptions={loadOptions}
        onChange={(event: any) => {
          setOption(event);
        }}
        isSearchable={false}
        isClearable={isClearable}
        placeholder={placeholder}
        isDisabled={disabled}
        value={option}
        loadingMessage={() => <div>Loading...</div>}
        noOptionsMessage={() => <div>try again later.</div>}
      />
    </div>
  );
};

export default UniversalDropdown;
