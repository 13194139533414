import { useState } from 'react';
import { Modal } from 'react-bootstrap';

import styles from './styles.module.scss';

interface IProps {
  onClose: () => void;
  onDone: (importCode: string) => void;
}

const ImportCodeModal = ({ onClose, onDone }: IProps) => {
  const [importCode, setImportCode] = useState('');

  return (
    <Modal show size="sm">
      <div className={styles.container}>
        <div style={{ fontWeight: 'bold', textAlign: 'center', marginBottom: '2rem' }}>
          入庫設定モーダル
        </div>
        <div style={{ display: 'flex', maxWidth: '100%' }}>
          <div
            style={{
              backgroundColor: '#008060',
              color: 'white',
              padding: '0.25rem 0.75rem',
              minWidth: 'max-content',
            }}
          >
            入庫番号
          </div>
          <input
            type="text"
            style={{ border: '1px solid #d3d8de', padding: '0 0.5rem', flex: 1, minWidth: 0 }}
            onChange={(event) => {
              setImportCode(event.target.value);
            }}
          />
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '2rem',
          }}
        >
          <button
            style={{
              backgroundColor: '#d3d8de',
              border: '1px solid #d3d8de',
              padding: '0.25rem 0.75rem',
              fontSize: '1rem',
              borderRadius: '0.25rem',
              marginRight: '1rem',
            }}
            onClick={onClose}
          >
            閉じる
          </button>
          <button
            style={{
              backgroundColor: '#008060',
              border: '1px solid #008060',
              color: 'white',
              padding: '0.25rem 0.75rem',
              fontSize: '1rem',
              borderRadius: '0.25rem',
            }}
            onClick={() => onDone(importCode)}
          >
            登錄
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ImportCodeModal;
