// Built-in
import { Breadcrumb } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';

// Component
import InventoryDetailsContainer from 'shared/containers/inventory-details';

const StockDetailScreen = () => {
  const params = useParams();
  const id = params.id ? (isNaN(parseInt(params.id)) ? undefined : parseInt(params.id)) : undefined;

  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/home' }}>
          TOP
        </Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/inventories' }}>
          在庫
        </Breadcrumb.Item>
        <Breadcrumb.Item active>在庫詳細</Breadcrumb.Item>
      </Breadcrumb>

      <InventoryDetailsContainer id={id} />
    </div>
  );
};

export default StockDetailScreen;
