import { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import { Form, Modal } from 'react-bootstrap';
import { useForm, Controller, useWatch } from 'react-hook-form';
import { MdClose } from 'react-icons/md';

import UIStore from 'stores/ui';
import useStores from 'hooks/use-stores';

import styles from './styles.module.scss';
import { popupConfirmBack } from 'utils/modals';
import ReactDatePickerWrapper from 'shared/components/datepicker-wrapper';
import { convertToHalfWidth } from 'utils/validator';

interface IProps {
  onClose: () => void;
  onCreate: (data: any) => void;
}

const EditModal = observer(({ onClose, onCreate }: IProps) => {
  const uiStore: UIStore = useStores().uiStore;

  const initialFormValues = useMemo(() => {
    return {
      effective_date: new Date(),
      tax_rate: 0,
    };
  }, []);

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { isDirty, isValid },
  } = useForm<any>({
    defaultValues: initialFormValues,
    mode: 'onChange',
  });

  const taxRate = useWatch({ control, name: 'tax_rate' });

  useEffect(() => {
    console.log(taxRate);
  }, [taxRate]);

  const onSubmit = (data: any) => {
    const payload = JSON.parse(JSON.stringify(data));
    console.log(data);
    if (typeof payload.tax_rate === 'string') {
      payload.tax_rate = parseInt(payload.tax_rate);
    }
    console.log(payload);
    onCreate(payload);
  };

  const onErrors = (errors: any) => {
    console.log('Errors: ', errors);
  };

  return (
    <Modal show backdrop="static" centered>
      <div className="edit-modal">
        <div className="edit-modal--title">
          消費税率作成モーダル
          <button
            onClick={(event: any) => {
              event.preventDefault();
              popupConfirmBack(isDirty, () => onClose());
            }}
          >
            <MdClose />
          </button>
        </div>
        <Form onSubmit={handleSubmit(onSubmit, onErrors)}>
          <Form.Group className="d-flex">
            <Form.Label>適用開始日</Form.Label>
            <div style={{ width: '75%', margin: '0 0 0 auto' }}>
              <Controller
                control={control}
                name="effective_date"
                render={({ field }) => (
                  <ReactDatePickerWrapper
                    selected={field.value}
                    className={styles.datePicker}
                    dateFormat="yyyy/MM/dd"
                    onChange={(date: Date) => field.onChange(date)}
                  />
                )}
              />
            </div>
          </Form.Group>
          <Form.Group className={styles.group}>
            <Form.Label className="required">税率(%)</Form.Label>
            <Controller
              control={control}
              name="tax_rate"
              rules={{ required: true }}
              render={({ field: { onChange } }) => (
                <Form.Control
                  value={taxRate}
                  onChange={(event) => {
                    let val = event.target.value;

                    switch (true) {
                      case val === '':
                        onChange(0);
                        break;
                      case /^[０-９0-9]+$/.test(val) === true:
                        onChange(parseInt(convertToHalfWidth(val)));
                        break;
                      case /^[０-９0-9]+\.$/.test(val) === true:
                        onChange(convertToHalfWidth(val));
                        break;
                      case /^[０-９0-9]+\.[０-９0-9]+$/.test(val) === true:
                        onChange(parseFloat(convertToHalfWidth(val)));
                        break;
                      default:
                        break;
                    }
                  }}
                />
              )}
            />
          </Form.Group>
          <div className="d-flex justify-content-end">
            <button className="edit-modal--save" type="submit" disabled={!isValid}>
              登録
            </button>
          </div>
        </Form>
      </div>

      {isDirty && uiStore.confirmBox.visible && <div className="overlappedBackdrop"></div>}
    </Modal>
  );
});

export default EditModal;
