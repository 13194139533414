// Built-in
import { useRef } from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { MdOutlineCircle } from 'react-icons/md';
import { useForm } from 'react-hook-form';

// Component
import DataTable from 'shared/components/data-table';
import TimeRangeFilter from 'shared/components/time-range-filter';
import DynamicDropdownFilter from 'shared/components/dynamic-dropdown-filter';
import CustomerFilter from 'shared/components/customer-filter';
import RadioButtonFilter from 'shared/components/radio-button-filter';
import TextFilter from 'shared/components/text-filter';

// Type
import { DEFAULT_IMPORT_FILTER, IImportFilter, Import, importType } from 'types/import';

// Store
import { DataProvider } from 'shared/data-providers/table-data-provider';
import { DropdownDataProvider } from 'shared/data-providers/dropdown-data-provider';

// Style
import styles from './styles.module.scss';

// Utils
import { openInNewTab } from 'utils';

const groupDataProvider = new DropdownDataProvider('groups/dropdown', (item) => ({
  label: item.group_name,
  value: item.id,
}));

const ImportScreen = () => {
  const navigate = useNavigate();

  const dataProvider = useRef(
    new DataProvider<IImportFilter>({
      dataSource: 'imports',
      localStorageKey: 'import_filters',
      defaultFilters: DEFAULT_IMPORT_FILTER,
    })
  );

  const inputUserDataProvider = useRef(
    new DropdownDataProvider('users/dropdown/input_person', (item) => ({
      label: item.name,
      value: item.id,
    }))
  ).current;

  const { control, setValue, handleSubmit, reset } = useForm<IImportFilter>({
    defaultValues: dataProvider.current.filters,
  });

  const resetFilters = () => {
    reset(DEFAULT_IMPORT_FILTER);
    dataProvider.current.clearFilters();
  };

  const search = handleSubmit((values) => {
    const newFilter = {
      ...values,
      sort: dataProvider.current.filters.sort,
      sort_type: dataProvider.current.filters.sort_type,
    };
    dataProvider.current.setFilters(newFilter);
  });

  const headers = [
    { label: '', oneLine: true, centered: true },
    { label: '入庫番号', key: 'import_code', oneLine: true },
    { label: '入庫種別', oneLine: true },
    { label: '顧客' },
    { label: '入力日', key: 'input_date', oneLine: true },
    { label: '入庫日', key: 'import_date', oneLine: true },
    { label: '配送', oneLine: true, centered: true },
    { label: '売上', oneLine: true, centered: true },
    { label: '詳細' },
    { label: '完了', centered: true },
    { label: '在庫', oneLine: true, centered: true },
  ];

  const delegate = (item: Import) => [
    <button onClick={() => navigate(`/imports/${item.id}`)}>修正</button>,
    <Link to={`/imports/${item.id}`}>{item.import_code}</Link>,
    `${importType[item.import_type]}`,
    `${
      item.customer?.customer_name +
      (item.customer?.hall_name ? '　' + item.customer?.hall_name : '')
    }`,
    item.input_date,
    item.import_date,
    item.deliveries?.length > 0 ? (
      <div>
        {item.deliveries.map((d, index) => (
          <>
            <Link to={`/deliveries/${d.id}`} target="_blank" style={{ display: 'block' }}>
              {d.delivery_code}
            </Link>
          </>
        ))}
      </div>
    ) : (
      item.complete === 0 && (
        <button
          onClick={() => navigate(`/deliveries/create?target_type=IMPORT&target_id=${item.id}`)}
        >
          作成
        </button>
      )
    ),
    item.sales?.length > 0 ? (
      <div>
        {item.sales.map((d, index) => (
          <>
            <Link to={`/sales/${d.id}`} target="_blank" style={{ display: 'block' }}>
              {d.sale_code}
            </Link>
          </>
        ))}
      </div>
    ) : (
      item.complete === 0 && (
        <button onClick={() => navigate(`/sales/create?target_type=IMPORT&target_id=${item.id}`)}>
          作成
        </button>
      )
    ),
    item.import_detail,
    item.complete === 1 && <MdOutlineCircle className="status-icon" />,
    item.stocks.length > 0 ? (
      <button onClick={() => openInNewTab(`/inventories?import_code=${item.import_code}`)}>
        在庫
      </button>
    ) : (
      ''
    ),
  ];

  return (
    <div className="list-screen">
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/home' }}>
          TOP
        </Breadcrumb.Item>
        <Breadcrumb.Item active>入庫</Breadcrumb.Item>
      </Breadcrumb>

      <div className={styles.filterContainer}>
        <div className={styles.inputDate}>
          <TimeRangeFilter
            label="入力日"
            control={control}
            keys={['input_date_from', 'input_date_to']}
          />
        </div>

        <div className={styles.importDate}>
          <TimeRangeFilter
            label="入庫日"
            control={control}
            keys={['import_date_from', 'import_date_to']}
          />
        </div>

        <div className={styles.importCode}>
          <TextFilter label="入庫番号" control={control} keys={['import_code']} />
        </div>

        <div className={styles.importType}>
          <RadioButtonFilter
            label="入庫種別"
            control={control}
            keys={['import_type']}
            options={[
              { label: '全て', value: null },
              { label: '預かり', value: 1 },
              { label: '廃棄', value: 2 },
            ]}
          />
        </div>

        <div className={styles.buttonGroup}>
          <button className="action-btn" onClick={search}>
            検索
          </button>
          <button className="action-btn" onClick={resetFilters}>
            クリア
          </button>
        </div>

        <div className={styles.wrap}></div>

        <div className={styles.groupId}>
          <DynamicDropdownFilter
            label="グループ"
            dataProvider={groupDataProvider}
            control={control}
            keys={['group_id']}
            setValue={setValue}
          />
        </div>

        <div className={styles.customerId}>
          <CustomerFilter label="顧客" control={control} keys={['customer_id']} />
        </div>

        <div className={styles.userInputId}>
          <DynamicDropdownFilter
            label="入力者"
            dataProvider={inputUserDataProvider}
            control={control}
            keys={['user_input_id']}
            setValue={setValue}
          />
        </div>

        <div className={styles.wrap}></div>

        <div className={styles.delivery}>
          <RadioButtonFilter
            label="配送"
            control={control}
            keys={['created_delivery']}
            options={[
              { label: '全て', value: null },
              { label: '作成済', value: 1 },
              { label: '未作成', value: 0 },
            ]}
          />
        </div>

        <div className={styles.sale}>
          <RadioButtonFilter
            label="売上"
            control={control}
            keys={['created_sale']}
            options={[
              { label: '全て', value: null },
              { label: '完了', value: 1 },
              { label: '未完了', value: 0 },
            ]}
          />
        </div>

        <div className={styles.complete}>
          <RadioButtonFilter
            label="完了"
            control={control}
            keys={['complete']}
            options={[
              { label: '全て', value: null },
              { label: '完了', value: 1 },
              { label: '未完了', value: 0 },
            ]}
          />
        </div>
      </div>

      <div className="d-flex justify-content-end">
        <button className="create" onClick={() => navigate('/imports/create')}>
          新しい入庫
        </button>
      </div>

      <div className={styles.tableWrapper}>
        <DataTable<Import, IImportFilter>
          dataProvider={dataProvider.current}
          headers={headers}
          delegate={delegate}
        />
      </div>
    </div>
  );
};

export default ImportScreen;
