import { del, get, post, postFormData, put } from 'services';

export const getInventoryItemById = (id: number) => get(`stocks/${id}`, {});

export const createInventoryItem = (data: any) => post(`stocks`, data);

export const updateInventoryItem = (id: number, data: any) => put(`stocks/${id}`, data);

export const updateInventoryItems = (ids: Array<number>, data: any) =>
  put(`stocks/multiple/${ids.join(',')}`, data);

export const deleteInventoryItems = (ids: Array<number>) => del(`stocks/${ids.join(',')}`);

export const uploadQRFile = (file: File) => {
  const formData = new FormData();
  formData.append('file_qr', file);
  return postFormData('stocks/qr', formData);
};

export const updateMatchingData = (matchedRows: Array<any>, unmatchedRows: Array<any>) =>
  put(`stocks/qr`, { stock_details: matchedRows, stock_qr_details: unmatchedRows });
