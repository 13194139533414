// Built-in
import { useEffect, useRef, useState } from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { useForm, useWatch } from 'react-hook-form';
import { useMutation } from 'react-query';
import { Link } from 'react-router-dom';

// Component
import DataTable from 'shared/components/data-table';
import TextFilter from 'shared/components/text-filter';
import EditModal from './edit-modal';

// Type
import { DEFAULT_LCD_FILTER, ILcdFilter, Lcd } from 'types/lcd';

// Store
import UIStore from 'stores/ui';
import { DataProvider } from 'shared/data-providers/table-data-provider';
import useStores from 'hooks/use-stores';

// Service
import { createLcd, deleteLcd, updateLcd } from 'services/lcd';

// Hook
import { useFormError } from 'hooks/use-form-error';

// Style
import styles from './styles.module.scss';

// Util
import { detectFullWidthCharacter, removeFullWidthChars } from 'utils/validator';

const LCDScreen = () => {
  const uiStore: UIStore = useStores().uiStore;

  const [editModal, setEditModal] = useState(false);
  const [selected, setSelected] = useState<any>(null);

  const dataProvider = useRef(
    new DataProvider<ILcdFilter>({
      dataSource: 'lcds',
      localStorageKey: 'lcd_filters',
      defaultFilters: DEFAULT_LCD_FILTER,
    })
  );

  const { control, handleSubmit, setValue, reset } = useForm<ILcdFilter>({
    defaultValues: dataProvider.current.filters,
  });

  const code = useWatch({ control, name: 'lcd_code' });

  const { showFormError } = useFormError();

  const updateMutation = useMutation((data) => updateLcd(selected.id, data), {
    onSuccess: () => {
      setEditModal(false);
      dataProvider.current.load();
    },
    onError: (err) => showFormError(err),
  });

  const createMutation = useMutation((data) => createLcd(data), {
    onSuccess: () => {
      setEditModal(false);
      dataProvider.current.load();
    },
    onError: (err) => showFormError(err),
  });

  const deleteMutation = useMutation((id: number) => deleteLcd(id), {
    onSuccess: () => dataProvider.current.load(),
  });

  const headers = [
    { label: '液晶CD', key: 'lcd_code' },
    { label: '液晶メーカー', key: 'maker_name' },
    { label: 'インチ' },
    { label: '液晶型番' },
    { label: '形態' },
    { label: '参照基準価格' },
    { label: '備考' },
    { label: '重量' },
    { label: '' },
  ];

  const delegate = (item: any) => {
    const code = item.lcd_code;
    const makerName = item.maker_name;
    const inch = item.lcd_inch;
    const model = item.lcd_model;
    const form = item.lcd_form;
    const basePrice = item.base_unit_price ? item.base_unit_price.toLocaleString('en-US') : null;
    const note = item.lcd_note;
    const weight = item.lcd_weight ? item.lcd_weight + 'g' : null;
    const editBtn = (
      <button
        className="edit"
        onClick={() => {
          setSelected(item);
          setEditModal(true);
        }}
      >
        修
      </button>
    );

    return [code, makerName, inch, model, form, basePrice, note, weight, editBtn];
  };

  const onSubmit = (values: any) => {
    const newFilter = {
      ...values,
      sort: dataProvider.current.filters.sort,
      sort_type: dataProvider.current.filters.sort_type,
    };

    dataProvider.current.setFilters(newFilter);
  };

  const resetFilter = () => {
    reset(DEFAULT_LCD_FILTER);
    dataProvider.current.clearFilters();
  };

  const showConfirmDeletePopup = () => {
    uiStore.showConfirmBox({
      title: 'このレコードを削除しますか？',
      confirmText: '削除する',
      cancelText: ' キャンセル',
      onConfirm: () => {
        uiStore.hideConfirmBox();
        deleteMutation.mutate(selected.id);
      },
    });
  };

  const openCreateLCDModal = () => {
    setSelected(null);
    setEditModal(true);
  };

  const onClose = () => {
    setEditModal(false);
  };

  const onSave = (data: any) => {
    updateMutation.mutate(data);
  };

  const onCreate = (data: any) => {
    createMutation.mutate(data);
  };

  const onDelete = () => {
    setEditModal(false);
    showConfirmDeletePopup();
  };

  useEffect(() => {
    if (typeof code === 'string' && detectFullWidthCharacter(code)) {
      setValue('lcd_code', removeFullWidthChars(code));
    }
  }, [code]);

  return (
    <div>
      <div className="list-screen">
        <Breadcrumb>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/home' }}>
            TOP
          </Breadcrumb.Item>
          <Breadcrumb.Item active>マスター</Breadcrumb.Item>
          <Breadcrumb.Item active>液晶</Breadcrumb.Item>
        </Breadcrumb>

        <div className={styles.filterContainer}>
          <div className={styles.code}>
            <TextFilter label="液晶CD" keys={['lcd_code']} control={control} />
          </div>
          <div className={styles.maker}>
            <TextFilter label="液晶メーカー" keys={['maker_name']} control={control} />
          </div>
          <div className={styles.buttonGroup}>
            <button className="action-btn" onClick={handleSubmit(onSubmit)}>
              検索
            </button>
            <button className="action-btn" onClick={resetFilter}>
              クリア
            </button>
          </div>
          <div className={styles.model}>
            <TextFilter label="液晶型番" keys={['lcd_model']} control={control} />
          </div>
          <div className={styles.inch}>
            <TextFilter label="インチ" type="number" keys={['lcd_inch']} control={control} />
          </div>
        </div>

        <div className="d-flex justify-content-end">
          <button className="create" onClick={openCreateLCDModal}>
            作成
          </button>
        </div>

        <div className={styles.tableWrapper}>
          <DataTable<Lcd, ILcdFilter>
            dataProvider={dataProvider.current}
            headers={headers}
            delegate={delegate}
          />
        </div>
      </div>

      {editModal && (
        <EditModal
          initialValues={selected}
          onClose={onClose}
          onSave={onSave}
          onCreate={onCreate}
          onDelete={onDelete}
        />
      )}
    </div>
  );
};

export default LCDScreen;
