import { DeviceType } from './device-type';
import { Lcd } from './lcd';
import { Maker } from './maker';

export type Product = {
  id?: number;
  device_type_code: string | null;
  product_name: string | null;
  product_kana: string | null;
  model_name: string | null;
  spec: string | null;
  cover_image: string | null;
  cover_delete?: boolean;
  intro_start_date: string;
  maker_id: number | null;
  lcd1_id: number | null;
  lcd2_id: number | null;
  lcd3_id: number | null;
  product_memo: string;
  qr_output: 0 | 1;
  qr_product_name: string | null;
  qr_product_kana: string | null;
  count_target: 0 | 1;
  accessory_product_id: number |null;
  status: 0 | 1;
  device_type?: DeviceType | null;
  maker?: Maker | null;
  lcd1?: Lcd | null;
  lcd2?: Lcd | null;
  lcd3?: Lcd | null;
};

export interface IProductFilter {
  device_type_code: string | null;
  product_id: number | null;
  intro_start_date: string | null;
  before: 0 | 1;
  after: 0 | 1;
  maker_id: number | null;
  product_name: string | null;
  product_kana: string | null;
  status: 0 | 1 | null;
  spec: string | null;
  sort: string | null;
  sort_type: 'asc' | 'desc';
}

export const DEFAULT_PRODUCT_FILTER: IProductFilter = {
  before: 0,
  after: 1,
  device_type_code: null,
  product_id: null,
  maker_id: null,
  product_kana: null,
  product_name: null,
  intro_start_date: null,
  status: null,
  spec: null,
  sort: 'id',
  sort_type: 'asc',
};
