// Built-in
import { useMemo, useRef } from 'react';
import { Breadcrumb, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { MdOutlineCircle, MdRemove } from 'react-icons/md';
import { Link, useNavigate } from 'react-router-dom';

// Component
import Checkbox from 'shared/components/checkbox';
import DynamicDropdownFilter from 'shared/components/dynamic-dropdown-filter';
import RadioButtonFilter from 'shared/components/radio-button-filter';
import TextFilter from 'shared/components/text-filter';
import TimeRangeFilter from 'shared/components/time-range-filter';
import CustomerFilter from 'shared/components/customer-filter';
import DataTable from 'shared/components/data-table';

// Type
import { DEFAULT_SALE_FILTER, ISaleFilter, Sale, saleType } from 'types/sale';

// Store
import { DropdownDataProvider } from 'shared/data-providers/dropdown-data-provider';
import { DataProvider } from 'shared/data-providers/table-data-provider';

// Style
import styles from './styles.module.scss';

const SaleScreen = () => {
  const navigate = useNavigate();

  const dataProvider = useRef(
    new DataProvider<ISaleFilter>({
      dataSource: 'sales',
      localStorageKey: 'sale-filter',
      defaultFilters: DEFAULT_SALE_FILTER,
    })
  );

  const inputUserDataProvider = useRef(
    new DropdownDataProvider('users/dropdown/input_person', (item) => ({
      label: item.name,
      value: item.id,
    }))
  ).current;

  const { control, handleSubmit, setValue, reset } = useForm({
    defaultValues: dataProvider.current.filters,
  });

  const resetForm = () => {
    reset(DEFAULT_SALE_FILTER);
    dataProvider.current.clearFilters();
  };

  const headers = useMemo(() => {
    return [
      { label: '', oneLine: true, centered: true },
      { label: '売上番号', key: 'sale_code' },
      { label: '売上区分' },
      { label: '顧客' },
      { label: '入力日', key: 'input_date', oneLine: true },
      { label: '入力者' },
      { label: '売上日', key: 'sale_date', oneLine: true },
      { label: '合計額' },
      { label: '配送', oneLine: true, centered: true },
      { label: '完了', centered: true },
      { label: '集計日', key: 'aggregation_date', oneLine: true },
      { label: '詳細/業務連絡' },
    ];
  }, []);

  const delegate = (item: Sale) => {
    let name: string;
    let completedStatus: any;
    let editBtn: any;
    let shippingStatus: any;
    let saleCodeLink: any;
    let saleDetail: string | null;

    name =
      item.customer?.customer_name +
      (item.customer?.hall_name ? '　' + item.customer?.hall_name : '');

    const customerName = (
      (item.different_billing == 1) ? (<div className="alert-cell">{name}</div>): name
    );

    completedStatus = item.complete ? <MdOutlineCircle className="status-icon" /> : '';

    editBtn = <button onClick={() => navigate(`/sales/${item.id}`)}>修正</button>;

    if (item.deliveries?.length) {
      shippingStatus = (
        <div>
          {item.deliveries.map((d, index) => (
            <>
              <Link to={`/deliveries/${d.id}`} target="_blank" style={{ display: 'block' }}>
                {d.delivery_code}
              </Link>
            </>
          ))}
        </div>
      );
    } else {
      // sell, disposal, resource, LCD
      if (!item.complete && [2, 6, 7, 8].includes(item.sale_type)) {
        shippingStatus = (
          <button
            onClick={(_event: any) => {
              navigate(`/deliveries/create?target_type=SALE&target_id=${item.id}`);
            }}
          >
            作成
          </button>
        );
      } else {
        shippingStatus = <MdRemove className="status-icon" />;
      }
    }

    saleCodeLink = <Link to={{ pathname: `/sales/${item.id}` }}>{item.sale_code}</Link>;

    const aggregationDate = (
      (item.sale_date != item.aggregation_date) ? (
        <div className="alert-cell">
          {item.aggregation_date}
        </div>
      ): item.aggregation_date
    );

    saleDetail =
      (item.sale_detail ? item.sale_detail : '') +
      (item.business_contact ? item.business_contact.split(/\r\n|\n/)[0] : '');

    //小計に0がある売上の合計金額は赤くする
    const totalAmount = (
      (item.zero_sale_detail == 1) ? (<div className="alert-cell">{item.total_amount?.toLocaleString('en-US')}</div>): item.total_amount?.toLocaleString('en-US')
    );

    return [
      editBtn,
      saleCodeLink,
      saleType[`${item.sale_type}`],
      customerName,
      item.input_date,
      item.user_input_person.name,
      item.sale_date,
      totalAmount,
      shippingStatus,
      completedStatus,
      aggregationDate,
      saleDetail,
    ];
  };

  const search = handleSubmit((values) => {
    const newFilter = {
      ...values,
      sort: dataProvider.current.filters.sort,
      sort_type: dataProvider.current.filters.sort_type,
    };
    dataProvider.current.setFilters(newFilter);
  });

  return (
    <div className="list-screen">
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/home' }}>
          TOP
        </Breadcrumb.Item>
        <Breadcrumb.Item active>売上</Breadcrumb.Item>
      </Breadcrumb>

      <Form onSubmit={search} className={styles.filterContainer}>
        <div className={styles.inputDate}>
          <TimeRangeFilter
            control={control}
            label="入力日"
            keys={['input_date_from', 'input_date_to']}
          />
        </div>

        <div className={styles.saleDate}>
          <TimeRangeFilter
            control={control}
            label="売上日"
            keys={['sale_date_from', 'sale_date_to']}
          />
        </div>

        <div className={styles.saleCode}>
          <TextFilter label="売上番号" control={control} keys={['sale_code']} />
        </div>

        <div className={styles.userInputId}>
          <DynamicDropdownFilter
            label="入力者"
            control={control}
            keys={['user_input_id']}
            setValue={setValue}
            dataProvider={inputUserDataProvider}
          />
        </div>

        <div className={styles.buttonGroup}>
          <button className="action-btn" type="submit">
            検索
          </button>
          <button className="action-btn" onClick={resetForm} type="button">
            クリア
          </button>
        </div>

        <div className={styles.wrap}></div>

        <div className={styles.customerId}>
          <CustomerFilter control={control} label="顧客" keys={['customer_id']} />
        </div>

        <div className={styles.customerIdInDetail}>
          <CustomerFilter control={control} label="明細顧客" keys={['customer_id_in_detail']} />
        </div>

        <div className={styles.wrap}></div>

        <div className={styles.delivery}>
          <RadioButtonFilter
            label="配送"
            control={control}
            keys={['created_delivery']}
            options={[
              { label: '全て', value: null },
              { label: '作成済', value: 1 },
              { label: '未作成', value: 0 },
            ]}
          />
        </div>

        <div className={styles.complete}>
          <RadioButtonFilter
            label="完了"
            control={control}
            keys={['complete']}
            options={[
              { label: '全て', value: null },
              { label: ' 完了', value: 1 },
              { label: '未完了', value: 0 },
            ]}
          />
        </div>

        <div className={styles.excludeEstimate}>
          <Checkbox
            control={control}
            label="売上区分"
            checkboxLabel="見積以外"
            name="exclude_estimate"
            onChange={(isChecked: boolean) => {
              setValue('exclude_estimate', isChecked ? 1 : null);
            }}
          />
        </div>
      </Form>

      <div className="d-flex justify-content-end">
        <button className="create" onClick={() => navigate('/sales/create')}>
          新しい売上
        </button>
      </div>

      <div className={styles.tableWrapper}>
        <DataTable dataProvider={dataProvider.current} headers={headers} delegate={delegate} />
      </div>
    </div>
  );
};

export default SaleScreen;
