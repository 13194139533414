// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_group__GqNQu {\n  display: flex;\n  align-items: center;\n  margin: 0.5em 0;\n}\n.styles_group__GqNQu label {\n  margin: 0 0 0 0;\n}\n.styles_group__GqNQu input {\n  width: 75%;\n  margin: 0 0 0 auto;\n}", "",{"version":3,"sources":["webpack://./src/screens/drivers/edit-modal/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,eAAA;AACF;AACE;EACE,eAAA;AACJ;AAEE;EACE,UAAA;EACA,kBAAA;AAAJ","sourcesContent":[".group {\n  display: flex;\n  align-items: center;\n  margin: 0.5em 0;\n\n  label {\n    margin: 0 0 0 0;\n  }\n\n  input {\n    width: 75%;\n    margin: 0 0 0 auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"group": "styles_group__GqNQu"
};
export default ___CSS_LOADER_EXPORT___;
