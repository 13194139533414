import { useEffect, useRef, useState } from 'react';
import { Control, Controller, useWatch } from 'react-hook-form';
import { AsyncPaginate } from 'react-select-async-paginate';
import { Spinner } from 'react-bootstrap';
import { MdError } from 'react-icons/md';

import FilterItem from '../filter-item';
import CustomerSelectModal from 'shared/containers/customer-select-modal';
import { get } from 'services';
import { getCustomerByCode } from 'services/customer';
import { Customer } from 'types/customer';

import styles from './styles.module.scss';

interface IProps {
  label?: string;
  keys: [string];
  control: Control<any, any>;
  required?: boolean;
  disabled?: boolean;
  placeholder?: string;
  fallbackValue?: Partial<Customer>;
  allowModal?: boolean;
  inputClassName?: string;
  groupId?: number;
}

const CustomerFilter = ({
  label,
  keys,
  control,
  required = false,
  disabled = false,
  placeholder = '選択',
  fallbackValue,
  allowModal = true,
  inputClassName,
  groupId,
}: IProps) => {
  const [customerModal, setCustomerModal] = useState(false);
  const [customerCode, setCustomerCode] = useState('');
  const [option, setOption] = useState<any>(null);
  const [options, setOptions] = useState<Array<any>>([]);
  const [error, setError] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const customerId = useWatch({ control, name: keys[0] });

  const inputRef = useRef<HTMLInputElement | null>(null);
  const timeoutFunc = useRef<any>(null);

  useEffect(() => {
    if (!customerId) {
      setOption(null);
      return;
    }

    const match = options.find((item) => item.value === customerId);
    if (match) {
      setOption(match);
      setCustomerCode(match.code);
    } else if (fallbackValue) {
      const option = {
        label: `${fallbackValue.customer_name || ''} ${fallbackValue.hall_name || ''}`,
        value: fallbackValue.id,
        code: fallbackValue.customer_code,
      };
      const code = fallbackValue.customer_code as string;

      setOption(option);
      setCustomerCode(code);
    }
  }, [customerId, options]);

  const loadCustomerOptions = async () => {
    try {
      const data = await get('customers/dropdown', { group_id: groupId });
      const formatted = data.map((item: any) => ({
        label: `${item.customer_name || ''} ${item.hall_name || ''}`,
        value: item.id,
        code: item.customer_code,
      }));
      setOptions(formatted);
      return { options: formatted };
    } catch (err) {
      setOptions([]);
      return { options: [] };
    }
  };

  const renderInput = (onChange: (...event: any[]) => void) => (
    <div
      className={styles.inputWrapper}
      style={{
        backgroundColor: `${disabled ? 'rgb(222,222,222)' : 'white'}`,
      }}
    >
      <input
        className={inputClassName}
        ref={inputRef}
        value={customerCode}
        disabled={disabled}
        onChange={(event) => {
          if (timeoutFunc.current !== null) clearTimeout(timeoutFunc.current);
          let code = event.target.value;
          setCustomerCode(code);

          // the user intentionally clears the input
          if (!code) {
            onChange(null);
            setError(null);
            return;
          }

          timeoutFunc.current = setTimeout(async () => {
            try {
              setIsLoading(true);
              const customer = await getCustomerByCode(code);
              if (customer.status === 0) {
                throw new Error('Inactive Customer');
              } else {
                onChange(customer.id);
                setError(null);
              }
            } catch (err) {
              onChange(undefined);
              setError('error');
            } finally {
              setIsLoading(false);
            }
          }, 400);
        }}
      />
      <div className={styles.meta}>
        {isLoading && <Spinner animation="border" className={styles.spinner} />}
        {error && !isLoading && <MdError className={styles.error} />}
      </div>
    </div>
  );

  return (
    <div className={styles.container}>
      {label && <FilterItem label={label} required={required} />}
      <div className={styles.content}>
        <Controller
          control={control}
          name={keys[0]}
          rules={{ required }}
          render={({ field: { onChange } }) => (
            <>
              {renderInput(onChange)}
              <AsyncPaginate
                key={groupId}
                className={styles.dropdown}
                loadingMessage={() => <div>Loading</div>}
                isSearchable={false}
                isClearable
                placeholder={placeholder}
                isDisabled={disabled}
                value={option}
                onChange={(opt: any) => {
                  onChange(opt ? opt.value : null);

                  // onClear
                  if (!opt) {
                    setCustomerCode('');
                  }
                  // onSelect
                  else {
                    setCustomerCode(opt.code);
                  }

                  setError(null);
                }}
                defaultOptions
                loadOptions={loadCustomerOptions}
              />

              {customerModal && (
                <CustomerSelectModal
                  onClose={() => setCustomerModal(false)}
                  groupId={groupId}
                  onSelect={(customer) => {
                    onChange(customer.id);
                    setCustomerCode(customer.customer_code);
                    setCustomerModal(false);
                    setError(null);
                  }}
                />
              )}
            </>
          )}
        />

        {!disabled && allowModal && (
          <button type="button" onClick={() => setCustomerModal(true)}>
            参照
          </button>
        )}
      </div>
    </div>
  );
};

export default CustomerFilter;
