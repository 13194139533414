import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { Control, Controller, useWatch } from 'react-hook-form';

import FilterItem from '../filter-item';

import './styles.scss';

interface IProps {
  label: string;
  options: Array<{ label: string; value: any }>;
  keys: [string];
  control: Control<any, any>;
  defaultValue?: any;
  disabled?: boolean;
  sideEffect?: () => void;
}

const RadioButtonFilter = ({
  label,
  options,
  keys,
  control,
  defaultValue,
  disabled = false,
  sideEffect,
}: IProps) => {
  const [value, setValue] = useState<any>(defaultValue);

  const watcher = useWatch({ control, name: keys[0] });
  useEffect(() => {
    setValue(watcher);
  }, [watcher]);

  return (
    <div className="d-flex flex-row radio-button-filter">
      <FilterItem label={label} />
      <Controller
        name={keys[0]}
        control={control}
        render={({ field }) => (
          <Form
            style={{
              width: '100%',
              border: '1px solid lightgray',
              backgroundColor: 'white',
            }}
            className="d-flex align-items-center"
          >
            {options.map((option, index) => (
              <Form.Check
                key={index}
                id={Math.random().toString()}
                inline
                label={option.label}
                type="radio"
                name={`group-${label}`}
                checked={option.value === value}
                disabled={disabled}
                onChange={(event) => {
                  if (event.target.checked) {
                    setValue(option.value);
                    field.onChange(option.value);
                  }
                  sideEffect && sideEffect();
                }}
              />
            ))}
          </Form>
        )}
      />
    </div>
  );
};

export default RadioButtonFilter;
