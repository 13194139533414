import useStores from 'hooks/use-stores';
import { observer } from 'mobx-react';
import { useMemo } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { MdClose } from 'react-icons/md';
import UIStore from 'stores/ui';
import { Truck } from 'types/truck';
import { popupConfirmBack } from 'utils/modals';
import { convertToHalfWidth } from 'utils/validator';
import styles from './styles.module.scss';

interface IProps {
  initialValues?: Truck;
  onClose: () => void;
  onSave: (data: Truck) => void;
  onDelete: () => void;
}

const EditTruckModal = observer(({ initialValues, onClose, onSave, onDelete }: IProps) => {
  const uiStore: UIStore = useStores().uiStore;

  const initialFormValues = useMemo(() => {
    let values: any = {};
    if (initialValues) {
      values = initialValues;
      return values;
    }

    values = { status: 1, sort: 0 };
    return values;
  }, []);

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { isValid, isDirty },
  } = useForm<Truck>({
    defaultValues: initialFormValues,
    mode: 'onChange',
  });

  const sort = useWatch({ control, name: 'sort' });

  const modalTitle = useMemo(() => {
    return !initialValues ? 'トラック作成モーダル' : 'トラック編集モーダル';
  }, [initialValues]);

  const onSubmit = (data: any) => {
    onSave(data);
  };

  const onErrors = (errors: any) => {
    console.log('Errors: ', errors);
  };

  const onBackBtnClick = (event: any) => {
    event.preventDefault();
    popupConfirmBack(isDirty, () => onClose());
  };

  return (
    <Modal show backdrop="static" centered>
      <div className="edit-modal">
        <div className="edit-modal--title">
          {modalTitle}
          <button onClick={onBackBtnClick}>
            <MdClose />
          </button>
        </div>
        <Form onSubmit={handleSubmit(onSubmit, onErrors)}>
          {initialValues && (
            <Form.Group className={styles.group}>
              <Form.Label>トラックID</Form.Label>
              <Form.Control
                type="number"
                disabled={!!initialValues?.id}
                {...register('id', { required: true })}
              />
            </Form.Group>
          )}
          <Form.Group className={styles.group}>
            <Form.Label className="required">並び順</Form.Label>
            <Form.Control
              value={sort}
              onChange={(event) => {
                let val = event.target.value;
                switch (true) {
                  case val === '':
                    setValue('sort', 0, { shouldDirty: true });
                    break;
                  case /^[０-９0-9]+$/.test(val) === true:
                    setValue('sort', parseInt(convertToHalfWidth(val)), {
                      shouldDirty: true,
                    });
                    break;
                  default:
                    break;
                }
              }}
            />
          </Form.Group>
          <Form.Group className={styles.group}>
            <Form.Label className="required">トラック名</Form.Label>
            <Form.Control {...register('truck_name', { required: true })} />
          </Form.Group>
          <Form.Group className="d-flex align-items-center">
            <Form.Label style={{ minWidth: 120 }}>使用中止</Form.Label>
            <Controller
              control={control}
              name="status"
              render={({ field: { value, onChange } }) => (
                <Form.Check
                  className="custom-checkbox"
                  defaultChecked={!value}
                  onChange={(event) => {
                    const checked = event.target.checked;
                    onChange(checked ? 0 : 1);
                  }}
                />
              )}
            />
          </Form.Group>
          <div className="d-flex justify-content-end">
            {initialValues && (
              <button
                className="edit-modal--delete"
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  onDelete();
                }}
              >
                削除
              </button>
            )}
            <button className="edit-modal--save" type="submit" disabled={!isValid}>
              登録
            </button>
          </div>
        </Form>
      </div>
      {isDirty && uiStore.confirmBox.visible && <div className="overlappedBackdrop"></div>}
    </Modal>
  );
});

export default EditTruckModal;
