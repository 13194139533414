export const DATE_FORMAT = 'YYYY/MM/DD';
export const DATETIME_FORMAT = 'YYYY/MM/DD HH:mm:ss';

export enum UserType {
  PersonInCharge = 'PERSON_IN_CHARGE',
  InputPerson = 'INPUT_PERSON',
  ConfirmPerson = 'CONFIRM_PERSON',
}

export enum UserAuthority {
  User = 'USER',
  Master = 'MASTER',
  Quotation = 'QUOTATION',
  Purchase = 'PURCHASE',
  Sale = 'SALE',
  Import = 'IMPORT',
  Export = 'EXPORT',
  Stock = 'STOCK',
  Delivery = 'DELIVERY',
  Vehicle = 'VEHICLE',
  Report = 'REPORT',
  DepositWithdrawal = 'DEPOSIT_WITHDRAWAL',
}

export const USER_TYPES = [
  {
    label: '担当者',
    value: 'PERSON_IN_CHARGE',
  },
  {
    label: '入力者',
    value: 'INPUT_PERSON',
  },
  {
    label: '確認者',
    value: 'CONFIRM_PERSON',
  },
];

export const DEFAULT_USER_TYPES: {
  [key: string]: 0 | 1;
} = {
  PERSON_IN_CHARGE: 1,
  INPUT_PERSON: 0,
  CONFIRM_PERSON: 0,
};

export const DEFAULT_USER_AUTHORITIES: {
  [key: string]: 0 | 1;
} = {
  DELIVERY: 1,
  DEPOSIT_WITHDRAWAL: 1,
  EXPORT: 1,
  IMPORT: 1,
  MASTER: 1,
  PURCHASE: 1,
  QUOTATION: 1,
  REPORT: 1,
  SALE: 1,
  STOCK: 1,
  USER: 1,
  VEHICLE: 1,
};

export const USER_AUTHORITIES = [
  {
    label: '見積',
    value: 'QUOTATION',
  },
  {
    label: '買取',
    value: 'PURCHASE',
  },
  {
    label: '売上',
    value: 'SALE',
  },
  {
    label: '入庫',
    value: 'IMPORT',
  },
  {
    label: '出庫',
    value: 'EXPORT',
  },
  {
    label: '在庫',
    value: 'STOCK',
  },
  {
    label: '配送',
    value: 'DELIVERY',
  },
  {
    label: '車両',
    value: 'VEHICLE',
  },
  {
    label: '集計',
    value: 'REPORT',
  },
  {
    label: '入出金',
    value: 'DEPOSIT_WITHDRAWAL',
  },
  {
    label: 'マスター',
    value: 'MASTER',
  },
  {
    label: 'ユーザー管理',
    value: 'USER',
  },
];

export const KANA_ROWS = [
  { label: 'ア', value: 'ア' },
  { label: 'カ', value: 'カ' },
  { label: 'サ', value: 'サ' },
  { label: 'タ', value: 'タ' },
  { label: 'ナ', value: 'ナ' },
  { label: 'ハ', value: 'ハ' },
  { label: 'マ', value: 'マ' },
  { label: 'ヤ', value: 'ヤ' },
  { label: 'ラ', value: 'ラ' },
  { label: 'ワ', value: 'ワ' },
];

export const DEADLINE_DAYS = [
  { label: '末', value: 0 },
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
  { label: '5', value: 5 },
  { label: '6', value: 6 },
  { label: '7', value: 7 },
  { label: '8', value: 8 },
  { label: '9', value: 9 },
  { label: '10', value: 10 },
  { label: '11', value: 11 },
  { label: '12', value: 12 },
  { label: '13', value: 13 },
  { label: '14', value: 14 },
  { label: '15', value: 15 },
  { label: '16', value: 16 },
  { label: '17', value: 17 },
  { label: '18', value: 18 },
  { label: '19', value: 19 },
  { label: '20', value: 20 },
  { label: '21', value: 21 },
  { label: '22', value: 22 },
  { label: '23', value: 23 },
  { label: '24', value: 24 },
  { label: '25', value: 25 },
  { label: '26', value: 26 },
  { label: '27', value: 27 },
  { label: '28', value: 28 },
];

export const VEHICLE_COST_TYPES: Array<{ label: string; value: 1 | 2 }> = [
  { label: '車両整備', value: 1 },
  { label: '高速代', value: 2 },
];

export const MONTHS = [
  { label: '01', value: 1 },
  { label: '02', value: 2 },
  { label: '03', value: 3 },
  { label: '04', value: 4 },
  { label: '05', value: 5 },
  { label: '06', value: 6 },
  { label: '07', value: 7 },
  { label: '08', value: 8 },
  { label: '09', value: 9 },
  { label: '10', value: 10 },
  { label: '11', value: 11 },
  { label: '12', value: 12 },
];

export const currentYear = new Date().getFullYear();
export const currentMonth = new Date().getMonth() + 1;

export const currentYearOption = {
  label: `${currentYear}`,
  value: currentYear,
};

export const currentMonthOption = {
  label: currentMonth < 10 ? `0${currentMonth}` : `${currentMonth}`,
  value: currentMonth,
};

export const YEARS = (() => {
  const start = 2022;
  const end = currentYear + 1;
  const YEARS: { label: string; value: number }[] = [];

  for (let i = start; i <= end; i++) {
    const val = {
      label: i.toString(),
      value: i,
    };
    YEARS.push(val);
  }

  return YEARS;
})();

export const EXPORT_TYPE_TEXT: { [key: number]: string } = {
  1: '預かり',
  2: '返却',
};
