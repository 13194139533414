// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_btnContainer__i4aVF {\n  display: flex;\n}\n.styles_btnContainer__i4aVF button {\n  margin: 0 1em;\n}\n.styles_btnContainer__i4aVF button:first-of-type {\n  margin: 0 1em 0 0;\n}\n\n.styles_selectWrapper__maJav {\n  margin: 3em 0;\n}", "",{"version":3,"sources":["webpack://./src/screens/monthly-report/styles.module.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;AADF;AAGE;EACE,aAAA;AADJ;AAIE;EACE,iBAAA;AAFJ;;AAMA;EACE,aAAA;AAHF","sourcesContent":["@use '../../assets/styles/variables' as *;\n\n.btnContainer {\n  display: flex;\n\n  button {\n    margin: 0 1em;\n  }\n\n  button:first-of-type {\n    margin: 0 1em 0 0;\n  }\n}\n\n.selectWrapper {\n  margin: 3em 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btnContainer": "styles_btnContainer__i4aVF",
	"selectWrapper": "styles_selectWrapper__maJav"
};
export default ___CSS_LOADER_EXPORT___;
