// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_dropdown__AEdsX {\n  border: 1px solid lightgray;\n  min-width: 10rem;\n  border-radius: 0.25rem;\n}\n\n.styles_group__-OGWQ {\n  display: flex;\n  align-items: center;\n  margin: 0.5em 0;\n}\n.styles_group__-OGWQ input {\n  width: 75%;\n  margin: 0 0 0 auto;\n}", "",{"version":3,"sources":["webpack://./src/screens/lcds/edit-modal/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,2BAAA;EACA,gBAAA;EACA,sBAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,eAAA;AACF;AACE;EACE,UAAA;EACA,kBAAA;AACJ","sourcesContent":[".dropdown {\n  border: 1px solid lightgray;\n  min-width: 10rem;\n  border-radius: 0.25rem;\n}\n\n.group {\n  display: flex;\n  align-items: center;\n  margin: 0.5em 0;\n\n  input {\n    width: 75%;\n    margin: 0 0 0 auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropdown": "styles_dropdown__AEdsX",
	"group": "styles_group__-OGWQ"
};
export default ___CSS_LOADER_EXPORT___;
