export const saleExportOptions = [
  {
    label: '出庫業販伝票', // internal sale
    value: 'R0406',
  },
  {
    label: '請求書', // invoice
    value: 'R0407',
  },
  {
    label: '見積書', // quotation
    value: 'R0405',
  },
  {
    label: '受領書 ', // receipt
    value: 'R0404',
  },
  {
    label: '計算書', // account
    value: 'R0401',
  },
  {
    label: '納品書（白紙）', // delivery (empty)
    value: 'R0403',
  },
  {
    label: '納品書 ', // delivery
    value: 'R0402',
  },
];
  

export const quotationExportOptions = [
  { label: '見積書', value: 'R0201' },
  { label: '見積書（店舗別）', value: 'R0202' },
];

export const purchaseExportOptions = [
  { label: '入庫業販伝票', value: 'R0303' },
  { label: '引取明細書', value: 'R0301' },
  { label: '引取明細書（店舗別）', value: 'R0302' },
];

export const importExportOptions = [
  { label: '入庫票', value: 'R1101' },
  { label: '入庫QR一覧', value: 'R1102' },
];

export const exportExportType1Options = [
  { label: '出庫票', value: 'R1201' },
  { label: '出庫依頼票', value: 'R1203' },
];

export const exportExportType2Options = [
  { label: '出庫票', value: 'R1201' },
  { label: '出庫依頼票', value: 'R1203' },
  { label: '納品書', value: 'R1204' },
  { label: '受領書', value: 'R1205' },
];

export const exportDeliveryOptions = [
  { label: '予定表', value: 'R2101' },
  { label: '点呼票', value: 'R2102' },
  { label: '見出', value: 'R2103' },
  { label: '入出庫票', value: 'R2104' },
  { label: '配送集計', value: 'R2105' },
];

export const stockExportOptions = [
  { label: '在庫一覧', value: 'R1301' },
  { label: '出庫依頼票', value: 'R1304' },
];

