// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_modalContent__mthn\\+ {\n  overflow-y: scroll;\n  max-height: 500px;\n}\n\n.styles_titleContainer__GAuCn {\n  display: flex;\n  align-items: center;\n  padding: 0.75em 1em;\n  position: sticky;\n  top: 0;\n  background-color: white;\n}\n.styles_titleContainer__GAuCn .styles_warningImg__eHh2Q {\n  width: 50px;\n  margin-right: 10px;\n  width: 2em;\n  height: 2em;\n  object-fit: contain;\n  margin: 0 1em 0 0;\n}\n\n.styles_content__yit3G {\n  white-space: pre;\n  padding: 0.75em 1em;\n}\n\n.styles_footer__vE3u4 {\n  display: flex;\n  justify-content: flex-end;\n  padding: 0.75em 1em;\n}", "",{"version":3,"sources":["webpack://./src/shared/containers/alert-box/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,iBAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,gBAAA;EACA,MAAA;EACA,uBAAA;AACF;AACE;EACE,WAAA;EACA,kBAAA;EACA,UAAA;EACA,WAAA;EACA,mBAAA;EACA,iBAAA;AACJ;;AAGA;EACE,gBAAA;EACA,mBAAA;AAAF;;AAGA;EACE,aAAA;EACA,yBAAA;EACA,mBAAA;AAAF","sourcesContent":[".modalContent {\n  overflow-y: scroll;\n  max-height: 500px;\n}\n\n.titleContainer {\n  display: flex;\n  align-items: center;\n  padding: 0.75em 1em;\n  position: sticky;\n  top: 0;\n  background-color: white;\n\n  .warningImg {\n    width: 50px;\n    margin-right: 10px;\n    width: 2em;\n    height: 2em;\n    object-fit: contain;\n    margin: 0 1em 0 0;\n  }\n}\n\n.content {\n  white-space: pre;\n  padding: 0.75em 1em;\n}\n\n.footer {\n  display: flex;\n  justify-content: flex-end;\n  padding: 0.75em 1em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalContent": "styles_modalContent__mthn+",
	"titleContainer": "styles_titleContainer__GAuCn",
	"warningImg": "styles_warningImg__eHh2Q",
	"content": "styles_content__yit3G",
	"footer": "styles_footer__vE3u4"
};
export default ___CSS_LOADER_EXPORT___;
