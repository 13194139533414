import Select from 'react-select';
import FilterItem from '../filter-item';
import styles from './styles.module.scss';

interface IProps {
  monthOptions: Array<any>;
  yearOptions: Array<any>;
  monthValue: any;
  yearValue: any;
  onYearChange: (newValue: any) => void;
  onMonthChange: (newValue: any) => void;
  dropdownStyle?: any;
  label?: string;
  maxMenuHeight?: {
    month?: number;
    year?: number;
  };
}

const YearMonthSelect = ({
  yearOptions,
  monthValue,
  yearValue,
  dropdownStyle,
  label,
  monthOptions,
  onMonthChange,
  onYearChange,
  maxMenuHeight,
}: IProps) => {
  return (
    <div className={styles.container}>
      {label && <FilterItem label={label} />}

      <div className={styles.selectGroup}>
        <Select
          options={yearOptions}
          value={yearValue}
          onChange={(newValue) => onYearChange(newValue)}
          className={dropdownStyle ? dropdownStyle : styles.defaultDropdownStyle}
          isSearchable={false}
          maxMenuHeight={maxMenuHeight && maxMenuHeight.year}
        />
        <span className={styles.text}>年</span>
      </div>
      <div className={styles.selectGroup}>
        <Select
          options={monthOptions}
          value={monthValue}
          onChange={(newValue) => onMonthChange(newValue)}
          className={dropdownStyle ? dropdownStyle : styles.defaultDropdownStyle}
          isSearchable={false}
          maxMenuHeight={maxMenuHeight && maxMenuHeight.month}
        />
        <span className={styles.text}>月</span>
      </div>
    </div>
  );
};

export default YearMonthSelect;
