import { useEffect, useState } from 'react';
import { Control, Controller, useWatch } from 'react-hook-form';
import classNames from 'classnames';

import FilterItem from '../filter-item';

import styles from './styles.module.scss';
import { convertToHalfWidth } from 'utils/validator';

interface IProps {
  label?: string;
  type?: 'signed-number' | 'number' | 'text';
  keys: [string];
  control: Control<any, any>;
  disabled?: boolean;
  exceptChars?: Array<string>;
}

const TextFilter = ({
  label,
  type = 'text',
  keys,
  control,
  disabled = false,
  exceptChars,
}: IProps) => {
  const [value, setValue] = useState<any>('');

  const watcher = useWatch({ control, name: keys[0] });

  useEffect(() => {
    if (watcher === undefined || watcher === null) setValue('');
    else setValue(watcher);
  }, [watcher]);

  return (
    <div className={classNames('d-flex align-items-center w-100', styles.container)}>
      {label && <FilterItem label={label} />}
      <Controller
        name={keys[0]}
        control={control}
        render={({ field }) => (
          <input
            className="px-2"
            disabled={disabled}
            value={value}
            onChange={(event) => {
              let val;
              if (type === 'number') {
                const isNumber = /^[０-９0-9]+$/.test(event.target.value);
                if (event.target.value !== '' && !isNumber) return;
                val = parseInt(convertToHalfWidth(event.target.value));
              } else if (type === 'signed-number') {
                const isNumber = /^[ー\-]?[０-９0-9]+$/.test(event.target.value);
                if (event.target.value !== '' && event.target.value !== '-' && event.target.value !== 'ー' && !isNumber) return;
                val = convertToHalfWidth(event.target.value);
              } else {
                val = event.target.value;
              }
              field.onChange(val);
              setValue(val);
            }}
          />
        )}
      />
    </div>
  );
};

export default TextFilter;
