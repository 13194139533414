// Built-in
import { useQueryParams } from 'hooks/use-query-params';
import { useEffect, useState } from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { getCustomerByCode, getCustomerDetails } from 'services/customer';
import { getPurchaseById } from 'services/purchase';

// Component
import ImportDetailsContainer from 'shared/containers/import-details';

const CreateImportScreen = () => {
  const query = useQueryParams();
  const purchaseIdStr = query.get('purchase_id');

  const purchaseId = (() => {
    if (!purchaseIdStr) return undefined;
    if (isNaN(parseInt(purchaseIdStr))) return undefined;
    return parseInt(purchaseIdStr);
  })();

  const [ready, setReady] = useState<boolean>(false);
  const [purchaseType, setPurchaseType] = useState<number | undefined>(undefined);
  const [customerId, setCustomerId] = useState<number | undefined>(undefined);

  const getPurchaseType = async () => {
    try {
      const purchase = await getPurchaseById(purchaseId as number);
      const customer = await getCustomerDetails(purchase.customer.id);
      setPurchaseType(purchase.purchase_type);
      const groups: Array<any> = customer.groups;
      groups.forEach((group) => {
        if (group.id === 3) setCustomerId(customer.id);
      });
    } catch (err) {
    } finally {
      setReady(true);
    }
  };

  useEffect(() => {
    if (purchaseId === undefined) {
      setReady(true);
    } else {
      getPurchaseType();
    }
  }, []);

  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/home' }}>
          TOP
        </Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/imports' }}>
          入庫
        </Breadcrumb.Item>
        <Breadcrumb.Item active>入庫詳細</Breadcrumb.Item>
      </Breadcrumb>
      {ready && (
        <ImportDetailsContainer
          purchaseId={purchaseId}
          purchaseType={purchaseType}
          customerId={customerId}
        />
      )}
    </div>
  );
};

export default CreateImportScreen;
