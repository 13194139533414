import { get, post } from './index';

export const getWithdrawalInfo = (payload: any) => {
  const { year, month, customerId } = payload;
  return get(`withdrawals/${year}/${month}/${customerId}`, {});
};

export const updateWithdrawal = (payload: any) => {
  const { year, month, customerId, data } = payload;
  return post(`withdrawals/${year}/${month}/${customerId}`, data);
};

export const updateUnrestrictedWithdrawal = (payload: any) => {
  const { year, month, customerId, data } = payload;
  return post(`withdrawals/unrestricted/${year}/${month}/${customerId}`, data);
};
