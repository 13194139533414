import { useEffect, useRef, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { Control, Controller, useFormState, useWatch } from 'react-hook-form';
import moment from 'moment';

import CustomFilter from 'shared/components/custom-filter';
import DateFilter from 'shared/components/date-filter';
import StaticDropdownFilter from 'shared/components/static-dropdown-filter';
import CustomerFilter from 'shared/components/customer-filter';
import TextFilter from 'shared/components/text-filter';
import DynamicDropdownFilter from 'shared/components/dynamic-dropdown-filter';
import { getCustomerDetails } from 'services/customer';
import { DATETIME_FORMAT } from 'consts';
import PopupPrint from 'shared/components/popup-print';
import { quotationExportOptions } from 'consts/export-options';
import styles from './styles.module.scss';
import TextAreaFilter from 'shared/components/textarea-filter';
import { DropdownDataProvider } from 'shared/data-providers/dropdown-data-provider';
import { getUserInfo } from 'services/user';

interface IProps {
  control: Control<any, any>;
  onSave: () => void;
  setValue: any;
  onPrint: (option: string | number) => any;
}

const QuotationDetailsForm = ({ control, onSave, setValue, onPrint }: IProps) => {
  const { isValid } = useFormState({ control });
  const [showPrintModal, setShowPrintModal] = useState<boolean>(false);

  const id = useWatch({ control, name: 'id' });
  const picUser = useWatch({ control, name: 'user_person_in_charge' });
  const picUserId = useWatch({ control, name: 'user_pic_id' });
  const inputUser = useWatch({ control, name: 'user_input_person' });
  const confirmUser = useWatch({ control, name: 'user_confirm_person' });
  const customerId = useWatch({ control, name: 'customer_id' });
  const customer = useWatch({ control, name: 'customer' });
  const memo = useWatch({ control, name: 'quotation_memo' });
  const quotationDetail = useWatch({ control, name: 'quotation_detail' });
  const businessContact = useWatch({ control, name: 'business_contact' });

  const confirmUserDataProvider = useRef(
    new DropdownDataProvider('users/dropdown/confirm_person', (item) => ({
      label: item.name,
      value: item.id,
    }))
  ).current;
  const picDataProvider = useRef(
    new DropdownDataProvider('users/dropdown/person_in_charge', (item) => ({
      label: item.name,
      value: item.id,
    }))
  ).current;
  const inputUserDataProvider = useRef(
    new DropdownDataProvider('users/dropdown/input_person', (item) => ({
      label: item.name,
      value: item.id,
    }))
  ).current;

  const customerQuery = useQuery(['customer', customerId], () => getCustomerDetails(customerId), {
    enabled: false,
    keepPreviousData: true,
    onSuccess: (customer) => {
      if (!customer.pic_id) customer.pic_name = undefined;
      setValue('customer', customer);
      // feedback UI: 125
      // only do this if picUserId isn't set.
      if (control._formState.isDirty && !picUserId && customer.pic_id) {
        userPicQuery.mutate(customer.pic_id);
      }
      // set memo from customer_memo
      if (control._formState.isDirty) {
        setValue('quotation_memo', customer.customer_memo ? customer.customer_memo: "", { shouldDirty: true });
      }
    },
  });

  const userPicQuery = useMutation((id: number) => getUserInfo(id), {
    onSuccess: (user) => {
      if (user.user_types.PERSON_IN_CHARGE === 1) {
        setValue('user_pic_id', user.id);
      }
    },
  });

  const openPrintModal = (_event: any) => {
    setShowPrintModal(true);
  };
  useEffect(() => {
    if (customerId) {
      customerQuery.refetch();
    } else {
      setValue('customer.hall_name', undefined);
      setValue('customer', undefined);
    }
  }, [customerId]);

  return (
    <>
      <div className={styles.form}>
        <button
          aria-label="registerBtn"
          className="secondary-btn ms-2"
          onClick={onSave}
          disabled={!isValid}
        >
          登録
        </button>

        <div className={styles.group2}>
          <div className={styles.group20}>
            <div className={styles.quotationCode}>
              <TextFilter label="見積番号" control={control} keys={['quotation_code']} disabled />
            </div>
            {id && (
              <button
                aria-label="print"
                type="button"
                className="secondary-btn ms-2"
                onClick={openPrintModal}
              >
                印刷
              </button>
            )}
          </div>
          <div className={styles.group21}>
            <div className={styles.inputDate}>
              <DateFilter label="入力日" control={control} keys={['input_date']} required />
            </div>

            <div className={styles.scheduleDate}>
              <DateFilter label="予定日" control={control} keys={['scheduled_date']} />
            </div>

            <div className={styles.quotationType}>
              <StaticDropdownFilter
                label="買取区分"
                control={control}
                keys={['quotation_type']}
                isClearable={false}
                required
                options={[
                  { label: '引取', value: 1 },
                  { label: '仕入', value: 2 },
                ]}
              />
            </div>
          </div>

          <div className={styles.group22}>
            <div className={styles.customerId}>
              <CustomerFilter
                label="顧客"
                control={control}
                keys={['customer_id']}
                fallbackValue={customer}
                required
              />
            </div>
          </div>

          <div className={styles.group23}>
            <div className={styles.address}>
              <TextFilter label="住所" control={control} keys={['customer.address']} disabled />
            </div>
            <div className={styles.picNameHallName}>
              <div style={{ flex: 3 }}>
                <TextFilter
                  label="顧客担当者"
                  control={control}
                  keys={['customer.pic_name']}
                  disabled
                />
              </div>
              {/* <Controller
                control={control}
                name="customer.hall_name"
                render={({ field }) => {
                  return (
                    <input
                      className="px-2"
                      disabled
                      style={{
                        height: '30px',
                        width: 'fit-content',
                        flex: 2,
                        border: '1px solid lightgray',
                      }}
                      value={field.value ? field.value : ''}
                      onBlur={field.onBlur}
                      onChange={field.onChange}
                    />
                  );
                }}
              /> */}
            </div>
          </div>

          <div className={styles.group24}>
            <div className={styles.tel}>
              <TextFilter label="TEL" control={control} keys={['customer.tel']} disabled />
            </div>

            <div className={styles.fax}>
              <TextFilter label="FAX" control={control} keys={['customer.fax']} disabled />
            </div>
          </div>
        </div>

        <div className={styles.group3}>
          <div className={styles.updateAt}>
            <Controller
              control={control}
              name="updated_at"
              render={({ field: { value } }) => (
                <CustomFilter
                  className="flex-grow-1"
                  label="修正日時"
                  content={value && moment(new Date(value)).format(DATETIME_FORMAT)}
                />
              )}
            />
          </div>

          <div className={styles.userPicId}>
            <DynamicDropdownFilter
              label="担当者"
              control={control}
              keys={['user_pic_id']}
              dataProvider={picDataProvider}
              fallbackValue={picUser ? { label: picUser.name, value: picUser.id } : undefined}
            />
          </div>

          <div className={styles.userInputId}>
            <DynamicDropdownFilter
              label="入力者"
              control={control}
              required
              keys={['user_input_id']}
              dataProvider={inputUserDataProvider}
              fallbackValue={inputUser ? { label: inputUser.name, value: inputUser.id } : undefined}
            />
          </div>

          <div className={styles.userConfirmId}>
            <DynamicDropdownFilter
              label="確認者"
              control={control}
              keys={['user_confirm_id']}
              dataProvider={confirmUserDataProvider}
              fallbackValue={
                confirmUser ? { label: confirmUser.name, value: confirmUser.id } : undefined
              }
            />
          </div>
        </div>

        <div className={styles.group4}>
          <div className={styles.quotationDetail}>
            <TextAreaFilter
              label="詳細"
              value={quotationDetail}
              onChange={(event) => {
                setValue('quotation_detail', event.target.value, { shouldDirty: true });
              }}
              rows={7}
            />
          </div>

          <div className={styles.businessContact}>
            <TextAreaFilter
              label="業務連絡"
              value={businessContact}
              onChange={(event) => {
                setValue('business_contact', event.target.value, { shouldDirty: true });
              }}
              rows={7}
            />
          </div>

          <div className={styles.memo}>
            <TextAreaFilter
              label="メモ"
              value={memo}
              onChange={(event) => {
                setValue('quotation_memo', event.target.value, { shouldDirty: true });
              }}
              rows={7}
            />
          </div>
        </div>
      </div>

      {showPrintModal && (
        <PopupPrint
          title="印刷選択モーダル"
          options={quotationExportOptions}
          actions={[
            { label: '閉じる', callback: () => setShowPrintModal(false) },
            {
              label: '印刷',
              callback: (option: string | number) => {
                onPrint(option);
                setShowPrintModal(false);
              },
            },
          ]}
        />
      )}
    </>
  );
};

export default QuotationDetailsForm;
