import { Form } from 'react-bootstrap';
import { Control, Controller } from 'react-hook-form';
import FilterItem from '../filter-item';

import styles from './styles.module.scss';

interface IProps {
  control: Control<any, any>;
  label: string;
  checkboxLabel?: string;
  name: string;
  defaultChecked?: boolean;
  onChange: (isChecked: boolean) => void;
  disabled?: boolean;
}

const Checkbox = ({ control, label, checkboxLabel, name, onChange, disabled = false }: IProps) => {
  return (
    <div className={styles.container}>
      <FilterItem label={label} />
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <div className={styles.checkbox}>
            <Form.Check
              disabled={disabled}
              checked={!!field.value}
              onChange={(event) => {
                onChange(event.target.checked);
              }}
            />
            {checkboxLabel && (
              <span
                onClick={() => {
                  onChange(!field.value);
                }}
              >
                {checkboxLabel}
              </span>
            )}
          </div>
        )}
      />
    </div>
  );
};

export default Checkbox;
