export type WithdrawalCustomer = {
  id: number;
  purchase_year: number;
  purchase_month: number;
  customer_id: number;
  start_date: string;
  end_date: string;
  last_request_balance: number;
  total_amount_request: number;
  purchase_amount: number;
  withdrawal_amount: number;
  adjust_amount: number;
  request_balance: number;
  created_at: string | null;
  updated_at: string | null;
  deleted_at: string | null;
  customer: {
    id: number;
    customer_code: string;
    customer_name: string;
    customer_kana: string | null;
    hall_name: string | null;
    hall_kana: string | null;
  };
};

export type Withdrawal = {
  id: number | string;
  withdrawal_date: string;
  withdrawal_type: number;
  out_amount: number;
};

export const withdrawalTypes: Array<{
  [key: string]: any;
}> = [
  {
    label: '振込',
    value: 1,
  },
  {
    label: '現金',
    value: 2,
  },
  {
    label: '値引',
    value: 3,
  },
  {
    label: '相殺',
    value: 4,
  },
  {
    label: '調整',
    value: 5,
  },
];
