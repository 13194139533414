import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import moment from 'moment';

import {
  getExportById,
  createExport,
  updateExport,
  completeExport,
  revertExportCompletion,
  deleteExport,
} from 'services/export';
import UIStore from 'stores/ui';
import { Export } from 'types/export';
import { getDirtyObject } from 'utils';
import ExportDetailsForm from './form';
import ExportDetailsTable from './table';
import useStores from 'hooks/use-stores';
import { DATE_FORMAT, EXPORT_TYPE_TEXT } from 'consts';
import { useFormError } from 'hooks/use-form-error';
import { exportForm } from 'services/export-form';
import { toast } from 'react-toastify';
import useBlockNavigation from 'hooks/use-block-navigation';
import styles from './styles.module.scss';
import SessionStore from 'stores/session';
interface IProps {
  id?: number;
  exportType: 1 | 2;
  setKey?: (arg: number) => void;
}

const ExportDetailsContainer = observer(({ id, exportType, setKey }: IProps) => {
  const navigate = useNavigate();
  const canBack = (window.history.state && window.history.state.idx > 0) ? true : false;

  const uiStore: UIStore = useStores().uiStore;
  const sessionStore: SessionStore = useStores().sessionStore;
  const [editable, setEditable] = useState(true);
  const [loaded, setLoaded] = useState(id ? false : true);
  const [pathToNavigate, setPathToNavigate] = useState<string | undefined>(undefined);

  const initialFormValues = useMemo(() => {
    const profile = sessionStore.profile;
    let values: any = {
      input_date: moment(new Date()).format(DATE_FORMAT),
    };

    if (exportType) {
      values.export_type = exportType;
      values.export_type_text = EXPORT_TYPE_TEXT[exportType];
    }

    const canBeUserInput = profile?.status === 1 && profile?.user_types?.INPUT_PERSON === 1;
    // set currently logged-in user as user input of this form.
    if (canBeUserInput) values.user_input_id = profile.id;

    return values;
  }, []);

  const {
    control,
    reset,
    setValue,
    getValues,
    formState: { dirtyFields, isDirty, isValid },
  } = useForm<Partial<Export>>({
    defaultValues: initialFormValues,
    mode: 'onChange',
  });

  const groupId = useWatch({ control, name: 'group_id' });
  const exportDate = useWatch({ control, name: 'export_date' });
  const deliveries = useWatch({ control, name: 'deliveries' });
  const sales = useWatch({ control, name: 'sales' });

  // Only allows deletion if this export is not completed and has not linked
  // to any sales or deliveries.
  const isDeleteBtnVisible = useMemo(() => {
    return (
      editable &&
      id &&
      !(sales === undefined ? sales : sales.length) &&
      !(deliveries === undefined ? deliveries : deliveries.length)
    );
  }, [editable, sales, deliveries, id]);

  const { showFormError } = useFormError();

  const { refetch } = useQuery(['export', id], () => getExportById(id as number), {
    enabled: !!id,
    onSettled: () => uiStore.hideLoading(),
    onSuccess: (data) => {
      reset(data);
      setEditable(data.complete === 1 ? false : true);
      setLoaded(true);
    },
  });

  const createMutation = useMutation((data: any) => createExport(data), {
    onMutate: () => uiStore.showLoading(),
    onSettled: () => uiStore.hideLoading(),
    onError: (err) => showFormError(err),
    onSuccess: (data) => {
      toast.success('登録しました');
      control._formState.isDirty = false;
      setTimeout(() => {
        if (pathToNavigate) {
          navigate(pathToNavigate + `&target_id=${data.id}`);
        } else {
          navigate(`/exports/${data.id}?type=${exportType}`);
        }
      }, 10);
    },
  });

  const updateMutation = useMutation((data: any) => updateExport(id as number, data), {
    onMutate: () => uiStore.showLoading(),
    onSettled: () => uiStore.hideLoading(),
    onError: (err) => showFormError(err),
    onSuccess: (_data) => {
      toast.success('登録しました');
      control._formState.isDirty = false;

      if (pathToNavigate) {
        // react-query doesn't know about newly updated state until later
        setTimeout(() => {
          navigate(pathToNavigate + `&target_id=${id}`);
        }, 10);
      } else {
        // Fix a bug that causes data in dataProvider.selectedList to be stale.
        // Changing rows when submitting form will not set the values back to
        // dataProvider.selectedList because changeSource is reset to 'out'
        // & didMount's value is preserved after the first render. So, when ever
        // there is a form update, unmount then re-mount the whole component.
        // Note: DO NOT TOUCH THIS CODE EVER
        setKey && setKey(Math.random());
      }
    },
  });

  const completeMutation = useMutation(() => completeExport(id as number), {
    onMutate: () => uiStore.showLoading(),
    onSettled: () => uiStore.hideLoading(),
    onSuccess: () => {
      refetch();
    },
  });

  const revertMutation = useMutation(() => revertExportCompletion(id as number), {
    onMutate: () => uiStore.showLoading(),
    onSettled: () => uiStore.hideLoading(),
    onSuccess: () => {
      refetch();
    },
  });

  const deleteMutation = useMutation(() => deleteExport(id as number), {
    onMutate: () => uiStore.showLoading(),
    onSettled: () => uiStore.hideLoading(),
    onSuccess: () => {
      toast.success('登録しました');
      control._formState.isDirty = false;

      setTimeout(() => {
        navigate('/exports', { replace: true });
      }, 10);
    },
  });

  useEffect(() => {
    if (id) uiStore.showLoading();
  }, []);

  const save = (path?: string) => {
    const values = getValues();
    let payload: any;

    if (id) {
      payload = getDirtyObject(dirtyFields, values);
      if (!isDirty) return;
      if (isDirty && JSON.stringify(payload) === '{}') {
        return;
      }
    } else {
      payload = values;
    }

    if (payload.export_details) {
      payload.export_details = payload.export_details?.map((item: any) => ({
        ...item,
        id: typeof item.id === 'string' ? undefined : item.id,
      }));
    }

    // used for navigating to sales or deliveries.
    setPathToNavigate(path);
    setTimeout(() => {
      id ? updateMutation.mutate(payload) : createMutation.mutate(payload);
    }, 10);
  };

  const complete = completeMutation.mutate;
  const revertComplete = revertMutation.mutate;

  const showConfirmDeletePopup = () => {
    uiStore.showConfirmBox({
      title: 'このレコードを削除しますか？',
      confirmText: '削除する',
      cancelText: ' キャンセル',
      onConfirm: () => {
        uiStore.hideConfirmBox();
        control._formState.isDirty = false;
        setTimeout(() => {
          deleteMutation.mutate();
        }, 10);
      },
    });
  };

  const exportExportDetail = async (type: string | number) => {
    try {
      uiStore.showLoading();
      const path = `exports/export-details/${id as number}`;
      const payload = {
        form_type: type,
      };

      await exportForm(path, payload);
    } catch (error: any) {
      uiStore.showAlertBox({
        title: 'エラー',
        type: 'error',
        content: error.message,
      });
    } finally {
      uiStore.hideLoading();
    }
  };

  const exportExportDetailToPDF = (type: string | number) => {
    exportExportDetail(type);
  };

  const exportExportDetailToExcel = () => {
    exportExportDetail('R1206');
  };

  // use control._formState.isDirty instead of
  // isDirty because isDirty is read-only.
  // This blocks when the user changes something then clicks navigate to
  // one of the pages on the sidebar.
  useBlockNavigation({ shouldBlock: control._formState.isDirty });

  return (
    <div className={styles.container}>
      <ExportDetailsForm
        id={id}
        exportType={exportType}
        control={control}
        onSave={save}
        editable={editable}
        onComplete={complete}
        onRevert={revertComplete}
        loaded={loaded}
        setValue={setValue}
        isValid={isValid}
        isDirty={isDirty}
        exportExportDetailToPDF={exportExportDetailToPDF}
        refetch={refetch}
      />
      {loaded && (
        <Controller
          control={control}
          name="export_details"
          render={({ field }) => (
            <ExportDetailsTable
              initialRows={field.value}
              editable={editable}
              setValue={setValue}
              groupId={groupId}
              exportDate={exportDate}
            />
          )}
        />
      )}
      <div className="d-flex justify-content-between align-items-center">
        {canBack ? 
          (<button className="primary-btn" onClick={() => navigate(-1)}>
            戻る
          </button>) : <div />
        }
        <div className="d-flex align-items-center">
          {isDeleteBtnVisible && (
            <button className="primary-btn--delete me-2" onClick={showConfirmDeletePopup}>
              削除
            </button>
          )}
          <button className="primary-btn" onClick={exportExportDetailToExcel}>
            一括出力
          </button>
        </div>
      </div>
    </div>
  );
});

export default ExportDetailsContainer;
