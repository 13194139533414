import { action, makeObservable, observable } from 'mobx';

class UIStore {
  constructor() {
    makeObservable(this);
  }

  @observable isShowLoading: boolean = false;

  @observable confirmBox: {
    visible: boolean;
    title?: string;
    content?: string;
    contentComponent?: any;
    confirmText?: string;
    cancelText?: string;
    onConfirm?: () => void;
    onCancel?: () => void;
  } = { visible: false };

  @observable alertBox: {
    visible: boolean;
    type?: 'error' | 'success';
    title?: string;
    content?: string;
    closeText?: string;
    onClose?: () => void;
  } = { visible: false };

  @action
  showConfirmBox(data: {
    title: string;
    content?: string;
    contentComponent?: any;
    confirmText: string;
    cancelText?: string;
    onConfirm?: () => void;
    onCancel?: () => void;
  }) {
    this.confirmBox = { visible: true, ...data };
  }

  @action hideConfirmBox() {
    this.confirmBox = { visible: false };
  }

  @action showAlertBox(data: {
    title: string;
    content: string;
    closeText?: string;
    onClose?: () => void;
    type?: 'error' | 'success';
  }) {
    this.alertBox = {
      visible: true,
      ...data,
      type: data.type ? data.type : 'error',
      closeText: data.closeText || '閉じる',
      onClose: data.onClose ? data.onClose : () => this.hideAlertBox(),
    };
  }

  @action hideAlertBox() {
    this.alertBox = { visible: false };
  }

  @action
  showLoading = () => {
    this.isShowLoading = true;
  };

  @action
  hideLoading = () => {
    this.isShowLoading = false;
  };
}

export default UIStore;
