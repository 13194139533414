import classNames from 'classnames';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { MdError } from 'react-icons/md';

import useStores from 'hooks/use-stores';
import UIStore from 'stores/ui';
import { convertToHalfWidth } from 'utils/validator';

import styles from './styles.module.scss';

interface IProps {
  className?: any;
  inputClassName?: any;
  value?: string;
  validate: (value: string, showError: (err: boolean) => void) => void;
  disabled?: boolean;
  type?: 'text' | 'number';
  allowFullWidth?: boolean;
}

const ValidatingInput = ({
  className,
  inputClassName,
  validate,
  value,
  disabled = false,
  type = 'text',
  allowFullWidth,
}: IProps) => {
  const uiStore: UIStore = useStores().uiStore;

  const [text, setText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<any>(null);

  const inputRef = useRef<HTMLInputElement | null>(null);

  // debouncer
  const timeoutFunc = useRef<any>(null);

  useEffect(() => {
    setText(value === undefined ? '' : value);
  }, [value]);

  const onTextChange = (event: ChangeEvent<HTMLInputElement>) => {
    let val = event.target.value;
    const pattern = allowFullWidth ? RegExp(/^[０-９0-9]+$/) : RegExp(/^[0-9]+$/);
    const isNumber = pattern.test(val);

    switch (true) {
      case val === '':
        setText('');
        break;
      case val !== '' && type === 'number':
        if (isNumber) {
          const newVal = allowFullWidth ? convertToHalfWidth(val) : val;
          setText(newVal);
        }
        break;
      default:
        setText(val);
        break;
    }

    if (timeoutFunc.current !== null) clearTimeout(timeoutFunc.current);
    setIsLoading(true);
    uiStore.showLoading();

    timeoutFunc.current = setTimeout(() => {
      let validatedValue: string = '';

      switch (true) {
        case val === '':
          validatedValue = '';
          break;
        case val !== '' && type === 'number':
          if (isNumber) {
            validatedValue = allowFullWidth ? convertToHalfWidth(val) : val;
          } else {
            validatedValue = text;
          }
          break;
        default:
          validatedValue = val;
          break;
      }

      validate(validatedValue, (err) => {
        setError(err ? 'error' : null);
        setIsLoading(false);
      });

      uiStore.hideLoading();

      timeoutFunc.current = null;
    }, 1000);
  };

  return (
    <div
      className={classNames(styles.container, className)}
      style={{
        backgroundColor: `${disabled ? 'rgb(222,222,222)' : 'white'}`,
      }}
    >
      <input
        ref={inputRef}
        className={`${inputClassName}`}
        value={text}
        disabled={disabled}
        onChange={onTextChange}
      />
      <div className={styles.meta}>
        {isLoading && <Spinner animation="border" className={styles.spinner} />}
        {error && !isLoading && <MdError className={styles.error} />}
      </div>
    </div>
  );
};

export default ValidatingInput;
