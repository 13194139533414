// Style
import styles from './styles.module.scss';

const Home = () => {
  return (
    <div className={styles.container}>
      <p className={styles.title}>左のメニューを選択してください。</p>
      <div>
        <div className={styles.content}>
          <p className={styles.content_bold}>見積</p>
          <p className={styles.margin__bottom}>パチンコ台などの商品買取りの見積を行う機能</p>
          <p className={styles.content_bold}>買取</p>
          <div className={styles.margin__bottom}>
            <p>パチンコ台やリサイクル品の商品買取りを行う機能</p>
            <p>登録したデータは月締処理にて、買掛項目として集計される。</p>
          </div>
          <p className={styles.content_bold}>売上（従来の出庫・業販画面）</p>
          <div className={styles.margin__bottom}>
            <p>
              パチンコ台の販売、運賃、梱包、ゴミ処分、手数料などの全ての受注（売上）を登録する機能
            </p>
            <p>登録したデータは月締処理にて、売掛項目として集計される。</p>
          </div>
        </div>

        <div className={styles.content_2}>
          <p className={styles.content_bold}>入庫</p>
          <p className={styles.margin__bottom}>
            パチンコ店からパチンコ台などの商品を預かり入庫する業務に関する機能
          </p>
          <p className={styles.content_bold}>出庫</p>
          <div className={styles.margin__bottom}>
            <p>パチンコ台などの倉庫に預かっている商品をパチンコ店へ出庫する業務に関する機能</p>
          </div>
          <p className={styles.content_bold}>在庫</p>
          <div className={styles.margin__bottom}>
            <p>現在倉庫で預かっているパチンコ台などの商品を管理する機能（在庫管理）</p>
            <p>
              登録されている商品データは月締処理にて、毎月の預かり費用として集計し売掛項目に計上される。
            </p>
          </div>
        </div>

        <div className={styles.content_3}>
          <p className={styles.content_bold}>配送</p>
          <div className={styles.margin__bottom}>
            <p>パチンコ台などの商品配送業務を管理機能</p>
            <p>配送のスケジュールやトラック・運転手の配置を管理する。</p>
            <p>配送終了したものはその費用を受注に登録できる。</p>
          </div>
          <p className={styles.content_bold}>車両</p>
          <div className={styles.margin__bottom}>
            <p>車両経費の計上機能機能</p>
          </div>
          <p className={styles.content_bold}>集計</p>
          <div className={styles.margin__bottom}>
            <p>毎月の精算処理を実施する機能</p>
            <p>預かり報告書・買取売上明細・請求書・支払伝票など毎月の帳票出力を行う機能</p>
          </div>
          <p className={styles.content_bold}>入出金</p>
          <div className={styles.margin__bottom}>
            <p>顧客からの入金および出金（支払い）を登録する機能</p>
            <p>精算処理で売掛残・買掛残に反映される。</p>
          </div>
          <p className={styles.content_bold}>マスター</p>
          <div className={styles.margin__bottom}>
            <p>各種マスターデータの登録・削除</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
