// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_header__bRhZF {\n  text-align: left;\n  width: 100vw;\n  height: 50px;\n  background-color: aqua;\n  padding: 10px 0;\n}", "",{"version":3,"sources":["webpack://./src/shared/containers/layouts/header/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,YAAA;EACA,YAAA;EACA,sBAAA;EACA,eAAA;AACF","sourcesContent":[".header {\n  text-align: left;\n  width: 100vw;\n  height: 50px;\n  background-color: aqua;\n  padding: 10px 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "styles_header__bRhZF"
};
export default ___CSS_LOADER_EXPORT___;
