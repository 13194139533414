export type Lcd = {
  id: number;
  lcd_code: string;
  lcd_form?: string;
  lcd_inch?: number;
  lcd_model?: string;
  lcd_note?: string;
  lcd_weight?: number;
  base_unit_price?: number;
  maker_name?: string;
  status: 0 | 1;
};

export interface ILcdFilter {
  lcd_code: string | null;
  lcd_model: string | null;
  maker_name: string | null;
  lcd_inch: number | null;
  sort: string | null;
  sort_type: 'asc' | 'desc';
}

export const DEFAULT_LCD_FILTER: ILcdFilter = {
  lcd_code: null,
  lcd_model: null,
  lcd_inch: null,
  maker_name: null,
  sort: 'lcd_code',
  sort_type: 'asc',
};
