// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_dropdown__dAuT2 {\n  height: 30px;\n  border: 1px solid lightgray;\n  border-radius: 0.25rem;\n  flex: 1 1;\n}", "",{"version":3,"sources":["webpack://./src/shared/components/dynamic-dropdown/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,2BAAA;EACA,sBAAA;EACA,SAAA;AACF","sourcesContent":[".dropdown {\n  height: 30px;\n  border: 1px solid lightgray;\n  border-radius: 0.25rem;\n  flex: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropdown": "styles_dropdown__dAuT2"
};
export default ___CSS_LOADER_EXPORT___;
