// Built-in
import { useEffect, useMemo, useRef, useState } from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { useMutation } from 'react-query';
import { MdClose } from 'react-icons/md';

// Component
import DataTable from 'shared/components/data-table';
import TextFilter from 'shared/components/text-filter';
import DynamicDropdownFilter from 'shared/components/dynamic-dropdown-filter';
import StaticDropdownFilter from 'shared/components/static-dropdown-filter';
import EditModal from './edit-modal';

// Type
import { Customer, DEFAULT_CUSTOMER_FILTER, ICustomerFilter } from 'types/customer';

// Store
import { DataProvider } from 'shared/data-providers/table-data-provider';
import { DropdownDataProvider } from 'shared/data-providers/dropdown-data-provider';
import UIStore from 'stores/ui';
import useStores from 'hooks/use-stores';

// Service
import { createCustomer, deleteCustomer, saveCustomer } from 'services/customer';

// Style
import styles from './styles.module.scss';

// Const
import { KANA_ROWS } from 'consts';

// Hook
import { useFormError } from 'hooks/use-form-error';
import RadioButtonFilter from 'shared/components/radio-button-filter';

const CustomerScreen = () => {
  const uiStore: UIStore = useStores().uiStore;

  const [editModal, setEditModal] = useState(false);
  const [selected, setSelected] = useState<any>(null);

  const dataProvider = useRef(
    new DataProvider<ICustomerFilter>({
      dataSource: 'customers',
      localStorageKey: 'customer_filters',
      defaultFilters: DEFAULT_CUSTOMER_FILTER,
    })
  );
  const groupDropdownDataProvider = useRef(
    new DropdownDataProvider('groups/dropdown', (group) => ({
      label: group.group_name,
      value: group.id,
    }))
  ).current;

  const { control, handleSubmit, setValue, reset } = useForm<ICustomerFilter>({
    defaultValues: dataProvider.current.filters,
  });

  const { showFormError } = useFormError();

  const headers = useMemo(() => {
    return [
      { label: '顧客CD', key: 'customer_code' },
      { label: 'グループ' },
      { label: '顧客名'},
      { label: 'ホール名'},
      { label: '住所' },
      { label: 'TEL' },
      { label: 'FAX' },
      { label: '締日' },
      { label: '備考' },
      { label: 'メモ' },
      { label: '使用中止' },
      { label: '' },
    ];
  }, []);

  const delegate = (item: Customer) => {
    const customerCode = item.customer_code;
    const groups = item.groups.map((group) => group.group_name).join(', ');
    const customerName = item.customer_name;
    const hallName = item.hall_name;
    const address = (item.postal_code ? "〒" + item.postal_code : "") + " " + (item.address?item.address:"");
    const tel = item.tel?item.tel:"";
    const fax = item.fax?item.fax:"";
    const deadlineDay = item.deadline_day ? item.deadline_day : '末';
    const customerNote = item.customer_note;
    const customerMemo = item.customer_memo;
    const status = item.status === 1 ? '' : <MdClose className="status-icon" />;
    const editBtn = (
      <button
        className="edit"
        onClick={() => {
          setSelected(item);
          setEditModal(true);
        }}
      >
        修
      </button>
    );

    return [
      customerCode,
      groups,
      customerName,
      hallName,
      address,
      tel,
      fax,
      deadlineDay,
      customerNote,
      customerMemo,
      status,
      editBtn,
    ];
  };

  const saveMutation = useMutation(
    (data: Omit<Customer, any>) => saveCustomer(selected!.id, data),
    {
      onSuccess: () => {
        setEditModal(false);
        dataProvider.current.load();
      },
      onError: (err) => showFormError(err),
    }
  );

  const createMutation = useMutation((data: Omit<Customer, any>) => createCustomer(data), {
    onSuccess: () => {
      setEditModal(false);
      dataProvider.current.load();
    },
    onError: (err) => showFormError(err),
  });

  const deleteMutation = useMutation(() => deleteCustomer(selected!.id), {
    onSuccess: () => dataProvider.current.load(),
  });

  const resetFilter = () => {
    reset(DEFAULT_CUSTOMER_FILTER);
    dataProvider.current.clearFilters();
  };

  const openCreateCustomerModal = () => {
    setSelected(null);
    setEditModal(true);
  };

  const showConfirmDeletePopup = () => {
    uiStore.showConfirmBox({
      title: 'このレコードを削除しますか？',
      confirmText: '削除する',
      cancelText: ' キャンセル',
      onConfirm: () => {
        uiStore.hideConfirmBox();
        deleteMutation.mutate();
      },
    });
  };

  const onClose = () => {
    setEditModal(false);
  };

  const onCreate = (data: any) => {
    createMutation.mutate(data);
  };

  const onSave = (data: any) => {
    saveMutation.mutate(data);
  };

  const onDelete = () => {
    setEditModal(false);
    showConfirmDeletePopup();
  };

  return (
    <div>
      <div className="list-screen">
        <Breadcrumb>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/home' }}>
            TOP
          </Breadcrumb.Item>
          <Breadcrumb.Item active>マスター</Breadcrumb.Item>
          <Breadcrumb.Item active>顧客</Breadcrumb.Item>
        </Breadcrumb>

        <div className={styles.filterContainer}>
          <div className={styles.customerCode}>
            <TextFilter label="顧客CD" control={control} keys={['customer_code']} />
          </div>

          <div className={styles.groupId}>
            <DynamicDropdownFilter
              label="グループ"
              keys={['group_id']}
              control={control}
              dataProvider={groupDropdownDataProvider}
              setValue={setValue}
              placeholder="選択"
            />
          </div>

          <div className={styles.status}>
            <RadioButtonFilter
              label={'使用'}
              control={control}
              keys={['status']}
              options={[
                { label: '全て', value: null },
                { label: '使用中', value: 1 },
                { label: '使用中止', value: 0 },
              ]}
            />
          </div>

          <div className={styles.buttonGroup}>
            <button
              className="action-btn"
              onClick={handleSubmit((values) => {
                const newFilter = {
                  ...values,
                  sort: dataProvider.current.filters.sort,
                  sort_type: dataProvider.current.filters.sort_type,
                };
                dataProvider.current.setFilters(newFilter);
              })}
            >
              検索
            </button>
            <button className="action-btn" onClick={resetFilter}>
              クリア
            </button>
          </div>

          <div className={styles.wrap}></div>

          <div className={styles.customerNameKana}>
            <TextFilter label="顧客名・カナ" control={control} keys={['customer_name_kana']} />
          </div>

          <div className={styles.customerHallName}>
            <TextFilter
              label="ホール名・カナ"
              control={control}
              keys={['customer_hall_name_kana']}
            />
          </div>

          <div className={styles.customerKanaRow}>
            <StaticDropdownFilter
              label="顧客カナ"
              keys={['customer_kana_row']}
              control={control}
              options={KANA_ROWS}
              setValue={setValue}
              placeholder="選択"
            />
          </div>

          <div className={styles.customerHallKanaRow}>
            <StaticDropdownFilter
              label="ホールカナ"
              keys={['customer_hall_kana_row']}
              control={control}
              options={KANA_ROWS}
              setValue={setValue}
              placeholder="選択"
            />
          </div>
        </div>

        <div className="d-flex justify-content-end">
          <button className="create" onClick={openCreateCustomerModal}>
            作成
          </button>
        </div>

        <div className={styles.tableWrapper}>
          <DataTable dataProvider={dataProvider.current} headers={headers} delegate={delegate} />
        </div>
      </div>

      {editModal && (
        <EditModal
          customerId={selected?.id}
          onClose={onClose}
          onCreate={onCreate}
          onSave={onSave}
          onDelete={onDelete}
        />
      )}
    </div>
  );
};

export default CustomerScreen;
