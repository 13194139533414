// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_container__EF9S- {\n  width: 100%;\n  display: flex;\n  align-items: center;\n}\n.styles_container__EF9S- .styles_datePicker__v0gjW {\n  border: 1px solid #d3d8de;\n  font-size: 1rem;\n  padding: 0 0.5rem;\n  height: 30px;\n  width: 100%;\n}\n.styles_container__EF9S- .styles_datePicker__v0gjW:focus {\n  outline: none;\n}", "",{"version":3,"sources":["webpack://./src/shared/components/date-filter/styles.module.scss"],"names":[],"mappings":"AAEA;EACE,WAAA;EACA,aAAA;EACA,mBAAA;AADF;AAGE;EACE,yBAAA;EACA,eAAA;EACA,iBAAA;EACA,YAAA;EACA,WAAA;AADJ;AAGI;EACE,aAAA;AADN","sourcesContent":["@import '../../../assets/styles/variables';\n\n.container {\n  width: 100%;\n  display: flex;\n  align-items: center;\n\n  .datePicker {\n    border: 1px solid $lightBlueColor;\n    font-size: 1rem;\n    padding: 0 0.5rem;\n    height: 30px;\n    width: 100%;\n\n    &:focus {\n      outline: none;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "styles_container__EF9S-",
	"datePicker": "styles_datePicker__v0gjW"
};
export default ___CSS_LOADER_EXPORT___;
