import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import moment from 'moment';

import SaleDetailsForm from './form';
import SaleDetailsTable from './table';
import { Sale } from 'types/sale';
import useStores from 'hooks/use-stores';
import { useFormError } from 'hooks/use-form-error';
import UIStore from 'stores/ui';
import { updateSale, createSale, deleteSale, getSaleById } from 'services/sale';
import { popupConfirmDelete } from 'utils/modals';
import { getDirtyObject, removeKeys } from 'utils';

import styles from './styles.module.scss';
import { exportForm } from 'services/export-form';
import { toast } from 'react-toastify';
import useBlockNavigation from 'hooks/use-block-navigation';
import SessionStore from 'stores/session';

interface IProps {
  id?: number;
  purchaseId?: number;
  initialValues?: any;
  target?: {
    [key: string]: any;
  };
}

const SaleDetailsContainer = observer(({ id, purchaseId, initialValues, target }: IProps) => {
  const navigate = useNavigate();
  const uiStore: UIStore = useStores().uiStore;
  const sessionStore: SessionStore = useStores().sessionStore;
  const [pathToNavigate, setPathToNavigate] = useState<string | undefined>(undefined);

  const initialFormValues = useMemo(() => {
    let values: any = {};
    const profile = sessionStore.profile;
    const canBeUserInput = profile?.status === 1 && profile?.user_types?.INPUT_PERSON === 1;

    // duplicated or created from import, export,...
    if (initialValues) {
      values = initialValues;
      if (values.target_type === 'IMPORT' && canBeUserInput) {
        values.user_input_id = profile.id;
      }
    } else {
      // new sale
      values = {
        input_date: moment(new Date()).format('YYYY/MM/DD'),
        complete: 0,
        sale_details: [],
        target_type: null,
        target_id: null,
        sale_type: 3,
      };

      // set currently logged-in user as user input of this form.
      if (canBeUserInput) values.user_input_id = profile.id;
    }

    // new sale with target can also have initialValues (from import, export)
    if (target) {
      values.target_type = target.targetType;
      values.target_id = target.targetId;

      switch (target.targetType) {
        case 'DELIVERY':
          values.sale_type = 4;
          break;
        case 'PURCHASE':
          values.sale_type = 6;
          break;
        case 'IMPORT':
        case 'EXPORT':
          break;
        default:
          values.sale_type = 2;
          break;
      }
    }

    return values;
  }, []);

  const {
    control,
    setValue,
    getValues,
    reset,
    formState: { isDirty: _, dirtyFields },
  } = useForm<Partial<Sale>>({
    defaultValues: initialFormValues,
    mode: 'onChange',
  });
  const completed = useWatch({ control, name: 'complete' });
  const customerId = useWatch({ control, name: 'customer_id' });
  const billingBy = useWatch({ control, name: 'customer.billing_by' });

  const { showFormError } = useFormError();

  const canRemove = useMemo(() => {
    return !completed && id;
  }, [completed, id]);

  const canDuplicate = useMemo(() => {
    return id;
  }, [id]);

  const { refetch } = useQuery(['sale', id], () => getSaleById(id as number), {
    enabled: !!id,
    onSettled: () => uiStore.hideLoading(),
    onSuccess: (data) => {
      reset(data);
    },
  });

  const createMutation = useMutation((payload: any) => createSale(payload, purchaseId), {
    onMutate: () => uiStore.showLoading(),
    onSettled: () => uiStore.hideLoading(),
    onError: (err) => showFormError(err),
    onSuccess: (data) => {
      toast.success('登録しました');
      control._formState.isDirty = false;

      setTimeout(() => {
        if (pathToNavigate) {
          navigate(pathToNavigate + data.id);
        } else {
          navigate(`/sales/${data.id}`);
        }
      }, 10);
    },
  });

  const updateMutation = useMutation((data: any) => updateSale(id as number, data), {
    onMutate: () => uiStore.showLoading(),
    onSettled: () => uiStore.hideLoading(),
    onError: (err) => showFormError(err),
    onSuccess: (_data) => {
      toast.success('登録しました');
      control._formState.isDirty = false;

      setTimeout(() => {
        if (pathToNavigate) {
          navigate(pathToNavigate + id);
        } else {
          refetch();
        }
      }, 10);
    },
  });

  const deleteMutation = useMutation(() => deleteSale(id as number), {
    onMutate: () => uiStore.showLoading(),
    onSettled: () => uiStore.hideLoading(),
    onSuccess: () => {
      toast.success('登録しました');
      control._formState.isDirty = false;

      setTimeout(() => {
        navigate('/sales');
      }, 10);
    },
  });

  const save = (path?: string) => {
    const values = getValues();
    let payload: any;

    if (id) {
      payload = getDirtyObject(dirtyFields, values);

      switch (true) {
        case !control._formState.isDirty:
          if (path) {
            navigate(path + id);
          }
          return;
        case JSON.stringify(payload) === '{}':
          if (path) {
            navigate((path as string) + id);
          }
          return;
        default:
          break;
      }
    } else {
      payload = values;
      if (!payload.aggregation_date) payload.aggregation_date = payload.sale_date;
    }

    removeKeys(payload, ['customer']);

    if (payload.sale_details) {
      payload.sale_details = payload.sale_details.map((product: any) => {
        const item = { ...product };
        if (typeof item.id === 'string') item.id = undefined;
        // use customer_id from form if customer_id is empty
        if (!item.customer_id) item.customer_id = billingBy ? billingBy.id : customerId;
        // item.customer = undefined;
        // item.product = undefined;
        item.product_quantity = item.product_quantity || 0;
        item.purchase_unit_price = item.purchase_unit_price || 0;
        return item;
      });
    }

    setPathToNavigate(path);
    setTimeout(() => {
      if (!id) {
        createMutation.mutate(payload);
      } else {
        // if this sale has linked to 1 or many deliveries,
        // its type has to be one of those [2,6,7,8]
        /*
        // Disable check for existence of shipping link, as sales cannot be edited.
        if (
          !!values.deliveries?.length &&
          values.sale_type &&
          ![2, 6, 7, 8].includes(values.sale_type)
        ) {
          uiStore.showAlertBox({
            title: 'エラー',
            content: '既に入庫と紐づけられたため、変更できません。',
          });
          setPathToNavigate('');
          return;
        }
        */
        updateMutation.mutate(payload);
      }
    }, 10);
  };

  const duplicateSaleForm = (_event: any) => {
    window.open(`${window.location.origin}/sales/create?id=${id}`);
  };

  const deleteSaleItem = (_event: any) => {
    popupConfirmDelete(() => deleteMutation.mutate());
  };

  const exportSaleDetailToPDF = async (type: string | number, by: number) => {
    try {
      uiStore.showLoading();
      const payload = {
        form_type: type,
        export_detail_customer: by,
      };

      await exportForm(`sales/export-details/${id as number}`, payload);
    } catch (error: any) {
      uiStore.showAlertBox({
        title: 'エラー',
        type: 'error',
        content: error.message,
      });
    } finally {
      uiStore.hideLoading();
    }
  };

  useBlockNavigation({ shouldBlock: control._formState.isDirty });

  useEffect(() => {
    if (id) uiStore.showLoading();
  }, []);

  return (
    <div className={styles.container}>
      <SaleDetailsForm
        control={control}
        setValue={setValue}
        onSave={save}
        id={id}
        exportSaleDetailToPDF={exportSaleDetailToPDF}
        refetch={refetch}
      />
      <Controller
        control={control}
        name="sale_details"
        render={({ field: { value } }) => (
          <SaleDetailsTable
            initialRows={value as Array<any>}
            control={control}
            setValue={setValue}
            billingBy={billingBy}
          />
        )}
      />
      <div className="d-flex justify-content-between">
        <button className="primary-btn" onClick={() => navigate(-1)}>
          戻る
        </button>
        <div>
          {canRemove && (
            <button className="primary-btn--delete me-2" onClick={deleteSaleItem}>
              削除
            </button>
          )}
          {canDuplicate && (
            <button className="primary-btn" onClick={duplicateSaleForm}>
              複製
            </button>
          )}
        </div>
      </div>
    </div>
  );
});

export default SaleDetailsContainer;
