import { Truck } from './truck';

export type VehicleCost = {
  id: number;
  truck_id: number;
  cost_type: 1 | 2;
  cost_name: string;
  effective_date: string;
  payee_id?: number | null;
  amount?: number | string;
  cost_memo?: string;
  payee?: any;
  truck?: Truck;
};

export interface IVehicleCostFilter {
  year: number | null;
  month: number | null;
  sort: string | null;
  sort_type: 'asc' | 'desc';
}

const thisYear = new Date().getFullYear();
const thisMonth = new Date().getMonth() + 1;

export const DEFAULT_VEHICLE_COST_FILTER: IVehicleCostFilter = {
  year: thisYear,
  month: thisMonth,
  sort: 'effective_date',
  sort_type: 'asc',
};
