import { observable, makeObservable, action, runInAction } from 'mobx';

import { get } from 'services';

export interface IDropdownDataProvider<I> {
  data: Array<I>;

  load(): Promise<any>;
}

export class DropdownDataProvider<I> implements IDropdownDataProvider<I> {
  @observable data: Array<any> = [];
  dataSource: string;
  params?: any;
  isLoading: boolean = false;
  isLoaded: boolean = false;
  format: (item: any) => { label: string; value: number | string };
  promise: any;

  constructor(url: string, format: (item: any) => { label: string; value: number | string }, params?: any) {
    this.dataSource = url;
    this.params = params;
    this.format = format;
    makeObservable(this);
  }

  @action
  async load() {
    if (!this.isLoaded && !this.isLoading) {
      try {
        this.isLoading = true;
        this.promise = get(this.dataSource, this.params||{});
        const data = await this.promise;
        runInAction(() => {
          this.isLoading = false;
          this.isLoaded = true;
          this.data = data.map(this.format);
        });
        return {
          options: data.map(this.format),
          hasMore: false,
        };
      } catch (err) {
        runInAction(() => {
          this.isLoading = false;
          this.isLoaded = false;
          this.data = [];
        });
        return {
          options: [],
          hasMore: false,
        };
      }
    } else {
      return this.promise.then((data: any) => ({
        options: data.map(this.format),
        hasMore: false,
      }));
    }
  }
}
