import { UNSAFE_NavigationContext } from 'react-router-dom';
import type { History, Transition } from 'history';
import { useContext, useEffect } from 'react';
import UIStore from 'stores/ui';
import useStores from './use-stores';

interface IProps {
  shouldBlock: boolean;
  onConfirm?: () => void;
  onCancel?: () => void;
}

const useBlockNavigation = ({ shouldBlock, onConfirm, onCancel }: IProps) => {
  const navigator = useContext(UNSAFE_NavigationContext).navigator as History;
  const uiStore: UIStore = useStores().uiStore;
  useEffect(() => {
    if (shouldBlock === false) return;

    const unblock = navigator.block((tx: Transition) => {
      // logout
      if (tx.location.pathname === '/login') {
        unblock();
        tx.retry();
        return;
      }
      if (shouldBlock) {
        uiStore.showConfirmBox({
          title: '変更内容を保存せず、終了しますか？',
          content: '',
          confirmText: 'OK',
          cancelText: 'キャンセル',
          onConfirm: () => {
            onConfirm && onConfirm();
            unblock();
            tx.retry();
            uiStore.hideConfirmBox();
          },
          onCancel: () => {
            onCancel && onCancel();
            uiStore.hideConfirmBox();
          },
        });
      } else {
        unblock();
        tx.retry();
      }
    });
    return unblock;
  }, [navigator, shouldBlock]);
};
export default useBlockNavigation;
