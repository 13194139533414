// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_filterContainer__SSIrK {\n  display: flex;\n  flex-wrap: wrap;\n  width: 90%;\n}\n.styles_filterContainer__SSIrK > * {\n  margin-bottom: 0.1em;\n}\n.styles_filterContainer__SSIrK > div {\n  width: 12.5%;\n}\n.styles_filterContainer__SSIrK > div:nth-of-type(1), .styles_filterContainer__SSIrK > div:nth-of-type(2), .styles_filterContainer__SSIrK > div:nth-of-type(3), .styles_filterContainer__SSIrK > div:nth-of-type(8), .styles_filterContainer__SSIrK > div:nth-of-type(9), .styles_filterContainer__SSIrK > div:nth-of-type(10) {\n  width: 16.6666666667%;\n}\n\n.styles_buttonGroup__afw1H {\n  display: flex;\n  width: 7.5%;\n  margin: 0 0 0 auto;\n}\n.styles_buttonGroup__afw1H button {\n  width: 5em;\n}\n.styles_buttonGroup__afw1H button:first-of-type {\n  margin: 0 1em 0 auto;\n}", "",{"version":3,"sources":["webpack://./src/shared/containers/stock-select-modal/stock-filter/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,eAAA;EACA,UAAA;AACF;AACE;EACE,oBAAA;AACJ;AACE;EACE,YAAA;AACJ;AAEE;EAME,qBAAA;AALJ;;AASA;EACE,aAAA;EACA,WAAA;EACA,kBAAA;AANF;AAQE;EACE,UAAA;AANJ;AASE;EACE,oBAAA;AAPJ","sourcesContent":[".filterContainer {\n  display: flex;\n  flex-wrap: wrap;\n  width: 90%;\n\n  & > * {\n    margin-bottom: 0.1em;\n  }\n  & > div {\n    width: calc(50% / 4);\n  }\n\n  & > div:nth-of-type(1),\n  & > div:nth-of-type(2),\n  & > div:nth-of-type(3),\n  & > div:nth-of-type(8),\n  & > div:nth-of-type(9),\n  & > div:nth-of-type(10) {\n    width: calc(50% / 3);\n  }\n}\n\n.buttonGroup {\n  display: flex;\n  width: 7.5%;\n  margin: 0 0 0 auto;\n\n  button {\n    width: 5em;\n  }\n\n  button:first-of-type {\n    margin: 0 1em 0 auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filterContainer": "styles_filterContainer__SSIrK",
	"buttonGroup": "styles_buttonGroup__afw1H"
};
export default ___CSS_LOADER_EXPORT___;
