import { IDataProvider } from 'shared/data-providers/table-data-provider';

const useSortableTable = <T, F extends { [key: string]: any }>(
  dataProvider: IDataProvider<T, F>
) => {
  const sort = (sort: string, sortType: 'asc' | 'desc') => {
    const alreadySorted =
      dataProvider.filters.sort === sort && dataProvider.filters.sort_type === sortType;

    if (alreadySorted) return;

    const newFilters = JSON.parse(JSON.stringify(dataProvider.filters));
    newFilters.sort = sort;
    newFilters.sort_type = sortType;
    dataProvider.setFilters(newFilters);
  };

  const isActive = (sort: string, sortType: 'asc' | 'desc') => {
    return dataProvider.filters.sort === sort && dataProvider.filters.sort_type === sortType;
  };

  return { sort, isActive };
};

export default useSortableTable;
