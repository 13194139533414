import stringWidth from 'string-width';

export const rowsValidator = (rows: any, keys: string[]) => {
  if (!rows.length) return true;

  return keys
    .map((key) => {
      const isValid = rows.every((row: { [key: string]: any }) => !!row[key] === true);
      return isValid;
    })
    .every((bol) => bol === true);
};

// check full-width & half-width
export const mbStrWidth = (input: string) => {
  let len = 0;
  for (let i = 0; i < input.length; i++) {
    let code = input.charCodeAt(i);
    if ((code >= 0x0020 && code <= 0x1fff) || (code >= 0xff61 && code <= 0xff9f)) {
      len += 1;
    } else if ((code >= 0x2000 && code <= 0xff60) || code >= 0xffa0) {
      len += 2;
    } else {
      len += 0;
    }
  }
  return len;
};

export const blockInputMathSymbols = (event: any, keys: Array<string>) => {
  if (keys.includes(event.key)) {
    event.preventDefault();
  }
};

export const detectFullWidthCharacter = (str: string) => {
  const arr = str.split('');

  const containedFullWidthChar = arr.some((char) => stringWidth(char) === 2);

  return containedFullWidthChar;
};

export const removeFullWidthChars = (str: string) => {
  const arr = str.split('');
  const newStr = arr.filter((char) => stringWidth(char) === 1).join('');

  return newStr;
};

export const convertToHalfWidth = (str: string) => {
  str = str.replace('ー', '-');
  return str.replace(/[！-～]/g, (fullWidthChar) =>
    String.fromCharCode(fullWidthChar.charCodeAt(0) - 0xfee0)
  );
};
