import stores from 'stores';
import UIStore from 'stores/ui';

export const popupConfirmBack = (
  condition: boolean,
  onClose: () => void,
  title?: string,
  confirmText?: string,
  cancelText?: string
) => {
  const uiStore: UIStore = stores.uiStore;

  if (condition) {
    uiStore.showConfirmBox({
      title: title ? title : '変更内容を保存せず、終了しますか？',
      confirmText: confirmText ? confirmText : 'OK',
      cancelText: cancelText ? cancelText : 'キャンセル',
      onConfirm: () => {
        onClose();
        uiStore.hideConfirmBox();
      },
    });
  } else {
    onClose();
  }
};

export const popupConfirmDelete = (
  onConfirm: () => void,
  title?: string,
  confirmText?: string,
  cancelText?: string
) => {
  const uiStore: UIStore = stores.uiStore;

  uiStore.showConfirmBox({
    title: title ? title : 'このレコードを削除しますか？',
    confirmText: confirmText ? confirmText : '削除する',
    cancelText: cancelText ? cancelText : 'キャンセル',
    onConfirm: () => {
      onConfirm && onConfirm();
      uiStore.hideConfirmBox();
    },
  });
};

export const popupConfirmSaveBeforeNavigate = ({
  onConfirm,
  onCancel,
}: {
  onConfirm?: any;
  onCancel?: any;
}) => {
  const uiStore: UIStore = stores.uiStore;

  uiStore.showConfirmBox({
    title: '変更内容を保存せず、終了しますか？',
    confirmText: '登録',
    cancelText: 'キャンセル',
    onConfirm: () => {
      onConfirm && onConfirm();
      uiStore.hideConfirmBox();
    },
    onCancel: () => {
      onCancel && onCancel();
    },
  });
};
