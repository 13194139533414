// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_container__E5t\\+\\+ {\n  display: flex;\n  align-items: center;\n}\n\n.styles_dropdown__QB\\+fH {\n  border: 1px solid lightgray;\n  border-radius: 0.25rem;\n  flex: 1 1;\n}", "",{"version":3,"sources":["webpack://./src/shared/containers/delivery-details/components/dropdown-select-options/styles.module.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,mBAAA;AADF;;AAIA;EACE,2BAAA;EACA,sBAAA;EACA,SAAA;AADF","sourcesContent":["@use '../../../../../assets/styles/variables' as *;\n\n.container {\n  display: flex;\n  align-items: center;\n}\n\n.dropdown {\n  border: 1px solid lightgray;\n  border-radius: 0.25rem;\n  flex: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "styles_container__E5t++",
	"dropdown": "styles_dropdown__QB+fH"
};
export default ___CSS_LOADER_EXPORT___;
