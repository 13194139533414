import { del, post, put } from 'services';

export const updateGroup = (
  id: number,
  data: { group_name?: string; group_note?: string; status?: 0 | 1; customer_ids?: Array<number> }
) => {
  return put(`groups/${id}`, data);
};

export const createGroup = (data: {
  group_name: string;
  group_note: string;
  status: 0 | 1;
  customer_ids?: Array<number>;
}) => {
  return post('groups', data);
};

export const deleteGroup = (id: number) => del(`groups/${id}`);
