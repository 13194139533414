// Built-in
import { useEffect, useRef, useState } from 'react';
import { Breadcrumb, Form } from 'react-bootstrap';

import { Link, useNavigate } from 'react-router-dom';

// Component
import DataTable from 'shared/components/data-table';
import FilterItem from 'shared/components/filter-item';

// Store
import UIStore from 'stores/ui';
import useStores from 'hooks/use-stores';
import { DataProvider } from 'shared/data-providers/table-data-provider';

// Service
import { exportForm } from 'services/export-form';

// Hook
import { useQueryParams } from 'hooks/use-query-params';

// Style
import styles from './styles.module.scss';

const InvoiceScreen = () => {
  const navigate = useNavigate();
  const uiStore: UIStore = useStores().uiStore;
  const [date, setDate] = useState<string>('');
  const [didMount, setDidMount] = useState<boolean>(false);
  const [selectedCustomers, setSelectedCustomers] = useState<Array<number>>([]);  

  const dataProvider = useRef(new DataProvider({ dataSource: 'reports/invoices' }));

  const query = useQueryParams();
  let params: any = {};
  for (let key of query.keys()) {
    params[key] = query.get(key);
  }

  const exportInvoiceFormToPDF = async (item?: any) => {
    try {
      uiStore.showLoading();
      const payload = {
        month: params.month,
        year: params.year,
        customer_id: selectedCustomers.join(','),
        form_type: 'R2203',
      };
      await exportForm('reports/export', payload);
    } catch (error: any) {
      uiStore.showAlertBox({
        title: 'エラー',
        type: 'error',
        content: error.message,
      });
    } finally {
      uiStore.hideLoading();
    }
  };

  const headers = [
    { label: '顧客CD' },
    { label: '顧客' },
    { label: '前回請求額' },
    { label: '入金額' },
    { label: '調整額' },
    { label: '当月売上金額' },
    { label: '今回請求額' },
    {
      label: (
        <div className={styles.header}>
          <button
            aria-label="print-all"
            className="secondary-btn"
            onClick={() => exportInvoiceFormToPDF()}
          >
          印刷
          </button>
  
          <Form.Check
            className={styles.allcheck}
            onChange={(event: any) => {
              if (event.target.checked) {
                const temp = dataProvider.current.data.map((item) => item.id);
                setSelectedCustomers(temp);
              } else {
                setSelectedCustomers([]);
              }
            }}
          />
        </div>
      ),
      centered: true,
    },    
  ];

  const delegate = (item: any) => {
    let name = `${item.customer_name} ${item.hall_name || ''}`;
    let checkbox = (
      <div className={styles['flex-center']}>
        <Form.Check
          className={styles.checkbox}
          checked={selectedCustomers.includes(item.id as number)}
          onChange={(event: any) => {
            if (event.target.checked) {
              const temp = [...selectedCustomers, item.id as number];
              setSelectedCustomers(temp);
            } else {
              const temp = selectedCustomers.filter((id) => id !== item.id);
              setSelectedCustomers(temp);
            }
          }}
        />
      </div>
    );    
    return [
      item.customer_code,
      name,
      item.sale_ledger.last_total_amount_request.toLocaleString('en-US'),
      item.sale_ledger.last_deposit_amount.toLocaleString('en-US'),
      item.sale_ledger.last_adjust_amount.toLocaleString('en-US'),
      item.sale_ledger.sale_amount.toLocaleString('en-US'),
      item.sale_ledger.total_amount_request.toLocaleString('en-US'),
      checkbox,
    ];
  };

  useEffect(() => {
    if (
      JSON.stringify(params) === '{}' ||
      !params.month ||
      !params.year ||
      isNaN(+params.month) ||
      isNaN(+params.year)
    ) {
      navigate(`/home`);
      return;
    }

    let formattedMonth = params.month.length === 1 ? `0${params.month}` : params.month;

    dataProvider.current.setFilters({
      month: +params.month,
      year: +params.year,
    });
    dataProvider.current.setLimit(50);

    setDate(`${params.year}/${formattedMonth}`);
    setDidMount(true);
  }, []);

  return (
    <div className={styles.container}>
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/home' }}>
          TOP
        </Breadcrumb.Item>
        <Breadcrumb.Item active>集計</Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/form-report' }}>
          帳票出力
        </Breadcrumb.Item>
        <Breadcrumb.Item active>請求書</Breadcrumb.Item>
      </Breadcrumb>

      <div className={styles.header}>
        <div className={styles.inputGroup}>
          <FilterItem label="処理月" />
          <input type="text" readOnly value={date} />
        </div>
        <button
          aria-label="back"
          className="secondary-btn"
          onClick={() => navigate(`/form-report`)}
        >
          戻る
        </button>
      </div>

      <div className={styles.tableWrapper}>
        {didMount && (
          <DataTable headers={headers} dataProvider={dataProvider.current} delegate={delegate} />
        )}
      </div>
    </div>
  );
};

export default InvoiceScreen;
