import moment from 'moment';

import { Customer } from './customer';
import { Product } from './product';
import { User } from './user';

export type Purchase = {
  id: number;
  purchase_code: string;
  quotation_id: number;
  purchase_type: number;
  input_date: string;
  purchase_date: string;
  aggregation_date: string;
  customer_id: number;
  user_pic_id: number;
  user_input_id: number;
  user_confirm_id: number;
  purchase_detail: string;
  business_contact: string;
  purchase_memo: string;
  complete: number;
  purchase_details: Array<PurchaseProduct>;
  updated_at: string;
  imports: Array<{
    id: number;
    import_code: string;
    import_fee: number;
  }>;
  deliveries: Array<{
    id: number;
    delivery_code: string;
    amount_fee: number;
  }>;

  customer: Partial<Customer>;
  user_person_in_charge: User;
  user_input_person: User;
  user_confirm_person: User;
  different_billing: number | null;
};

export type PurchaseProduct = {
  id: number | string;
  purchase_id?: number;
  customer_id?: number;
  device_type_code?: string;
  product_id?: number;
  product_quantity?: number;
  purchase_unit_price?: number;
  purchase_detail_note?: string;
  store?: string;

  customer?: Partial<Customer>;
  product?: Partial<Product>;
};

export interface IPurchaseFilter {
  input_date_from: string;
  input_date_to: string;
  customer_id: string | null;
  customer_id_in_detail: string | null;
  user_input_id: number | null;
  delivery: number | null;
  complete: number | null;
  purchase_code: string | null;
  purchase_date_from: string | null;
  purchase_date_to: string | null;
  sort: string | null;
  sort_type: 'asc' | 'desc';
}

const now = new Date();
const date = now.getDate();
const month = now.getMonth();
const year = now.getFullYear();

export const DEFAULT_PURCHASE_FILTER: IPurchaseFilter = {
  input_date_from: moment(new Date(year, month - 1, date)).format('YYYY-MM-DD'),
  input_date_to: moment(now).format('YYYY-MM-DD'),
  customer_id: null,
  customer_id_in_detail: null,
  user_input_id: null,
  delivery: null,
  complete: null,
  purchase_code: null,
  purchase_date_from: null,
  purchase_date_to: null,
  sort: 'purchase_code',
  sort_type: 'asc',
};
