import axios from 'axios';
import { API_URL } from 'configs';
import { history } from 'routes/history';
import stores from 'stores';

const defaultHeaders = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

const instance = axios.create({
  headers: defaultHeaders,
});

instance.defaults.baseURL = API_URL;

let headers: any = { ...defaultHeaders };

export const setAuthorization = (token: string) => {
  if (token !== '') headers = { ...headers, Authorization: `Bearer ${token}` };
  else delete headers['Authorization'];
};

instance.interceptors.request.use((config) => {
  const accessToken = localStorage.getItem('access_token');
  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }
  return config;
});

instance.interceptors.response.use(
  (response) => {
    if (response.data) {
      return response.data;
    }

    return Promise.reject({ message: '' });
  },
  (error) => {
    if (!error.response) {
      return Promise.reject({ message: '' });
    }
    if (error.response.status === 403) {
      // If the user log into 2 different accounts in the same web browser
      // then we have to reload in case the 2 accounts don't have the same
      // privileges.
      const currentToken = stores.sessionStore.token;
      const access_token = localStorage.getItem('access_token');
      if (currentToken !== access_token) {
        window.location.reload();
      }
      return Promise.reject({ message: error.response.data.message });
    }

    if (error.response.status === 401 && error.response.statusText === 'Unauthorized') {
      localStorage.removeItem('access_token');
      stores.sessionStore.clearSession();
      history.replace('/login');

      // omit error message
      return Promise.resolve(error.response);
    } else if (error.response.data.code && error.response.data.message) {
      return Promise.reject({
        message: error.response.data.message,
        errors: error.response.data.errors,
      });
    } else {
      return Promise.reject({ message: 'An error happened' });
    }
  }
);

export const get = async (path: string, params: any) => {
  const res = await instance.get(`/${path}`, { params });
  return res.data;
};

export const post = async (path: string, params: any) => {
  const res = await instance.post(`/${path}`, params);
  return res.data;
};

export const postFormData = async (path: string, formData: FormData) => {
  const res = await instance.post(`/${path}`, formData, {
    headers: { ...defaultHeaders, 'Content-Type': 'multipart/form-data' },
  });
  return res.data;
};

export const put = async (path: string, params: any) => {
  const res = await instance.put(`/${path}`, params);
  return res.data;
};

export const del = async (path: string) => {
  const res = await instance.delete(`/${path}`);
  return res.data;
};
