// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_tableWrapper__twauo table tbody tr td:first-of-type,\n.styles_tableWrapper__twauo table tbody tr td:nth-of-type(2),\n.styles_tableWrapper__twauo table tbody tr td:nth-of-type(4),\n.styles_tableWrapper__twauo table tbody tr td:last-of-type {\n  text-align: center;\n}", "",{"version":3,"sources":["webpack://./src/screens/vehicles/styles.module.scss"],"names":[],"mappings":"AAEI;;;;EAIE,kBAAA;AADN","sourcesContent":[".tableWrapper {\n  table tbody tr {\n    td:first-of-type,\n    td:nth-of-type(2),\n    td:nth-of-type(4),\n    td:last-of-type {\n      text-align: center;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableWrapper": "styles_tableWrapper__twauo"
};
export default ___CSS_LOADER_EXPORT___;
