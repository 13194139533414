import { Table } from 'react-bootstrap';

import styles from './styles.module.scss';

interface IProps<T> {
  headers: Array<{ label: any }>;
  data: Array<T & { id: any }>;
  renderRow: (item: T, index: number) => Array<any>;
  renderTotalRow?: () => any;
}

const DetailTable = <T,>({ headers, data, renderRow, renderTotalRow }: IProps<T>) => {
  return (
    <>
      <Table bordered hover className={styles.container}>
        <thead>
          <tr>
            {headers.map((item, index) => (
              <th key={index}>
                <div>{item.label}</div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={`${item.id}-${index}`}>
              {renderRow(item, index).map((cell, i) => (
                <td key={i}>{cell}</td>
              ))}
            </tr>
          ))}
          {renderTotalRow && <tr className="total">{renderTotalRow()}</tr>}
        </tbody>
      </Table>
    </>
  );
};

export default DetailTable;
