// Built-in
import { useRef, useState } from 'react';
import { Breadcrumb, BreadcrumbItem } from 'react-bootstrap';
import { useMutation } from 'react-query';
import { Link } from 'react-router-dom';
import { MdClose } from 'react-icons/md';

// Component
import DataTable from 'shared/components/data-table';
import EditTruckModal from './edit-modal';

// Type

// Store
import { DataProvider } from 'shared/data-providers/table-data-provider';
import UIStore from 'stores/ui';
import useStores from 'hooks/use-stores';

// Service
import { deleteTruck, saveTruck } from 'services/truck';

// Hook
import { useFormError } from 'hooks/use-form-error';

// Style
import styles from './styles.module.scss';

const TruckScreen = () => {
  const uiStore: UIStore = useStores().uiStore;

  const [editModal, setEditModal] = useState(false);
  const [selected, setSelected] = useState<any>(null);

  const { showFormError } = useFormError();

  const truckDataProvider = useRef(new DataProvider({ dataSource: 'trucks' }));

  const saveMutation = useMutation<any, unknown, any>((data) => saveTruck(data), {
    onSuccess: () => {
      setEditModal(false);
      truckDataProvider.current.load();
    },
    onError: (err) => showFormError(err),
  });

  const deleteMutation = useMutation<any, unknown, number>((id) => deleteTruck(id), {
    onSuccess: () => truckDataProvider.current.load(),
  });

  const headers = [
    { label: 'トラックID' },
    { label: '並び順' },
    { label: 'トラック名' },
    { label: '使用中止' },
    { label: '' },
  ];

  const delegate = (item: any) => {
    const id = item.id;
    const sort = item.sort;
    const truckName = item.truck_name;
    const status = item.status === 0 ? <MdClose className="status-icon" /> : '';
    const editBtn = (
      <button
        className="edit"
        onClick={() => {
          setSelected(item);
          setEditModal(true);
        }}
      >
        修
      </button>
    );

    return [id, sort, truckName, status, editBtn];
  };

  const showConfirmDeletePopup = () => {
    uiStore.showConfirmBox({
      title: 'このレコードを削除しますか？',
      confirmText: '削除する',
      cancelText: ' キャンセル',
      onConfirm: () => {
        uiStore.hideConfirmBox();
        deleteMutation.mutate(selected.id);
      },
    });
  };

  const onSave = (data: any) => {
    saveMutation.mutate({
      id: parseInt(data.id),
      sort: parseInt(data.sort),
      truck_name: data.truck_name,
      status: data.status,
    });
  };

  const onClose = () => {
    setEditModal(false);
  };

  const onDelete = () => {
    setEditModal(false);
    showConfirmDeletePopup();
  };

  const openCreateVehicleModal = () => {
    setSelected(null);
    setEditModal(true);
  };

  return (
    <div className="list-screen">
      <Breadcrumb>
        <BreadcrumbItem linkAs={Link} linkProps={{ to: '/home' }}>
          TOP
        </BreadcrumbItem>
        <BreadcrumbItem active>マスター</BreadcrumbItem>
        <BreadcrumbItem active>トラック</BreadcrumbItem>
      </Breadcrumb>

      <div className="d-flex justify-content-end">
        <button className="create" onClick={openCreateVehicleModal}>
          作成
        </button>
      </div>

      <div className={styles.tableWrapper}>
        <DataTable dataProvider={truckDataProvider.current} headers={headers} delegate={delegate} />
      </div>

      {editModal && (
        <EditTruckModal
          initialValues={selected}
          onClose={onClose}
          onDelete={onDelete}
          onSave={onSave}
        />
      )}
    </div>
  );
};

export default TruckScreen;
