import { useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import moment from 'moment';

import {
  completeImport,
  createImport,
  createStocksFromImport,
  deleteImport,
  getImportById,
  getImportDetailsFromExcel,
  revertImportCompletion,
  updateImport,
} from 'services/import';
import UIStore from 'stores/ui';
import { Import } from 'types/import';
import { getDirtyObject } from 'utils';
import ImportDetailsForm from './form';
import ImportDetailsTable from './table';
import useStores from 'hooks/use-stores';
import { DATE_FORMAT } from 'consts';
import { exportForm } from 'services/export-form';
import { useFormError } from 'hooks/use-form-error';
import { toast } from 'react-toastify';
import styles from './styles.module.scss';
import { v1 } from 'uuid';
import useBlockNavigation from 'hooks/use-block-navigation';
import SessionStore from 'stores/session';

interface IProps {
  id?: number;
  purchaseId?: number;
  purchaseType?: number;
  customerId?: number;
}

const ImportDetailsContainer = observer(({ id, purchaseId, purchaseType, customerId }: IProps) => {
  const navigate = useNavigate();

  const uiStore: UIStore = useStores().uiStore;
  const sessionStore: SessionStore = useStores().sessionStore;
  const inputRef = useRef<any>();
  const [inputKey, setInputKey] = useState<number>(0);
  const [editable, setEditable] = useState(true);
  const [pathToNavigate, setPathToNavigate] = useState<string | undefined>(undefined);

  const initialFormValues = useMemo(() => {
    const profile = sessionStore.profile;
    let values: any = {
      import_type: 1,
      input_date: moment(new Date()).format(DATE_FORMAT),
    };

    // create a new import from a purchase with type = 1.
    if (purchaseId && purchaseType === 1) {
      values.group_id = 3; //waste group
      values.import_type = 2; //waste
    }
    if (customerId) values.customer_id = customerId;

    const canBeUserInput = profile?.status === 1 && profile?.user_types?.INPUT_PERSON === 1;
    // set currently logged-in user as user input of this form.
    if (canBeUserInput) values.user_input_id = profile.id;

    return values;
  }, []);

  const {
    control,
    reset,
    setValue,
    getValues,
    formState: { dirtyFields, isDirty },
  } = useForm<Partial<Import>>({
    defaultValues: initialFormValues,
    mode: 'onChange',
  });
  const deliveries = useWatch({ control, name: 'deliveries' });
  const sales = useWatch({ control, name: 'sales' });

  const { showFormError } = useFormError();

  const isDeleteBtnVisible = useMemo(() => {
    return (
      editable &&
      id &&
      !(sales === undefined ? sales : sales.length) &&
      !(deliveries === undefined ? deliveries : deliveries.length)
    );
  }, [editable, sales, deliveries, id]);

  const { refetch } = useQuery(['import', id], () => getImportById(id as number), {
    enabled: !!id,
    onSettled: () => uiStore.hideLoading(),
    onSuccess: (data) => {
      reset(data);
      setEditable(data.complete === 1 ? false : true);
    },
  });

  const createMutation = useMutation((data: any) => createImport(data, purchaseId), {
    onMutate: () => uiStore.showLoading(),
    onSettled: () => uiStore.hideLoading(),
    onError: (err) => showFormError(err),
    onSuccess: (data) => {
      toast.success('登録しました');
      control._formState.isDirty = false;

      setTimeout(() => {
        if (pathToNavigate) {
          if (pathToNavigate === 'qr') navigate(pathToNavigate);
          else navigate(pathToNavigate + data.id);
        } else {
          navigate(`/imports/${data.id}`);
        }
      }, 10);
    },
  });

  const updateMutation = useMutation((data: any) => updateImport(id as number, data), {
    onMutate: () => uiStore.showLoading(),
    onSettled: () => uiStore.hideLoading(),
    onError: (err) => showFormError(err),
    onSuccess: (_data) => {
      toast.success('登録しました');
      control._formState.isDirty = false;

      setTimeout(() => {
        if (!pathToNavigate) {
          uiStore.showLoading();
          refetch();
          return;
        }

        if (pathToNavigate === 'qr') {
          navigate(pathToNavigate);
        } else {
          navigate(pathToNavigate + id);
        }
      }, 10);
    },
  });

  const completeMutation = useMutation(() => completeImport(id as number), {
    onMutate: () => uiStore.showLoading(),
    onSettled: () => uiStore.hideLoading(),
    onSuccess: () => refetch(),
  });

  const revertMutation = useMutation(() => revertImportCompletion(id as number), {
    onMutate: () => uiStore.showLoading(),
    onSettled: () => uiStore.hideLoading(),
    onSuccess: () => refetch(),
  });

  const deleteMutation = useMutation(() => deleteImport(id as number), {
    onMutate: () => uiStore.showLoading(),
    onSettled: () => uiStore.hideLoading(),
    onSuccess: () => {
      toast.success('登録しました');
      control._formState.isDirty = false;

      setTimeout(() => {
        navigate('/imports', { replace: true });
      }, 10);
    },
  });

  const createStocksMutation = useMutation(() => createStocksFromImport(id as number), {
    onMutate: () => uiStore.showLoading(),
    onSettled: () => uiStore.hideLoading(),
    onSuccess: () => {
      refetch();
      toast.success('登録しました');
    },
  });

  const exportImportForm = async (type: string | null = null) => {
    if (!id) return;

    try {
      uiStore.showLoading();
      const payload = {
        form_type: type,
      };

      await exportForm(`imports/export-details/${id}`, payload);
    } catch (error: any) {
      uiStore.showAlertBox({
        title: 'エラー',
        type: 'error',
        content: error.message,
      });
    } finally {
      uiStore.hideLoading();
    }
  };

  useEffect(() => {
    if (id) uiStore.showLoading();
  }, []);

  const save = (path?: string) => {
    const values = getValues();
    let payload: any;

    if (id) {
      payload = getDirtyObject(dirtyFields, values);
      if (!isDirty) return;
      if (isDirty && JSON.stringify(payload) === '{}') {
        return;
      }
    } else {
      payload = values;
    }

    if (payload.import_details) {
      payload.import_details = payload.import_details.map((item: any) => ({
        ...item,
        id: typeof item.id === 'string' ? undefined : item.id,
      }));
    }

    setPathToNavigate(path);
    setTimeout(() => {
      id ? updateMutation.mutate(payload) : createMutation.mutate(payload);
    }, 10);
  };

  const onUploadFile = async (event: any) => {
    if (event.target.files.length) {
      const file = event.target.files[0];
      try {
        const res = await getImportDetailsFromExcel(file);
        const { import_details: importDetailsFromExcel } = res;
        const newImportDetails = importDetailsFromExcel.map((item: any, index: number) => {
          return {
            ...item,
            id: v1(),
          };
        });
        setValue('import_details', newImportDetails, { shouldDirty: true });
      } catch (error: any) {
        uiStore.showAlertBox({
          title: 'エラー',
          type: 'error',
          content:
            '一括取り込みエクセルファイルの内容に問題があります。\nシートのヘッダー項目名や内容が正しい形式になっているか確認ください。',
        });
      } finally {
        setInputKey(Math.random());
      }
    }
  };

  useBlockNavigation({ shouldBlock: control._formState.isDirty });

  const showConfirmDeletePopup = () =>
    uiStore.showConfirmBox({
      title: 'このレコードを削除しますか？',
      confirmText: '削除する',
      cancelText: ' キャンセル',
      onConfirm: () => {
        uiStore.hideConfirmBox();
        deleteMutation.mutate();
        control._formState.isDirty = false;
      },
    });

  return (
    <div className={styles.container}>
      <ImportDetailsForm
        id={id}
        control={control}
        onSave={save}
        editable={editable}
        onComplete={() => completeMutation.mutate()}
        onRevert={() => revertMutation.mutate()}
        onCreateStocks={() => createStocksMutation.mutate()}
        setValue={setValue}
        exportImportForm={exportImportForm}
        refetch={refetch}
      />
      <Controller
        control={control}
        name="import_details"
        render={({ field }) => (
          <ImportDetailsTable initialRows={field.value} editable={editable} setValue={setValue} />
        )}
      />
      <div className="d-flex justify-content-between align-items-center">
        <button className="primary-btn" onClick={() => navigate('/imports')}>
          戻る
        </button>

        <div className="d-flex align-items-center">
          {isDeleteBtnVisible && (
            <button className="primary-btn--delete me-2" onClick={showConfirmDeletePopup}>
              削除
            </button>
          )}
          <button className="primary-btn me-2" onClick={() => exportImportForm('R1103')}>
            一括出力
          </button>
          <button
            className="primary-btn"
            onClick={() => {
              inputRef.current.click();
            }}
          >
            一括取り込み
          </button>

          <input
            key={inputKey}
            ref={inputRef}
            type="file"
            id="upload"
            style={{ display: 'none' }}
            onChange={onUploadFile}
          />
        </div>
      </div>
    </div>
  );
});

export default ImportDetailsContainer;
