import { useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { MdClose } from 'react-icons/md';
import { useMutation } from 'react-query';

import { deleteInventoryItems, updateInventoryItems } from 'services/inventory';
import UIStore from 'stores/ui';
import { getDirtyObject } from 'utils';
import useStores from 'hooks/use-stores';

import styles from './styles.module.scss';

interface IProps {
  selectedIds: Array<number>;
  onClose: () => void;
  onReload: () => void;
}

const UpdateStockModal = ({ selectedIds, onClose, onReload }: IProps) => {
  const uiStore: UIStore = useStores().uiStore;

  const {
    register,
    handleSubmit,
    formState: { isDirty, dirtyFields },
  } = useForm<{
    pallet_code: string;
    stock_note: string;
    stock_detail: string;
    destination: string;
    stock_memo: string;
    frame_color: string;
    deleted: false;
  }>();

  const showConfirmUpdateBulkPopup = () => {
    uiStore.showConfirmBox({
      title: '一括更新しますか？',
      confirmText: 'OK',
      cancelText: ' キャンセル',
      onConfirm: handleSubmit((values) => {
        if (values.deleted) {
          deleteMutation.mutate();
        } else {
          const dirtyObj = getDirtyObject(dirtyFields, values);
          updateMutation.mutate(dirtyObj);
        }
      }) ,
    });
  };

  const updateMutation = useMutation((data) => updateInventoryItems(selectedIds, data), {
    onMutate: () => uiStore.showLoading(),
    onSettled: () => uiStore.hideConfirmBox(),
    onSuccess: () => {
      uiStore.hideLoading();
      onClose();
      onReload();
    },
  });

  const deleteMutation = useMutation(() => deleteInventoryItems(selectedIds), {
    onMutate: () => uiStore.showLoading(),
    onSettled: () => uiStore.hideConfirmBox(),
    onSuccess: () => {
      uiStore.hideLoading();
      onClose();
      onReload();
    },
  });

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  return (
    <div className={styles.modalContainer}>
      <div
        style={{
          backgroundColor: 'white',
          width: '30%',
          minWidth: '20rem',
          maxWidth: '30rem',
          padding: '1rem 2rem',
        }}
      >
        <div className={styles.title}>
          <div>一括更新モーダル</div>
          <MdClose onClick={onClose} />
        </div>
        <Form>
          <div className="d-flex align-items-center my-1 mb-2">
            <div className={styles.label}>対象件数</div>
            <input type="text" value={selectedIds.length} disabled />
          </div>
          <div className="d-flex align-items-center my-1">
            <div className={styles.label}>在庫場所</div>
            <input type="text" {...register('pallet_code')} />
          </div>
          <div className="d-flex align-items-center my-1">
            <div className={styles.label}>備考</div>
            <input type="text" {...register('stock_note')} />
          </div>
          <div className="d-flex align-items-center my-1">
            <div className={styles.label}>詳細</div>
            <input type="text" {...register('stock_detail')} />
          </div>
          <div className="d-flex align-items-center my-1">
            <div className={styles.label}>納品先</div>
            <input type="text" {...register('destination')} />
          </div>
          <div className="d-flex align-items-center my-1">
            <div className={styles.label}>メモ</div>
            <input type="text" {...register('stock_memo')} />
          </div>
          <div className="d-flex align-items-center my-1">
            <div className={styles.label}>枠色</div>
            <input type="text" {...register('frame_color')} />
          </div>
          <div className="d-flex align-items-center my-1 mt-2">
            <div className={styles.label}>在庫削除</div>
            <Form.Check className={styles.checkbox} {...register('deleted')} />
          </div>
          <div className="d-flex my-1">
            <p>一括クリアしたい場合は入力フィールドに半角スペースを入力後、削除してから登録ボタンを押してください。</p>
          </div>

        </Form>
        <div className="d-flex justify-content-end mt-2">
          <button
            className="primary-btn"
            disabled={!isDirty}
            onClick={() => showConfirmUpdateBulkPopup()}
          >
            登録
          </button>
        </div>
      </div>
    </div>
  );
};

export default UpdateStockModal;
