import { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Table, Spinner, Pagination } from 'react-bootstrap';
import { AiFillCaretUp, AiFillCaretDown } from 'react-icons/ai';

import { IDataProvider } from 'shared/data-providers/table-data-provider';
import useSortableTable from 'hooks/use-sortable-table';

import styles from './styles.module.scss';
import './styles.scss';

interface DataTableProps<T, F extends { [key: string]: any }> {
  dataProvider: IDataProvider<T, F>;
  delegate: (item: T) => Array<any>;
  headers?: Array<{
    label: any;
    key?: string;
    order?: 'asc' | 'desc';
    oneLine?: boolean;
    centered?: boolean;
    headerClassName?: any;
    cellClassName?: (item: T) => any;
  }>;
  headerComponent?: React.ReactElement;
  scrollable?: boolean;
  showPagination?: { top: boolean; bottom: boolean };
}

const DataTable = observer(
  <T, F extends { [key: string]: any }>({
    dataProvider,
    delegate,
    headers,
    scrollable,
    headerComponent,
    showPagination = { top: true, bottom: true },
  }: DataTableProps<T, F>) => {
    const { sort, isActive } = useSortableTable<T, F>(dataProvider);

    useEffect(() => {
      dataProvider.load();
    }, []);

    const renderPagination = () => {
      const pages = Math.ceil(dataProvider.total / dataProvider.limit);
      const displayedPages = [];
      const smallest = dataProvider.page - 5;
      const biggest = dataProvider.page + 5;
      for (let i = smallest < 1 ? 1 : smallest; i < dataProvider.page; i++) {
        displayedPages.push(i);
      }
      displayedPages.push(dataProvider.page);
      for (let i = dataProvider.page + 1; i <= biggest && i <= pages; i++) {
        displayedPages.push(i);
      }
      return (
        <Pagination className={styles.pagination}>
          {dataProvider.page > 1 && (
            <Pagination.First className={styles.pageItem} onClick={() => dataProvider.setPage(1)} />
          )}
          {dataProvider.page > 1 && (
            <Pagination.Prev
              className={styles.pageItem}
              onClick={() => dataProvider.setPage(dataProvider.page - 1)}
            />
          )}
          {displayedPages.map((page) => (
            <Pagination.Item
              key={page}
              active={dataProvider.page === page}
              onClick={() => dataProvider.setPage(page)}
              className={styles.pageItem}
            >
              {page}
            </Pagination.Item>
          ))}
          {dataProvider.page < pages && (
            <Pagination.Next
              className={styles.pageItem}
              onClick={() => dataProvider.setPage(dataProvider.page + 1)}
            />
          )}
          {dataProvider.page < pages && (
            <Pagination.Last
              className={styles.pageItem}
              onClick={() => dataProvider.setPage(pages)}
            />
          )}
        </Pagination>
      );
    };

    return (
      <div className={styles.container}>
        <div className={styles.top}>
          <div className={styles.select}>
            <div>検索結果: {dataProvider.total}件</div>
            <select
              onChange={(e) => dataProvider.setLimit(parseInt(e.target.value))}
              value={dataProvider.limit}
            >
              {[20, 50, 100, 500].map((option: any, index: number) => (
                <option key={index} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>

          <div style={{ visibility: `${showPagination.top ? 'visible' : 'hidden'}` }}>
            {renderPagination()}
          </div>
        </div>

        <Table
          bordered
          hover
          className={`${styles.table}  ${scrollable ? styles.scrollableTable : ''}`}
        >
          <thead>
            {headerComponent}
            {headers && (
              <tr>
                {headers?.map((header, index) => (
                  <th key={index} className={header.headerClassName}>
                    <div>
                      <div>{header.label}</div>
                      {header.key && (
                        <div className="d-flex flex-column">
                          <AiFillCaretUp
                            onClick={() => sort(header.key as string, 'asc')}
                            className={isActive(header.key as string, 'asc') ? 'arrow-active' : ''}
                          />
                          <AiFillCaretDown
                            onClick={() => sort(header.key as string, 'desc')}
                            className={isActive(header.key as string, 'desc') ? 'arrow-active' : ''}
                          />
                        </div>
                      )}
                    </div>
                  </th>
                ))}
              </tr>
            )}
          </thead>
          <tbody>
            {dataProvider.loading ? (
              <tr>
                <td colSpan={100} className="text-center">
                  <Spinner animation="border" />
                </td>
              </tr>
            ) : (
              dataProvider.data.map((item: T, index) => (
                <tr key={index}>
                  {delegate(item).map((cell: any, i: number) => {
                    let cellStyle = {};
                    if (headers?.[i].oneLine)
                      cellStyle = { ...cellStyle, overflow: 'hidden', whiteSpace: 'nowrap' };
                    if (headers?.[i].centered) cellStyle = { ...cellStyle, textAlign: 'center' };
                    return (
                      <td key={i} className={headers?.[i].cellClassName?.(item)} style={cellStyle}>
                        {cell}
                      </td>
                    );
                  })}
                </tr>
              ))
            )}
          </tbody>
        </Table>

        <div style={{ visibility: `${showPagination.bottom ? 'visible' : 'hidden'}` }}>
          {renderPagination()}
        </div>
      </div>
    );
  }
);

export default DataTable;
