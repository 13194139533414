import { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import { Control, UseFormSetValue, useWatch } from 'react-hook-form';
import { BsPlusCircle } from 'react-icons/bs';
import { IoCloseCircleOutline } from 'react-icons/io5';
import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/ai';
import { v1 } from 'uuid';

import ProductSelectModal from 'shared/containers/product-select-modal';
import DetailTable from 'shared/components/detail-table';
import DynamicDropdown from 'shared/components/dynamic-dropdown';
import ValidatingInput from 'shared/components/validating-text-input';
import useEditableTable from 'hooks/use-editable-table';
import { Quotation, QuotationProduct } from 'types/quotation';
import { calculateProductPrice, getProductLcds } from 'utils/product';
import { getProductById } from 'services/product';
import styles from './styles.module.scss';
import { DropdownDataProvider } from 'shared/data-providers/dropdown-data-provider';
import InputWrapper from 'shared/components/input-wrapper';

interface IProps {
  initialRows: Array<QuotationProduct>;
  control: Control<Partial<Quotation>>;
  setValue: UseFormSetValue<Partial<Quotation>>;
}

const QuotationDetailsTable = ({ initialRows, control, setValue }: IProps) => {
  const [productModal, setProductModal] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const [productCount, setProductCount] = useState(0);
  const [price, setPrice] = useState(0);

  const timeoutRef = useRef<any>();

  const { rows, setRows, updateRow, removeRow, swap, changeSource, rowsRef } =
    useEditableTable<QuotationProduct>(initialRows);

  const customerId = useWatch({ control, name: 'customer_id' });

  const deviceTypeDataProvider = useRef(
    new DropdownDataProvider('device-types/dropdown', (item) => ({
      label: item.device_type_code + "　　" + item.device_type_name,
      value: item.device_type_code,
    }), {type:1})
  ).current;

  useEffect(() => {
    if (changeSource.current === 'in') setValue('quotation_details', rows, { shouldDirty: true });

    timeoutRef.current = setTimeout(() => {
      let count = 0;
      let total = 0;
      rows.forEach((row) => {
        if (row.product?.count_target === 1) count = count + (row.product_quantity || 0);
        total = total + (row.purchase_unit_price || 0) * (row.product_quantity || 0);
      });
      setProductCount(count);
      setPrice(total);
      timeoutRef.current = null;
    }, 500);
  }, [rows]);

  const headers = useMemo(() => {
    const headers = [
      { label: '' },
      { label: '種別' },
      { label: '商品CD' },
      { label: '' },
      { label: 'メーカー' },
      { label: '品名' },
      { label: 'スペック' },
      { label: '液晶' },
      { label: '数量' },
      { label: '参照基準' },
      { label: '買取単価' },
      { label: '買取合計' },
      { label: '備考' },
      { label: '店舗' },
      {
        label: (
          <button
            onClick={() => {
              {
                setRows([...rows, { id: v1() }]);
                rowsRef.current = [...rowsRef.current, {
                  id: v1(),
                }];
                changeSource.current = 'in';
              }
            }}
          >
            <BsPlusCircle size={20} style={{ color: 'white' }} />
          </button>
        ),
      },
    ];

    return headers;
  }, [customerId, rows, setRows]);

  const renderRow = useCallback(
    (item: any, index: number) => {
      const upDownArrow = (
        <div className="d-flex flex-column align-items-center justify-content-center">
          <AiFillCaretUp className="arrow-icon" onClick={() => swap(index, 'up')} />
          <AiFillCaretDown className="arrow-icon" onClick={() => swap(index, 'down')} />
        </div>
      );
      const deviceTypeCode = (
        <div>
          <DynamicDropdown
            name="device-type-code"
            dataProvider={deviceTypeDataProvider}
            value={item.device_type_code}
            onChange={(newValue) => {
              updateRow(index, {
                device_type_code: newValue ? newValue.toString() : undefined,
              });
            }}
            isClearable={false}
          />
        </div>
      );
      const productId = (
        <ValidatingInput
          type="number"
          allowFullWidth
          value={item.product_id}
          validate={async (value, showError) => {
            let payload: any = {};
            let e = false;
            if (value === '') {
              payload.product_id = undefined;
              payload.product = undefined;
            } else {
              try {
                const res = await getProductById(parseInt(value));
                payload.product_id = res.id;
                payload.device_type_code = res.device_type_code;
                payload.product = res;
              } catch (err) {
                payload.product_id = parseInt(value);
                payload.product = undefined;
                e = true;
              }
            }
            updateRow(index, payload);
            showError(e);
          }}
        />
      );
      const productModalBtn = (
        <button
          onClick={() => {
            setSelectedIndex(index);
            setProductModal(true);
          }}
        >
          参
        </button>
      );
      const makerName = item.product?.maker?.maker_name;
      const productName = item.product?.product_name;
      const spec = item.product?.spec;
      const productLCDs = item.product && getProductLcds(item.product);

      const productQuantity = (
        <InputWrapper
          type="number"
          isFloat
          allowFullWidth
          defaultValue={item.product_quantity}
          onChange={(value: any) => {
            updateRow(index, { product_quantity: parseFloat(value as string) });
          }}
        />
      );

      const baseUnitPrice =
        item.product &&
        calculateProductPrice(item.product, item.device_type_code).toLocaleString('en-US');
      const purchaseUnitPrice = (
        <InputWrapper
          type="number"
          isFloat
          allowFullWidth
          defaultValue={item.purchase_unit_price}
          onChange={(value: any) => {
            updateRow(index, { purchase_unit_price: parseFloat(value as string) });
          }}
        />
      );
      const totalPrice =
        item.product_quantity && item.purchase_unit_price
          ? Math.round(item.product_quantity * item.purchase_unit_price).toLocaleString('en-US')
          : 0;
      const detailNote = (
        <InputWrapper
          defaultValue={item.quotation_detail_note || ''}
          onChange={(value: any) => {
            updateRow(index, { quotation_detail_note: value });
          }}
        />
      );
      const store = (
        <InputWrapper
          defaultValue={item.store}
          onChange={(value: any) => {
            updateRow(index, { store: value });
          }}
        />
      );
      const removeIcon = (
        <IoCloseCircleOutline size={25} className="delete-icon" onClick={() => removeRow(index)} />
      );

      const cells = [
        upDownArrow,
        deviceTypeCode,
        productId,
        productModalBtn,
        makerName,
        productName,
        spec,
        productLCDs,
        productQuantity,
        baseUnitPrice,
        purchaseUnitPrice,
        totalPrice,
        detailNote,
        store,
        removeIcon,
      ];

      return cells;
    },
    [customerId, rows]
  );

  const renderTotalRow = () => (
    <>
      <td colSpan={8} align="right">
        合計
      </td>
      <td style={{ textAlign: 'right', width: '5em', padding: '0 0.25em' }}>{productCount}</td>
      <td style={{ width: '5em' }}></td>
      <td></td>
      <td colSpan={4} style={{ textAlign: 'left', padding: '0 0.25em' }}>
        {price.toLocaleString('en-US')}
      </td>
    </>
  );

  return (
    <div>
      <div className={`${styles.detailTableWrapper} quotation-table__override`}>
        <DetailTable<QuotationProduct>
          headers={headers}
          data={rows}
          renderTotalRow={renderTotalRow}
          renderRow={renderRow}
        />
      </div>

      {productModal && (
        <ProductSelectModal
          onClose={() => setProductModal(false)}
          onSelect={(item) => {
            updateRow(selectedIndex as number, {
              product_id: item.id,
              product_quantity: 0,
              purchase_unit_price: 0,
              device_type_code: item.device_type_code,
              product: item,
            });
            setProductModal(false);
          }}
        />
      )}
    </div>
  );
};

export default QuotationDetailsTable;
