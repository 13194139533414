import { observable, action, makeObservable, runInAction } from 'mobx';
import { get } from 'services';
import stores from 'stores';
import { removeKeys } from 'utils';

const { uiStore } = stores;

export interface ISelectDataProvider<Item, F extends { [key: string]: any }> {
  data: Array<Item>;
  selectedList: Array<Item>;
  removedList: Array<{ id: number }>;
  total: number;
  loading: boolean;
  limit: number;
  page: number;
  filters?: F;

  load(): Promise<void>;
  setFilters(filters: F): void;
  clearFilters(): void;
  selectItem(item: Item, exportFlag: 1 | 2 | 3 | 9): void;
  unSelectItem(item: Item): void;
  reset(): void;
}

export class SelectDataProvider<F extends { [key: string]: any }> implements ISelectDataProvider<any, F> {
  @observable data: Array<any> = [];
  @observable selectedList: Array<any> = [];
  @observable removedList: Array<{ id: number }> = [];
  @observable colorTrackers: Array<{ id: number; type: 'FRAME' | 'BASE_CELL' }> = [];
  @observable total: number = 0;
  @observable loading: boolean = false;
  @observable limit = 500;
  @observable page = 1;
  @observable filters?: F;
  dataSource: string;
  localStorageKey?: string;
  defaultFilters?: F;
  sourceKey: string = 'id';
  targetKey: string = 'id';

  constructor(
    dataSource: string,
    localStorageKey?: string,
    defaultFilters?: F,
    sourceKey?: string,
    targetKey?: string,
    limit?: number
  ) {
    this.defaultFilters = defaultFilters;
    this.filters = defaultFilters;
    if (localStorageKey) {
      this.localStorageKey = localStorageKey;
      const data = localStorage.getItem(localStorageKey);
      if (data) {
        this.filters = JSON.parse(data);
      }
    }
    this.dataSource = dataSource;
    if (sourceKey) this.sourceKey = sourceKey;
    if (targetKey) this.targetKey = targetKey;
    if (limit) this.limit = limit;
    makeObservable(this);
  }

  @action
  async load(): Promise<void> {
    const filters: any = { ...this.filters };
    let params = {
      page: this.page,
      perPage: this.limit,
      ...filters,
    };

    try {
      this.loading = true;
      const data = await get(this.dataSource, params);
      runInAction(() => {
        this.data = data.data.filter(
          (item: any) =>
            this.removedList.findIndex((target) => target.id == item[this.sourceKey]) < 0
        );
        this.total = data.total;
        this.limit = data.per_page;
        this.page = data.current_page;
      });
    } catch (err: any) {
      uiStore.showAlertBox({ title: 'エラー', content: err.message });
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  @action setFilters(filters: F) {
    let temp: any = {};
    for (const [key, value] of Object.entries(filters)) {
      if (!(typeof value === 'string' && value.trim() === '')) {
        temp[key] = value;
      }
    }
    this.filters = temp;
    if (this.localStorageKey) {
      localStorage.setItem(this.localStorageKey, JSON.stringify(temp));
    }

    this.page = 1;
    this.load();
  }

  @action clearFilters(): void {
    this.filters = this.defaultFilters;
    if (this.localStorageKey) {
      localStorage.removeItem(this.localStorageKey);
    }
    this.load();
  }

  @action selectItem(item: any, exportFlag: 1 | 2 | 3 | 9): void {
    let index: number | undefined = undefined;
    let willRemove: boolean = false;
    let newItem = JSON.parse(JSON.stringify(item));

    // check if the selected list contains 'FRAME' or 'BASE_CELL'
    // of this stock item. If true, combine into Full.
    // However, The stock can be partially exported
    // somewhere else, we need exportFlag to check it. in that case, we
    // also remove it from stock table.
    const alreadyExportedFrameOrBaseCellHere = this.selectedList.some((selectedItem, idx) => {
      if (selectedItem.stock_id === item.stock_id) {
        index = idx;
        return true;
      }
      return false;
    });

    switch (item.export_stock_type) {
      case 'FULL':
        this.selectedList.push(newItem);
        willRemove = true;
        break;

      case 'FRAME':
        // NOTE: if this current export has a stock
        // with just base_cell, then that elsewhere (below) is this
        // export itself and alreadyExportedFrameOrBaseCellHere will catch
        // that case, so it shouldn't be a problem.
        if (alreadyExportedFrameOrBaseCellHere) {
          newItem.export_stock_type = 'FULL';
          newItem.device_type_code = newItem.device_type_code.includes('P') ? 'P' : 'S';
          this.selectedList.splice(index!, 1, newItem);
          willRemove = true;
        } else {
          newItem.base_number = null;
          newItem.base_code = null;
          newItem.cell_number = null;
          newItem.cell_code = null;
          this.selectedList.push(newItem);

          // had exported base_cell elsewhere
          if (exportFlag === 3) willRemove = true;
        }
        break;

      case 'BASE_CELL':
        if (alreadyExportedFrameOrBaseCellHere) {
          newItem.export_stock_type = 'FULL';
          newItem.device_type_code = newItem.device_type_code.includes('P') ? 'P' : 'S';
          this.selectedList.splice(index!, 1, newItem);
          willRemove = true;
        } else {
          newItem.frame_number = null;
          newItem.frame_code = null;
          this.selectedList.push(newItem);

          // had exported frame elsewhere
          if (exportFlag === 2) willRemove = true;
        }
        break;

      default:
        break;
    }

    if (['FRAME', 'BASE_CELL'].includes(newItem.export_stock_type)) {
      this.colorTrackers.push({
        id: item.stock_id,
        type: item.export_stock_type,
      });
    } else {
      // in case we select the remaining half of a stock which we have selected
      // previously, that stock's device type will become 'FULL'
      // so we need it remove it from colorTracker so if we unselect,
      // the whole stock will again become untracked.
      const isTracked = this.colorTrackers.some((tracker) => tracker.id === item.stock_id);

      if (isTracked) {
        const newColorTrackers = this.colorTrackers.filter(
          (tracker) => tracker.id !== item.stock_id
        );
        this.colorTrackers = newColorTrackers;
      }
    }

    if (willRemove) {
      this.removedList.push({ id: item[this.targetKey] });
      const newData = this.data.filter((target) => target[this.sourceKey] !== item[this.targetKey]);
      this.data = newData;
    }
  }

  @action unSelectItem(item: any): void {
    const newSelectedList = this.selectedList.filter(
      (target) => target[this.targetKey] !== item[this.targetKey]
    );
    this.selectedList = newSelectedList;
    const index = this.removedList.findIndex((target) => target.id === item[this.targetKey]);
    if (index >= 0) {
      this.removedList.splice(index, 1);
    }
    this.load();

    const isTracked = this.colorTrackers.some((tracker) => tracker.id === item.stock_id);
    if (isTracked) {
      const newColorTrackers = this.colorTrackers.filter((tracker) => tracker.id !== item.stock_id);
      this.colorTrackers = newColorTrackers;
    }
  }

  @action reset(): void {
    this.selectedList = [];
    this.removedList = [];
    this.colorTrackers = [];
    this.data = [];
    this.load();
  }
}
