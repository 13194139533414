import { get, post, put, del } from 'services';

export const getSaleById = (id: number) => get(`sales/${id}`, {});

export const createSale = (payload: any, purchaseId: number | string = '') =>
  post(`sales/${purchaseId}`, payload);

export const updateSale = (id: number, payload: any) => put(`sales/${id}`, payload);

export const deleteSale = (id: number) => del(`sales/${id}`);

export const checkSaleCode = (code: string) => get(`sales/target-check/${code}`, {});

export const linkSale = (targetType: string, targetId: number, saleId: number) =>
  post(`sales/set-target/${saleId}`, {
    target_type: targetType,
    target_id: targetId,
  });
