import styles from './styles.module.scss';

interface IProps {
  label?: string;
  labelComponent?: any;
  required?: boolean;
}

const FilterItem = ({ label, labelComponent, required = false }: IProps) => {
  return (
    <div className={styles.container}>
      <span className={`${required ? 'required' : ''}`}>{label}</span>
      {labelComponent}
    </div>
  );
};

export default FilterItem;
