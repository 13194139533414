// Built-in
import { useRef, useState } from 'react';
import { Breadcrumb, BreadcrumbItem } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useMutation } from 'react-query';
import moment from 'moment';

// Component
import DataTable from 'shared/components/data-table';
import EditModal from './edit-modal';

// Type

// Store
import { DataProvider } from 'shared/data-providers/table-data-provider';
import UIStore from 'stores/ui';
import useStores from 'hooks/use-stores';

// Service
import { createTaxRecord, deleteTaxRecord } from 'services/consumption-tax';

// Hook
import { useFormError } from 'hooks/use-form-error';

// Style
import styles from './styles.module.scss';

const VATScreen = () => {
  const uiStore: UIStore = useStores().uiStore;
  const [editModal, setEditModal] = useState(false);
  const { showFormError } = useFormError();
  const dataProvider = useRef(new DataProvider({ dataSource: 'taxes' }));

  const createMutation = useMutation<any, unknown, any>((data) => createTaxRecord(data), {
    onSuccess: () => {
      setEditModal(false);
      dataProvider.current.load();
    },
    onError: (err) => showFormError(err),
  });
  const deleteMutation = useMutation((id: number) => deleteTaxRecord(id), {
    onSuccess: () => dataProvider.current.load(),
  });

  const headers = [{ label: '適用開始日' }, { label: '税率(%)' }, { label: '' }];

  const delegate = (item: any) => [
    moment(item.effective_date).format('YYYY/MM/DD'),
    item.tax_rate.toString(),
    <button
      className="edit"
      onClick={() => {
        setEditModal(false);
        uiStore.showConfirmBox({
          title: 'このレコードを削除しますか？',
          confirmText: '削除する',
          cancelText: ' キャンセル',
          onConfirm: () => {
            uiStore.hideConfirmBox();
            deleteMutation.mutate(item.id);
          },
        });
      }}
    >
      削除
    </button>,
  ];

  return (
    <div className="list-screen">
      <Breadcrumb>
        <BreadcrumbItem linkAs={Link} linkProps={{ to: '/home' }}>
          TOP
        </BreadcrumbItem>
        <BreadcrumbItem active>マスター</BreadcrumbItem>
        <BreadcrumbItem active>消費税率</BreadcrumbItem>
      </Breadcrumb>

      <div className="d-flex justify-content-end">
        <button className="create" onClick={() => setEditModal(true)}>
          作成
        </button>
      </div>

      <div className={styles.tableWrapper}>
        <DataTable dataProvider={dataProvider.current} headers={headers} delegate={delegate} />
      </div>

      {editModal && (
        <EditModal
          onClose={() => setEditModal(false)}
          onCreate={(data) =>
            createMutation.mutate({
              effective_date: moment(data.effective_date).format('YYYY-MM-DD'),
              tax_rate: parseFloat(data.tax_rate),
            })
          }
        />
      )}
    </div>
  );
};

export default VATScreen;
