import { action, makeObservable, observable } from 'mobx';
import { Profile } from 'types/profile';

type Date = {
  month: string | number;
  year: string | number;
};
class SessionStore {
  @observable profile?: Profile;
  @observable isLoggingOut: boolean = false;
  @observable token: string | null = null;
  @observable settlement: Date | null = null;
  @observable formReportDate: Date | null = null;
  @observable depositDate: Date | null = null;
  @observable withdrawalDate: Date | null = null;

  constructor() {
    makeObservable(this);
  }

  @action setProfile(profile: Profile) {
    this.profile = profile;
  }

  @action setToken(token: string | null) {
    this.token = token;
  }

  @action clearSession() {
    this.profile = undefined;
    this.token = null;
    this.settlement = null;
    this.formReportDate = null;
    this.depositDate = null;
    this.withdrawalDate = null;
  }

  @action setSettlement(date: Date) {
    this.settlement = date;
  }

  @action setWithdrawalDate(date: Date) {
    this.withdrawalDate = date;
  }

  @action setFormReportDate(date: Date) {
    this.formReportDate = date;
  }

  @action setDepositDate(date: Date) {
    this.depositDate = date;
  }

  @action setIsLoggingOut(val: boolean) {
    this.isLoggingOut = val;
  }
}

export default SessionStore;
