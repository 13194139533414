import { useEffect, useMemo, useState, useCallback } from 'react';
import { Form } from 'react-bootstrap';
import { UseFormSetValue } from 'react-hook-form';
import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/ai';
import { BsPlusCircle } from 'react-icons/bs';
import { IoCloseCircleOutline } from 'react-icons/io5';
import { v1 } from 'uuid';

import DetailTable from 'shared/components/detail-table';
import DynamicDropdown from 'shared/components/dynamic-dropdown';
import ValidatingInput from 'shared/components/validating-text-input';
import { DropdownDataProvider } from 'shared/data-providers/dropdown-data-provider';
import { Import, ImportProduct } from 'types/import';
import ProductSelectModal from '../product-select-modal';
import useEditableTable from 'hooks/use-editable-table';
import { getProductById } from 'services/product';

import styles from './styles.module.scss';
import InputWrapper from 'shared/components/input-wrapper';

interface IProps {
  initialRows?: Array<ImportProduct>;
  editable: boolean;
  setValue: UseFormSetValue<Partial<Import>>;
}

const deviceTypeDataProvider = new DropdownDataProvider('device-types/dropdown', (item) => ({
  label: item.device_type_code,
  value: item.device_type_code,
}), {type:2});

const ImportDetailsTable = ({ initialRows, editable, setValue }: IProps) => {
  const [productModal, setProductModal] = useState(false);
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const { rows, setRows, updateRow, removeRow, swap, duplicateRow, changeSource, rowsRef } =
    useEditableTable<any>(initialRows);

  useEffect(() => {
    if (changeSource.current === 'in') setValue('import_details', rows, { shouldDirty: true });
  }, [rows]);
  const getBackgroundColor = useCallback(
    (item: Partial<ImportProduct>, field: 'frame_number' | 'cell_number' | 'base_number') => {
      if (!!item.is_duplicate) return '#bee5b0';
      if (!item.import_qr_detail || Object.keys(item.import_qr_detail).length === 0)
        return '#f8f1ae';

      if (item.check_match?.[field] != null) {
        if (item.check_match[field]) return '#d3eeff';
        return '#ff9997';
      }
      return 'transparent';
    },
    []
  );

  const renderRow = (item: Partial<ImportProduct>, index: number) => {
    const duplicateBtn = (
      <button onClick={() => duplicateRow(index)} disabled={!editable}>
        複
      </button>
    );
    const upDownArrow = editable && (
      <div className="d-flex flex-column align-items-center justify-content-center">
        <AiFillCaretUp className="arrow-icon" onClick={() => swap(index, 'up')} />
        <AiFillCaretDown className="arrow-icon" onClick={() => swap(index, 'down')} />
      </div>
    );
    const deviceTypeCode = (
      <DynamicDropdown
        dataProvider={deviceTypeDataProvider}
        value={item.device_type_code}
        onChange={(value) => updateRow(index, { device_type_code: value })}
        isClearable={false}
        disabled={!editable}
      />
    );
    const productId = (
      <ValidatingInput
        type="number"
        value={item.product_id?.toString()}
        validate={async (value, showError) => {
          let payload: any = {};
          let hasError = false;
          if (value === '') {
            payload.product_id = undefined;
            payload.product = undefined;
          } else {
            try {
              const res = await getProductById(parseInt(value));
              payload.product_id = res.id;
              payload.device_type_code = res.device_type_code;
              payload.product = res;
            } catch (err) {
              payload.product_id = parseInt(value);
              payload.product = undefined;
              hasError = true;
            }
          }
          updateRow(index, payload);
          showError(hasError);
        }}
        disabled={!editable}
      />
    );
    const productModalBtn = (
      <button
        onClick={() => {
          setActiveIndex(index);
          setProductModal(true);
        }}
        disabled={!editable}
      >
        参
      </button>
    );
    const makerName = item.product?.maker?.maker_name;
    const modelName = item.product?.model_name;
    const spec = item.product?.spec;
    const qrCheck = (
      <Form.Check
        className="custom-checkbox"
        checked={!!item.qr}
        onChange={(event) => updateRow(index, { qr: event.target.checked ? 1 : 0 })}
        disabled={!editable}
      />
    );
    const cellNumber = (
      <div className={styles.cellNumber}>
        <div className={styles.inputWrapper}>
          {editable ? (
            <InputWrapper
              defaultValue={item.cell_number}
              onChange={(value: any) => {
                updateRow(index, { cell_number: value });
              }}
            />
          ) : (
            item.cell_number
          )}
        </div>
        <span style={{ backgroundColor: getBackgroundColor(item, 'cell_number') }}></span>
      </div>
    );
    const frameNumber = (
      <div className={styles.frameNumber}>
        <div className={styles.inputWrapper}>
          {editable ? (
            <InputWrapper
              defaultValue={item.frame_number}
              onChange={(value: any) => {
                updateRow(index, { frame_number: value });
              }}
            />
          ) : (
            item.frame_number
          )}
        </div>
        <span style={{ backgroundColor: getBackgroundColor(item, 'frame_number') }}></span>
      </div>
    );

    const baseNumber = (
      <div className={styles.baseNumber}>
        <div className={styles.inputWrapper}>
          {editable ? (
            <InputWrapper
              defaultValue={item.base_number}
              onChange={(value: any) => {
                updateRow(index, { base_number: value });
              }}
            />
          ) : (
            item.base_number
          )}
        </div>
        <span style={{ backgroundColor: getBackgroundColor(item, 'base_number') }}></span>
      </div>
    );
    const detailNote = (
      <InputWrapper
        readOnly={!editable}
        defaultValue={item.import_detail_note}
        onChange={(value: any) => updateRow(index, { import_detail_note: value })}
      />
    );
    const detailOfDetail = (
      <InputWrapper
        readOnly={!editable}
        defaultValue={item.import_detail_detail}
        onChange={(value: any) => updateRow(index, { import_detail_detail: value })}
      />
    );
    const destination = (
      <InputWrapper
        readOnly={!editable}
        defaultValue={item.destination}
        onChange={(value: any) => updateRow(index, { destination: value })}
      />
    );
    const detailMemo = (
      <InputWrapper
        readOnly={!editable}
        defaultValue={item.import_detail_memo}
        onChange={(value: any) => updateRow(index, { import_detail_memo: value })}
      />
    );
    const frameColor = (
      <InputWrapper
        defaultValue={item.frame_color}
        onChange={(value: any) => {
          updateRow(index, { frame_color: value });
        }}
        readOnly={!editable}
      />
    );
    const removeIcon = (
      <IoCloseCircleOutline
        size={25}
        className="delete-icon"
        onClick={() => {
          if (editable) {
            removeRow(index);
          }
        }}
      />
    );

    const tempRows = [
      duplicateBtn,
      upDownArrow,
      deviceTypeCode,
      productId,
      productModalBtn,
      makerName,
      modelName,
      spec,
      qrCheck,
      cellNumber,
      frameNumber,
      baseNumber,
      detailNote,
      detailOfDetail,
      destination,
      detailMemo,
      frameColor,
      removeIcon,
    ];

    return tempRows;
  };

  const headers: Array<any> = useMemo(() => {
    const arr: Array<any> = [
      { label: '' },
      { label: '' },
      { label: '種別' },
      { label: '商品CD' },
      { label: '' },
      { label: 'メーカー' },
      { label: '型式名' },
      { label: 'スペック' },
      { label: 'QR' },
      { label: 'セル番号' },
      { label: '枠番号' },
      { label: '基盤番号' },
      { label: '備考' },
      { label: '詳細' },
      { label: '納品先' },
      { label: 'メモ' },
      { label: '枠色' },
      {
        label: (
          <button onClick={() => {
            setRows([...rows, { id: v1(), qr: 1 }]);
            rowsRef.current = [...rowsRef.current, {
              id: v1(),
              qr: 1,
            }];
            changeSource.current = 'in';
          }
          }>
            <BsPlusCircle size={20} style={{ color: 'white' }} />
          </button>
        ),
      },
    ];

    return arr;
  }, [editable, rows]);

  return (
    <>
      <div className={`${styles.detailTableWrapper}  import-table__override`}>
        <DetailTable<Partial<ImportProduct>> headers={headers} data={rows} renderRow={renderRow} />
      </div>
      {productModal && (
        <ProductSelectModal
          onClose={() => setProductModal(false)}
          onSelect={(item) => {
            updateRow(activeIndex!, {
              product_id: item.id,
              product_quantity: 0,
              purchase_unit_price: 0,
              device_type_code: rows[activeIndex!].device_type_code || item.device_type_code,
              product: item,
            });
            setProductModal(false);
          }}
        />
      )}
    </>
  );
};

export default ImportDetailsTable;
