import { useEffect, useState } from 'react';
import { Control, Controller, UseFormSetValue, useWatch } from 'react-hook-form';
import Select from 'react-select';
import { observer } from 'mobx-react';

import FilterItem from '../filter-item';

import styles from './styles.module.scss';

interface IProps {
  label?: string;
  keys: [string, string] | [string];
  control: Control<any, any>;
  options: Array<{ label: string; value: string | number }>;
  setValue?: UseFormSetValue<any>;
  isClearable?: boolean;
  required?: boolean;
  placeholder?: string;
  disabled?: boolean;
}

const StaticDropdownFilter = observer(
  ({
    label,
    keys,
    control,
    options,
    setValue,
    isClearable = true,
    required = false,
    placeholder = '選択',
    disabled = false,
  }: IProps) => {
    const [option, setOption] = useState<any>(null);

    const watcher = useWatch({ control, name: keys[0] });
    useEffect(() => {
      if (watcher) {
        const filteredList = options.filter((item) => item.value === watcher);
        if (filteredList.length) {
          setOption(filteredList[0]);
        }
      } else {
        setOption(null);
      }
    }, [watcher]);

    return (
      <div className={styles.container}>
        {label && <FilterItem label={label} required={required} />}
        <Controller
          control={control}
          name={keys[0]}
          rules={{ required }}
          render={({ field: { onChange, onBlur } }) => (
            <Select
              className={`${styles.dropdown} ${disabled && styles.noBorder}`}
              isSearchable={false}
              isClearable={isClearable}
              placeholder={placeholder}
              isDisabled={disabled}
              options={options}
              onBlur={onBlur}
              onChange={(newValue) => {
                onChange(newValue ? newValue.value : null);
              }}
              value={option}
            />
          )}
        />
      </div>
    );
  }
);

export default StaticDropdownFilter;
