// Built-in
import { useRef, useState, useEffect } from 'react';
import { Breadcrumb, Table } from 'react-bootstrap';
import { useMutation } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';
import { IoCloseCircleOutline } from 'react-icons/io5';

// Component
import useStores from 'hooks/use-stores';
import { updateMatchingData, uploadQRFile } from 'services/inventory';
import UIStore from 'stores/ui';
import { useCallbackPrompt } from 'hooks/use-callback-prompt';

// Service
import { exportForm, exportFormPost } from 'services/export-form';

// Style
import styles from './styles.module.scss';
import useBlockNavigation from 'hooks/use-block-navigation';
import { toast } from 'react-toastify';

const TransshipmentQRFileScreen = () => {
  const navigate = useNavigate();

  const uiStore: UIStore = useStores().uiStore;

  const inputRef = useRef<any>(null);

  const [leftProducts, setLeftProducts] = useState<Array<any>>([]);
  const [rightProducts, setRightProducts] = useState<Array<any>>([]);
  const [loaded, setLoaded] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const loadedRef = useRef(false);

  useBlockNavigation({ shouldBlock: isDirty });

  const uploadMutation = useMutation((file: File) => uploadQRFile(file), {
    onMutate: () => uiStore.showLoading(),
    onSettled: () => uiStore.hideLoading(),
    onSuccess: (res) => {
      const tempLeft = res.stock_details;
      const tempRight = res.stock_details.map((item: any) => item.stock_qr_detail);
      if (res.stock_qr_details) {
        tempRight.push(...res.stock_qr_details);
        res.stock_qr_details.forEach((_item: any) => tempLeft.push(null));
      }
      setLeftProducts(tempLeft);
      setRightProducts(tempRight);
      setLoaded(true);
      setTimeout(() => {
        loadedRef.current = true;
        setIsDirty(true);
      }, 100);
    },
  });

  const saveMutation = useMutation(
    ({ matchedRows, unmatchedRows }: any) => updateMatchingData(matchedRows, unmatchedRows),
    {
      onMutate: () => uiStore.showLoading(),
      onSettled: () => uiStore.hideLoading(),
      onSuccess: () => {
        // cancel block navigation after mutating data.
        toast.success('登録しました');
        setIsDirty(false);
        // loadedRef.current = false;

        setTimeout(() => {
          navigate('/inventories');
        }, 10);
      },
    }
  );

  useEffect(() => {
    if (loadedRef.current) {
      setIsDirty(true);
    }
  }, [leftProducts, rightProducts]);

  const checkFieldMatch = (
    item: any,
    key: 'device_type_code' | 'frame_number' | 'base_number' | 'cell_number'
  ) => {
    if (key === 'device_type_code') {
      if (item?.check_match?.[key]) return styles.matched;
      else return styles.unmatched;
    } else {
      if (
        item?.check_match?.['frame_number'] ||
        item?.check_match?.['base_number'] ||
        item?.check_match?.['cell_number']
      )
        return styles.matched;
      else return styles.unmatched;
    }
  };

  const removeRightRow = (index: number) => {
    const temp = [...rightProducts];
    temp.splice(index, 1, null);
    setRightProducts(temp);
  };

  const exportTransshipmentQrToPDF = async () => {
    try {
      uiStore.showLoading();
      // Rows where stock_details === null, replace them
      // with an object that has stock_qr_details as a property.
      // Only apply this logic when exporting a PDF.
      const clonedLeftProducts = JSON.parse(JSON.stringify(leftProducts));
      clonedLeftProducts.forEach((item: any, index: number) => {
        if (item === null) {
          clonedLeftProducts[index] = {
            stock_qr_detail: JSON.parse(JSON.stringify(rightProducts[index])),
          };
        }
      });

      const payload = {
        stock_details: JSON.parse(JSON.stringify(clonedLeftProducts)),
      };
      // await exportForm('stocks/export-transshipment-qr-file', payload, 'POST');
      await exportFormPost('stocks/export-transshipment-qr-file', payload);
    } catch (error: any) {
      uiStore.showAlertBox({
        title: 'エラー',
        type: 'error',
        content: error.message,
      });
    } finally {
      uiStore.hideLoading();
    }
  };

  return (
    <div className={styles.container}>
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/home' }}>
          TOP
        </Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/inventories' }}>
          在庫
        </Breadcrumb.Item>
        <Breadcrumb.Item active>積替QRファイル</Breadcrumb.Item>
      </Breadcrumb>

      <div className="d-flex justify-content-between align-items-center">
        <div>
          <button className="primary-btn me-2" onClick={() => inputRef.current.click()}>
            QRファイル
          </button>
          <input
            type="file"
            accept=".csv, .xls, .xlsx"
            className="d-none"
            ref={inputRef}
            onChange={(event) => {
              const files = event.target.files;
              if (files && files.length > 0) {
                uploadMutation.mutate(files[0]);
                event.target.value = '';
              }
            }}
          />
          <button className="primary-btn" disabled={!loaded} onClick={exportTransshipmentQrToPDF}>
            印刷
          </button>
        </div>
        <button
          className="primary-btn"
          disabled={!loaded}
          onClick={() => {
            let matchedRows = leftProducts;
            for (let i = 0; i < leftProducts.length; i++) {
              if (matchedRows[i]) matchedRows[i].stock_qr_detail = rightProducts[i];
            }
            const unmatchedIndexes: Array<number> = [];
            matchedRows.forEach((item, index) => !item && unmatchedIndexes.push(index));
            matchedRows = matchedRows.filter((item) => !!item);
            let unmatchedRows: Array<any> = [];
            rightProducts.forEach(
              (item, index) => unmatchedIndexes.includes(index) && unmatchedRows.push(item)
            );
            unmatchedRows = unmatchedRows.filter((item) => !!item);
            saveMutation.mutate({ matchedRows, unmatchedRows });
          }}
        >
          登録
        </button>
      </div>

      <div className="mt-4">
        <Table hover bordered className={styles.table}>
          <thead>
            <tr>
              <th colSpan={6} className={styles.orange}>
                在庫
              </th>
              <th colSpan={9} className={styles.purple}>
                QRファイル
              </th>
            </tr>
            <tr>
              {[
                '種別',
                '機種CD',
                '型式名',
                '枠番号',
                'セル番号',
                '基盤番号',
                '種別',
                '枠番号',
                'セル番号',
                '基盤番号',
                '枠コード',
                'セルコード',
                '基盤コード',
                '在庫場所',
                '',
              ].map((header, index) => (
                <th key={index}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {leftProducts.map((item, index) => (
              <tr key={index}>
                <td className={styles.disabled}>{item?.current_device_type_code}</td>
                <td className={styles.disabled}>{item?.product_id}</td>
                <td className={styles.disabled}>{item?.product?.model_name}</td>
                <td className={styles.disabled}>{item?.frame_number}</td>
                <td className={styles.disabled}>{item?.cell_number}</td>
                <td className={styles.disabled}>{item?.base_number}</td>
                <td className={checkFieldMatch(item, 'device_type_code')}>
                  {rightProducts[index]?.device_type_code}
                </td>
                <td className={checkFieldMatch(item, 'frame_number')}>
                  {rightProducts[index]?.frame_number}
                </td>
                <td className={checkFieldMatch(item, 'cell_number')}>
                  {rightProducts[index]?.cell_number}
                </td>
                <td className={checkFieldMatch(item, 'base_number')}>
                  {rightProducts[index]?.base_number}
                </td>
                <td className={styles.disabled}>{rightProducts[index]?.frame_code}</td>
                <td className={styles.disabled}>{rightProducts[index]?.cell_code}</td>
                <td className={styles.disabled}>{rightProducts[index]?.base_code}</td>
                <td>
                  <input
                    type="text"
                    value={rightProducts[index]?.pallet_code || ''}
                    onChange={(event) => {
                      const temp = [...rightProducts];
                      temp.splice(index, 1, {
                        ...rightProducts[index],
                        pallet_code: event.target.value,
                      });
                      setRightProducts(temp);
                    }}
                  />
                </td>
                <td>
                  <IoCloseCircleOutline
                    size={20}
                    className="delete-icon"
                    onClick={() => removeRightRow(index)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <div className="d-flex justify-content-end">
        <button className="primary-btn" onClick={() => navigate(-1)}>
          キャンセル
        </button>
      </div>
    </div>
  );
};

export default TransshipmentQRFileScreen;
