// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_container__yBxIb {\n  display: flex;\n  align-items: center;\n  padding: 0;\n}\n.styles_container__yBxIb .styles_tilde__Bisij {\n  font-size: 1.5em;\n  height: 30px;\n}\n.styles_container__yBxIb .styles_tilde__Bisij::before {\n  content: \"~\";\n}\n\n.styles_dropdown__wl6cv {\n  width: 100%;\n  border: 1px solid lightgray;\n}\n.styles_dropdown__wl6cv:has(input:disabled) {\n  border: none;\n}", "",{"version":3,"sources":["webpack://./src/shared/components/hour-range-filter/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,UAAA;AACF;AACE;EACE,gBAAA;EACA,YAAA;AACJ;AACI;EACE,YAAA;AACN;;AAIA;EACE,WAAA;EACA,2BAAA;AADF;AAGE;EACE,YAAA;AADJ","sourcesContent":[".container {\n  display: flex;\n  align-items: center;\n  padding: 0;\n\n  .tilde {\n    font-size: 1.5em;\n    height: 30px;\n\n    &::before {\n      content: '\\007E';\n    }\n  }\n}\n\n.dropdown {\n  width: 100%;\n  border: 1px solid lightgray;\n\n  &:has(input:disabled) {\n    border: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "styles_container__yBxIb",
	"tilde": "styles_tilde__Bisij",
	"dropdown": "styles_dropdown__wl6cv"
};
export default ___CSS_LOADER_EXPORT___;
