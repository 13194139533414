// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_container__K5Z\\+S {\n  min-height: 100vh;\n  display: flex;\n  flex-direction: column;\n  background-color: #f8f8fb;\n}\n.styles_container__K5Z\\+S .styles_body__Rn3Pd {\n  display: flex;\n  flex-direction: row;\n  flex: 1 1;\n}\n.styles_container__K5Z\\+S .styles_body__Rn3Pd .styles_content__CmKlc {\n  flex: 1 1;\n  padding: 1.5rem 2.5rem;\n  max-width: calc(100% - 15rem);\n}", "",{"version":3,"sources":["webpack://./src/shared/containers/layouts/default/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,aAAA;EACA,sBAAA;EACA,yBAAA;AACF;AACE;EACE,aAAA;EACA,mBAAA;EACA,SAAA;AACJ;AACI;EACE,SAAA;EACA,sBAAA;EACA,6BAAA;AACN","sourcesContent":[".container {\n  min-height: 100vh;\n  display: flex;\n  flex-direction: column;\n  background-color: #f8f8fb;\n\n  .body {\n    display: flex;\n    flex-direction: row;\n    flex: 1;\n\n    .content {\n      flex: 1;\n      padding: 1.5rem 2.5rem;\n      max-width: calc(100% - 15rem);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "styles_container__K5Z+S",
	"body": "styles_body__Rn3Pd",
	"content": "styles_content__CmKlc"
};
export default ___CSS_LOADER_EXPORT___;
