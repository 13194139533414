// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_container__paMje {\n  margin: 0 !important;\n  position: relative;\n}\n.styles_container__paMje thead {\n  position: sticky;\n  top: 0;\n  color: white;\n  z-index: 2;\n  transform: translateY(-1px);\n}\n.styles_container__paMje thead th {\n  padding: 0.1em 0;\n}\n.styles_container__paMje tbody tr td {\n  padding: 0.1em;\n  word-break: normal;\n}\n.styles_container__paMje input {\n  border-radius: 0.25rem;\n  padding: 0.15rem 0.5rem;\n}\n.styles_container__paMje textarea {\n  border-radius: 0.25rem;\n  border: 1px solid lightgray !important;\n  padding: 0.15rem 0.5rem;\n}", "",{"version":3,"sources":["webpack://./src/shared/components/detail-table/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,oBAAA;EACA,kBAAA;AACF;AACE;EACE,gBAAA;EACA,MAAA;EACA,YAAA;EACA,UAAA;EACA,2BAAA;AACJ;AACI;EACE,gBAAA;AACN;AAKM;EACE,cAAA;EACA,kBAAA;AAHR;AAQE;EACE,sBAAA;EACA,uBAAA;AANJ;AASE;EACE,sBAAA;EACA,sCAAA;EACA,uBAAA;AAPJ","sourcesContent":[".container {\n  margin: 0 !important;\n  position: relative;\n\n  thead {\n    position: sticky;\n    top: 0;\n    color: white;\n    z-index: 2;\n    transform: translateY(-1px);\n\n    th {\n      padding: 0.1em 0;\n    }\n  }\n\n  tbody {\n    tr {\n      td {\n        padding: 0.1em;\n        word-break: normal;\n      }\n    }\n  }\n\n  input {\n    border-radius: 0.25rem;\n    padding: 0.15rem 0.5rem;\n  }\n\n  textarea {\n    border-radius: 0.25rem;\n    border: 1px solid lightgray !important;\n    padding: 0.15rem 0.5rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "styles_container__paMje"
};
export default ___CSS_LOADER_EXPORT___;
