import moment from 'moment';

import { Customer } from './customer';
import { Delivery } from './delivery';
import { Export } from './export';
import { Import } from './import';
import { Product } from './product';
import { Purchase } from './purchase';
import { User } from './user';

/**
 * @SaleList
 */
type TargetType = 'PURCHASE' | 'IMPORT' | 'EXPORT' | 'DELIVERY';

export const saleType: {
  [key: string]: string;
} = {
  '1': '見積', // estimate
  '2': '販売', // sell
  '3': '手数料', // commission
  '4': '運賃', // fee
  '5': '廃棄', // wast
  '6': '処分', // disposal
  '7': '資源', // resource
  '8': '液晶', // LCD
  '9': '保管料', // warehouse
};

const now = new Date();
const date = now.getDate();
const month = now.getMonth();
const year = now.getFullYear();

export interface ISaleFilter {
  input_date_from: string | null;
  input_date_to: string | null;
  sale_date_from: string | null;
  sale_date_to: string | null;
  customer_id: number | null;
  customer_id_in_detail: number | null;
  sale_code: string | null;
  created_delivery: 0 | 1 | null;
  exclude_estimate: 1 | null;
  user_input_id: number | null;
  complete: 0 | 1 | null;
  sort: string | null;
  sort_type: 'asc' | 'desc';
}

export const DEFAULT_SALE_FILTER: ISaleFilter = {
  input_date_from: moment(new Date(year, month - 1, date)).format('YYYY-MM-DD'),
  input_date_to: moment(now).format('YYYY-MM-DD'),
  exclude_estimate: null,
  customer_id: null,
  customer_id_in_detail: null,
  complete: null,
  created_delivery: null,
  sale_code: null,
  sale_date_from: null,
  sale_date_to: null,
  user_input_id: null,
  sort: 'sale_code',
  sort_type: 'asc',
};

export type SaleDetail = {
  id: number | string | undefined;
  sale_id?: number;
  device_type_code?: string;
  product_id?: number | null;
  customer_id?: number | string;
  product_quantity?: number;
  purchase_unit_price?: number;
  sale_detail_note?: string | null;
  tax_included?: number;
  tax_rate?: number;
  tax_effective_date?: string;
  sort?: number;
  created_at?: string;
  updated_at?: string;
  deleted_at?: string | null;
  customer?: Customer;
  product?: Product;
};

export type Sale = {
  id: string;
  sale_code: string;
  target_type: string | null;
  target_id: number | null;
  sale_type: number;
  input_date: string | null;
  sale_date: string | null;
  aggregation_date: string | null;
  customer_id: number | string;
  user_pic_id: number | null;
  user_input_id: number | null;
  user_confirm_id: number | null;
  sale_detail: string | null;
  business_contact: string | null;
  sale_memo: string | null;
  complete: 0 | 1;
  created_at: string | null;
  updated_at: string | null;
  deleted_at: string | null;
  sale_type_text: string | null;
  complete_text: string | null;
  purchase: Purchase;
  import: Import;
  export: Export;
  deliveries: Array<Partial<Delivery>>;
  customer: Customer;
  user_person_in_charge: User;
  user_input_person: User;
  user_confirm_person: User;
  sale_details: Array<SaleDetail>;
  delivery_cost: number | string;
  commission: number | string;
  total_amount: number | null;
  different_billing: number | null;
  zero_sale_detail: number | null;
};
