import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import { Control, Controller, UseFormSetValue, useFormState, useWatch } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';
import moment from 'moment';

import CustomerFilter from 'shared/components/customer-filter';
import DateFilter from 'shared/components/date-filter';
import DynamicDropdownFilter from 'shared/components/dynamic-dropdown-filter';
import CustomFilter from 'shared/components/custom-filter';
import StaticDropdownFilter from 'shared/components/static-dropdown-filter';
import TextFilter from 'shared/components/text-filter';
import { getCustomerDetails } from 'services/customer';
import { DATETIME_FORMAT } from 'consts';
import PopupPrint from 'shared/components/popup-print';
import { saleExportOptions } from 'consts/export-options';
import styles from './styles.module.scss';
import TextAreaFilter from 'shared/components/textarea-filter';
import { DropdownDataProvider } from 'shared/data-providers/dropdown-data-provider';
import { popupConfirmSaveBeforeNavigate } from 'utils/modals';
import { getUserInfo } from 'services/user';
import ImportCodeModal from './import-code-modal';
import ExportCodeModal from './export-code-modal';
import { checkImportCode } from 'services/import';
import { checkExportCode } from 'services/export';
import { linkSale } from 'services/sale';

interface IProps {
  control: Control<any, any>;
  setValue: UseFormSetValue<any>;
  onSave: (path?: string) => void;
  id?: number;
  exportSaleDetailToPDF: (type: number | string, by: number) => void;
  refetch?: any;
}

const SaleDetailsForm = ({
  control,
  setValue,
  onSave,
  id,
  exportSaleDetailToPDF,
  refetch,
}: IProps) => {
  const [importCodeModal, setImportCodeModal] = useState(false);
  const [exportCodeModal, setExportCodeModal] = useState(false);

  const customerId = useWatch({ control, name: 'customer_id' });
  const picUser = useWatch({ control, name: 'user_person_in_charge' });
  const inputUser = useWatch({ control, name: 'user_input_person' });
  const confirmUser = useWatch({ control, name: 'user_confirm_person' });
  const deliveries = useWatch({ control, name: 'deliveries' });
  const saleType = useWatch({ control, name: 'sale_type' });
  const importObj = useWatch({ control, name: 'import' });
  const exportObj = useWatch({ control, name: 'export' });
  const targetId = useWatch({ control, name: 'target_id' });
  const customer = useWatch({ control, name: 'customer' });
  const completed = useWatch({ control, name: 'complete' });
  const memo = useWatch({ control, name: 'sale_memo' });
  const saleDetail = useWatch({ control, name: 'sale_detail' });
  const businessContact = useWatch({ control, name: 'business_contact' });
  const picUserId = useWatch({ control, name: 'user_pic_id' });
  const saleDate = useWatch({ control, name: 'sale_date' });

  const { isValid } = useFormState({ control });
  const navigate = useNavigate();
  const [showPrintModal, setShowPrintModal] = useState<boolean>(false);

  const confirmUserDataProvider = useRef(
    new DropdownDataProvider('users/dropdown/confirm_person', (item) => ({
      label: item.name,
      value: item.id,
    }))
  ).current;
  const picDataProvider = useRef(
    new DropdownDataProvider('users/dropdown/person_in_charge', (item) => ({
      label: item.name,
      value: item.id,
    }))
  ).current;
  const inputUserDataProvider = useRef(
    new DropdownDataProvider('users/dropdown/input_person', (item) => ({
      label: item.name,
      value: item.id,
    }))
  ).current;

  const canCreateDelivery = useMemo(() => {
    return [2, 6, 7, 8].includes(saleType) && isValid && !completed;
  }, [saleType, isValid, completed]);

  const customerQuery = useQuery(['customer', customerId], () => getCustomerDetails(customerId), {
    enabled: false,
    keepPreviousData: true,
    onSuccess: (customer) => {
      if (!customer.pic_id) customer.pic_name = undefined;
      setValue('customer', customer);
      // feedback UI: 125
      // only do this if picUserId isn't set.
      if (control._formState.isDirty && !picUserId && customer.pic_id) {
        userPicQuery.mutate(customer.pic_id);
      }
      // set memo from customer_memo
      if (control._formState.isDirty) {
        setValue('sale_memo', customer.customer_memo ? customer.customer_memo: "", { shouldDirty: true });
      }
    },
  });

  const userPicQuery = useMutation((id: number) => getUserInfo(id), {
    onSuccess: (user) => {
      if (user.user_types.PERSON_IN_CHARGE === 1) {
        setValue('user_pic_id', user.id);
      }
    },
  });

  const importCodeCheckMutation = useMutation((code: string) => checkImportCode(code), {
    onSuccess: (data) => linkMutation.mutate({ targetType: 'IMPORT', targetId: data.id }),
    onSettled: () => setImportCodeModal(false),
  });

  const exportCodeCheckMutation = useMutation((code: string) => checkExportCode(code), {
    onSuccess: (data) => linkMutation.mutate({ targetType: 'EXPORT', targetId: data.id }),
    onSettled: () => setExportCodeModal(false),
  });

  const linkMutation = useMutation(
    ({ targetType, targetId }: { targetType: string; targetId: number }) =>
      linkSale(targetType, targetId, id!),
    { onSuccess: () => refetch && refetch() }
  );

  const onPlusClick = (dest: string) => {
    if (!control._formState.isDirty && id) {
      navigate(dest + id);
      return;
    }

    popupConfirmSaveBeforeNavigate({ onConfirm: () => onSave(dest) });
  };

  const closePrintModal = () => {
    setShowPrintModal(false);
  };

  const exportSaleDetail = (type: string | number, by: number) => {
    closePrintModal();
    exportSaleDetailToPDF(type, by);
  };

  useEffect(() => {
    if (customerId) customerQuery.refetch();
    else {
      setValue('customer.hall_name', undefined);
      setValue('customer', undefined);
    }
  }, [customerId]);

  useEffect(() => {
    setValue('aggregation_date', saleDate, { shouldDirty: true });
  }, [saleDate]);

  return (
    <>
      {importCodeModal && (
        <ImportCodeModal
          onClose={() => setImportCodeModal(false)}
          onDone={(importCode) => {
            importCodeCheckMutation.mutate(importCode);
          }}
        />
      )}
      {exportCodeModal && (
        <ExportCodeModal
          onClose={() => setExportCodeModal(false)}
          onDone={(exportCode) => {
            exportCodeCheckMutation.mutate(exportCode);
          }}
        />
      )}
      <Form
        onSubmit={(event: any) => {
          event.preventDefault();
          onSave();
        }}
        className={styles.form}
      >
        {!completed && (
          <button
            aria-label="registerBtn"
            className="secondary-btn ms-2"
            type="submit"
            disabled={!isValid}
          >
            登録
          </button>
        )}

        <div className={styles.group2}>
          <div className={styles.group20}>
            <div className={styles.saleCode}>
              <TextFilter label="売上番号" control={control} keys={['sale_code']} disabled />
              {id && (
                <button
                  aria-label="printBtn"
                  className="secondary-btn"
                  type="button"
                  onClick={(_event: any) => setShowPrintModal(true)}
                >
                  印刷
                </button>
              )}
            </div>
          </div>
          <div className={styles.group21}>
            <div className={styles.inputDate}>
              <DateFilter
                label="入力日"
                control={control}
                keys={['input_date']}
                disabled={!!completed}
                required
              />
            </div>

            <div className={styles.saleDate}>
              <DateFilter
                label="売上日"
                control={control}
                keys={['sale_date']}
                disabled={!!completed}
                required
              />
            </div>

            <div className={styles.saleType}>
              <StaticDropdownFilter
                label="売上区分"
                control={control}
                keys={['sale_type']}
                isClearable={false}
                disabled={!!completed}
                options={[
                  { label: '見積', value: 1 }, // estimate
                  { label: '販売', value: 2 }, // sell
                  { label: '手数料', value: 3 }, // commission
                  { label: '運賃', value: 4 }, // fee
                  // { label: '廃棄', value: 5 },
                  { label: '処分', value: 6 }, // disposal
                  { label: '資源', value: 7 }, // resource
                  { label: '液晶', value: 8 }, // LCD
                  { label: '保管料', value: 9 }, // warehouse
                ]}
              />
            </div>
          </div>

          <div className={styles.group22}>
            <div className={styles.customerId}>
              <CustomerFilter
                label="顧客"
                control={control}
                keys={['customer_id']}
                disabled={!!completed}
                required
                fallbackValue={customer}
              />
            </div>
          </div>

          <div className={styles.group23}>
            <div className={styles.customerAddress}>
              <TextFilter label="住所" control={control} keys={['customer.address']} disabled />
            </div>

            <div className={styles.picNameHallName}>
              <TextFilter
                label="顧客担当者"
                control={control}
                keys={['customer.pic_name']}
                disabled
              />
              {/* <Controller
                control={control}
                name="customer.hall_name"
                render={({ field }) => (
                  <input
                    className="px-2"
                    disabled
                    value={field.value ? field.value : ''}
                    onBlur={field.onBlur}
                    onChange={field.onChange}
                    style={{
                      height: '30px',
                      width: 'fit-content',
                      flex: 2,
                      border: '1px solid lightgray',
                    }}
                    readOnly
                  />
                )}
              /> */}
            </div>
          </div>

          <div className={styles.group24}>
            <div className={styles.tel}>
              <TextFilter label="TEL" control={control} keys={['customer.tel']} disabled />
            </div>

            <div className={styles.fax}>
              <TextFilter label="FAX" control={control} keys={['customer.fax']} disabled />
            </div>
          </div>

          <div className={styles.group25}>
            <div className={styles.importCode}>
              <CustomFilter
                label="入庫"
                plus={!importObj && !exportObj}
                onPlusClick={() => setImportCodeModal(true)}
                content={
                  importObj && (
                    <Link to={`/imports/${importObj.id}`} target="_blank">
                      {importObj.import_code}
                    </Link>
                  )
                }
              />
            </div>

            <div className={styles.exportCode}>
              <CustomFilter
                label="出庫"
                plus={!exportObj && !importObj}
                onPlusClick={() => setExportCodeModal(true)}
                content={
                  exportObj && (
                    <Link
                      to={`/exports/${exportObj.id}?type=${exportObj.export_type}`}
                      target="_blank"
                    >
                      {exportObj.export_code}
                    </Link>
                  )
                }
              />
            </div>
            <div className={styles.importCommission}>
              <Controller
                control={control}
                name="import"
                render={({ field: { value } }) => (
                  <CustomFilter
                    label="作業料"
                    content={value ? value.import_fee.toLocaleString('en-US') : null}
                  />
                )}
              />
            </div>
          </div>

          <div className={styles.group26}>
            <div className={styles.delivery}>
              <CustomFilter
                label="配送"
                plus={canCreateDelivery}
                onPlusClick={() => onPlusClick(`/deliveries/create?target_type=SALE&target_id=`)}
                content={
                  <div className="d-flex">
                    {deliveries?.map(
                      (item: any) =>
                        (item.target_type === 'SALE' || item.target_type === 'PURCHASE' )&& (
                          <React.Fragment key={item.id}>
                            <Link to={`/deliveries/${item.id}`} target="_blank">
                              {item.delivery_code}
                            </Link>
                            &ensp;
                          </React.Fragment>
                        )
                    )}
                  </div>
                }
              />
            </div>

            <div className={styles.deliveryFees}>
              <Controller
                control={control}
                name="deliveries"
                render={({ field: { value } }) => (
                  <CustomFilter
                    label="運賃"
                    content={
                      value
                        ? value
                            .reduce(
                              (acc: any, curr: any) =>
                                acc + ((curr.amount_fee || 0) + (curr.amount_fee_extra || 0)),
                              0
                            )
                            .toLocaleString('en-US')
                        : null
                    }
                  />
                )}
              />
            </div>
          </div>
        </div>

        <div className={styles.group3}>
          <div className={styles.updateAt}>
            <Controller
              control={control}
              name="updated_at"
              render={({ field: { value } }) => (
                <CustomFilter
                  className="flex-grow-1"
                  label="修正日時"
                  content={value && moment(new Date(value)).format(DATETIME_FORMAT)}
                />
              )}
            />
          </div>

          <div className={styles.aggregationDate}>
            <DateFilter
              label="集計日"
              control={control}
              keys={['aggregation_date']}
              disabled={!!completed}
            />
          </div>

          <div className={styles.userPicId}>
            <DynamicDropdownFilter
              label="担当者"
              control={control}
              keys={['user_pic_id']}
              dataProvider={picDataProvider}
              fallbackValue={picUser ? { label: picUser.name, value: picUser.id } : undefined}
              disabled={!!completed}
            />
          </div>

          <div className={styles.userInputId}>
            <DynamicDropdownFilter
              label="入力者"
              control={control}
              keys={['user_input_id']}
              dataProvider={inputUserDataProvider}
              fallbackValue={inputUser ? { label: inputUser.name, value: inputUser.id } : undefined}
              disabled={!!completed}
              required
            />
          </div>

          <div className={styles.userConfirmId}>
            <DynamicDropdownFilter
              label="確認者"
              control={control}
              keys={['user_confirm_id']}
              dataProvider={confirmUserDataProvider}
              fallbackValue={
                confirmUser ? { label: confirmUser.name, value: confirmUser.id } : undefined
              }
              disabled={!!completed}
            />
          </div>

          <div className={styles.complete}>
            <Controller
              control={control}
              name="complete"
              render={({ field: { value } }) => (
                <CustomFilter
                  label="完了"
                  content={value === 1 ? '完了' : value === 0 ? '未完了' : null}
                />
              )}
            />
          </div>
        </div>

        <div className={styles.group4}>
          <TextAreaFilter
            label="詳細"
            value={saleDetail}
            onChange={(event) => {
              setValue('sale_detail', event.target.value, { shouldDirty: true });
            }}
          />

          <TextAreaFilter
            label="業務連絡"
            value={businessContact}
            onChange={(event) => {
              setValue('business_contact', event.target.value, { shouldDirty: true });
            }}
          />

          <TextAreaFilter
            label="メモ"
            value={memo}
            onChange={(event) => {
              setValue('sale_memo', event.target.value, { shouldDirty: true });
            }}
          />
        </div>
      </Form>

      {showPrintModal && (
        <PopupPrint
          title="印刷選択モーダル"
          options={saleExportOptions}
          actions={[
            {
              label: '閉じる',
              callback: () => closePrintModal(),
            },
            {
              label: '印刷',
              callback: (option: number | string) => exportSaleDetail(option, 0),
            },
            {
              label: ' 明細顧客別印刷',
              callback: (option: number | string) => exportSaleDetail(option, 1),
            },
          ]}
        />
      )}
    </>
  );
};

export default SaleDetailsForm;
