import { Export } from 'types/export';
import { del, get, post, postFormData, put } from './index';

export const countProductExportDetails = (id: number | string) =>
  get(`exports/count/device-type/${id}`, {});

export const getExportById = (id: number) => get(`exports/${id}`, {});

export const createExport = (data: Partial<Export>) => post('exports', data);

export const updateExport = (id: number, data: Partial<Export>) => put(`exports/${id}`, data);

export const completeExport = (id: number) => post(`exports/completion/${id}`, {});

export const revertExportCompletion = (id: number) => post(`exports/revert/${id}`, {});

export const deleteExport = (id: number) => del(`exports/${id}`);

export const registExportQRDetail = (id: number, payload: Array<any>) =>
  put(`exports/qr/${id}`, { export_details: payload });

export const uploadExportQRFile = (id: number, file: File) => {
  const formData = new FormData();
  formData.append('file_qr', file);
  return postFormData(`exports/qr/${id}`, formData);
};

export const checkExportCode = (code: string) => get(`exports/target-check/${code}`, {});
