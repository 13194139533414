// Built-in
import ReactDatePicker, { ReactDatePickerCustomHeaderProps } from 'react-datepicker';

// Style
import styles from './styles.module.scss';

interface IProps {
  selected?: Date | null;
  className?: string;
  onChange(date: Date | null, event: React.SyntheticEvent<any> | undefined): void;
  dateFormat?: string | string[];
  value?: string;
  disabled?: boolean;
  isClearable?: boolean;
  onChangeRaw?(event: React.MouseEvent<HTMLInputElement> | React.KeyboardEvent<HTMLInputElement> | any): void;
  maxDate?: Date | undefined;
  minDate?: Date | undefined;
}

const ReactDatePickerWrapper = ({
  selected,
  className,
  onChange,
  onChangeRaw,
  dateFormat,
  value,
  disabled,
  isClearable,
  maxDate,
  minDate,
}: IProps) => {
  const renderCustomHeader = ({
    date,
    decreaseMonth,
    increaseMonth,
    changeMonth,
    changeYear,
    nextMonthButtonDisabled,
    prevMonthButtonDisabled,
  }: ReactDatePickerCustomHeaderProps) => {
    return (
      <div className={styles.customHeader}>
        <span onClick={decreaseMonth}>{'<'}</span>
        <span>{`${date.getFullYear()}年 ${date.getMonth() + 1}月`}</span>
        <span onClick={increaseMonth}>{'>'}</span>
      </div>
    );
  };

  return (
    <ReactDatePicker
      renderCustomHeader={renderCustomHeader}
      locale={'ja'}
      onChange={onChange}
      onChangeRaw={onChangeRaw}
      dateFormat={dateFormat}
      selected={selected}
      dayClassName={(date) => styles.day}
      className={className}
      popperClassName={styles.popper}
      calendarClassName={styles.calender}
      wrapperClassName={styles.wrapper}
      value={value}
      disabled={disabled}
      isClearable={isClearable}
      maxDate={maxDate}
      minDate={minDate}
    />
  );
};

export default ReactDatePickerWrapper;
