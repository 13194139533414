import { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Control, Controller, useFormState, useWatch } from 'react-hook-form';
import moment from 'moment';
import { useMutation } from 'react-query';

import DateFilter from 'shared/components/date-filter';
import DynamicDropdownFilter from 'shared/components/dynamic-dropdown-filter';
import TextFilter from 'shared/components/text-filter';
import { DropdownDataProvider } from 'shared/data-providers/dropdown-data-provider';
import CustomFilter from 'shared/components/custom-filter';
import StaticDropdownFilter from 'shared/components/static-dropdown-filter';
import SaleConfirmModal from '../sale-confirm-modal';
import { DATETIME_FORMAT } from 'consts';
import PopupPrint from 'shared/components/popup-print';
import { importExportOptions } from 'consts/export-options';
import styles from './styles.module.scss';
import TextAreaFilter from 'shared/components/textarea-filter';
import { popupConfirmSaveBeforeNavigate } from 'utils/modals';
import { checkSaleCode, linkSale } from 'services/sale';

interface IProps {
  id?: number;
  control: Control<any, any>;
  onSave: (path?: string) => void;
  onComplete: () => void;
  onRevert: () => void;
  onCreateStocks: () => void;
  editable: boolean;
  setValue: any;
  exportImportForm: (type: string | null) => void;
  refetch?: any;
}

const ImportDetailsForm = ({
  id,
  control,
  onSave,
  editable,
  onComplete,
  onRevert,
  onCreateStocks,
  setValue,
  exportImportForm,
  refetch,
}: IProps) => {
  const navigate = useNavigate();

  const [refreshKey, setRefreshKey] = useState(0);
  const [showExportModal, setShowExportModal] = useState<boolean>(false);
  const [saleConfirmModal, setSaleConfirmModal] = useState(false);

  const { isDirty, isValid } = useFormState({ control });
  const groupId = useWatch({ control, name: 'group_id' });
  const importDetail = useWatch({ control, name: 'import_detail' });
  const businessContact = useWatch({ control, name: 'business_contact' });
  const memo = useWatch({ control, name: 'import_memo' });

  const confirmUserDataProvider = useRef(
    new DropdownDataProvider('users/dropdown/confirm_person', (item) => ({
      label: item.name,
      value: item.id,
    }))
  ).current;
  const picDataProvider = useRef(
    new DropdownDataProvider('users/dropdown/person_in_charge', (item) => ({
      label: item.name,
      value: item.id,
    }))
  ).current;
  const inputUserDataProvider = useRef(
    new DropdownDataProvider('users/dropdown/input_person', (item) => ({
      label: item.name,
      value: item.id,
    }))
  ).current;

  const groupDataProvider = useRef(
    new DropdownDataProvider('groups/dropdown', (group) => ({
      label: group.group_name,
      value: group.id,
    }))
  );
  const customerDataProvider = useRef(
    new DropdownDataProvider('customers/dropdown', (item) => ({
      label: item.customer_code + ':' + (item.hall_name ? item.hall_name : item.customer_name),
      value: item.id,
    }))
  );

  const checkSaleCodeMutation = useMutation((code: string) => checkSaleCode(code), {
    onSettled: () => {
      setSaleConfirmModal(false);
    },
    onSuccess: (data) => {
      console.log('Response data: ', data);
      linkMutation.mutate(data.id);
    },
  });

  const linkMutation = useMutation((saleId: number) => linkSale('IMPORT', id!, saleId), {
    onSuccess: () => {
      refetch && refetch();
    },
  });

  useEffect(() => {
    if (groupId) {
      customerDataProvider.current = new DropdownDataProvider(
        `customers/dropdown?group_id=${groupId}`,
        (item) => ({
          label: item.customer_code + ':' + (item.hall_name ? item.hall_name : item.customer_name),
          value: item.id,
        })
      );
      setRefreshKey(Math.random());
    }
  }, [groupId]);

  const sumDeliveryFee = (deliveries: Array<any>) => {
    let sum = 0;
    deliveries.forEach((item) => (sum = sum + item.amount_fee + item.amount_fee_extra || 0));
    return sum;
  };

  const exportForm = (type: string | null) => {
    exportImportForm(type);
    setShowExportModal(false);
  };

  const onPlusClick = (dest: string) => {
    if (!control._formState.isDirty && id) {
      if (dest === 'qr') {
        navigate(dest);
      } else {
        navigate(dest + id);
      }
      return;
    }

    popupConfirmSaveBeforeNavigate({
      onConfirm: () => onSave(dest),
    });
  };

  return (
    <>
      {saleConfirmModal && (
        <SaleConfirmModal
          onClose={() => setSaleConfirmModal(false)}
          onDone={(type, saleCode) => {
            if (type === 1) onPlusClick(`/sales/create?target_type=IMPORT&target_id=`);
            else {
              checkSaleCodeMutation.mutate(saleCode!);
            }
          }}
        />
      )}
      <div className={styles.form}>
        {editable && (
          <button
            aria-label="registerBtn"
            className="secondary-btn"
            onClick={() => onSave()}
            disabled={!isValid}
          >
            登録
          </button>
        )}

        <div className={styles.group1}>
          <div className={styles.group11}>
            <div className={styles.importCode}>
              <TextFilter label="入庫番号" control={control} keys={['import_code']} disabled />
            </div>

            <div className={styles.importType}>
              <StaticDropdownFilter
                label="入庫区分"
                control={control}
                keys={['import_type']}
                options={[
                  { label: '預かり', value: 1 }, //azukri
                  { label: '廃棄', value: 2 }, // waste
                ]}
                disabled={!editable || !!id}
                isClearable={false}
                required
              />
              {id && (
                <button className="secondary-btn ms-2" onClick={() => setShowExportModal(true)}>
                  印刷
                </button>
              )}
            </div>
          </div>

          <div className={styles.group12}>
            <div className={styles.inputDate}>
              <DateFilter
                label="入力日"
                control={control}
                keys={['input_date']}
                required
                disabled={!editable}
              />
            </div>

            <div className={styles.importDate}>
              <DateFilter
                label="入庫日"
                control={control}
                keys={['import_date']}
                required
                disabled={!editable}
              />
            </div>
          </div>

          <div className={styles.group13}>
            <div className={styles.groupId}>
              <Controller
                control={control}
                name="group"
                render={({ field: { value } }) => (
                  <DynamicDropdownFilter
                    label="グループ"
                    dataProvider={groupDataProvider.current}
                    control={control}
                    keys={['group_id']}
                    disabled={!editable}
                    effect={() => {
                      setValue('customer_id', undefined);
                    }}
                    required
                    fallbackValue={value ? { label: value.group_name, value: value.id } : undefined}
                  />
                )}
              />
            </div>

            <div className={styles.customerId}>
              <Controller
                control={control}
                name="customer"
                render={({ field: { value } }) => (
                  <DynamicDropdownFilter
                    key={refreshKey}
                    label="入庫元"
                    dataProvider={customerDataProvider.current}
                    control={control}
                    keys={['customer_id']}
                    disabled={!editable}
                    required
                    fallbackValue={
                      value
                        ? {
                            label:
                              value.customer_code +
                              ':' +
                              (value.hall_name ? value.hall_name : value.customer_name),
                            value: value.id,
                          }
                        : undefined
                    }
                  />
                )}
              />
            </div>
          </div>

          <div className={styles.group14}>
            <div className={styles.deliveryCode}>
              <Controller
                control={control}
                name="deliveries"
                render={({ field }) => (
                  <CustomFilter
                    label="配送"
                    plus={editable && isValid}
                    onPlusClick={() =>
                      onPlusClick(`/deliveries/create?target_type=IMPORT&target_id=`)
                    }
                    content={field.value?.map((item: any) => (
                      <>
                        <Link to={`/deliveries/${item.id}`} target="_blank">
                          {item.delivery_code}
                        </Link>
                        &ensp;
                      </>
                    ))}
                  />
                )}
              />
            </div>

            <div className={styles.deliveryFee}>
              <Controller
                control={control}
                name="deliveries"
                render={({ field }) => (
                  <CustomFilter
                    label="運賃"
                    content={field.value && sumDeliveryFee(field.value).toLocaleString('en-US')}
                  />
                )}
              />
            </div>

            <div className={styles.importCommission}>
              <Controller
                control={control}
                name="import_fee"
                render={({ field }) => (
                  <CustomFilter label="入庫手数料" content={field.value?.toLocaleString('en-US')} />
                )}
              />
            </div>
          </div>

          <div className={styles.group15}>
            <div className={styles.saleCode}>
              <Controller
                control={control}
                name="sales"
                render={({ field }) => (
                  <CustomFilter
                    label="売上"
                    plus={(field.value?.length === 0 || !field.value) && editable && isValid}
                    onPlusClick={() => {
                      setSaleConfirmModal(true);
                    }}
                    content={
                      field.value?.length > 0 && (
                        <Link to={`/sales/${field.value[0].id}`} target="_blank">
                          {field.value[0].sale_code}
                        </Link>
                      )
                    }
                  />
                )}
              />
            </div>

            <div className={styles.pickUpFee}>
              <Controller
                control={control}
                name="pickup_fee"
                render={({ field }) => (
                  <CustomFilter label="引取作業料" content={field.value?.toLocaleString('en-US')} />
                )}
              />
            </div>

            <div className={styles.otherFee}>
              <TextFilter
                label="その他"
                control={control}
                keys={['other_fee']}
                disabled={!editable}
                type="number"
              />
            </div>
          </div>

          <div className={styles.group16}>
            <div className={styles.purchaseCode}>
              <Controller
                control={control}
                name="purchase"
                render={({ field }) =>
                  field.value ? (
                    <CustomFilter
                      label="買取"
                      content={
                        <Link to={`/purchases/${field.value.id}`} target="_blank">
                          {field.value?.purchase_code}
                        </Link>
                      }
                    />
                  ) : (
                    <></>
                  )
                }
              />
            </div>

            <div className={styles.laborCost}>
              <TextFilter
                label="人工費"
                control={control}
                keys={['labor_cost']}
                disabled={!editable}
                type="number"
              />
            </div>
          </div>
        </div>

        <div className={styles.group2}>
          <div className={styles.updatedAt}>
            <Controller
              control={control}
              name="updated_at"
              render={({ field: { value } }) => (
                <CustomFilter
                  className="flex-grow-1"
                  label="修正日時"
                  content={value && moment(new Date(value)).format(DATETIME_FORMAT)}
                />
              )}
            />
          </div>
          <div className={styles.userPicId}>
            <Controller
              control={control}
              name="user_person_in_charge"
              render={({ field: { value } }) => (
                <DynamicDropdownFilter
                  label="担当者"
                  dataProvider={picDataProvider}
                  control={control}
                  keys={['user_pic_id']}
                  disabled={!editable}
                  fallbackValue={value != null ? { label: value.name, value: value.id } : undefined}
                />
              )}
            />
          </div>

          <div className={styles.userInputId}>
            <Controller
              control={control}
              name="user_input_person"
              render={({ field: { value } }) => (
                <DynamicDropdownFilter
                  label="入力者"
                  dataProvider={inputUserDataProvider}
                  control={control}
                  keys={['user_input_id']}
                  disabled={!editable}
                  required
                  fallbackValue={value != null ? { label: value.name, value: value.id } : undefined}
                />
              )}
            />
          </div>

          <div className={styles.userConfirmId}>
            <Controller
              control={control}
              name="user_confirm_person"
              render={({ field: { value } }) => (
                <DynamicDropdownFilter
                  label="確認者"
                  dataProvider={confirmUserDataProvider}
                  control={control}
                  keys={['user_confirm_id']}
                  disabled={!editable}
                  fallbackValue={value != null ? { label: value.name, value: value.id } : undefined}
                />
              )}
            />
          </div>

          <div className={styles.stockRegister}>
            <div style={{ flex: 1 }}>
              <Controller
                control={control}
                name="stock_register"
                render={({ field }) => (
                  <CustomFilter
                    label="在庫"
                    content={field.value != null && (field.value === 1 ? '登録済' : '未登録')}
                  />
                )}
              />
            </div>
            {id && (
              <button className="secondary-btn ms-2" onClick={onCreateStocks} disabled={isDirty}>
                登録
              </button>
            )}
          </div>

          <div className={styles.complete}>
            <div style={{ flex: 1 }}>
              <Controller
                control={control}
                name="complete"
                render={({ field }) => (
                  <CustomFilter
                    label="完了"
                    content={field.value != null && (field.value === 1 ? '完了' : '未完了')}
                  />
                )}
              />
            </div>
            {editable && id && (
              <button className="secondary-btn ms-2" onClick={onComplete}>
                完了
              </button>
            )}
            {id && !editable && (
              <button className="secondary-btn ms-2" onClick={onRevert}>
                巻き戻し
              </button>
            )}
          </div>
        </div>
        <div className={styles.group3}>
          <TextAreaFilter
            label="詳細"
            value={importDetail}
            onChange={(event) => {
              setValue('import_detail', event.target.value, { shouldDirty: true });
            }}
            disabled={!editable}
            rows={9}
          />

          <TextAreaFilter
            label="業務連絡"
            value={businessContact}
            onChange={(event) => {
              setValue('business_contact', event.target.value, { shouldDirty: true });
            }}
            disabled={!editable}
            rows={9}
          />

          <TextAreaFilter
            label="メモ"
            value={memo}
            onChange={(event) => {
              setValue('import_memo', event.target.value, { shouldDirty: true });
            }}
            disabled={!editable}
            rows={9}
          />
        </div>
        <div className={styles.group4}>
          <div className={styles.importDetails}>
            <Controller
              control={control}
              name="import_details"
              render={({ field: { value } }) => (
                <CustomFilter label="入庫数" content={value && value.length} />
              )}
            />
          </div>

          <div className={styles.code}>
            <Controller
              control={control}
              name="import_details"
              render={({ field: { value } }) => {
                if (value) {
                  let temp: any = {};
                  for (let item of value) {
                    if (item.device_type_code) {
                      if (temp[item.device_type_code]) temp[item.device_type_code]++;
                      else temp[item.device_type_code] = 1;
                    }
                  }
                  return (
                    <div className="d-flex flex-wrap">
                      {Object.keys(temp).map((key) => (
                        <CustomFilter
                          key={key}
                          label={key}
                          content={
                            <div style={{ minWidth: 50, textAlign: 'center' }}>{temp[key]}</div>
                          }
                        />
                      ))}
                    </div>
                  );
                }
                return <></>;
              }}
            />
          </div>
        </div>

        <div className={styles.qrButton}>
          {id && (
            <button className="primary-btn" onClick={() => onPlusClick('qr')}>
              QRファイル
            </button>
          )}
        </div>
      </div>

      {showExportModal && (
        <PopupPrint
          title="印刷選択モーダル"
          options={importExportOptions}
          actions={[
            { label: '閉じる', callback: () => setShowExportModal(false) },
            {
              label: '印刷',
              callback: (option: string | number) => {
                exportForm(option as string);
              },
            },
          ]}
        />
      )}
    </>
  );
};

export default ImportDetailsForm;
