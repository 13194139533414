import useStores from 'hooks/use-stores';
import { observer } from 'mobx-react';
import { useMemo } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { MdClose } from 'react-icons/md';
import UIStore from 'stores/ui';

import { DeviceType } from 'types/device-type';
import { popupConfirmBack } from 'utils/modals';
import { convertToHalfWidth } from 'utils/validator';
import styles from './styles.module.scss';
interface IProps {
  initialValues?: DeviceType;
  onClose: () => void;
  onSave: (data: DeviceType) => void;
  onDelete: () => void;
}

const EditModal = observer(({ initialValues, onClose, onSave, onDelete }: IProps) => {
  const initialFormValues = useMemo(() => {
    let values: any = {};
    if (initialValues) {
      values = initialValues;
      return values;
    }
    values = {
      sale_display: 1,
      iexp_display: 1,
      sort: 0,
    };
    return values;
  }, []);

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { isValid, isDirty },
  } = useForm({
    defaultValues: initialFormValues,
    mode: 'onChange',
  });
  const uiStore: UIStore = useStores().uiStore;
  const deviceTypeCode = useWatch({ control, name: 'device_type_code' });
  const sort = useWatch({ control, name: 'sort' });

  const modalTitle = useMemo(() => {
    return !initialValues ? '種別作成モーダル' : '種別編集モーダル';
  }, [initialValues]);

  const onSubmit = (data: any) => {
    onSave(data);
  };

  const onBackBtnClick = (event: any) => {
    event.preventDefault();
    popupConfirmBack(isDirty, () => onClose());
  };

  const onSortChange = (event: any) => {
    if (event.target.value === '') {
      setValue('sort', 0, { shouldDirty: true });
    } else {
      const isNumber = /^[０-９0-9]+$/.test(event.target.value);
      if (isNumber) {
        const val = parseInt(convertToHalfWidth(event.target.value));
        setValue('sort', val, { shouldDirty: true });
      }
    }
  };

  const onDeviceTypeCodeChange = (event: any) => {
    setValue('device_type_code', convertToHalfWidth(event.target.value), { shouldDirty: true });
  };

  return (
    <Modal show backdrop="static" centered>
      <div className="edit-modal">
        <div className="edit-modal--title">
          {modalTitle}
          <button onClick={onBackBtnClick}>
            <MdClose />
          </button>
        </div>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group className={styles.group}>
            <Form.Label className="required">コード</Form.Label>
            <Form.Control
              disabled={!!initialValues?.device_type_code}
              value={deviceTypeCode}
              required={true}
              onChange={onDeviceTypeCodeChange}
            />
          </Form.Group>

          <Form.Group className={styles.group}>
            <Form.Label className="required">種別名</Form.Label>
            <Form.Control {...register('device_type_name', { required: true })} />
          </Form.Group>

          <Form.Group className={styles.group}>
            <Form.Label className="required">並び順</Form.Label>
            <Form.Control onChange={onSortChange} value={sort} />
          </Form.Group>

          <Form.Group className="d-flex align-items-center">
            <Form.Label style={{ minWidth: 120, margin: '0.25em 0' }}>販売売上</Form.Label>
            <Controller
              control={control}
              name="sale_display"
              render={({ field: { onChange, value } }) => (
                <Form.Check
                  className="custom-checkbox"
                  defaultChecked={!!value}
                  onChange={(event) => onChange(event.target.checked ? 1 : 0)}
                />
              )}
            />
          </Form.Group>

          <Form.Group className="d-flex align-items-center">
            <Form.Label style={{ minWidth: 120, margin: '0.25em 0' }}>入庫出庫</Form.Label>
            <Controller
              control={control}
              name="iexp_display"
              render={({ field: { onChange, value } }) => (
                <Form.Check
                  className="custom-checkbox"
                  defaultChecked={!!value}
                  onChange={(event) => onChange(event.target.checked ? 1 : 0)}
                />
              )}
            />
          </Form.Group>
          <div className="d-flex justify-content-end">
            {initialValues && (
              <button
                className="edit-modal--delete"
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  onDelete();
                }}
              >
                削除
              </button>
            )}
            <button className="edit-modal--save" type="submit" disabled={!isValid}>
              登録
            </button>
          </div>
        </Form>
      </div>
      {isDirty && uiStore.confirmBox.visible && <div className="overlappedBackdrop"></div>}
    </Modal>
  );
});

export default EditModal;
