import { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Control, Controller, useWatch } from 'react-hook-form';
import moment from 'moment';
import { useMutation } from 'react-query';

import DateFilter from 'shared/components/date-filter';
import DynamicDropdownFilter from 'shared/components/dynamic-dropdown-filter';
import TextFilter from 'shared/components/text-filter';
import { DropdownDataProvider } from 'shared/data-providers/dropdown-data-provider';
import CustomFilter from 'shared/components/custom-filter';
import { DATETIME_FORMAT } from 'consts';
import styles from './styles.module.scss';
import PopupPrint from 'shared/components/popup-print';
import { exportExportType1Options, exportExportType2Options } from 'consts/export-options';
import TextAreaFilter from 'shared/components/textarea-filter';
import { popupConfirmSaveBeforeNavigate } from 'utils/modals';
import SaleConfirmModal from '../sale-confirm-modal';
import { checkSaleCode } from 'services/sale';
import { linkSale } from 'services/sale';

interface IProps {
  id?: number;
  exportType?: 1 | 2;
  control: Control<any, any>;
  onSave: (path?: string) => void;
  onComplete: () => void;
  onRevert: () => void;
  editable: boolean;
  loaded: boolean;
  setValue: any;
  isValid: boolean;
  isDirty: boolean;
  exportExportDetailToPDF: (type: string | number) => void;
  refetch?: any;
}

const ExportDetailsForm = ({
  id,
  exportType,
  control,
  onSave,
  editable,
  onComplete,
  onRevert,
  loaded,
  isValid,
  setValue,
  exportExportDetailToPDF,
  refetch,
}: IProps) => {
  // this is a key
  const [x, setX] = useState(0);
  const navigate = useNavigate();
  const [showExportModal, setShowExportModal] = useState<boolean>(false);
  const [saleConfirmModal, setSaleConfirmModal] = useState(false);

  // disable group dropdown if there is any export_details created
  // from that group.
  const exportDetails = useWatch({ control, name: 'export_details' });
  const exportDetail = useWatch({ control, name: 'export_detail' });
  const businessContact = useWatch({ control, name: 'business_contact' });
  const memo = useWatch({ control, name: 'export_memo' });
  const groupId = useWatch({ control, name: 'group_id' });

  const confirmUserDataProvider = useRef(
    new DropdownDataProvider('users/dropdown/confirm_person', (item) => ({
      label: item.name,
      value: item.id,
    }))
  ).current;
  const picDataProvider = useRef(
    new DropdownDataProvider('users/dropdown/person_in_charge', (item) => ({
      label: item.name,
      value: item.id,
    }))
  ).current;
  const inputUserDataProvider = useRef(
    new DropdownDataProvider('users/dropdown/input_person', (item) => ({
      label: item.name,
      value: item.id,
    }))
  ).current;
  const groupDataProvider = useRef(
    new DropdownDataProvider('groups/dropdown', (item) => ({
      label: item.group_name,
      value: item.id,
    }))
  );
  const customerDataProvider = useRef(
    new DropdownDataProvider('customers/dropdown', (item) => ({
      label: item.hall_name ? item.hall_name : item.customer_name,
      value: item.id,
    }))
  );
  const groupIdRef = useRef<number | null>(null);

  const checkSaleCodeMutation = useMutation((code: string) => checkSaleCode(code), {
    onSettled: () => {
      setSaleConfirmModal(false);
    },
    onSuccess: (data) => {
      console.log('Response data: ', data);
      linkMutation.mutate(data.id);
    },
  });

  const linkMutation = useMutation((saleId: number) => linkSale('EXPORT', id!, saleId), {
    onSuccess: () => {
      refetch && refetch();
    },
  });

  useEffect(() => {
    if (groupId && loaded) {
      customerDataProvider.current = new DropdownDataProvider(
        `customers/dropdown?group_id=${groupId}`,
        (item) => ({
          label: item.hall_name ? item.hall_name : item.customer_name,
          value: item.id,
        })
      );
      if (groupIdRef.current) setValue('customer_id', undefined);
      setX(x + 1);
      groupIdRef.current = groupId;
    }
  }, [groupId]);

  const sumDeliveryFee = (deliveries: Array<any>) => {
    let sum = 0;
    deliveries.forEach((item) => (sum = sum + item.amount_fee + item.amount_fee_extra));
    return sum;
  };

  const showPopupPrint = () => {
    setShowExportModal(true);
  };

  const setTextAreaRows = () => {
    switch (window.outerWidth) {
      case 1920:
        return 8;
      case 1600:
        return 8;
      default:
        return 8;
    }
  };

  const onPlusClick = (dest: string) => {
    if (!control._formState.isDirty && id) {
      // user clicks navigate to deliveries or sales without editing.
      navigate(`/${dest}/create?target_type=EXPORT&target_id=${id}`);
      return;
    }

    popupConfirmSaveBeforeNavigate({
      onConfirm: () => onSave(`/${dest}/create?target_type=EXPORT`),
    });
  };

  return (
    <>
      {saleConfirmModal && (
        <SaleConfirmModal
          onClose={() => setSaleConfirmModal(false)}
          onDone={(type, saleCode) => {
            if (type === 1) onPlusClick('sales');
            else {
              checkSaleCodeMutation.mutate(saleCode!);
            }
          }}
        />
      )}
      <div className={styles.form}>
        {editable && (
          <button
            aria-label="registerBtn"
            className="secondary-btn"
            onClick={() => onSave()}
            disabled={!isValid}
          >
            登録
          </button>
        )}

        <div className={styles.group2}>
          <div className={styles.group20}>
            <div className={styles.exportCode}>
              <TextFilter label="出庫番号" control={control} keys={['export_code']} disabled />
            </div>

            <div className={styles.exportType}>
              <TextFilter label="出庫区分" control={control} keys={['export_type_text']} disabled />
              {id && (
                <button className="secondary-btn ms-2" onClick={showPopupPrint}>
                  印刷
                </button>
              )}
            </div>
          </div>

          <div className={styles.group21}>
            <div className={styles.inputDate}>
              <DateFilter
                label="入力日"
                control={control}
                keys={['input_date']}
                required
                disabled={!editable}
              />
            </div>

            <div className={styles.exportDate}>
              <DateFilter
                label="出庫日"
                control={control}
                keys={['export_date']}
                required
                disabled={!editable}
              />
            </div>
          </div>
          <div className={styles.group22}>
            <div className={styles.groupId}>
              <Controller
                control={control}
                name="group"
                render={({ field: { value } }) => (
                  <DynamicDropdownFilter
                    label="グループ"
                    dataProvider={groupDataProvider.current}
                    control={control}
                    keys={['group_id']}
                    disabled={!editable || exportDetails?.length}
                    required
                    fallbackValue={value ? { label: value.group_name, value: value.id } : undefined}
                  />
                )}
              />
            </div>

            <div className={styles.customerId}>
              <Controller
                control={control}
                name="customer"
                render={({ field: { value } }) => (
                  <DynamicDropdownFilter
                    key={x}
                    label="出庫先"
                    dataProvider={customerDataProvider.current}
                    control={control}
                    keys={['customer_id']}
                    disabled={!editable}
                    required
                    fallbackValue={
                      value
                        ? {
                            label: value.hall_name ? value.hall_name : value.customer_name,
                            value: value.id,
                          }
                        : undefined
                    }
                  />
                )}
              />
            </div>
          </div>
          <div className={styles.group23}>
            <div className={styles.deliveryCode}>
              <Controller
                control={control}
                name="deliveries"
                render={({ field }) => (
                  <CustomFilter
                    label="配送"
                    plus={editable && isValid}
                    onPlusClick={() => onPlusClick('deliveries')}
                    content={field.value?.map((item: any) => (
                      <>
                        <Link to={`/deliveries/${item.id}`} target="_blank">
                          {item.delivery_code}
                        </Link>
                        &ensp;
                      </>
                    ))}
                  />
                )}
              />
            </div>

            <div className={styles.deliveryFee}>
              <Controller
                control={control}
                name="deliveries"
                render={({ field }) => (
                  <CustomFilter
                    label="運賃"
                    content={field.value && sumDeliveryFee(field.value).toLocaleString('en-US')}
                  />
                )}
              />
            </div>

            <div className={styles.laborCost}>
              <TextFilter
                label="人工費"
                control={control}
                keys={['labor_cost']}
                disabled={!editable}
                type="number"
              />
            </div>
          </div>
          <div className={styles.group24}>
            <div className={styles.saleCode}>
              <Controller
                control={control}
                name="sales"
                render={({ field }) => (
                  <CustomFilter
                    label="売上"
                    plus={(field.value?.length === 0 || !field.value) && editable && isValid}
                    onPlusClick={() => {
                      setSaleConfirmModal(true);
                    }}
                    content={
                      field.value?.length > 0 && (
                        <Link to={`/sales/${field.value[0].id}`} target="_blank">
                          {field.value[0].sale_code}
                        </Link>
                      )
                    }
                  />
                )}
              />
            </div>

            <div className={styles.exportFee}>
              <TextFilter
                label="出庫手数料"
                control={control}
                keys={['export_fee']}
                disabled={!editable}
                type="number"
              />
            </div>
          </div>
        </div>

        <div className={styles.group3}>
          <div className={styles.updatedAt}>
            <Controller
              control={control}
              name="updated_at"
              render={({ field: { value } }) => (
                <CustomFilter
                  className="flex-grow-1"
                  label="修正日時"
                  content={value && moment(new Date(value)).format(DATETIME_FORMAT)}
                />
              )}
            />
          </div>

          <div className={styles.userPicId}>
            <Controller
              control={control}
              name="user_person_in_charge"
              render={({ field: { value } }) => (
                <DynamicDropdownFilter
                  label="担当者"
                  dataProvider={picDataProvider}
                  control={control}
                  keys={['user_pic_id']}
                  disabled={!editable}
                  fallbackValue={value != null ? { label: value.name, value: value.id } : undefined}
                />
              )}
            />
          </div>

          <div className={styles.userInputId}>
            <Controller
              control={control}
              name="user_input_person"
              render={({ field: { value } }) => (
                <DynamicDropdownFilter
                  label="入力者"
                  dataProvider={inputUserDataProvider}
                  control={control}
                  keys={['user_input_id']}
                  disabled={!editable}
                  required
                  fallbackValue={value != null ? { label: value.name, value: value.id } : undefined}
                />
              )}
            />
          </div>

          <div className={styles.userConfirmId}>
            <Controller
              control={control}
              name="user_confirm_person"
              render={({ field: { value } }) => (
                <DynamicDropdownFilter
                  label="確認者"
                  dataProvider={confirmUserDataProvider}
                  control={control}
                  keys={['user_confirm_id']}
                  disabled={!editable}
                  fallbackValue={value != null ? { label: value.name, value: value.id } : undefined}
                />
              )}
            />
          </div>

          <div className={styles.complete}>
            <div style={{ flex: 1 }}>
              <Controller
                control={control}
                name="complete"
                render={({ field }) => (
                  <CustomFilter
                    label="完了"
                    content={field.value != null && (field.value === 1 ? '完了' : '未完了')}
                  />
                )}
              />
            </div>
            {id && editable && (
              <button className="secondary-btn ms-2" onClick={onComplete}>
                完了
              </button>
            )}
            {id && !editable && (
              <button className="secondary-btn ms-2" onClick={onRevert}>
                巻き戻し
              </button>
            )}
          </div>
        </div>
        <div className={styles.group4}>
          <div className={styles.exportDetail}>
            <TextAreaFilter
              label="詳細"
              value={exportDetail}
              onChange={(event) => {
                setValue('export_detail', event.target.value, { shouldDirty: true });
              }}
              disabled={!editable}
              rows={setTextAreaRows()}
            />
          </div>

          <div className={styles.contact}>
            <TextAreaFilter
              label="業務連絡"
              value={businessContact}
              onChange={(event) => {
                setValue('business_contact', event.target.value, { shouldDirty: true });
              }}
              disabled={!editable}
              rows={setTextAreaRows()}
            />
          </div>

          <div className={styles.memo}>
            <TextAreaFilter
              label="メモ"
              value={memo}
              onChange={(event) => {
                setValue('export_memo', event.target.value, { shouldDirty: true });
              }}
              disabled={!editable}
              rows={setTextAreaRows()}
            />
          </div>
        </div>
        <div className={styles.group6}>
          <div className={styles.exportDetails}>
            <Controller
              control={control}
              name="export_details"
              render={({ field: { value } }) => (
                <CustomFilter label="出庫数" content={value && value.length} />
              )}
            />
          </div>

          <div className={styles.code}>
            <Controller
              control={control}
              name="export_details"
              render={({ field: { value } }) => {
                if (value) {
                  let temp: any = {};
                  for (let item of value) {
                    if (item.device_type_code) {
                      if (temp[item.device_type_code]) temp[item.device_type_code]++;
                      else temp[item.device_type_code] = 1;
                    }
                  }
                  return (
                    <div className="d-flex flex-wrap">
                      {Object.keys(temp).map((key) => (
                        <CustomFilter
                          key={key}
                          label={key}
                          content={
                            <div style={{ minWidth: 50, textAlign: 'center' }}>{temp[key]}</div>
                          }
                        />
                      ))}
                    </div>
                  );
                }
                return <></>;
              }}
            />
          </div>
        </div>

        <div className={styles.qrButton}>
          <button
            className="primary-btn"
            onClick={() => {
              if (control._formState.isDirty === false) {
                navigate('qr');
              } else {
                popupConfirmSaveBeforeNavigate({
                  onConfirm: () => onSave('qr'),
                });
              }
            }}
          >
            QRファイル
          </button>
        </div>
      </div>

      {showExportModal && (
        <PopupPrint
          title="印刷選択モーダル"
          options={exportType === 1 ? exportExportType1Options : exportExportType2Options}
          actions={[
            { label: '閉じる', callback: () => setShowExportModal(false) },
            {
              label: '印刷',
              callback: (option: string | number) => {
                exportExportDetailToPDF(option as string);
                setShowExportModal(false);
              },
            },
          ]}
        />
      )}
    </>
  );
};

export default ExportDetailsForm;
