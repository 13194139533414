// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_datePicker__kxNXB {\n  border: 1px solid lightgray;\n  border-radius: 0.25rem;\n  width: 100%;\n  line-height: 2em;\n  font-size: 1rem;\n  padding: 0 0.5em;\n}\n\n.styles_group__TS8bk {\n  display: flex;\n  align-items: center;\n  margin: 0.5em 0;\n}\n.styles_group__TS8bk label {\n  margin: 0 0 0 0;\n}\n.styles_group__TS8bk input {\n  width: 75%;\n  margin: 0 0 0 auto;\n}", "",{"version":3,"sources":["webpack://./src/screens/consumption-tax/edit-modal/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,2BAAA;EACA,sBAAA;EACA,WAAA;EACA,gBAAA;EACA,eAAA;EACA,gBAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,eAAA;AACF;AACE;EACE,eAAA;AACJ;AAEE;EACE,UAAA;EACA,kBAAA;AAAJ","sourcesContent":[".datePicker {\n  border: 1px solid lightgray;\n  border-radius: 0.25rem;\n  width: 100%;\n  line-height: 2em;\n  font-size: 1rem;\n  padding: 0 0.5em;\n}\n\n.group {\n  display: flex;\n  align-items: center;\n  margin: 0.5em 0;\n\n  label {\n    margin: 0 0 0 0;\n  }\n\n  input {\n    width: 75%;\n    margin: 0 0 0 auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"datePicker": "styles_datePicker__kxNXB",
	"group": "styles_group__TS8bk"
};
export default ___CSS_LOADER_EXPORT___;
