import { useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import { UseFormSetValue } from 'react-hook-form';
import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/ai';
import { BsPlusCircle } from 'react-icons/bs';
import { IoCloseCircleOutline } from 'react-icons/io5';

import DetailTable from 'shared/components/detail-table';
import useEditableTable from 'hooks/use-editable-table';
import { Export, ExportDetail } from 'types/export';
import StockSelectModal from '../stock-select-modal';
import { IStockExportFilter } from 'types/stock-export';
import { SelectDataProvider } from 'shared/data-providers/select-data-provider';
import styles from './styles.module.scss';

interface IProps {
  initialRows?: Array<ExportDetail>;
  editable: boolean;
  setValue: UseFormSetValue<Partial<Export>>;
  groupId?: number;
  exportDate?: string;
}

const ExportDetailsTable = ({ initialRows, editable, setValue, groupId, exportDate }: IProps) => {
  const [stockModal, setStockModal] = useState(false);
  const dataProvider = useRef(
    new SelectDataProvider<IStockExportFilter>(
      'stocks/modal/for-export',
      undefined,
      undefined,
      'id',
      'stock_id',
      2000
    )
  ).current;

  const { rows, updateAllRow, removeRow, swap, changeSource } = useEditableTable<any>(initialRows);
  const didMount = useRef<any>(false);

  useEffect(() => {
    if (changeSource.current === 'in' || !didMount.current) {
      setValue('export_details', rows, { shouldDirty: true });
      if (dataProvider) {
        dataProvider.reset();
        // set data back to dataProvider's SelectedList
        // whenever rows state changes (delete, swap, ...)
        rows.forEach((item: ExportDetail) => {
          if (item.export_stock_type === 'FULL' && item.stock_id) {
            dataProvider.removedList.push({ id: item.stock_id });
          } else {
            dataProvider.colorTrackers.push({
              id: item.stock_id as number,
              type: item.export_stock_type as any,
            });
          }
          dataProvider.selectedList.push(item);
        });
      }
      didMount.current = true;
    }
  }, [rows, dataProvider]);

  useEffect(() => {
    dataProvider.setFilters({ group_id: groupId });
  }, [groupId]);

  const renderRow = (item: Partial<ExportDetail>, index: number) => {
    const fillColor = () => {
      if (item.qr) {
        if (!item.export_qr_detail) {
          return 'rgb(248, 241, 174)'; // yellow
        }
        const isMatched =
          item.frame_number === item.export_qr_detail.frame_number ||
          item.cell_number === item.export_qr_detail.cell_number ||
          item.base_number === item.export_qr_detail.base_number;
        if (isMatched) {
          return 'rgb(211, 238, 255)'; // blue
        } else {
          return 'rgb(255 ,153, 151)'; // red
        }
      }

      return 'transparent';
    };

    const upDownArrow = editable && (
      <div className="d-flex flex-column align-items-center justify-content-center">
        <AiFillCaretUp className="arrow-icon" onClick={() => swap(index, 'up')} />
        <AiFillCaretDown className="arrow-icon" onClick={() => swap(index, 'down')} />
      </div>
    );
    const deviceTypeCode = item.device_type_code;
    const makerName = item.product?.maker?.maker_name;
    const modelName = item.product?.model_name;
    const qrCheck = <Form.Check className="custom-checkbox" checked={!!item.qr} disabled={true} />;
    const cellNumber = (
      <div className={styles.cell}>
        <div>{item.cell_number}</div>
        <span style={{ backgroundColor: `${fillColor()}` }}></span>
      </div>
    );
    const frameNumber = (
      <div className={styles.frame}>
        <div>{item.frame_number}</div>
        <span style={{ backgroundColor: `${fillColor()}` }}></span>
      </div>
    );
    const baseNumber = (
      <div className={styles.base}>
        <div>{item.base_number}</div>
        <span style={{ backgroundColor: `${fillColor()}` }}></span>
      </div>
    );
    const palletCode = item.pallet_code;
    const note = item.export_detail_note;
    const detailOfDetail = item.export_detail_detail;
    const memo = item.export_detail_memo;
    const destination = item.destination;
    const frameColor = item.frame_color;
    const removeIcon = editable && (
      <IoCloseCircleOutline size={25} className="delete-icon" onClick={() => removeRow(index)} />
    );

    const tempRows = [
      upDownArrow,
      deviceTypeCode,
      makerName,
      modelName,
      qrCheck,
      cellNumber,
      frameNumber,
      baseNumber,
      palletCode,
      note,
      detailOfDetail,
      destination,
      memo,
      frameColor,
      removeIcon,
    ];

    return tempRows;
  };

  const headers: Array<any> = [
    { label: '' },
    { label: '種別' },
    { label: 'メーカー' },
    { label: '型式名' },
    { label: 'QR' },
    { label: 'セル番号' },
    { label: '枠番号' },
    { label: '基盤番号' },
    { label: '在庫場所' },
    { label: '備考' },
    { label: '詳細' },
    { label: '納品先' },
    { label: 'メモ' },
    { label: '枠色' },
    {
      label: (
        <>
          {editable && (
            <button
              onClick={() => {
                if (groupId) setStockModal(true);
              }}
            >
              <BsPlusCircle size={15} style={{ color: 'white' }} />
            </button>
          )}
        </>
      ),
    },
  ];

  return (
    <>
      <div className={styles.tableWrapper}>
        <DetailTable<Partial<ExportDetail>> headers={headers} data={rows} renderRow={renderRow} />
      </div>
      {stockModal && (
        <StockSelectModal
          dataProvider={dataProvider}
          groupId={groupId}
          exportDate={exportDate}
          onClose={() => setStockModal(false)}
          onConfirm={(data) => {
            updateAllRow(data);
            setStockModal(false);
          }}
        />
      )}
    </>
  );
};

export default ExportDetailsTable;
