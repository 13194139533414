import { useEffect, useRef, useState } from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { useMutation, useQuery } from 'react-query';
import { Link } from 'react-router-dom';

import { MONTHS, YEARS, currentMonthOption, currentYearOption } from 'consts';
import useStores from 'hooks/use-stores';
import { getLedgerInfo } from 'services/report';
import { updateUnrestrictedWithdrawal, updateWithdrawal } from 'services/withdrawal';
import DataTable from 'shared/components/data-table';
import YearMonthSelect from 'shared/components/year-month-select';
import { DataProvider } from 'shared/data-providers/table-data-provider';
import SessionStore from 'stores/session';
import UIStore from 'stores/ui';
import { LedgerInfo } from 'types/ledger';
import { WithdrawalCustomer } from 'types/withdrawal';
import EditModal from './edit-modal';

import styles from './styles.module.scss';
import EditAllModal from './edit-all-modal';

const WithdrawalScreen = () => {
  const uiStore: UIStore = useStores().uiStore;
  const sessionStore: SessionStore = useStores().sessionStore;

  const [didMount, setDidMount] = useState<boolean>(false);
  const [month, setMonth] = useState<any>(currentMonthOption);
  const [year, setYear] = useState<any>(currentYearOption);
  const [editModal, setEditModal] = useState<{
    withdrawalCustomer: WithdrawalCustomer | null;
    isShow: boolean;
  }>({
    withdrawalCustomer: null,
    isShow: false,
  });
  const [editAllModal, setEditAllModal] = useState(false);
  const [completed, setCompleted] = useState<boolean>(false);

  const dataProvider = useRef(
    new DataProvider({ dataSource: `withdrawals`, localStorageKey: 'withdrawal_filter' })
  );

  const ledgerQueryData = useQuery<LedgerInfo>(
    ['ledger', month.value, year.value],
    () =>
      getLedgerInfo({
        month: month.value,
        year: year.value,
      }),
    {
      enabled: false,
      onSuccess: (data) => setCompleted(data.complete === 1),
      onError: (_err) => setCompleted(false),
    }
  );

  const updateWithdrawalMutation = useMutation((payload: any) => updateWithdrawal(payload), {
    onMutate: () => uiStore.showLoading(),
    onSettled: () => uiStore.hideLoading(),
    onSuccess: (_data) => {
      setEditModal({
        isShow: false,
        withdrawalCustomer: null,
      });
      dataProvider.current.setFilters({ month: month.value, year: year.value }, true);
    },
  });

  const updateUnrestrictedWithdrawalMutation = useMutation(
    (payload: any) => updateUnrestrictedWithdrawal(payload),
    {
      onMutate: () => uiStore.showLoading(),
      onSettled: () => uiStore.hideLoading(),
      onSuccess: (_data) => {
        setEditAllModal(false);
        dataProvider.current.setFilters({ month: month.value, year: year.value }, true);
      },
    }
  );

  const headers = [
    { label: '顧客CD' },
    { label: '顧客' },
    { label: '前回買掛額' },
    { label: '出金額' },
    { label: '調整額' },
    { label: '当月残' },
    { label: '' },
  ];

  const delegate = (item: WithdrawalCustomer) => {
    let code = item.customer.customer_code;
    let name = `${
      item.customer?.customer_name +
      (item.customer?.hall_name ? '　' + item.customer?.hall_name : '')
    }`;
    let totalAmountRequest = item.total_amount_request; // purchase
    let withdrawalAmount = item.withdrawal_amount;
    let adjustAmount = item.adjust_amount;
    let remain = item.request_balance;
    let registerButton = (
      <div className={styles.flexCenter}>
        <button
          aria-label="registerBtn"
          onClick={(_event: any) => {
            setEditModal({
              isShow: true,
              withdrawalCustomer: item,
            });
          }}
        >
          出金
        </button>
      </div>
    );

    return [
      code,
      name,
      totalAmountRequest.toLocaleString('en-US'),
      withdrawalAmount.toLocaleString('en-US'),
      adjustAmount.toLocaleString('en-US'),
      remain.toLocaleString('en-US'),
      registerButton,
    ];
  };

  const searchWithdrawals = (_event: any) => {
    ledgerQueryData.refetch();

    const filterOption: any = {
      month: month.value,
      year: year.value,
    };

    // search results 1 month prior
    // sales & purchases paid this month happened a month prior.
    dataProvider.current.setFilters(filterOption);
    setDidMount(true);
  };

  const onMonthChange = (newValue: any) => {
    setMonth(newValue);
    sessionStore.setWithdrawalDate({
      month: newValue.value,
      year: year.value,
    });
  };

  const onYearChange = (newValue: any) => {
    setYear(newValue);
    sessionStore.setWithdrawalDate({
      month: month.value,
      year: newValue.value,
    });
  };

  useEffect(() => {
    let filterOption: any = {};
    if (sessionStore.withdrawalDate) {
      setYear({
        label: sessionStore.withdrawalDate.year,
        value: sessionStore.withdrawalDate.year,
      });
      setMonth({
        label: sessionStore.withdrawalDate.month,
        value: sessionStore.withdrawalDate.month,
      });
      filterOption = {
        year: sessionStore.withdrawalDate.year,
        month: sessionStore.withdrawalDate.month,
      };
    } else {
      filterOption = {
        year: year.value,
        month: month.value,
      };
    }
    dataProvider.current.setFilters(filterOption);
  }, []);

  return (
    <div className={styles.container}>
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/home' }}>
          TOP
        </Breadcrumb.Item>
        <Breadcrumb.Item active>入出金</Breadcrumb.Item>
        <Breadcrumb.Item active>出金</Breadcrumb.Item>
      </Breadcrumb>

      <div className={styles.selectGroup}>
        <YearMonthSelect
          label="処理年月"
          monthOptions={MONTHS}
          yearOptions={YEARS}
          yearValue={year}
          monthValue={month}
          dropdownStyle={styles.dropdown}
          onMonthChange={onMonthChange}
          onYearChange={onYearChange}
          maxMenuHeight={{ month: 500, year: undefined }}
        />

        <button aria-label="search" onClick={searchWithdrawals} className="secondary-btn">
          検索
        </button>
      </div>

      <div className={styles.tableWrapper}>
        {didMount && (
          <>
            <button
              onClick={(_event: any) => {
                // setEditModal({ isShow: true, depositCustomer: null });
                setEditAllModal(true);
              }}
              style={{
                color: '#008060',
                border: '1px solid #008060',
                backgroundColor: 'white',
                padding: '0.15rem 0.5rem',
                fontWeight: 'bold',
                borderRadius: '0.25rem',
                position: 'absolute',
                right: 0,
                top: 0,
                zIndex: 1000,
              }}
            >
              出金
            </button>
            <DataTable headers={headers} delegate={delegate} dataProvider={dataProvider.current} />
          </>
        )}
      </div>

      {editModal.isShow && (
        <EditModal
          completed={completed}
          withdrawalCustomer={editModal.withdrawalCustomer as WithdrawalCustomer}
          date={{
            year: year.value,
            month: month.value,
          }}
          onClose={() => {
            setEditModal({
              isShow: false,
              withdrawalCustomer: null,
            });
          }}
          onUpdate={(payload: any) => updateWithdrawalMutation.mutate(payload)}
        />
      )}

      {editAllModal && (
        <EditAllModal
          completed={completed}
          // withdrawalCustomer={editModal.withdrawalCustomer as WithdrawalCustomer}
          date={{
            year: year.value,
            month: month.value,
          }}
          onClose={() => {
            setEditAllModal(false);
          }}
          onUpdate={(payload: any) => updateUnrestrictedWithdrawalMutation.mutate(payload)}
        />
      )}
    </div>
  );
};

export default WithdrawalScreen;
