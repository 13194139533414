// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_customHeader__c7SjN {\n  display: flex !important;\n  align-items: center;\n  justify-content: space-between;\n  font-size: 1.25em;\n  padding: 0 1em;\n}\n.styles_customHeader__c7SjN span {\n  color: #444;\n  font-weight: bold;\n}\n.styles_customHeader__c7SjN span:first-of-type,\n.styles_customHeader__c7SjN span:last-of-type {\n  cursor: pointer;\n}\n.styles_customHeader__c7SjN span:nth-of-type(2) {\n  cursor: context-menu;\n}\n\n.styles_popper__qG43d,\n.styles_calender__kCso8,\n.styles_wrapper__0k4a-,\n.styles_day__95wTI {\n  cursor: context-menu;\n}", "",{"version":3,"sources":["webpack://./src/shared/components/datepicker-wrapper/styles.module.scss","webpack://./src/assets/styles/_variables.scss"],"names":[],"mappings":"AAEA;EACE,wBAAA;EACA,mBAAA;EACA,8BAAA;EACA,iBAAA;EACA,cAAA;AADF;AAGE;EACE,WCNQ;EDOR,iBAAA;AADJ;AAIE;;EAEE,eAAA;AAFJ;AAKE;EACE,oBAAA;AAHJ;;AAOA;;;;EAIE,oBAAA;AAJF","sourcesContent":["@use '../../../assets/styles/variables' as *;\n\n.customHeader {\n  display: flex !important;\n  align-items: center;\n  justify-content: space-between;\n  font-size: 1.25em;\n  padding: 0 1em;\n\n  span {\n    color: $grayColor;\n    font-weight: bold;\n  }\n\n  span:first-of-type,\n  span:last-of-type {\n    cursor: pointer;\n  }\n\n  span:nth-of-type(2) {\n    cursor: context-menu;\n  }\n}\n\n.popper,\n.calender,\n.wrapper,\n.day {\n  cursor: context-menu;\n}\n","$primaryColor: #008060;\n$lightGreenColor: #d2ecd0;\n$darkGreenColor: #02644c;\n$lightMagentaColor: #d0cee2;\n$grayColor: #444;\n$lightBlueColor: #d3d8de;\n$lightGrayColor: lightgray;\n$deleteButtonColor: #e55451;\n\n$whiteColor: #fff;\n$blackColor: #000;\n$blueColor: #0d6efd;\n\n$importColColor: #ffff88;\n$exportColColor: #cce5ff;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"customHeader": "styles_customHeader__c7SjN",
	"popper": "styles_popper__qG43d",
	"calender": "styles_calender__kCso8",
	"wrapper": "styles_wrapper__0k4a-",
	"day": "styles_day__95wTI"
};
export default ___CSS_LOADER_EXPORT___;
