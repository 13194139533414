import { observer } from 'mobx-react';

import styles from './styles.module.scss';
import { IStockExportFilter } from 'types/stock-export';
import { ExportDetail } from 'types/export';
import { SelectDataProvider } from 'shared/data-providers/select-data-provider';
import StockTable from './stock-table';
import ExportTable from './export-table';
import StockFilter from './stock-filter';

interface IProps {
  dataProvider: SelectDataProvider<IStockExportFilter>;
  groupId?: number;
  exportDate?: string;
  onClose: () => void;
  onConfirm: (data: Array<ExportDetail>) => void;
}

const StockSelectModal = observer(({ dataProvider, groupId, exportDate, onClose, onConfirm }: IProps) => {
  const onMouseDown = () => {
    document.addEventListener('mousemove', mouseMoveHandler);
    document.addEventListener('mouseup', mouseUpHandler);
  };

  const mouseMoveHandler = (event: any) => {
    const clientX = event.clientX;
    const stockWrapper = document.getElementById('stock-table-wrapper');
    const exportWrapper = document.getElementById('export-table-wrapper');

    const middleMargin = 16;

    const newStockWidth = clientX - middleMargin;
    const newExportWidth = window.innerWidth - clientX - middleMargin;
    const stockMaxWidth = (window.innerWidth * 3) / 4;
    const exportMaxWidth = (window.innerWidth * 3) / 4;
    if (newStockWidth >= stockMaxWidth || newExportWidth >= exportMaxWidth) return;

    (stockWrapper as HTMLElement).style.width = `${newStockWidth}px`;
    (exportWrapper as HTMLElement).style.width = `${newExportWidth}px`;
  };

  const mouseUpHandler = (_event: any) => {
    // Remove the handlers of `mousemove` and `mouseup`
    document.removeEventListener('mousemove', mouseMoveHandler);
    document.removeEventListener('mouseup', mouseUpHandler);
  };

  const Footer = () => {
    return (
      <div className={styles.footer}>
        <button className={styles.closeBtn} onClick={onClose}>
          閉じる
        </button>
      </div>
    );
  };

  const Header = () => {
    return (
      <div className={styles.header}>
        <h2>在庫選択モーダル</h2>
        <button className="secondary-btn" onClick={() => onConfirm(dataProvider.selectedList)}>
          登録
        </button>
      </div>
    );
  };

  return (
    <div className={styles.backdrop}>
      <div className={styles.container}>
        <Header />

        <div className={styles.content}>
          <div className={styles.filterWrapper}>
            <StockFilter dataProvider={dataProvider} groupId={groupId} />
          </div>

          <div className={styles.tableContainer}>
            <div className={styles.tableWrapper} id="stock-table-wrapper">
              <StockTable dataProvider={dataProvider} exportDate={exportDate} />
            </div>
            <div className={styles.resizer} onMouseDown={onMouseDown}></div>
            <div className={styles.tableWrapper} id="export-table-wrapper">
              <ExportTable dataProvider={dataProvider} />
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
});

export default StockSelectModal;
