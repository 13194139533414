// Built-in
import { useEffect, useState } from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';

// Component
import QuotationDetailContainer from 'shared/containers/quotation-details';

// Service
import { getQuotationById } from 'services/quotation';

// Hook
import { useQueryParams } from 'hooks/use-query-params';
import { removeKeys } from 'utils';
import { v1 } from 'uuid';

const CreateQuotationScreen = () => {
  const query = useQueryParams();
  const duplicateIdStr = query.get('id');
  const duplicateId = (() => {
    if (!duplicateIdStr) return undefined;
    if (isNaN(parseInt(duplicateIdStr))) return undefined;
    return parseInt(duplicateIdStr);
  })();

  const [initialValues, setInitialValues] = useState(undefined);
  const [loaded, setLoaded] = useState(false);

  useQuery(['quotation', duplicateId], () => getQuotationById(duplicateId as number), {
    enabled: !!duplicateId,
    onSuccess: (data) => {
      const newQuotation = JSON.parse(JSON.stringify(data));
      newQuotation.quotation_details = data.quotation_details.map((old: any) => {
        const newQuotationDetail = JSON.parse(JSON.stringify(old));
        newQuotationDetail.id = v1();
        removeKeys(newQuotationDetail, ['updated_at', 'created_at', 'deleted_at', 'quotation_id']);
        return newQuotationDetail;
      });

      removeKeys(newQuotation, [
        'quotation_code',
        'updated_at',
        'created_at',
        'deleted_at',
        'id',
        'purchase_posted',
        'purchase_posted_text',
      ]);

      setInitialValues(newQuotation);
      setLoaded(true);
    },
  });

  useEffect(() => {
    if (!duplicateId) setLoaded(true);
  }, []);

  return (
    <div id="quotation-details-screen">
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/home' }}>
          TOP
        </Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/quotations' }}>
          見積
        </Breadcrumb.Item>
        <Breadcrumb.Item active>見積詳細</Breadcrumb.Item>
      </Breadcrumb>
      {loaded && <QuotationDetailContainer initialValues={initialValues} />}
      <div id="dynamic-dropdown-menu-portal"></div>
    </div>
  );
};

export default CreateQuotationScreen;
