import { Table } from 'react-bootstrap';
import styles from './styles.module.scss';
import { SelectDataProvider } from 'shared/data-providers/select-data-provider';
import { ExportDetail } from 'types/export';
import { IStockExportFilter } from 'types/stock-export';
import { observer } from 'mobx-react';
import { IoCloseCircleOutline } from 'react-icons/io5';

interface IProps {
  dataProvider: SelectDataProvider<IStockExportFilter>;
}

const ExportTable = observer(({ dataProvider }: IProps) => {
  const headers = [
    { label: 'No' },
    { label: '種別' },
    { label: 'メーカー' },
    { label: '型式名' },
    { label: '枠番号' },
    { label: 'セル番号' },
    { label: '基盤番号' },
    { label: '在庫場所' },
    { label: '備考' },
    { label: '詳細' },
    { label: '納品先' },
    { label: 'メモ' },
    { label: '枠色' },
    { label: '' },
  ];

  const unSelectStock = (item: ExportDetail) => {
    dataProvider.unSelectItem(item);
  };

  return (
    <Table bordered hover className={styles.exportDetailTable}>
      <thead>
        <tr>
          {headers.map((header, index) => (
            <th key={index}>
              <div>{header.label}</div>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {dataProvider.selectedList.map((item: ExportDetail, index) => (
          <tr key={index}>
            <td></td>
            <td className={styles.onelineStyle}>{item.device_type_code}</td>
            <td>{item.product?.maker?.maker_name}</td>
            <td>{item.product?.model_name}</td>
            <td>{item.frame_number}</td>
            <td>{item.cell_number}</td>
            <td>{item.base_number}</td>
            <td>{item.pallet_code}</td>
            <td>{item.export_detail_note}</td>
            <td>{item.export_detail_detail}</td>
            <td>{item.destination}</td>
            <td>{item.export_detail_memo}</td>
            <td>{item.frame_color}</td>
            <td>
              <IoCloseCircleOutline
                size={15}
                onClick={() => unSelectStock(item)}
                style={{ transform: 'scale(1.5)' }}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
});

export default ExportTable;
