import { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Control, Controller, UseFormSetValue, useFormState, useWatch } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import moment from 'moment';

import PurchaseDetailsTable from './table';
import TextFilter from 'shared/components/text-filter';
import CustomFilter from 'shared/components/custom-filter';
import DateFilter from 'shared/components/date-filter';
import StaticDropdownFilter from 'shared/components/static-dropdown-filter';
import CustomerFilter from 'shared/components/customer-filter';
import DynamicDropdownFilter from 'shared/components/dynamic-dropdown-filter';
import { getCustomerDetails } from 'services/customer';
import { Purchase } from 'types/purchase';
import { DATETIME_FORMAT } from 'consts';
import PopupPrint from 'shared/components/popup-print';
import { purchaseExportOptions } from 'consts/export-options';
import styles from './styles.module.scss';
import TextAreaFilter from 'shared/components/textarea-filter';
import { DropdownDataProvider } from 'shared/data-providers/dropdown-data-provider';
import { popupConfirmSaveBeforeNavigate } from 'utils/modals';
import { getUserInfo } from 'services/user';

interface IProps {
  id?: number;
  control: Control<Partial<Purchase>, any>;
  onSave: (path?: string) => void;
  setValue: UseFormSetValue<Partial<Purchase>>;
  editable: boolean;
  exportPurchaseFormInPDF: (payload: { type: string; byCustomer: number }) => void;
}

const PurchaseDetailsForm = ({
  id,
  control,
  onSave,
  setValue,
  editable,
  exportPurchaseFormInPDF,
}: IProps) => {
  const { isValid } = useFormState({ control });
  const [showExportModal, setShowExportModal] = useState<boolean>(false);
  const navigate = useNavigate();

  const picUser = useWatch({ control, name: 'user_person_in_charge' });
  const inputUser = useWatch({ control, name: 'user_input_person' });
  const confirmUser = useWatch({ control, name: 'user_confirm_person' });
  const customerId = useWatch({ control, name: 'customer_id' });
  const purchaseType = useWatch({ control, name: 'purchase_type' });
  const customer = useWatch({ control, name: 'customer' });
  const deliveries = useWatch({ control, name: 'deliveries' });
  const imports = useWatch({ control, name: 'imports' });
  const memo = useWatch({ control, name: 'purchase_memo' });
  const purchaseDetail = useWatch({ control, name: 'purchase_detail' });
  const businessContact = useWatch({ control, name: 'business_contact' });
  const picUserId = useWatch({ control, name: 'user_pic_id' });
  const purchaseDate = useWatch({ control, name: 'purchase_date' });

  const confirmUserDataProvider = useRef(
    new DropdownDataProvider('users/dropdown/confirm_person', (item) => ({
      label: item.name,
      value: item.id,
    }))
  ).current;
  const picDataProvider = useRef(
    new DropdownDataProvider('users/dropdown/person_in_charge', (item) => ({
      label: item.name,
      value: item.id,
    }))
  ).current;
  const inputUserDataProvider = useRef(
    new DropdownDataProvider('users/dropdown/input_person', (item) => ({
      label: item.name,
      value: item.id,
    }))
  ).current;

  const customerQuery = useQuery(['customer', customerId], () => getCustomerDetails(customerId!), {
    enabled: false,
    keepPreviousData: true,
    onSuccess: (customer) => {
      if (!customer.pic_id) customer.pic_name = undefined;
      setValue('customer', customer);
      // feedback UI: 125
      // only do this if picUserId isn't set.
      if (control._formState.isDirty && !picUserId && customer.pic_id) {
        userPicQuery.mutate(customer.pic_id);
      }
      // set memo from customer_memo
      if (control._formState.isDirty) {
        setValue('purchase_memo', customer.customer_memo ? customer.customer_memo: "", { shouldDirty: true });
      }
    },
  });

  const userPicQuery = useMutation((id: number) => getUserInfo(id), {
    onSuccess: (user) => {
      if (user.user_types.PERSON_IN_CHARGE === 1) {
        setValue('user_pic_id', user.id);
      }
    },
  });

  const sumDeliveryFee = (deliveries: Array<any>) => {
    let sum = 0;
    deliveries.forEach((item) => (sum = sum + item.amount_fee + (item.amount_fee_extra || 0)));
    return sum;
  };

  const openExportModal = (_event: any) => {
    setShowExportModal(true);
  };

  const exportForm = (payload: any) => {
    const { type, byCustomer } = payload;

    exportPurchaseFormInPDF({ type, byCustomer });
    setShowExportModal(false);
  };

  const onPlusClick = (dest: string) => {
    if (!control._formState.isDirty && id) {
      navigate(dest + id);
      return;
    }
    popupConfirmSaveBeforeNavigate({
      onConfirm: () => onSave(dest),
    });
  };

  useEffect(() => {
    if (customerId) customerQuery.refetch();
    else {
      setValue('customer.hall_name', undefined);
      setValue('customer', undefined);
    }
  }, [customerId]);

  useEffect(() => {
    setValue('aggregation_date', purchaseDate, { shouldDirty: true });
  }, [purchaseDate]);

  return (
    <>
      <div className={styles.form}>
        {editable && (
          <button
            aria-label="registerBtn"
            className="secondary-btn ms-2"
            onClick={() => onSave()}
            disabled={!isValid}
          >
            登録
          </button>
        )}

        <div className={styles.group2}>
          <div className={styles.group21}>
            <div className={styles.purchaseCode}>
              <TextFilter label="買取番号" control={control} keys={['purchase_code']} disabled />
            </div>
            {id && (
              <button className="secondary-btn ms-2" onClick={openExportModal}>
                印刷
              </button>
            )}
          </div>
          <div className={styles.group22}>
            <div className={styles.inputDate}>
              <DateFilter
                label="入力日"
                control={control}
                keys={['input_date']}
                disabled={!editable}
                required
              />
            </div>

            <div className={styles.purchaseDate}>
              <DateFilter
                label="買取日"
                control={control}
                keys={['purchase_date']}
                disabled={!editable}
              />
            </div>

            <div className={styles.purchaseType}>
              <StaticDropdownFilter
                label="買取区分"
                control={control}
                keys={['purchase_type']}
                options={[
                  { label: '引取', value: 1 },
                  { label: '仕入', value: 2 },
                ]}
                disabled={!editable}
                isClearable={false}
                required
              />
            </div>
          </div>

          <div className={styles.group23}>
            <div className={styles.customerId}>
              <CustomerFilter
                label="顧客"
                control={control}
                keys={['customer_id']}
                required
                disabled={!editable}
                fallbackValue={customer}
              />
            </div>
          </div>

          <div className={styles.group24}>
            <div className={styles.address}>
              <TextFilter label="住所" control={control} keys={['customer.address']} disabled />
            </div>
            <div className={styles.picNameHallName}>
              <div style={{ flex: 3 }}>
                <TextFilter
                  label="顧客担当者"
                  control={control}
                  keys={['customer.pic_name']}
                  disabled
                />
              </div>
            </div>
          </div>

          <div className={styles.group25}>
            <div className={styles.tel}>
              <TextFilter label="TEL" control={control} keys={['customer.tel']} disabled />
            </div>

            <div className={styles.fax}>
              <TextFilter label="FAX" control={control} keys={['customer.fax']} disabled />
            </div>
          </div>

          <div className={styles.group26}>
            <div className={styles.import}>
              {purchaseType === 1 && (
                <Controller
                  control={control}
                  name="imports"
                  render={({ field: { value } }) => (
                    <>
                      <CustomFilter
                        label="入庫"
                        plus={editable && value?.length === 0 && isValid}
                        onPlusClick={() => onPlusClick('/imports/create?purchase_id=')}
                        content={
                          value &&
                          value?.length > 0 && (
                            <Link to={`/imports/${value[0].id}`} target="_blank">
                              {value[0].import_code}
                            </Link>
                        )
                        }
                      />
                    </>
                  )}
                />
              )}
            </div>

            <div className={styles.export}>
              <Controller
                control={control}
                name="deliveries"
                render={({ field: { value } }) => (
                  <>
                    <CustomFilter
                      label="配送"
                      plus={editable && isValid}
                      onPlusClick={() =>
                        onPlusClick(`/deliveries/create?target_type=PURCHASE&target_id=`)
                      }
                      content={value?.map((item: any) => (
                        <>
                          <Link to={`/deliveries/${item.id}`} target="_blank">
                            {item.delivery_code}
                          </Link>
                          &ensp;
                        </>
                      ))}
                    />
                  </>
                )}
              />
            </div>
          </div>

          <div className={styles.group27}>
            <div className={styles.importCommission}>
              {purchaseType === 1 && (
                <CustomFilter
                  label="作業料"
                  content={imports && imports.length > 0 && imports[0].import_fee}
                />
              )}
            </div>

            <div className={styles.deliveryFee}>
              <CustomFilter label="運賃" content={deliveries && sumDeliveryFee(deliveries)} />
            </div>
          </div>
        </div>

        <div className={styles.group3}>
          <div className={styles.updateAt}>
            <Controller
              control={control}
              name="updated_at"
              render={({ field: { value } }) => (
                <CustomFilter
                  className="flex-grow-1"
                  label="修正日時"
                  content={value && moment(new Date(value)).format(DATETIME_FORMAT)}
                />
              )}
            />
          </div>

          <div className={styles.aggregationDate}>
            <DateFilter
              label="集計日"
              control={control}
              keys={['aggregation_date']}
              disabled={!editable}
            />
          </div>

          <div className={styles.userPicId}>
            <DynamicDropdownFilter
              label="担当者"
              control={control}
              keys={['user_pic_id']}
              dataProvider={picDataProvider}
              fallbackValue={picUser ? { label: picUser.name, value: picUser.id } : undefined}
              disabled={!editable}
            />
          </div>

          <div className={styles.userInputId}>
            <DynamicDropdownFilter
              label="入力者"
              control={control}
              required
              keys={['user_input_id']}
              dataProvider={inputUserDataProvider}
              fallbackValue={inputUser ? { label: inputUser.name, value: inputUser.id } : undefined}
              disabled={!editable}
            />
          </div>

          <div className={styles.userConfirmId}>
            <DynamicDropdownFilter
              label="確認者"
              control={control}
              keys={['user_confirm_id']}
              dataProvider={confirmUserDataProvider}
              fallbackValue={
                confirmUser ? { label: confirmUser.name, value: confirmUser.id } : undefined
              }
              disabled={!editable}
            />
          </div>

          <div className={styles.complete}>
            <Controller
              control={control}
              name="complete"
              render={({ field: { value } }) => (
                <CustomFilter
                  label="完了"
                  content={value === 1 ? '完了' : value === 0 ? '未完了' : null}
                />
              )}
            />
          </div>
        </div>

        <div className={styles.group4}>
          <TextAreaFilter
            label="詳細"
            value={purchaseDetail}
            onChange={(event) => {
              setValue('purchase_detail', event.target.value, { shouldDirty: true });
            }}
          />

          <TextAreaFilter
            label="業務連絡"
            value={businessContact}
            onChange={(event) => {
              setValue('business_contact', event.target.value, { shouldDirty: true });
            }}
          />

          <TextAreaFilter
            label="メモ"
            value={memo}
            onChange={(event) => {
              setValue('purchase_memo', event.target.value, { shouldDirty: true });
            }}
          />
        </div>
      </div>

      {showExportModal && (
        <PopupPrint
          title="印刷選択モーダル"
          options={purchaseExportOptions}
          actions={[
            { label: '閉じる', callback: () => setShowExportModal(false) },
            {
              label: '印刷',
              callback: (option: string | number) => {
                exportForm({ type: option as string, byCustomer: 0 });
              },
            },
            {
              label: '明細顧客別印刷',
              callback: (option: string | number) => {
                exportForm({ type: option as string, byCustomer: 1 });
              },
            },
          ]}
        />
      )}
    </>
  );
};

export default PurchaseDetailsForm;
