import { observer } from 'mobx-react';

import useStores from 'hooks/use-stores';
import UIStore from 'stores/ui';

import styles from './styles.module.scss';

const ConfirmBox = observer(() => {
  const uiStore: UIStore = useStores().uiStore;
  const {
    visible,
    title,
    content,
    confirmText,
    cancelText,
    onConfirm,
    onCancel,
    contentComponent,
  } = uiStore.confirmBox;

  return visible ? (
    <div className={styles.modalContainer}>
      <div className={styles.container}>
        <div className={styles.title}>{title}</div>
        {content && <div className={styles.content}>{content}</div>}
        {contentComponent}
        <div className="d-flex justify-content-evenly mt-4">
          <button className={styles.confirmBtn} onClick={() => onConfirm && onConfirm()}>
            {confirmText}
          </button>
          {cancelText && (
            <button
              className={styles.cancelBtn}
              onClick={() => {
                onCancel && onCancel();
                uiStore.hideConfirmBox();
              }}
            >
              {cancelText}
            </button>
          )}
        </div>
      </div>
    </div>
  ) : null;
});

export default ConfirmBox;
