import { useEffect, useState } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import styles from './styles.module.scss';

interface IProps {
  placement?: 'top' | 'right' | 'bottom' | 'left';
  delay?: {
    show: number;
    hide: number;
  };
  children: any;
  tooltip?: string;
}

const TooltipTrigger = ({ placement = 'left', delay, children, tooltip }: IProps) => {
  const popover = (content: any) => {
    return (
      <Popover>
        <div className={styles.popoverContent}>{content}</div>
      </Popover>
    );
  };

  const [state, setState] = useState<any>('');

  useEffect(() => {
    if (!tooltip) {
      const err = <span style={{ color: 'red' }}>X</span>;
      setState(err);
    } else {
      setState(tooltip);
    }
  }, [tooltip]);

  return (
    <OverlayTrigger
      overlay={popover(state)}
      placement={placement}
      delay={delay ? delay : { hide: 100, show: 50 }}
    >
      {children}
    </OverlayTrigger>
  );
};

export default TooltipTrigger;
