import { QueryClient } from 'react-query';

import stores from 'stores';

const uiStore = stores.uiStore;

const queryClient = new QueryClient({
  defaultOptions: {
    mutations: {
      retry: false,
      onError: (error: any) => {
        let errArr = [];
        let msg = error.message;
        if (error.errors) {
          for (let key of Object.keys(error.errors)) {
            errArr.push(error.errors[key][0]);
          }
          msg = msg + '\n' + errArr.join('\n');
        }
        uiStore.showAlertBox({ title: 'エラー', content: msg });
      },
    },
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});

export default queryClient;
