import { useState } from 'react';
import { Link } from 'react-router-dom';

import styles from './styles.module.scss';
import stores from 'stores';

const SubMenu = ({ option }: { option: any }) => {
  const [expand, setExpand] = useState(false);

  // only if this SubMenu has sub-items in it
  const showSubNav = () => {
    if (option.subMenus) {
      setExpand(!expand);
    }
  };

  // Note: if the user logged in with user1 in 2 tabs
  // then logs out in tab 1 and logs in with user2.
  // Permissions between accounts might not the same so,
  // we detect token changes here.
  const detectTokenChange = () => {
    const accessToken = localStorage.getItem('access_token');
    if (accessToken !== stores.sessionStore.token) {
      window.location.reload();
    }
  };

  const onSubMenuClick = () => {
    detectTokenChange();
  };

  const onSubItemClick = (event: any) => {
    detectTokenChange();

    // prevent tab from being closed after clicking on an item.
    event.stopPropagation();
  };

  return (
    <li className={styles.nav__menu_border} onClick={showSubNav}>
      {option.path ? (
        <div onClick={onSubMenuClick}>
          <Link
            to={option.path ? option.path : ''}
            className={`${styles.title_style} ${
              window.location.pathname === option.path && styles.active
            }`}
          >
            {option.name}
            <div>
              {option.subMenus && expand
                ? option.iconOpened
                : option.subMenus
                ? option.iconClosed
                : null}
            </div>
          </Link>
        </div>
      ) : (
        <div className={styles.title_style}>
          {option.name}
          <div>
            {option.subMenus && expand
              ? option.iconOpened
              : option.subMenus
              ? option.iconClosed
              : null}
          </div>
        </div>
      )}

      {expand &&
        option.subMenus.map((item: any, index: number) => (
          <li key={index} className={styles.nav__menu_border} onClick={onSubItemClick}>
            <Link
              to={item.path}
              className={`${styles.title_style_link} ${
                window.location.pathname === item.path && styles.active
              }`}
            >
              {item.name}
            </Link>
          </li>
        ))}
    </li>
  );
};

export default SubMenu;
