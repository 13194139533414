// Built-in
import { useEffect, useState } from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import FilterItem from 'shared/components/filter-item';
import { useQueryParams } from 'hooks/use-query-params';

// Component
import UniversalDropdown from 'shared/components/universal-dropdown';

// Store
import UIStore from 'stores/ui';
import useStores from 'hooks/use-stores';

// Service
import { exportForm } from 'services/export-form';

// Style
import styles from './styles.module.scss';

const AzukariReportScreen = () => {
  const navigate = useNavigate();
  const uiStore: UIStore = useStores().uiStore;
  const [date, setDate] = useState<any>(null);
  const [selectedGroup, setSelectedGroup] = useState<{
    id: number;
    group_code: string;
    group_name: string;
  } | null>(null);

  const query = useQueryParams();
  let params: any = {};
  for (let key of query.keys()) {
    params[key] = query.get(key);
  }

  const ExportAzukariFormToExcel = async (_event: any) => {
    try {
      uiStore.showLoading();
      const payload = {
        month: params.month,
        year: params.year,
        form_type: 'R2204',
        group_id: selectedGroup!.id,
      };
      await exportForm('reports/export', payload);
    } catch (error: any) {
      uiStore.showAlertBox({
        title: 'エラー',
        type: 'error',
        content: error.message,
      });
    } finally {
      uiStore.hideLoading();
    }
  };

  useEffect(() => {
    if (
      JSON.stringify(params) === '{}' ||
      !params.month ||
      !params.year ||
      isNaN(+params.month) ||
      isNaN(+params.year)
    ) {
      navigate(`/home`);
      return;
    }

    let formattedMonth = params.month.length === 1 ? `0${params.month}` : params.month;
    setDate(`${params.year}/${formattedMonth}`);
  }, []);

  return (
    <div className={`${styles.container}`}>
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/home' }}>
          TOP
        </Breadcrumb.Item>
        <Breadcrumb.Item active>集計</Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/form-report' }}>
          帳票出力
        </Breadcrumb.Item>
        <Breadcrumb.Item active>預かり報告書</Breadcrumb.Item>
      </Breadcrumb>

      <form className={styles.form}>
        <div className={styles.inputGroup}>
          <FilterItem label="処理月" />
          <input type="text" readOnly value={date} />
        </div>

        <div className={styles.selectGroup}>
          <UniversalDropdown
            target="groups"
            label="グループ"
            formatter={(item: any) => ({
              label: item.group_name,
              value: item,
            })}
            placeholder="グループ名"
            dropdownStyle={styles.dropdown}
            onChange={(...arg: any) => {
              const [item] = arg;
              setSelectedGroup(item);
            }}
            isClearable={false}
            autoDefaultValue={true}
          />
          <button type="button" onClick={ExportAzukariFormToExcel} className="secondary-btn">
            出力
          </button>
        </div>
      </form>
    </div>
  );
};

export default AzukariReportScreen;
