import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';

import PurchaseDetailsForm from './form';
import PurchaseDetailsTable from './table';
import UIStore from 'stores/ui';
import useStores from 'hooks/use-stores';
import { Purchase } from 'types/purchase';
import { getDirtyObject, removeKeys } from 'utils';
import { exportForm } from 'services/export-form';
import { createPurchase, deletePurchase, getPurchaseById, updatePurchase } from 'services/purchase';
import { useFormError } from 'hooks/use-form-error';
import { toast } from 'react-toastify';
import useBlockNavigation from 'hooks/use-block-navigation';
import SessionStore from 'stores/session';
import moment from 'moment';

interface IProps {
  id?: number; // for editing
  quotationId?: number; // for creating from a quotation
  initialValues?: Partial<Purchase>; // for duplicating
}

const PurchaseDetailsContainer = observer(({ id, quotationId, initialValues }: IProps) => {
  const uiStore: UIStore = useStores().uiStore;
  const sessionStore: SessionStore = useStores().sessionStore;
  const navigate = useNavigate();

  const [editable, setEditable] = useState(true);
  const [pathToNavigate, setPathToNavigate] = useState<string | undefined>(undefined);
  const [importId, setImportId] = useState<number | undefined>(undefined);

  const initialFormValues = useMemo(() => {
    let values: any = {};
    const profile = sessionStore.profile;
    // duplicate or created from a quotation
    if (initialValues) {
      values = initialValues;
      return values;
    }
    // brand new purchase
    values = {
      purchase_type: 1,
      imports: [],
      purchase_details: [],
      input_date: moment(new Date()).format('YYYY/MM/DD'),
    };

    const canBeUserInput = profile?.status === 1 && profile?.user_types?.INPUT_PERSON === 1;

    // set currently logged-in user as user input of this form.
    if (canBeUserInput) values.user_input_id = profile.id;

    return values;
  }, []);

  const {
    setValue,
    getValues,
    control,
    reset,
    formState: { isDirty, dirtyFields },
  } = useForm<Partial<Purchase>>({
    defaultValues: initialFormValues,
    mode: 'onChange',
  });

  const customer = useWatch({ control, name: 'customer' });
  const billingBy = useWatch({ control, name: 'customer.billing_by' });

  const { showFormError } = useFormError();

  const { refetch } = useQuery(['purchase', id], () => getPurchaseById(id as number), {
    enabled: !!id,
    onSettled: () => uiStore.hideLoading(),
    onSuccess: (data) => {
      reset(data);
      if (data.complete === 1) setEditable(false);
      if (data.imports[0]?.id) setImportId(data.imports[0].id);
    },
  });

  const createMutation = useMutation((data: any) => createPurchase(data, quotationId), {
    onMutate: () => uiStore.showLoading(),
    onSettled: () => uiStore.hideLoading(),
    onError: (err) => showFormError(err),
    onSuccess: (data) => {
      toast.success('登録しました');
      control._formState.isDirty = false;
      setTimeout(() => {
        if (pathToNavigate) {
          navigate(pathToNavigate + data.id);
        } else {
          navigate(`/purchases/${data.id}`);
        }
      }, 10);
    },
  });

  const updateMutation = useMutation((data: any) => updatePurchase(id as number, data), {
    onMutate: () => uiStore.showLoading(),
    onSettled: () => uiStore.hideLoading(),
    onError: (err) => showFormError(err),
    onSuccess: (_data) => {
      toast.success('登録しました');
      control._formState.isDirty = false;

      setTimeout(() => {
        if (pathToNavigate) {
          navigate(pathToNavigate + id);
        } else {
          uiStore.showLoading();
          refetch();
        }
      }, 10);
    },
  });

  const deleteMutation = useMutation(() => deletePurchase(id as number), {
    onMutate: () => uiStore.showLoading(),
    onSettled: () => uiStore.hideLoading(),
    onSuccess: () => {
      toast.success('登録しました');
      control._formState.isDirty = false;

      setTimeout(() => {
        navigate('/purchases', { replace: true });
      }, 10);
    },
  });

  const exportPurchaseFormInPDF = async (payload: { type: string; byCustomer: number }) => {
    if (!id) return;

    const { type, byCustomer } = payload;
    try {
      uiStore.showLoading();
      await exportForm(`purchases/export-details/${id}`, {
        form_type: type,
        export_detail_customer: byCustomer,
      });
    } catch (error: any) {
      uiStore.showAlertBox({
        title: 'エラー',
        type: 'error',
        content: error.message,
      });
    } finally {
      uiStore.hideLoading();
    }
  };

  const createSaleFromPurchase = () => {
    uiStore.showConfirmBox({
      title: '売上切替しますか？ （買取が削除されます）',
      confirmText: 'OK',
      cancelText: 'キャンセル',
      onConfirm: () => {
        uiStore.hideConfirmBox();
        navigate(`/sales/create?purchase_id=${id}`);
      },
    });
  };

  const deleteThisPurchase = () => {
    uiStore.showConfirmBox({
      title: 'このレコードを削除しますか？',
      confirmText: '削除する',
      cancelText: ' キャンセル',
      onConfirm: () => {
        uiStore.hideConfirmBox();
        deleteMutation.mutate();
      },
    });
  };

  const save = (path?: string) => {
    const values = getValues();
    let payload: any;

    if (id) {
      payload = getDirtyObject(dirtyFields, values);
      switch (true) {
        case !isDirty:
          if (path) navigate((path as string) + id);
          return;
        case isDirty && JSON.stringify(payload) === '{}':
          if (path) navigate((path as string) + id);
          return;
        default:
          break;
      }
    } else {
      payload = values;
    }
    removeKeys(payload, ['customer']);

    if (payload.purchase_details) {
      payload.purchase_details = payload.purchase_details.map((item: any) => {
        return {
          ...item,
          id: typeof item.id === 'string' ? undefined : item.id,
          customer_id: item.customer_id || billingBy?.id, // the user omits customer_id
        };
      });
    }
    setPathToNavigate(path);
    setTimeout(() => {
      id ? updateMutation.mutate(payload) : createMutation.mutate(payload);
    }, 10);
  };

  useBlockNavigation({ shouldBlock: control._formState.isDirty });

  useEffect(() => {
    if (id) uiStore.showLoading();
  }, []);

  return (
    <div>
      <PurchaseDetailsForm
        id={id}
        control={control}
        setValue={setValue}
        onSave={save}
        editable={editable}
        exportPurchaseFormInPDF={exportPurchaseFormInPDF}
      />
      <Controller
        control={control}
        name="purchase_details"
        render={({ field }) => (
          <PurchaseDetailsTable
            control={control}
            initialRows={field.value as Array<any>}
            setValue={setValue}
            editable={editable}
            customerId={customer?.id}
            billingBy={billingBy}
            importId={importId}
          />
        )}
      />

      <div className="d-flex justify-content-between align-items-center">
        <button className="primary-btn" onClick={() => navigate(-1)}>
          戻る
        </button>
        {id && (
          <div className="d-flex justify-content-end align-items-center">
            <button className="primary-btn--delete me-2" onClick={deleteThisPurchase}>
              削除
            </button>
            <button
              className="primary-btn me-2"
              onClick={() => {
                // duplicate
                window.open(`${window.location.origin}/purchases/create?id=${id}`);
              }}
            >
              複製
            </button>
            <button className="primary-btn" onClick={createSaleFromPurchase}>
              売上切替
            </button>
          </div>
        )}
      </div>
    </div>
  );
});

export default PurchaseDetailsContainer;
