import { del, get, post, put } from 'services';
import { Quotation } from 'types/quotation';

export const getQuotationById = (id: number) => get(`quotations/${id}`, {});

export const updateQuotation = (id: number, data: Partial<Quotation>) =>
  put(`quotations/${id}`, data);

export const createQuotation = (data: Partial<Quotation>) => post('quotations', data);

export const deleteQuotation = (id: number) => del(`quotations/${id}`);
