// Built-in
import { useRef, useState } from 'react';
import { Breadcrumb, BreadcrumbItem } from 'react-bootstrap';
import { useMutation } from 'react-query';
import { Link } from 'react-router-dom';
import { MdClose } from 'react-icons/md';

// Component
import DataTable from 'shared/components/data-table';
import EditModal from './edit-modal';

// Type
import { Driver } from 'types/driver';

// Store
import { DataProvider } from 'shared/data-providers/table-data-provider';
import UIStore from 'stores/ui';
import useStores from 'hooks/use-stores';

// Service
import { deleteDriver, saveDriver } from 'services/driver';

// Hook
import { useFormError } from 'hooks/use-form-error';

// Style
import styles from './styles.module.scss';

const DriverScreen = () => {
  const uiStore: UIStore = useStores().uiStore;

  const [editModal, setEditModal] = useState(false);
  const [selected, setSelected] = useState<Driver | undefined>(undefined);

  const { showFormError } = useFormError();

  const dataProvider = useRef(new DataProvider({ dataSource: 'drivers' }));

  const saveMutation = useMutation((data) => saveDriver(data), {
    onSuccess: () => {
      setEditModal(false);
      dataProvider.current.load();
    },
    onError: (err) => showFormError(err),
  });

  const deleteMutation = useMutation((id: number) => deleteDriver(id), {
    onSuccess: () => dataProvider.current.load(),
  });

  const headers = [
    { label: '運転手ID' },
    { label: '並び順' },
    { label: '運転手名' },
    { label: '使用中止' },
    { label: '' },
  ];

  const delegate = (item: any) => {
    const id = item.id;
    const sort = item.sort;
    const driverName = item.driver_name;
    const status = item.status === 0 ? <MdClose className="status-icon" /> : '';
    const editBtn = (
      <button
        className="edit"
        onClick={() => {
          setSelected(item);
          setEditModal(true);
        }}
      >
        修
      </button>
    );

    return [id, sort, driverName, status, editBtn];
  };

  const showConfirmDeletePopup = () => {
    uiStore.showConfirmBox({
      title: 'このレコードを削除しますか？',
      confirmText: '削除する',
      cancelText: ' キャンセル',
      onConfirm: () => {
        uiStore.hideConfirmBox();
        deleteMutation.mutate(selected!.id);
      },
    });
  };

  const openCreateDriverModal = () => {
    setSelected(undefined);
    setEditModal(true);
  };

  const onClose = () => {
    setEditModal(false);
  };

  const onSave = (data: any) => {
    saveMutation.mutate(data);
  };

  const onDelete = () => {
    setEditModal(false);
    showConfirmDeletePopup();
  };

  return (
    <div className="list-screen">
      <Breadcrumb>
        <BreadcrumbItem linkAs={Link} linkProps={{ to: '/home' }}>
          TOP
        </BreadcrumbItem>
        <BreadcrumbItem active>マスター</BreadcrumbItem>
        <BreadcrumbItem active>運転手</BreadcrumbItem>
      </Breadcrumb>

      <div className="d-flex justify-content-end">
        <button className="create" onClick={openCreateDriverModal}>
          作成
        </button>
      </div>

      <div className={styles.tableWrapper}>
        <DataTable dataProvider={dataProvider.current} headers={headers} delegate={delegate} />
      </div>

      {editModal && (
        <EditModal initialValues={selected} onClose={onClose} onSave={onSave} onDelete={onDelete} />
      )}
    </div>
  );
};

export default DriverScreen;
