// Built-in
import { useMemo, useRef } from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useNavigate, Link } from 'react-router-dom';
import { MdOutlineCircle } from 'react-icons/md';

// Component
import CustomerFilter from 'shared/components/customer-filter';
import DataTable from 'shared/components/data-table';
import TimeRangeFilter from 'shared/components/time-range-filter';
import TextFilter from 'shared/components/text-filter';
import RadioButtonFilter from 'shared/components/radio-button-filter';
import DynamicDropdownFilter from 'shared/components/dynamic-dropdown-filter';

// Type
import { DEFAULT_QUOTATION_FILTER, IQuotationFilter } from 'types/quotation';

// Store
import { DataProvider } from 'shared/data-providers/table-data-provider';

// Style
import styles from './styles.module.scss';
import { DropdownDataProvider } from 'shared/data-providers/dropdown-data-provider';

const QuotationScreen = () => {
  const navigate = useNavigate();

  const dataProvider = useRef(
    new DataProvider<IQuotationFilter>({
      dataSource: 'quotations',
      localStorageKey: 'quotation_filters',
      defaultFilters: DEFAULT_QUOTATION_FILTER,
    })
  );

  const inputUserDataProvider = useRef(
    new DropdownDataProvider('users/dropdown/input_person', (inputUser) => ({
      label: inputUser.name,
      value: inputUser.id,
    }))
  ).current;

  const { control, handleSubmit, setValue, reset } = useForm({
    defaultValues: dataProvider.current.filters,
  });

  const resetForm = () => {
    reset(DEFAULT_QUOTATION_FILTER);
    dataProvider.current.clearFilters();
  };

  const headers = useMemo(() => {
    return [
      { label: '', oneLine: true, centered: true },
      { label: '見積番号', key: 'quotation_code', oneLine: true },
      { label: '顧客' },
      { label: '入力日', key: 'input_date', oneLine: true },
      { label: '予定日', key: 'scheduled_date', oneLine: true },
      { label: '詳細' },
      { label: '転記', centered: true },
    ];
  }, []);

  const search = handleSubmit((values) => {
    const newFilter = {
      ...values,
      sort: dataProvider.current.filters.sort,
      sort_type: dataProvider.current.filters.sort_type,
    };
    dataProvider.current.setFilters(newFilter);
  });

  return (
    <div className="list-screen">
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/home' }}>
          TOP
        </Breadcrumb.Item>
        <Breadcrumb.Item active>見積</Breadcrumb.Item>
      </Breadcrumb>

      <div className={styles.filterContainer}>
        <div className={styles.inputDate}>
          <TimeRangeFilter
            control={control}
            label="入力日"
            keys={['input_date_from', 'input_date_to']}
          />
        </div>

        <div className={styles.quotationCode}>
          <TextFilter label="見積番号" control={control} keys={['quotation_code']} />
        </div>

        <div className={styles.userInputId}>
          <DynamicDropdownFilter
            label="入力者"
            control={control}
            keys={['user_input_id']}
            dataProvider={inputUserDataProvider}
            setValue={setValue}
          />
        </div>

        <div className={styles.buttonGroup}>
          <button className="action-btn" onClick={search}>
            検索
          </button>
          <button className="action-btn" onClick={resetForm}>
            クリア
          </button>
        </div>

        <div className={styles.wrap}></div>

        <div className={styles.customerId}>
          <CustomerFilter label="顧客" control={control} keys={['customer_id']} />
        </div>

        <div className={styles.purchasePosted}>
          <RadioButtonFilter
            label="転記"
            control={control}
            keys={['purchase_posted']}
            options={[
              { label: '全て', value: null },
              { label: '転記済', value: 1 },
              { label: '未転記', value: 0 },
            ]}
          />
        </div>
      </div>
      <div className="d-flex justify-content-end">
        <button className="create" onClick={() => navigate('/quotations/create')}>
          新しい見積
        </button>
      </div>

      <div className={styles.tableWrapper}>
        <DataTable
          dataProvider={dataProvider.current}
          headers={headers}
          delegate={(item) => [
            <button onClick={() => navigate(`/quotations/${item.id}`)}>修正</button>,
            <Link to={`/quotations/${item.id}`}>{item.quotation_code}</Link>,
            <p>
              {item.customer?.customer_name}
              {item.customer?.hall_name ? '　' + item.customer?.hall_name : ''}
            </p>,
            item.input_date,
            item.scheduled_date,
            item.quotation_detail,
            item.purchase && (
              <div>
                {item.purchase?.map((d: any, index: number) => (
                  <>
                    <Link to={`/purchases/${d.id}`} target="_blank" style={{ display: 'block' }}>
                      {d.purchase_code}
                    </Link>
                  </>
                ))}
              </div>
            ),
          ]}
        />
      </div>
    </div>
  );
};

export default QuotationScreen;
