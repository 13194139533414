import { action, makeObservable, observable, runInAction } from 'mobx';

import { get } from 'services';
import stores from 'stores';
import { removeKeys } from 'utils';

const { uiStore } = stores;

export interface IDataProvider<T, F extends { [key: string]: any }> {
  data: Array<T>;
  total: number;
  loading: boolean;
  limit: number;
  page: number;
  filters: Partial<F>;
  loadMore(): Promise<void>;
  load(): Promise<void>;
  setLimit(limit?: number): void;
  setPage(page?: number, reload?: boolean): void;
  clearFilters: () => void;
  setFilters: (filters: Partial<F>) => void;
}

export class DataProvider<F extends { [key: string]: any }> implements IDataProvider<any, F> {
  @observable data: Array<any> = [];
  @observable total: number = 0;
  @observable loading: boolean = false;
  @observable limit = 20;
  @observable page = 1;
  @observable filters: Partial<F> = {};
  dataSource: string;
  localStorageKey: string = '';
  defaultFilters: Partial<F> = {};

  // don't show error messages if 1 of these APIs failed.
  exceptions = ['products'];

  constructor({
    dataSource,
    localStorageKey,
    defaultFilters,
    queryFilters,
  }: {
    dataSource: string;
    localStorageKey?: string;
    defaultFilters?: F;
    queryFilters?: any;
  }) {
    // API endpoint
    this.dataSource = dataSource;

    // default filters in list screen
    this.defaultFilters = defaultFilters || {};

    this.filters = queryFilters || this.defaultFilters;

    if (localStorageKey && !queryFilters) {
      this.localStorageKey = localStorageKey;
      const dataInStorage = localStorage.getItem(localStorageKey);
      if (dataInStorage) {
        const data = JSON.parse(dataInStorage);
        this.limit = data.limit;
        this.page = data.page;

        // Remove limit & page from this.filters because they are not
        // part of the filters in the first place . Limit & page
        // will be managed locally through this.limit & this.page.
        // setPage, setLimit, setFilter will set limit & page
        // back to local storage.
        removeKeys(data, ['limit', 'page']);
        this.filters = data;
      }
    }
    makeObservable(this);
  }

  @action
  async loadMore() {
    this.load();
  }

  @action
  async load(): Promise<void> {
    const filters: { [key: string]: any } = JSON.parse(JSON.stringify(this.filters));
    let params: {
      page: number;
      perPage: number;
      sort?: string;
      sort_type?: 'asc' | 'desc';
    } = {
      page: this.page,
      perPage: this.limit,
      ...filters,
    };

    try {
      this.loading = true;
      const data = await get(this.dataSource, params);
      runInAction(() => {
        this.data = data.data;
        this.total = data.total;
        this.limit = data.per_page;
        this.page = data.current_page;
      });
    } catch (err: any) {
      if (!this.exceptions.includes(this.dataSource)) {
        uiStore.showAlertBox({ title: 'エラー', content: err.message });
      }
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  /**
   *
   * @param limit number of records per page
   */
  @action setLimit(limit: number): void {
    this.limit = limit;
    this.page = 1;
    this.load();

    if (this.localStorageKey) {
      let newFilter: { [key: string]: any } = JSON.parse(JSON.stringify(this.filters));
      newFilter.page = 1;
      newFilter.limit = limit;
      localStorage.setItem(this.localStorageKey, JSON.stringify(newFilter));
    }
  }

  /**
   * Whenever the user navigates to a new page, we set the local 'this.page' to that
   * value and store also that value in localStorage.
   * So even if the user refreshes the website, the page will not be reset back
   * to 1.
   * @param page page number as API's param
   * @param reload whether to fetch new data or not
   */
  @action setPage(page: number, reload: boolean = true): void {
    this.page = page;

    if (reload) this.load();

    if (this.localStorageKey) {
      let newFilter: { [key: string]: any } = JSON.parse(JSON.stringify(this.filters));
      newFilter.page = page;
      newFilter.limit = this.limit;
      localStorage.setItem(this.localStorageKey, JSON.stringify(newFilter));
    }
  }

  /**
   * Set this.filters with the value of 'filters' param.
   * Also stores current page & limit in local storage.
   * However, limit & page in localStorage will only be used in the constructor
   * at the creation of object to set the default page & limit.
   * Subsequent API calls will still use this.page & this.limit
   * @param filters an object
   */
  @action setFilters(filters: Partial<F>, keepCurrentPage: boolean = false) {
    // set new filter for searching
    this.filters = filters;
    if (!keepCurrentPage) this.page = 1;
    this.load();

    if (this.localStorageKey) {
      // data to be stored in storage
      let newFilters: any = JSON.parse(JSON.stringify(filters));
      newFilters.limit = this.limit;
      newFilters.page = 1;
      localStorage.setItem(this.localStorageKey, JSON.stringify(newFilters));
    }
  }

  /**
   * Sometimes, when clearing the filters, we want to keep the current
   * settings for records per page (limit). If that's the case,
   * pass in the things you want to exclude.
   * Example: ["limit", "page"]
   */
  @action clearFilters(keys?: Array<string>): void {
    this.filters = this.defaultFilters;
    if (this.localStorageKey) localStorage.removeItem(this.localStorageKey);

    if (keys === undefined) {
      this.page = 1;
      this.limit = 20;
    } else {
      if (keys.includes('page') === false) this.page = 1;
      if (keys.includes('limit') === false) this.limit = 20;
    }
    this.load();
  }
}
