import React, { useLayoutEffect, useState } from 'react';
import { Router } from 'react-router-dom';
import RouteList from 'routes';
import { Provider } from 'mobx-react';
import { QueryClientProvider } from 'react-query';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

import stores from 'stores';
import { history } from 'routes/history';
import LoadingSpinner from 'shared/containers/loading-spinner';
import AlertBox from 'shared/containers/alert-box';
import ConfirmBox from 'shared/containers/confirm-box';
import queryClient from 'utils/query-client';
import ToastWrapper from 'shared/components/toast';

Bugsnag.start({
  apiKey: '447470c28618e3b2a5884f9c3a26d023',
  plugins: [new BugsnagPluginReact()],
});

const ErrorBoundary = Bugsnag?.getPlugin('react')?.createErrorBoundary(React);

const CustomRouter = ({ history, ...props }: any) => {
  const [state, setState] = useState({
    action: history.action,
    location: history.location,
  });

  useLayoutEffect(() => history.listen(setState), [history]);

  return (
    <Router
      {...props}
      location={state.location}
      navigationType={state.action}
      navigator={history}
    />
  );
};

function App() {
  const Container = ErrorBoundary || React.Fragment;

  return (
    <Container>
      <Provider {...stores}>
        <QueryClientProvider client={queryClient}>
          <CustomRouter history={history}>
            <RouteList />
            <LoadingSpinner />
            <AlertBox />
            <ConfirmBox />
            <ToastWrapper />
          </CustomRouter>
        </QueryClientProvider>
      </Provider>
    </Container>
  );
}

export default App;
