// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_container__8M0Ou {\n  height: 30px;\n}\n.styles_container__8M0Ou input {\n  flex: 1 1;\n  height: 100%;\n  border: 1px solid lightgray;\n  width: 100%;\n}\n.styles_container__8M0Ou div:nth-of-type(2) {\n  width: 100%;\n  border: 1px solid lightgray;\n  height: 30px;\n  background-color: white;\n}\n.styles_container__8M0Ou div:nth-of-type(2).styles_disabled__NQR4k {\n  background-color: #dedede;\n}\n.styles_container__8M0Ou div:nth-of-type(2) textarea {\n  width: 100%;\n  resize: none;\n}\n.styles_container__8M0Ou div:nth-of-type(2) textarea:focus {\n  outline: none;\n}\n.styles_container__8M0Ou div:nth-of-type(2) textarea:disabled {\n  background-color: #dedede;\n}", "",{"version":3,"sources":["webpack://./src/shared/components/text-filter/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;AACE;EACE,SAAA;EACA,YAAA;EACA,2BAAA;EACA,WAAA;AACJ;AAEE;EACE,WAAA;EACA,2BAAA;EACA,YAAA;EACA,uBAAA;AAAJ;AAEI;EACE,yBAAA;AAAN;AAGI;EACE,WAAA;EACA,YAAA;AADN;AAGM;EACE,aAAA;AADR;AAIM;EACE,yBAAA;AAFR","sourcesContent":[".container {\n  height: 30px;\n\n  input {\n    flex: 1;\n    height: 100%;\n    border: 1px solid lightgray;\n    width: 100%;\n  }\n\n  div:nth-of-type(2) {\n    width: 100%;\n    border: 1px solid lightgray;\n    height: 30px;\n    background-color: white;\n\n    &.disabled {\n      background-color: #dedede;\n    }\n\n    textarea {\n      width: 100%;\n      resize: none;\n\n      &:focus {\n        outline: none;\n      }\n\n      &:disabled {\n        background-color: #dedede;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "styles_container__8M0Ou",
	"disabled": "styles_disabled__NQR4k"
};
export default ___CSS_LOADER_EXPORT___;
