// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_container__SsrgN {\n  display: flex;\n  align-items: center;\n  width: 100%;\n  height: 30px;\n}\n\n.styles_dropdown__R-lx3 {\n  height: 30px;\n  border: 1px solid lightgray;\n  border-radius: 0;\n  flex: 1 1;\n}\n\n.styles_noBorder__xhY-G {\n  border: none !important;\n}", "",{"version":3,"sources":["webpack://./src/shared/components/static-dropdown-filter/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,WAAA;EACA,YAAA;AACF;;AAEA;EACE,YAAA;EACA,2BAAA;EACA,gBAAA;EACA,SAAA;AACF;;AAEA;EACE,uBAAA;AACF","sourcesContent":[".container {\n  display: flex;\n  align-items: center;\n  width: 100%;\n  height: 30px;\n}\n\n.dropdown {\n  height: 30px;\n  border: 1px solid lightgray;\n  border-radius: 0;\n  flex: 1;\n}\n\n.noBorder {\n  border: none !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "styles_container__SsrgN",
	"dropdown": "styles_dropdown__R-lx3",
	"noBorder": "styles_noBorder__xhY-G"
};
export default ___CSS_LOADER_EXPORT___;
