import { get, post } from './index';

interface IPayload {
  month: number;
  year: number;
}

export const aggregateLedger = (payload: IPayload) => post(`ledgers/aggregate`, payload);
export const confirmLedger = (payload: IPayload) => post(`ledgers/confirm`, payload);
export const revertLedger = (payload: IPayload) => post(`ledgers/revert`, payload);
export const getLedgerInfo = (payload: IPayload) => get(`ledgers/show-info`, payload);
