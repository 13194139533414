// Built-in
import { useRef, useState, useMemo } from 'react';
import { Breadcrumb, BreadcrumbItem } from 'react-bootstrap';
import { useMutation } from 'react-query';
import { Link } from 'react-router-dom';
import { MdClose } from 'react-icons/md';

// Component
import DataTable from 'shared/components/data-table';
import EditModal from './edit-modal';

// Type
import { User } from 'types/user';

// Store
import useStores from 'hooks/use-stores';
import UIStore from 'stores/ui';
import { DataProvider } from 'shared/data-providers/table-data-provider';

// Service
import { createUser, deleteUser, updateUser } from 'services/user';

// Hook
import { useFormError } from 'hooks/use-form-error';

// Style
import styles from './styles.module.scss';
import { observer } from 'mobx-react';

const UserScreen = observer(() => {
  const uiStore: UIStore = useStores().uiStore;

  const [editModal, setEditModal] = useState(false);
  const [selected, setSelected] = useState<User | undefined>(undefined);

  const { showFormError } = useFormError();

  const dataProvider = useRef(new DataProvider({ dataSource: 'users' }));

  const headers = useMemo(() => {
    return [
      { label: 'ユーザーNo' },
      { label: '並び順' },
      { label: '氏名' },
      { label: 'ログインID' },
      { label: '使用中止' },
      { label: '' },
    ];
  }, []);

  const delegate = (user: any) => {
    const id = user.id;
    const sort = user.sort;
    const name = user.name;
    const username = user.username;
    const status = user.status === 0 ? <MdClose className="status-icon" /> : '';
    const editBtn = (
      <button
        className="edit"
        onClick={() => {
          // fix a mobX warning
          const copy = JSON.parse(JSON.stringify(user));
          setSelected(copy);
          setEditModal(true);
        }}
      >
        修
      </button>
    );

    return [id, sort, name, username, status, editBtn];
  };

  const updateMutation = useMutation(
    (data: User & { password?: string }) => updateUser(data.id, data),
    {
      onSuccess: () => {
        setEditModal(false);
        dataProvider.current.load();
      },
      onError: (err: any) => showFormError(err),
    }
  );

  const createMutation = useMutation((data: User & { password?: string }) => createUser(data), {
    onSuccess: () => {
      setEditModal(false);
      dataProvider.current.load();
    },
    onError: (err: any) => showFormError(err),
  });

  const deleteMutation = useMutation((id: number) => deleteUser(id), {
    onSuccess: () => dataProvider.current.load(),
    onError: (err: any) => showFormError(err),
  });

  const showConfirmDeletePopup = () => {
    uiStore.showConfirmBox({
      title: 'このレコードを削除しますか？',
      confirmText: '削除する',
      cancelText: ' キャンセル',
      onConfirm: () => {
        uiStore.hideConfirmBox();
        deleteMutation.mutate((selected as User).id);
      },
    });
  };

  const openCreateNewUserModal = () => {
    setSelected(undefined);
    setEditModal(true);
  };

  const onClose = () => {
    setEditModal(false);
  };

  const onUpdate = (data: User & { password?: string }) => {
    updateMutation.mutate(data);
  };

  const onCreate = (data: User & { password?: string }) => {
    createMutation.mutate(data);
  };

  const onDelete = () => {
    setEditModal(false);
    showConfirmDeletePopup();
  };

  return (
    <div className="list-screen">
      <Breadcrumb>
        <BreadcrumbItem linkAs={Link} linkProps={{ to: '/home' }}>
          TOP
        </BreadcrumbItem>
        <BreadcrumbItem active>マスター</BreadcrumbItem>
        <BreadcrumbItem active>ユーザー</BreadcrumbItem>
      </Breadcrumb>

      <div className="d-flex justify-content-end">
        <button className="create" onClick={openCreateNewUserModal}>
          作成
        </button>
      </div>

      <div className={styles.tableWrapper}>
        <DataTable dataProvider={dataProvider.current} headers={headers} delegate={delegate} />
      </div>

      {editModal && (
        <EditModal
          initialValues={selected}
          onClose={onClose}
          onUpdate={onUpdate}
          onCreate={onCreate}
          onDelete={onDelete}
        />
      )}
    </div>
  );
});

export default UserScreen;
