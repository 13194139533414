import { useEffect, useMemo, useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { MdClose } from 'react-icons/md';

import { DEFAULT_USER_AUTHORITIES, DEFAULT_USER_TYPES, USER_AUTHORITIES, USER_TYPES } from 'consts';
import { User } from 'types/user';

import styles from './styles.module.scss';
import useStores from 'hooks/use-stores';
import UIStore from 'stores/ui';
import { observer } from 'mobx-react';
import { popupConfirmBack } from 'utils/modals';
import { DEFAULT_PASSWORD, validateAccount, validatePassword } from 'consts/regex';
import { removeKeys } from 'utils';
import { convertToHalfWidth } from 'utils/validator';

interface IProps {
  initialValues?: User;
  onClose: () => void;
  onUpdate: (data: User & { password?: string }) => void;
  onCreate: (data: User & { password?: string }) => void;
  onDelete: () => void;
}

const EditModal = observer(({ initialValues, onClose, onUpdate, onCreate, onDelete }: IProps) => {
  const initialFormValues = useMemo(() => {
    let values: any = {};
    if (initialValues) {
      values = initialValues;
      values.password = '12345678';
      return values;
    }

    values = {
      user_types: DEFAULT_USER_TYPES,
      user_authorities: DEFAULT_USER_AUTHORITIES,
      status: 1,
      password: '',
      sort: 0,
    };
    return values;
  }, []);

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { isDirty, isValid },
    setFocus,
  } = useForm<User & { password?: string }>({
    defaultValues: initialFormValues,
    mode: 'onChange',
  });
  const uiStore: UIStore = useStores().uiStore;
  const [isPasswordValid, setPasswordValid] = useState<boolean>(true);
  const [isAccountValid, setAccountValid] = useState<boolean>(true);
  const password = useWatch({ control, name: 'password' });

  const modalTitle = useMemo(() => {
    return !initialValues ? 'ユーザー作成モーダル' : 'ユーザー編集モーダル';
  }, [initialValues]);

  const onSubmit = (data: User & { password?: string }) => {
    const submit = initialValues ? onUpdate : onCreate;
    const mode = initialValues ? 'edit' : 'create';
    let abort = false;
    const checkAccount = validateAccount(data.username, mode);
    const checkPassword = validatePassword(data.password as string, mode);
    if (!checkAccount) {
      setAccountValid(false);
      setFocus('username', { shouldSelect: false });
      abort = true;
    } else {
      setAccountValid(true);
    }
    if (!checkPassword) {
      setPasswordValid(false);
      if (checkAccount) {
        setFocus('password', { shouldSelect: false });
      }
      abort = true;
    } else {
      setPasswordValid(true);
    }

    if (abort) return;

    const payload: User & { password?: string } = { ...data, status: data.status ? 1 : 0 };
    if (mode === 'edit' && password === DEFAULT_PASSWORD) removeKeys(payload, ['password']);
    payload.sort = parseInt(payload.sort as any as string);
    submit(payload);
  };

  const onBackBtnClick = (event: any) => {
    event.preventDefault();
    popupConfirmBack(isDirty, () => onClose());
  };

  // while the user changes the password in edit mode,
  // if they change it back to default, remove error message
  useEffect(() => {
    if (['', undefined].includes(password)) setPasswordValid(true);
    if (initialValues && password === DEFAULT_PASSWORD) setPasswordValid(true);
  }, [password, initialValues]);

  return (
    <Modal show backdrop="static" centered size="lg">
      <div className="edit-modal">
        <div className="edit-modal--title">
          {modalTitle}
          <button onClick={onBackBtnClick}>
            <MdClose />
          </button>
        </div>
        <Form onSubmit={handleSubmit(onSubmit)}>
          {initialValues && (
            <Form.Group className={styles.group}>
              <Form.Label>ユーザーNo</Form.Label>
              <Form.Control disabled {...register('id')} />
            </Form.Group>
          )}

          <Form.Group className={styles.group}>
            <Form.Label>並び順</Form.Label>
            <Form.Control
              {...register('sort', {
                onChange(event) {
                  let value = event.target.value;
                  setValue('sort', parseInt(convertToHalfWidth(value)) || 0);
                },
              })}
            />
          </Form.Group>

          {/* fullname */}
          <Form.Group className={styles.group}>
            <Form.Label className="required">氏名</Form.Label>
            <Form.Control {...register('name', { required: true, maxLength: 100 })} />
          </Form.Group>

          {/* login ID */}
          <div>
            <Form.Group className={styles.group}>
              <Form.Label className="required">ログインID</Form.Label>
              <Form.Control
                {...register('username', {
                  maxLength: 100,
                  required: true,
                })}
                autoComplete="new-user"
                disabled={initialValues ? true : false}
              />
            </Form.Group>
            {!isAccountValid && (
              <p className={styles.error}>
                「4文字以上の半角英数字とドット（.）、アンダーバー（_）が使用可能です。」
              </p>
            )}
          </div>

          <div>
            <Form.Group className={styles.group}>
              <Form.Label className="required">パスワード</Form.Label>
              <Form.Control
                type="password"
                {...register('password', {
                  required: true,
                })}
                autoComplete="new-password"
              />
            </Form.Group>
            {!isPasswordValid && (
              <p className={styles.error}>
                8~20桁数の半角大文字、小文字、数字と特殊記号（@%+¥/'!#$?:,(){}[]~`-_
                ）の組込を使って下さい。
              </p>
            )}
          </div>

          <Form.Group className="d-flex align-items-center mb-2">
            <Form.Label style={{ minWidth: 100 }}>種別</Form.Label>
            <Controller
              control={control}
              name="user_types"
              render={({ field: { onChange, value } }) => (
                <div className={styles.checkboxContainer}>
                  {USER_TYPES.map((item) => (
                    <div className={styles.checkboxWrapper}>
                      <Form.Check
                        key={item.value}
                        label={item.label}
                        type="checkbox"
                        className="custom-checkbox"
                        // when creating a new user, auto-check "PERSON_IN_CHARGE"
                        defaultChecked={
                          !initialValues && item.label === '担当者' ? true : !!value[item.value]
                        }
                        onChange={(event) =>
                          onChange({ ...value, [item.value]: event.target.checked ? 1 : 0 })
                        }
                      />
                    </div>
                  ))}
                </div>
              )}
            />
          </Form.Group>

          <Form.Group className="d-flex align-items-center mb-2">
            <Form.Label style={{ minWidth: 100 }}>権限</Form.Label>
            <Controller
              control={control}
              name="user_authorities"
              render={({ field: { onChange, value } }) => (
                <div className={styles.checkboxContainer}>
                  {USER_AUTHORITIES.map((item) => (
                    <div className={styles.checkboxWrapper}>
                      <Form.Check
                        key={item.value}
                        label={item.label}
                        type="checkbox"
                        className="custom-checkbox"
                        // when creating a new user, auto-check all authorities.
                        defaultChecked={!initialValues ? true : !!value[item.value]}
                        onChange={(event) =>
                          onChange({ ...value, [item.value]: event.target.checked ? 1 : 0 })
                        }
                      />
                    </div>
                  ))}
                </div>
              )}
            />
          </Form.Group>

          <Form.Group className={styles.group}>
            <Form.Label style={{ minWidth: 100 }}>メモ</Form.Label>
            <Form.Control {...register('user_memo', { required: false })} />
          </Form.Group>

          <Form.Group className="d-flex align-items-center">
            <Form.Label style={{ minWidth: 120, margin: 0 }}>使用中止</Form.Label>
            <Controller
              control={control}
              name="status"
              render={({ field: { value, onChange } }) => (
                <div className={styles.status}>
                  <Form.Check
                    className="custom-checkbox"
                    // checked === inactive, unchecked === active
                    defaultChecked={!value}
                    onChange={(event) => onChange(event.target.checked ? 0 : 1)}
                  />
                </div>
              )}
            />
          </Form.Group>

          <div className="d-flex justify-content-end">
            {initialValues && (
              <button
                className="edit-modal--delete"
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  onDelete();
                }}
              >
                削除
              </button>
            )}
            <button className="edit-modal--save" type="submit" disabled={!isValid}>
              登録
            </button>
          </div>
        </Form>
      </div>

      {isDirty && uiStore.confirmBox.visible && <div className="overlappedBackdrop"></div>}
    </Modal>
  );
});

export default EditModal;
