// Built-ins
import { useEffect, useMemo, useRef, useState } from 'react';
import { Form, Modal, Spinner } from 'react-bootstrap';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { MdClose, MdError } from 'react-icons/md';
import { AsyncPaginate } from 'react-select-async-paginate';
import Select from 'react-select';
import moment from 'moment';
import { useQuery } from 'react-query';
import { observer } from 'mobx-react';

// Components
import CustomerSelectModal from 'shared/containers/customer-select-modal';
import ReactDatePickerWrapper from 'shared/components/datepicker-wrapper';

// Services
import { get } from 'services';
import { getCustomerByCode } from 'services/customer';

// Types
import { VehicleCost } from 'types/vehicle-cost';
import { VEHICLE_COST_TYPES } from 'consts';

// Stores
import UIStore from 'stores/ui';
import useStores from 'hooks/use-stores';

// Styles
import styles from './styles.module.scss';

// Utils
import { popupConfirmBack } from 'utils/modals';
import { convertToHalfWidth } from 'utils/validator';
import { removeKeys } from 'utils';

interface IProps {
  initialValues?: VehicleCost;
  onClose: () => void;
  onCreate: (data: VehicleCost) => void;
  onSave: (data: VehicleCost) => void;
  onDelete: () => void;
}

const EditModal = observer(({ initialValues, onClose, onCreate, onSave, onDelete }: IProps) => {
  const [customerModal, setCustomerModal] = useState(false);
  const [date, setDate] = useState(
    initialValues ? moment(initialValues.effective_date, 'YYYY-MM-DD').toDate() : null
  );
  const uiStore: UIStore = useStores().uiStore;

  const initialFormValues = useMemo(() => {
    let values: any = {};
    if (initialValues) {
      values = initialValues;
      return values;
    }
    return values;
  }, []);

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { isValid, isDirty },
  } = useForm<VehicleCost>({
    defaultValues: initialFormValues,
    mode: 'onChange',
  });

  const amount = useWatch({ control, name: 'amount' });

  const modalTitle = useMemo(() => {
    return !initialValues ? '車両経費作成モーダル' : '車両経費編集モーダル';
  }, [initialValues]);

  const [customerCode, setCustomerCode] = useState<string>(
    initialValues?.payee?.customer_code || ''
  );
  const initialCustomerCode = useRef<string>(initialValues?.payee?.customer_code || '');

  const customerQuery = useQuery(
    ['customer', customerCode],
    () => getCustomerByCode(customerCode || ''),
    {
      enabled: false,
      onSuccess: (data) => {
        setValue('payee', data);
        setValue('payee_id', data.id);
      },
      onError: () => {
        setValue('payee.customer_name', undefined);
        setValue('payee', undefined);
        setValue('payee_id', null);
      },
    }
  );

  useEffect(() => {
    if (customerCode !== initialCustomerCode.current) {
      customerQuery.refetch();
    }
  }, [customerCode]);

  const onSubmit = (data: any) => {
    removeKeys(data, ['payee', 'truck']);
    if (typeof data.amount === 'string') {
      data.amount = parseInt(data.amount);
    }

    initialValues ? onSave(data) : onCreate(data);
  };

  const onErrors = (errors: any) => {
    console.log('Errors: ', errors);
  };

  return (
    <Modal show backdrop="static" centered>
      <div className="edit-modal">
        <div className="edit-modal--title">
          {modalTitle}
          <button
            onClick={(event: any) => {
              event.preventDefault();
              popupConfirmBack(isDirty, () => onClose());
            }}
          >
            <MdClose />
          </button>
        </div>
        <Form onSubmit={handleSubmit(onSubmit, onErrors)}>
          <Form.Group className="d-flex align-items-center">
            <Form.Label className="required">日付</Form.Label>
            <div style={{ width: '85%', margin: '0 0 0 auto' }}>
              <Controller
                control={control}
                name="effective_date"
                rules={{ required: true }}
                render={() => (
                  <ReactDatePickerWrapper
                    selected={date}
                    dateFormat="yyyy/MM/dd"
                    className={styles.datePicker}
                    onChange={(date) => {
                      setDate(date);
                      if (!!date) {
                        setValue('effective_date', moment(date).format('YYYY-MM-DD'), {
                          shouldDirty: true,
                          shouldValidate: true,
                        });
                      } else
                        setValue('effective_date', '', {
                          shouldDirty: true,
                          shouldValidate: true,
                        });
                    }}
                  />
                )}
              />
            </div>
          </Form.Group>
          <Form.Group className={styles.group}>
            <Form.Label className="required">トラック</Form.Label>
            <Controller
              control={control}
              name="truck_id"
              rules={{ required: true }}
              render={({ field }) => (
                <AsyncPaginate
                  className={styles.dropdown}
                  loadingMessage={() => <div>Loading</div>}
                  isSearchable={false}
                  isClearable
                  placeholder="選択"
                  defaultValue={
                    initialValues?.truck
                      ? {
                          label: initialValues.truck.truck_name,
                          value: initialValues.truck.id,
                        }
                      : undefined
                  }
                  loadOptions={async () => {
                    try {
                      const data = await get('trucks/dropdown', {});
                      return {
                        options: data.map((item: any) => ({
                          label: item.truck_name,
                          value: item.id,
                        })),
                      };
                    } catch (err) {
                      return { options: [] };
                    }
                  }}
                  onChange={(value: any) => {
                    if (value) field.onChange(value.value);
                    else field.onChange(null);
                  }}
                />
              )}
            />
          </Form.Group>
          <Form.Group className={styles.group}>
            <Form.Label className="required">種別</Form.Label>
            <Controller
              control={control}
              name="cost_type"
              render={({ field: { onChange } }) => (
                <Select
                  className={styles.dropdown}
                  isSearchable={false}
                  isClearable
                  placeholder="選択"
                  defaultValue={
                    initialValues
                      ? initialValues.cost_type === 1
                        ? { label: '車両整備', value: initialValues.cost_type }
                        : { label: '高速代', value: initialValues.cost_type }
                      : {
                          label: '車両整備',
                          value: 1,
                        }
                  }
                  options={VEHICLE_COST_TYPES}
                  onChange={(nextValue) => {
                    if (nextValue) onChange(nextValue.value);
                    else onChange(null);
                  }}
                />
              )}
            />
          </Form.Group>
          <Form.Group className={styles.group}>
            <Form.Label className="required">経費名</Form.Label>
            <Form.Control {...register('cost_name', { required: true })} />
          </Form.Group>
          <Form.Group className={styles.group}>
            <Form.Label>金額</Form.Label>
            <Form.Control
              value={amount}
              onChange={(event) => {
                let val = event.target.value;

                switch (true) {
                  case val === '':
                    setValue('amount', 0, { shouldDirty: true });
                    break;
                  case /^[０-９0-9]+$/.test(val) === true:
                    setValue('amount', parseInt(convertToHalfWidth(val)), { shouldDirty: true });
                    break;
                  case /^[０-９0-9]+\.$/.test(val) === true:
                    setValue('amount', convertToHalfWidth(val), { shouldDirty: true });
                    break;
                  case /^[０-９0-9]+\.[０-９0-9]+$/.test(val) === true:
                    setValue('amount', parseFloat(convertToHalfWidth(val)), { shouldDirty: true });
                    break;
                  default:
                    break;
                }
              }}
            />
          </Form.Group>
          <Form.Group className={styles.group}>
            <Form.Label>支払先</Form.Label>
            <div
              className="d-flex align-items-center"
              style={{ width: '87%', margin: '0 0 0 auto' }}
            >
              <div style={{ width: '15em', margin: '0 0 0 0' }}>
                <Form.Control
                  {...register('payee.customer_code')}
                  onChange={(event: any) => {
                    setCustomerCode(event.target.value);
                  }}
                />

                {customerQuery.isLoading && (
                  <Spinner
                    animation="border"
                    size="sm"
                    style={{ minWidth: '1.5rem', minHeight: '1.5rem', marginLeft: '0.5rem' }}
                  />
                )}
                {!!customerQuery.error && !!customerCode.length && (
                  <MdError
                    style={{
                      minWidth: '1.5rem',
                      minHeight: '1.5rem',
                      color: 'red',
                      marginLeft: '0.5rem',
                    }}
                  />
                )}
              </div>
              <button
                style={{
                  border: '1px solid green',
                  color: 'green',
                  minWidth: 'max-content',
                  marginRight: '0.5rem',
                  marginLeft: '0.5rem',
                }}
                onClick={(event) => {
                  event.preventDefault();
                  setCustomerModal(true);
                }}
              >
                参照
              </button>
              <Form.Control disabled {...register('payee.customer_name')} />
              <Form.Control disabled {...register('payee.hall_name')} />
            </div>
          </Form.Group>
          <Form.Group className={styles.group}>
            <Form.Label>メモ</Form.Label>
            <Form.Control {...register('cost_memo')} />
          </Form.Group>
          <div className="d-flex justify-content-end">
            {initialValues && (
              <button
                className="edit-modal--delete"
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  onDelete();
                }}
              >
                削除
              </button>
            )}
            <button
              className="edit-modal--save"
              type="submit"
              disabled={!isValid || !!customerQuery.error}
            >
              登録
            </button>
          </div>
        </Form>
      </div>
      {customerModal && (
        <CustomerSelectModal
          onClose={() => setCustomerModal(false)}
          onSelect={(item) => {
            setValue('payee_id', item.id, { shouldDirty: true });
            setValue('payee', item, { shouldDirty: true });
            setCustomerModal(false);
          }}
        />
      )}

      {isDirty && uiStore.confirmBox.visible && <div className="overlappedBackdrop"></div>}
    </Modal>
  );
});

export default EditModal;
