import { observer } from 'mobx-react';
import { Spinner } from 'react-bootstrap';

import useStores from 'hooks/use-stores';

import './style.scss';

const LoadingSpinner = observer(() => {
  const uiStore = useStores().uiStore;

  return (
    <div id="loading" className={uiStore.isShowLoading ? 'show' : ''}>
      <Spinner animation="border" />
    </div>
  );
});

export default LoadingSpinner;
