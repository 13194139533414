// Built-in
import { useQueryParams } from 'hooks/use-query-params';
import { Breadcrumb } from 'react-bootstrap';
import { Link } from 'react-router-dom';

// Component
import ExportDetailsContainer from 'shared/containers/export-details';

// Const
import { EXPORT_TYPE_TEXT } from 'consts';

const CreateExportScreen = () => {
  const query = useQueryParams();
  const exportTypeStr = query.get('type');
  const exportType = exportTypeStr ? parseInt(exportTypeStr) : 1;

  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/home' }}>
          TOP
        </Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/exports' }}>
          出庫
        </Breadcrumb.Item>
        <Breadcrumb.Item active>出庫詳細（{EXPORT_TYPE_TEXT[exportType]}）</Breadcrumb.Item>
      </Breadcrumb>

      <ExportDetailsContainer exportType={exportType as 1 | 2} />
    </div>
  );
};

export default CreateExportScreen;
