import { useEffect, useRef, useState } from 'react';
import { v1 } from 'uuid';

const useEditableTable = <T>(initialValues?: Array<T>) => {
  const [rows, setRows] = useState<Array<T>>(initialValues || []);
  const changeSource = useRef<'in' | 'out'>('out');
  let rowsRef = useRef(initialValues || []);

  const updateRow = (index: number, data: any) => {
    const temp = [...rowsRef.current];
    temp.splice(index, 1, {
      ...rowsRef.current[index],
      ...data,
    });
    rowsRef.current = temp;
    changeSource.current = 'in';
    setRows(temp);
  };

  const removeRow = (index: number) => {
    const temp = [...rowsRef.current];
    temp.splice(index, 1);
    changeSource.current = 'in';
    setRows(temp);
  };

  const duplicateRow = (index: number) => {
    const temp = [...rowsRef.current];
    temp.splice(index + 1, 0, { ...rowsRef.current[index], id: v1() });
    changeSource.current = 'in';
    setRows(temp);
  };

  const swap = (index: number, direction: 'up' | 'down') => {
    if (direction === 'up') {
      if (index === 0) return;
      const tempRows = [...rowsRef.current];
      const temp = tempRows[index];
      tempRows[index] = tempRows[index - 1];
      tempRows[index - 1] = temp;
      changeSource.current = 'in';
      setRows(tempRows);
    } else {
      if (index === rows.length - 1) return;
      const tempRows = [...rowsRef.current];
      const temp = tempRows[index];
      tempRows[index] = tempRows[index + 1];
      tempRows[index + 1] = temp;
      changeSource.current = 'in';
      setRows(tempRows);
    }
  };

  const updateAllRow = (datas: Array<any>) => {
    changeSource.current = 'in';
    setRows(datas);
  };

  useEffect(() => {
    changeSource.current = 'out';
    setRows(initialValues || []);
    rowsRef.current = initialValues || [];
  }, [initialValues]);

  return { rows, setRows, updateRow, updateAllRow, removeRow, swap, duplicateRow, changeSource, rowsRef };
};

export default useEditableTable;
