import { useState, useRef, useEffect, useMemo } from 'react';
import { Form } from 'react-bootstrap';
import { Controller, UseFormSetValue, useFormState, Control, useWatch } from 'react-hook-form';
import { Link } from 'react-router-dom';
import moment from 'moment';

import TextFilter from 'shared/components/text-filter';
import DateFilter from 'shared/components/date-filter';
import StaticDropdownFilter from 'shared/components/static-dropdown-filter';
import CustomFilter from 'shared/components/custom-filter';
import { DropdownDataProvider } from 'shared/data-providers/dropdown-data-provider';
import ValidatingInput from 'shared/components/validating-text-input';
import ProductSelectModal from 'shared/containers/product-select-modal';
import DynamicDropdownFilter from 'shared/components/dynamic-dropdown-filter';
import { DATETIME_FORMAT } from 'consts';
import { getProductById } from 'services/product';

import styles from './styles.module.scss';

interface IProps {
  control: Control<any, any>;
  setValue: UseFormSetValue<any>;
  onSave: () => void;
}

const InventoryDetailsForm = ({ control, setValue, onSave }: IProps) => {
  const [productModal, setProductModal] = useState(false);
  const [x, setX] = useState(0);
  const groupIdRef = useRef(null);

  const { isValid } = useFormState({ control });
  const groupId = useWatch({ control, name: 'group_id' });
  const exportFlag = useWatch({ control, name: 'export_flag' });

  const exported = useMemo(() => {
    // can't change current_device_type_code if exported
    return exportFlag !== 1 && exportFlag !== undefined;
  }, [exportFlag]);

  const deviceTypeDataProvider = useRef(
    new DropdownDataProvider('device-types/dropdown', (item) => ({
      label: item.device_type_code,
      value: item.device_type_code,
    }))
  ).current;
  const groupDataProvider = useRef(
    new DropdownDataProvider('groups/dropdown', (item) => ({
      label: item.group_name,
      value: item.id,
    }))
  );
  const customerDataProvider = useRef(
    new DropdownDataProvider('customers/dropdown', (item) => ({
      label: `${item.customer_name}${item.hall_name ? '　'+item.hall_name : ''}`,
      value: item.id,
    }))
  );

  useEffect(() => {
    if (groupId) {
      customerDataProvider.current = new DropdownDataProvider(
        `customers/dropdown?group_id=${groupId}`,
        (item) => ({
          label: `${item.customer_name}${item.hall_name ? '　'+item.hall_name : ''}`,
          value: item.id,
        })
      );
      if (groupIdRef.current) setValue('customer_id', undefined);
      setX(x + 1);
      groupIdRef.current = groupId;
    } else {
      setValue('qr', 1);
    }
  }, [groupId]);

  return (
    <>
      <div className={styles.form}>
        <div className={styles.stockCode}>
          <TextFilter control={control} label="在庫番号" keys={['stock_code']} disabled />
        </div>
        <div className={styles.stockType}>
          <StaticDropdownFilter
            control={control}
            label="在庫種別"
            keys={['stock_type']}
            required
            isClearable={false}
            options={[
              { label: '預かり', value: 1 },
              { label: '処分', value: 2 },
            ]}
          />
        </div>
        <div className={styles.updatedAt}>
          <Controller
            control={control}
            name="updated_at"
            render={({ field: { value } }) => (
              <CustomFilter
                className="flex-grow-1"
                label="修正日時"
                content={value && moment(new Date(value)).format(DATETIME_FORMAT)}
              />
            )}
          />

          <button className="primary-btn ms-2" disabled={!isValid} onClick={onSave}>
            登録
          </button>
        </div>
        <div className={styles.stockDate}>
          <DateFilter control={control} label="入庫日" keys={['stock_date']} required />
        </div>
        <div className={styles.importCode}>
          <Controller
            control={control}
            name="import"
            render={({ field: { value } }) => (
              <CustomFilter
                label="入庫番号"
                content={
                  value && (
                    <Link to={`/imports/${value.id}`} target="_blank">
                      {value.import_code}
                    </Link>
                  )
                }
              />
            )}
          />
        </div>
        <div className={styles.stockNote}>
          <TextFilter control={control} label="備考" keys={['stock_note']} />
        </div>
        <div className={styles.groupId}>
          <DynamicDropdownFilter
            control={control}
            label="グループ"
            keys={['group_id']}
            required
            dataProvider={groupDataProvider.current}
          />
        </div>

        <div className={styles.stockDetail}>
          <TextFilter control={control} label="詳細" keys={['stock_detail']} />
        </div>
        <div className={styles.customerId}>
          <Controller
            control={control}
            name="customer"
            render={({ field: { value } }) => (
              <DynamicDropdownFilter
                key={x}
                label="入庫元"
                dataProvider={customerDataProvider.current}
                control={control}
                keys={['customer_id']}
                required
                fallbackValue={
                  value
                    ? {
                        label: value.hall_name
                          ? `${value.customer_name} ${value.hall_name}`
                          : value.customer_name,
                        value: value.id,
                      }
                    : undefined
                }
              />
            )}
          />
        </div>
        <div className={styles.destination}>
          <TextFilter control={control} label="納品先" keys={['destination']} />
        </div>
        <div className={styles.importDeviceTypeCode}>
          <TextFilter
            control={control}
            label="入庫時種別"
            keys={['import_device_type_code']}
            disabled
          />
        </div>
        <div className={styles.currentDeviceTypeCode}>
          <DynamicDropdownFilter
            control={control}
            label="現在の種別"
            keys={['current_device_type_code']}
            required
            disabled={exported === true}
            dataProvider={deviceTypeDataProvider}
          />
        </div>
        <div className={styles.stockMemo}>
          <TextFilter control={control} label="メモ" keys={['stock_memo']} />
        </div>
        <div className={styles.productCD}>
          <div style={{ flex: 1 }}>
            <Controller
              control={control}
              name="product_id"
              rules={{ required: true }}
              render={({ field: { value, onChange } }) => (
                <CustomFilter
                  label="商品CD"
                  disabled={false}
                  noPadding
                  required
                  content={
                    <ValidatingInput
                      type="number"
                      className={styles.productId}
                      inputClassName={styles.shortInput}
                      value={value?.toString()}
                      validate={async (value, showError) => {
                        if (value === '') {
                          onChange(undefined);
                          setValue('product', undefined);
                          showError(false);
                          return;
                        }
                        try {
                          const res = await getProductById(parseInt(value));
                          onChange(res.id);
                          setValue('product', res);
                          showError(false);
                        } catch (err) {
                          setValue('product', undefined);
                          showError(true);
                        }
                      }}
                    />
                  }
                />
              )}
            />
          </div>

          <button className="secondary-btn ms-2" onClick={() => setProductModal(true)}>
            参
          </button>
        </div>
        <div className={styles.makerName}>
          <TextFilter
            control={control}
            label="メーカー"
            keys={['product.maker.maker_name']}
            disabled
          />
        </div>
        <div className={styles.frameColor}>
          <TextFilter control={control} label="枠色" keys={['frame_color']} />
        </div>
        <div className={styles.productName}>
          <TextFilter control={control} label="型式名" keys={['product.model_name']} disabled />
        </div>
        <div className={styles.productSpec}>
          <TextFilter control={control} label="スペック" keys={['product.spec']} disabled />
        </div>
        <div className={styles.qr}>
          <Controller
            control={control}
            name="qr"
            render={({ field: { value } }) => (
              <CustomFilter
                label="QR"
                disabled={false}
                content={
                  <Form.Check
                    className="custom-checkbox"
                    checked={!!value}
                    onChange={(event) =>
                      setValue('qr', event.target.checked ? 1 : 0, { shouldDirty: true })
                    }
                  />
                }
              />
            )}
          />
        </div>
        <div className={styles.palletCode}>
          <TextFilter control={control} label="在庫場所" keys={['pallet_code']} />
        </div>
      </div>

      {productModal && (
        <ProductSelectModal
          onClose={() => setProductModal(false)}
          onSelect={(item) => {
            setValue('product_id', item.id, { shouldDirty: true, shouldValidate: true });
            setValue('product', item);
            setProductModal(false);
          }}
        />
      )}
    </>
  );
};

export default InventoryDetailsForm;
