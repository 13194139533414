import { useEffect, useRef, useState } from 'react';

import { convertToHalfWidth } from 'utils/validator';

interface IProps {
  type?: string;
  defaultValue: any;
  onChange: any;
  disabled?: boolean;
  readOnly?: boolean;
  allowFullWidth?: boolean;
  isFloat?: boolean;
  isSigned?: boolean;
}

const InputWrapper = ({
  type = 'text',
  defaultValue,
  onChange,
  disabled = false,
  readOnly = false,
  allowFullWidth,
  isFloat = false,
  isSigned = false,
}: IProps) => {
  const [value, setValue] = useState<any>(defaultValue);
  const [lastInputChangeTime, setLastInputChangeTime] = useState<number>(0);

  useEffect(() => {
    if (!isNaN(defaultValue) && defaultValue !== 0) {
      setValue(defaultValue);
    }
  }, [defaultValue]);

  // debounce
  const timeoutFunc = useRef<any>(null);

  const onInputChange = (event: any) => {
    if (timeoutFunc.current !== null) clearTimeout(timeoutFunc.current);

    let val = event.target.value;
    const pattern = allowFullWidth ? RegExp(/^[０-９0-9]+$/) : RegExp(/^[0-9]+$/);
    const isNumber = pattern.test(val);

    switch (true) {
      case val === '':
        setValue('');
        break;
      case val !== '' && type === 'number':
        if (isNumber) {
          const newVal = allowFullWidth ? convertToHalfWidth(val) : val;
          setValue(newVal);
        }
        break;
      default:
        setValue(val);
        break;
    }

    timeoutFunc.current = setTimeout(() => {
      switch (true) {
        case val === '' && type === 'number':
          onChange(0);
          break;
        case val === '' && type !== 'number':
          onChange('');
          break;
        case val !== '' && type === 'number':
          if (isNumber) {
            const newFormVal = allowFullWidth ? convertToHalfWidth(val) : val;
            onChange(parseInt(newFormVal));
          }
          break;
        default:
          onChange(val);
          break;
      }

      timeoutFunc.current = null;
    }, 400);
  };

  useEffect(() => {
    if (type === 'number') setValue(defaultValue?.toString() || '');
  }, []);

  return type === 'number' && isFloat ? (
    <input
      value={value}
      onChange={(event) => {
        const currentTime = new Date().getTime();
        const timeSinceLastChange = currentTime - lastInputChangeTime;
        setLastInputChangeTime(currentTime);
        const val = event.target.value;
        const valid = /^[0-9.-]*$/.test(val);
        if (valid || val === '') {
          if (timeSinceLastChange < 500) {
            clearTimeout(timeoutFunc.current);
            timeoutFunc.current = null;
          }
          timeoutFunc.current = setTimeout(() => {
            onChange(val === '' ? 0 : parseFloat(val));
          }, 500);
          setValue(val);
        }
      }}
      disabled={disabled}
      readOnly={readOnly}
    />
  ) : type === 'number' && isSigned ? (
    <input
      onChange={(event) => {
        const currentTime = new Date().getTime();
        const timeSinceLastChange = currentTime - lastInputChangeTime;
        setLastInputChangeTime(currentTime);
        const val = event.target.value;
        const valid = /^[0-9.-]*$/.test(val);
        if (valid || val === '') {
          if (timeSinceLastChange < 500) {
            clearTimeout(timeoutFunc.current);
            timeoutFunc.current = null;
          }
          timeoutFunc.current = setTimeout(() => {
            onChange(val === '' ? 0 : parseInt(val));
          }, 500);
          setValue(val);
        }
      }}
      value={value}
      disabled={disabled}
      readOnly={readOnly}
    />
  ) : (
    <input onChange={onInputChange} value={value} disabled={disabled} readOnly={readOnly} />
  );
};

export default InputWrapper;
