// Built-in
import { useEffect, useRef, useState } from 'react';
import { Breadcrumb, Form } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { Controller, UseFormSetValue, useFormState, Control, useWatch } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { MdOutlineCircle } from 'react-icons/md';
import { observer } from 'mobx-react';
import classNames from 'classnames';

// Component
import RadioButtonFilter from 'shared/components/radio-button-filter';
import TimeRangeFilter from 'shared/components/time-range-filter';
import TextFilter from 'shared/components/text-filter';
import CustomerFilter from 'shared/components/customer-filter';
import DynamicDropdownFilter from 'shared/components/dynamic-dropdown-filter';
import DataTable from 'shared/components/data-table';
import PopupPrint from 'shared/components/popup-print';
import { stockExportOptions } from 'consts/export-options';
import UpdateStockModal from './update-modal';
import DropdownFilter from 'shared/components/dropdown-filter';

// Type
import { DEFAULT_INVENTORY_FILTER, IInventoryFilter, InventoryItem } from 'types/inventory';
import { DeviceType } from 'types/device-type';

// Store
import { DropdownDataProvider } from 'shared/data-providers/dropdown-data-provider';
import { DataProvider } from 'shared/data-providers/table-data-provider';
import useStores from 'hooks/use-stores';
import UIStore from 'stores/ui';

// Service
import { exportForm } from 'services/export-form';

// Hook
import { useQueryParams } from 'hooks/use-query-params';

// Style
import styles from './styles.module.scss';

const StockScreen = observer(() => {
  const navigate = useNavigate();
  const uiStore: UIStore = useStores().uiStore;

  const params = useQueryParams();
  const importCode = params.get('import_code');
  const exportCode = params.get('export_code');
  let queryFilters: { import_code?: string; export_code?: string } | undefined = undefined;
  if (importCode) queryFilters = { import_code: importCode };
  if (exportCode) {
    if (queryFilters) queryFilters.export_code = exportCode;
    else queryFilters = { export_code: exportCode };
  }

  const [updateModal, setUpdateModal] = useState(false);
  const [showExportModal, setShowExportModal] = useState<boolean>(false);
  const [selectedIds, setSelectedIds] = useState<Array<number>>([]);
  const [tableData, setTableData] = useState<Array<InventoryItem>>([]);

  const dataProvider = useRef(
    new DataProvider<IInventoryFilter>({
      dataSource: 'stocks',
      localStorageKey: 'stock_filters',
      defaultFilters: DEFAULT_INVENTORY_FILTER,
      queryFilters,
    })
  );

  const groupDataProvider = useRef(
    new DropdownDataProvider('groups/dropdown', (item) => ({
      label: item.group_name,
      value: item.id,
    }))
  ).current;
  const deviceTypeDataProvider = useRef(
    new DropdownDataProvider('device-types/dropdown', (item: DeviceType) => ({
      label: item.device_type_code,
      value: item.device_type_code,
    }))
  ).current;
  const makerDataProvider = useRef(
    new DropdownDataProvider('makers/dropdown', (maker) => ({
      label: `${maker.maker_code} | ${maker.maker_name}`,
      value: maker.id,
    }))
  ).current;

  const { control, handleSubmit, setValue, reset } = useForm<IInventoryFilter>({
    defaultValues: dataProvider.current.filters,
  });

  const groupId = useWatch({ control, name: 'group_id' });

  useEffect(() => {
    setTableData(dataProvider.current.data);
  }, [dataProvider.current.data]);

  const search = handleSubmit((values) => {
    const newFilter = {
      ...values,
      sort: dataProvider.current.filters.sort,
      sort_type: dataProvider.current.filters.sort_type,
    };

    dataProvider.current.setFilters(newFilter);
  });

  useEffect(() => {
    setValue('customer_id', undefined);
  }, [groupId]);

  const resetFilters = () => {
    reset(DEFAULT_INVENTORY_FILTER);
    dataProvider.current.clearFilters();
  };

  const isExported = (item: any, part: 'FRAME' | 'BASE_CELL') => {
    switch (item.export_flag) {
      case 1: {
        return null;
      }
      case 2: {
        if (part === 'FRAME') {
          return styles.disabled;
        }
        return null;
      }
      case 3: {
        if (part === 'BASE_CELL') {
          return styles.disabled;
        }
        return null;
      }
      case 9: {
        return styles.disabled;
      }
    }

    const e = part === 'FRAME' ? item.frame_export : item.base_cell_export;
    return e ? styles.disabled : null;
  };

  const headers = [
    { label: '', centered: true },
    { label: '入庫日', key: 'stock_date' },
    { label: 'グループ' },
    { label: '入庫元' },
    { label: '種別' },
    { label: 'メーカー' },
    { label: '型式名', key: 'maker_name' },
    {
      label: '機器番号',
      headerClassName: styles.blueHeader,
      cellClassName: (item: any) => isExported(item, 'FRAME'),
    },
    {
      label: '出庫日',
      key: 'export_date_frame',
      headerClassName: styles.blueHeader,
      cellClassName: (item: any) => isExported(item, 'FRAME'),
    },
    {
      label: '出庫先',
      headerClassName: styles.blueHeader,
      cellClassName: (item: any) => isExported(item, 'FRAME'),
    },
    {
      label: '機器番号',
      headerClassName: styles.orangeHeader,
      cellClassName: (item: any) => isExported(item, 'BASE_CELL'),
    },
    {
      label: '出庫日',
      key: 'export_date_base_cell',
      headerClassName: styles.orangeHeader,
      cellClassName: (item: any) => isExported(item, 'BASE_CELL'),
    },
    {
      label: '出庫先',
      headerClassName: styles.orangeHeader,
      cellClassName: (item: any) => isExported(item, 'BASE_CELL'),
    },
    { label: 'QR', centered: true },
    { label: '在庫場所', key: 'pallet_code' },
    { label: '備考' },
    { label: '詳細' },
    { label: '納品先' },
    { label: 'メモ' },
    { label: '枠色' },
    {
      label: (
        <Form.Check
          className={styles.checkbox}
          onChange={(event) => {
            if (!event.target.checked) setSelectedIds([]);
            else setSelectedIds(tableData.map((item) => item.id));
          }}
        />
      ),
      centered: true,
    },
  ];

  const renderHeaders = () => {
    return (
      <tr>
        <th colSpan={7} />
        <th colSpan={3} className={styles.blueHeader}>
          枠・その他（T)
        </th>
        <th colSpan={3} className={styles.orangeHeader}>
          セル・基盤
        </th>
        <th colSpan={8} />
      </tr>
    );
  };

  const delegate = (item: InventoryItem) => [
    <button onClick={() => navigate(`${item.id}`)}>修正</button>,
    item.stock_date,
    item.group?.group_name,
    item.customer?.hall_name ? item.customer?.hall_name : item.customer?.customer_name,
    item.current_device_type_code,
    item.product?.maker?.maker_name,
    item.product?.model_name,
    item.frame_number,
    item.frame_export?.export?.export_date,
    item.frame_export?.export?.customer && item.frame_export?.export?.customer?.hall_name
      ? item.frame_export?.export?.customer?.hall_name
      : item.frame_export?.export?.customer?.customer_name,
    <div style={{ minWidth: '7rem' }}>
      {item.cell_number}
      <br />
      {item.base_number}
    </div>,
    item.base_cell_export?.export?.export_date,
    item.base_cell_export?.export?.customer && item.base_cell_export?.export?.customer?.hall_name
      ? item.base_cell_export?.export?.customer?.hall_name
      : item.base_cell_export?.export?.customer?.customer_name,
    item.qr === 1 && <MdOutlineCircle />,
    item.pallet_code,
    item.stock_note,
    item.stock_detail,
    item.destination,
    item.stock_memo,
    item.frame_color,
    <Form.Check
      className={classNames(styles.checkbox, styles.secondary)}
      checked={selectedIds.includes(item.id)}
      onChange={(event) => {
        if (event.target.checked) {
          const temp = [...selectedIds];
          temp.push(item.id);
          setSelectedIds(temp);
        } else {
          const temp = [...selectedIds].filter((i) => i !== item.id);
          setSelectedIds(temp);
        }
      }}
    />,
  ];

  const showPopupPrint = () => {  //show Excel Export Modal
    setShowExportModal(true);
  };

  const exportStockFormInExcel = async (formType: string) => {
    const formValues = control._formValues;
    const payload = { ...formValues };
    try {
      uiStore.showLoading();
      payload.form_type = formType;
      await exportForm(`stocks/export-list`, payload);
    } catch (error: any) {
      uiStore.showAlertBox({
        title: 'エラー',
        type: 'error',
        content: error.message,
      });
    } finally {
      uiStore.hideLoading();
    }
  };

  return (
    <div>
      <div className="list-screen">
        <Breadcrumb>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/home' }}>
            TOP
          </Breadcrumb.Item>
          <Breadcrumb.Item active>在庫</Breadcrumb.Item>
        </Breadcrumb>

        <div className={styles.filterContainer}>
          <div className={styles.exportFlag}>
            <RadioButtonFilter
              label="在庫状況"
              control={control}
              keys={['export_flag']}
              options={[
                { label: '全て', value: 0 },
                { label: '在庫中', value: 1 },
                { label: '出庫済', value: 2 },
              ]}
            />
          </div>

          <div className={styles.stockType}>
            <RadioButtonFilter
              label="在庫種別"
              control={control}
              keys={['stock_type']}
              options={[
                { label: '全て', value: null },
                { label: '預かり', value: 1 },
                { label: '処分', value: 2 },
              ]}
            />
          </div>

          <div className={styles.qr}>
            <RadioButtonFilter
              label="QR"
              control={control}
              keys={['qr']}
              options={[
                { label: '全て', value: null },
                { label: '有り', value: 1 },
                { label: '無し', value: 0 },
              ]}
            />
          </div>

          <div>
            <DropdownFilter
              label="種別"
              control={control}
              keys={['device_type_code']}
              dataProvider={deviceTypeDataProvider}
            />
          </div>

          <div className={styles.buttonGroup}>
            <button className="action-btn" onClick={search}>
              検索
            </button>
            <button className="action-btn" onClick={resetFilters}>
              クリア
            </button>
          </div>

          <div className={styles.wrap}></div>

          <div className={styles.stockDate}>
            <TimeRangeFilter
              label="入庫日"
              control={control}
              keys={['stock_date_from', 'stock_date_to']}
            />
          </div>

          <div className={styles.customerId}>
            <CustomerFilter
              label="入庫元"
              control={control}
              keys={['customer_id']}
              groupId={groupId || undefined}
            />
          </div>

          <div className={styles.importCode}>
            <TextFilter label="入庫番号" control={control} keys={['import_code']} />
          </div>

          <div className={styles.wrap}></div>

          <div className={styles.exportDate}>
            <TimeRangeFilter
              label="出庫日"
              control={control}
              keys={['export_date_from', 'export_date_to']}
            />
          </div>

          <div className={styles.exportCustomerId}>
            <CustomerFilter
              label="出庫先"
              control={control}
              keys={['export_customer_id']}
              groupId={groupId || undefined}
            />
          </div>

          <div className={styles.exportCode}>
            <TextFilter label="出庫番号" control={control} keys={['export_code']} />
          </div>

          <div className={styles.wrap}></div>

          <div className={styles.groupId}>
            <DynamicDropdownFilter
              label="グループ"
              control={control}
              dataProvider={groupDataProvider}
              keys={['group_id']}
              isClearable={true}
              setValue={setValue}
              placeholder="選択"
            />
          </div>

          <div className={styles.makerId}>
            <DynamicDropdownFilter
              label="メーカー"
              dataProvider={makerDataProvider}
              control={control}
              keys={['maker_id']}
              isClearable={true}
              setValue={setValue}
              placeholder="選択"
            />
          </div>

          <div className={styles.modelName}>
            <TextFilter label="型式名" control={control} keys={['model_name']} />
          </div>

          <div className={styles.frameBaseCellNumber}>
            <TextFilter label="機器番号/ｺｰﾄﾞ" control={control} keys={['frame_cell_base_number']} />
          </div>

          <div className={styles.palletCode}>
            <TextFilter label="在庫場所" control={control} keys={['pallet_code']} />
          </div>

          <div className={styles.wrap}></div>

          <div className={styles.stockNote}>
            <TextFilter label="備考" control={control} keys={['stock_note']} />
          </div>

          <div className={styles.stockDetail}>
            <TextFilter label="詳細" control={control} keys={['stock_detail']} />
          </div>

          <div className={styles.destination}>
            <TextFilter label="納品先" control={control} keys={['destination']} />
          </div>

          <div className={styles.stockMemo}>
            <TextFilter label="メモ" control={control} keys={['stock_memo']} />
          </div>

          <div className={styles.frameColor}>
            <TextFilter label="枠色" control={control} keys={['frame_color']} />
          </div>
        </div>

        <div className="d-flex justify-content-end">
          <button className="primary-btn me-2" onClick={showPopupPrint}>
            エクセル出力
          </button>
          <button className="primary-btn me-2" onClick={() => navigate('qr')}>
            積替
          </button>
          <button className="primary-btn me-2" onClick={() => navigate('/inventories/create')}>
            新しい在庫
          </button>
          <button
            className="primary-btn"
            disabled={selectedIds.length === 0}
            onClick={() => setUpdateModal(true)}
          >
            一括更新
          </button>
        </div>

        <div className={styles.tableWrapper}>
          <DataTable<InventoryItem, IInventoryFilter>
            dataProvider={dataProvider.current}
            headerComponent={renderHeaders()}
            headers={headers}
            delegate={delegate}
          />
        </div>
      </div>
      {updateModal && (
        <UpdateStockModal
          selectedIds={selectedIds}
          onClose={() => setUpdateModal(false)}
          onReload={() => {
            setSelectedIds([]);
            dataProvider.current.load();
          }}
        />
      )}
      {showExportModal && (
        <PopupPrint
          title="出力選択モーダル"
          options={stockExportOptions}
          actions={[
            { label: '閉じる', callback: () => setShowExportModal(false) },
            {
              label: 'エクセル出力',
              callback: (option: string) => {
                exportStockFormInExcel(option);
                setShowExportModal(false);
              },
            },
          ]}
        />
      )}
    </div>
  );
});

export default StockScreen;
