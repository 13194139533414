export const DEFAULT_PASSWORD = '12345678';

export const passwordRegex = new RegExp(
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9@%+¥/'!#$?:,(){}[\]~`\-_])[a-zA-Z0-9@%+¥/'!#$?:,(){}[\]~`\-_]{8,20}$/
);

export const accountRegex = new RegExp(/^[a-zA-Z0-9._]{4,100}$/);

export const validatePassword = (password: string, mode: 'edit' | 'create') => {
  switch (mode) {
    case 'edit':
      if (password === DEFAULT_PASSWORD) return true;
      return passwordRegex.test(password as string);
    case 'create':
      return passwordRegex.test(password);
  }
};

export const validateAccount = (account: string, mode: 'edit' | 'create') => {
  switch (mode) {
    case 'edit':
      return true;
    case 'create':
      return accountRegex.test(account);
  }
};
