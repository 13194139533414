import { useEffect, useState, useRef, useMemo } from 'react';
import { Table } from 'react-bootstrap';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import moment from 'moment';

import Checkbox from 'shared/components/checkbox';
import CustomerFilter from 'shared/components/customer-filter';
import DateFilter from 'shared/components/date-filter';
import DynamicDropdownFilter from 'shared/components/dynamic-dropdown-filter';
import HourRangeFilter from 'shared/components/hour-range-filter';
import RadioButtonFilter from 'shared/components/radio-button-filter';
import StaticDropdownFilter from 'shared/components/static-dropdown-filter';
import TextFilter from 'shared/components/text-filter';
import DropdownSelectOptions from './components/dropdown-select-options';
import { DATETIME_FORMAT } from 'consts';
import { useFormError } from 'hooks/use-form-error';
import useStores from 'hooks/use-stores';
import UIStore from 'stores/ui';
import { DropdownDataProvider } from 'shared/data-providers/dropdown-data-provider';
import { getCustomerDetails } from 'services/customer';
import {
  completeDelivery,
  createDelivery,
  deleteDelivery,
  getDeliveryById,
  revertCompleteDelivery,
  updateDelivery,
} from 'services/delivery';
import { countProductImportDetails } from 'services/import';
import { countProductExportDetails } from 'services/export';
import {
  DEFAULT_DELIVERY_VOLUMES,
  Delivery,
  DELIVERY_TARGET_TYPES,
  DELIVERY_TYPES,
  DEVICE_TYPE_CODES,
  volume,
} from 'types/delivery';
import { popupConfirmDelete, popupConfirmSaveBeforeNavigate } from 'utils/modals';
import { getDirtyObject, removeKeys } from 'utils';
import CustomFilter from 'shared/components/custom-filter';
import styles from './styles.module.scss';
import { toast } from 'react-toastify';
import TextAreaFilter from 'shared/components/textarea-filter';
import useBlockNavigation from 'hooks/use-block-navigation';
import SessionStore from 'stores/session';
import { getUserInfo } from 'services/user';
import { convertToHalfWidth } from 'utils/validator';
import InputWrapper from 'shared/components/input-wrapper';

interface IProps {
  id?: number;
  target?: {
    [key: string]: any;
  };
}

const DeliveryDetailContainer = observer(({ id, target }: IProps) => {
  const navigate = useNavigate();
  const uiStore: UIStore = useStores().uiStore;
  const sessionStore: SessionStore = useStores().sessionStore;
  const [pathToNavigate, setPathToNavigate] = useState<string | undefined>(undefined);

  const { showFormError } = useFormError();

  const initialFormValues = useMemo(() => {
    // creating a new delivery
    let values: Partial<Delivery> = {
      complete: 0,
      target_type: 'OTHER',
      delivery_type: 1,
      delivery_volume: DEFAULT_DELIVERY_VOLUMES,
    };
    const profile = sessionStore.profile;

    // creating a new delivery from a target
    if (target) {
      const { targetType: type, targetId: id } = target;
      values = {
        ...values,
        target_type: type,
        target_id: id,
      };
    }

    const canBeUserInput = profile?.status === 1 && profile?.user_types?.INPUT_PERSON === 1;
    // set currently logged-in user as user input of this form.
    if (canBeUserInput) values.user_input_id = profile.id;

    return values;
  }, []);

  // Note: Don't remove isDirty
  const {
    control,
    setValue,
    getValues,
    reset,
    formState: { isValid, dirtyFields, isDirty: _ },
  } = useForm<Partial<Delivery>>({
    defaultValues: initialFormValues,
    mode: 'onChange',
  });

  const [canChangeTarget1, setCanChangeTarget1] = useState<boolean>(true);
  const [canChangeTarget2, setCanChangeTarget2] = useState<boolean>(true);

  const targetType = useWatch({ control, name: 'target_type' });
  const customerId = useWatch({ control, name: 'customer_id' });
  const picUser = useWatch({ control, name: 'user_person_in_charge' });
  const inputUser = useWatch({ control, name: 'user_input_person' });
  const confirmUser = useWatch({ control, name: 'user_confirm_person' });
  const complete = useWatch({ control, name: 'complete' });
  const truck = useWatch({ control, name: 'truck' });
  const driver = useWatch({ control, name: 'driver' });
  const assistant1 = useWatch({ control, name: 'assistant1' });
  const assistant2 = useWatch({ control, name: 'assistant2' });
  const journeyEnterLater = useWatch({ control, name: 'journey_enter_later' });
  const journeyStart = useWatch({ control, name: 'journey_start' });
  const journeyEnd = useWatch({ control, name: 'journey_end' });
  const deliveryDate = useWatch({ control, name: 'delivery_date' });
  const deliveryVolume = useWatch({ control, name: 'delivery_volume' });
  const targetId = useWatch({ control, name: 'target_id' });
  const target2Id = useWatch({ control, name: 'target2_id' });
  const customer = useWatch({ control, name: 'customer' });
  const hourFrom = useWatch({ control, name: 'hour_from' });
  const hourTo = useWatch({ control, name: 'hour_to' });
  const deliveryDetail = useWatch({ control, name: 'delivery_detail' });
  const sales = useWatch({ control, name: 'sales' });
  const picUserId = useWatch({ control, name: 'user_pic_id' });
  const expwayCharge = useWatch({ control, name: 'expway_charge' });
  const expwaySection = useWatch({ control, name: 'expway_section' });
  const sort = useWatch({ control, name: 'sort' });
  
  const canChangeDeliveryVolume = useMemo(() => {
    return !complete || ['IMPORT', 'EXPORT', 'IMPORT_EXPORT'].includes(targetType as string);
  }, [complete, targetType]);

  const shouldShowSale = useMemo(() => {
    return targetType === 'PURCHASE' || targetType === 'OTHER';
  }, [targetType]);

  const canCreateSale = useMemo(() => {
    return (!sales || sales.length === 0) && !complete && isValid;
  }, [complete, sales, isValid]);

  const canEditJourney = useMemo(() => {
    return !complete && !journeyEnterLater;
  }, [complete, journeyEnterLater]);

  const derivedDistance = useMemo(() => {
    if (!journeyStart || !journeyEnd) return 0;
    if (journeyEnd <= journeyStart) return 0;
    const dist = journeyEnd - journeyStart;

    return dist;
  }, [journeyStart, journeyEnd]);

  const confirmUserDataProvider = useRef(
    new DropdownDataProvider('users/dropdown/confirm_person', (userConfirm) => ({
      label: userConfirm.name,
      value: userConfirm.id,
    }))
  ).current;

  const picDataProvider = useRef(
    new DropdownDataProvider('users/dropdown/person_in_charge', (userPIC) => ({
      label: userPIC.name,
      value: userPIC.id,
    }))
  ).current;

  const inputUserDataProvider = useRef(
    new DropdownDataProvider('users/dropdown/input_person', (userInput) => ({
      label: userInput.name,
      value: userInput.id,
    }))
  ).current;

  const truckDropdownDataProvider = useRef(
    new DropdownDataProvider('trucks/dropdown', (truck) => ({
      label: truck.truck_name,
      value: truck.id,
    }))
  ).current;

  const driverDropdownDataProvider = useRef(
    new DropdownDataProvider('drivers/dropdown', (driver) => ({
      label: driver.driver_name,
      value: driver.id,
    }))
  ).current;

  const { refetch } = useQuery(['delivery', id], () => getDeliveryById(id as number), {
    enabled: !!id,
    onSettled: () => uiStore.hideLoading(),
    onSuccess: (data: Partial<Delivery>) => {
      if (data.delivery_volume === null || JSON.stringify(data.delivery_volume) === '[]') {
        data.delivery_volume = DEFAULT_DELIVERY_VOLUMES;
      }
      setCanChangeTarget1(true);
      setCanChangeTarget2(true);
      reset(data);
    },
  });

  const customerQuery = useQuery(['customer', customerId], () => getCustomerDetails(customerId!), {
    enabled: false,
    keepPreviousData: true,
    onSuccess: (customer) => {
      if (!customer.pic_id) customer.pic_name = undefined;
      setValue('customer', customer);
      // feedback UI: 125
      // only do this if picUserId isn't set.
      if (control._formState.isDirty && !picUserId && customer.pic_id) {
        userPicQuery.mutate(customer.pic_id);
      }
    },
  });

  const userPicQuery = useMutation((id: number) => getUserInfo(id), {
    onSuccess: (user) => {
      if (user.user_types.PERSON_IN_CHARGE === 1) {
        setValue('user_pic_id', user.id);
      }
    },
  });

  const createMutation = useMutation((payload: any) => createDelivery(payload), {
    onMutate: () => uiStore.showLoading(),
    onSettled: () => uiStore.hideLoading(),
    onError: (err) => showFormError(err),
    onSuccess: (data) => {
      toast.success('登録しました');
      control._formState.isDirty = false;

      setTimeout(() => {
        if (pathToNavigate) {
          navigate(pathToNavigate + data.id);
        } else {
          navigate(`/deliveries/${data.id}`, { replace: true });
        }
      }, 10);
    },
  });

  const updateMutation = useMutation((payload: any) => updateDelivery(id as number, payload), {
    onMutate: () => uiStore.showLoading(),
    onSettled: () => uiStore.hideLoading(),
    onError: (err) => showFormError(err),
    onSuccess: (_data) => {
      toast.success('登録しました');
      control._formState.isDirty = false;

      setTimeout(() => {
        if (pathToNavigate) {
          navigate(pathToNavigate + id);
        } else {
          refetch();
        }
      }, 10);
    },
  });

  const deleteMutation = useMutation((id: number) => deleteDelivery(id), {
    onMutate: () => uiStore.showLoading(),
    onSettled: () => uiStore.hideLoading(),
    onSuccess: () => {
      control._formState.isDirty = false;
      toast.success('登録しました');

      setTimeout(() => {
        navigate(`/deliveries`);
      }, 10);
    },
  });

  const completeMutation = useMutation(() => completeDelivery(id as number), {
    onMutate: () => uiStore.showLoading(),
    onSettled: () => uiStore.hideLoading(),
    onSuccess: () => refetch(),
  });

  const revertMutation = useMutation(() => revertCompleteDelivery(id as number), {
    onMutate: () => uiStore.showLoading(),
    onSettled: () => uiStore.hideLoading(),
    onSuccess: () => refetch(),
  });

  const setInfo = (...arg: any[]) => {
    let [data, target] = arg;
    target = target.toLowerCase();
    if (data) {
      if (!customerId) {
        setValue('customer_id', data.customer.id, { shouldDirty: true, shouldValidate: true });
      }
      if (['IMPORT', 'EXPORT', 'IMPORT_EXPORT'].includes(targetType as string)) {
        // On the client-side, the 7 device type codes have to be fixed.
        // However, API's response doesn't always return all 7 nor the
        // order in which they are returned in guaranteed to be the same
        // every time, so, in this case, we have to manually parse it.
        const getDeviceTypeCodes = async () => {
          let response: any;
          let copy: volume[] = [];
          let newDeliveryVolume: volume[] = [];
          try {
            uiStore.showLoading();
            if (target === 'import') {
              // example response: {P: 1, PW: 2}
              // This is for import only
              response = await countProductImportDetails(data.id);
            } else {
              response = await countProductExportDetails(data.id);
            }
            copy = JSON.parse(JSON.stringify(deliveryVolume));
            DEVICE_TYPE_CODES.forEach((code) => {
              if (code in response) {
                newDeliveryVolume = copy.map((volume, _index) => {
                  if (volume.device_type_code === code) {
                    volume[target as 'import' | 'export'] = response[code];
                  }
                  return volume;
                });
              }
            });
            setValue('delivery_volume', newDeliveryVolume, { shouldDirty: true });
          } catch (error: any) {
            toast.error(error.message);
          } finally {
            uiStore.hideLoading();
          }
        };
        getDeviceTypeCodes();
      }
    }
  };

  const openTargetDetailScreen = (payload: { id: number; target: string }) => {
    let formattedTarget: string;
    let path: string;
    const { id, target } = payload;

    if (target.endsWith('y')) {
      formattedTarget = target.replace(/y$/, 'ies').toLowerCase();
    } else {
      formattedTarget = target.concat('s').toLowerCase();
    }

    path = `${window.location.origin}/${formattedTarget}/${id}`;
    window.open(path);
  };

  const clearVolume = (type: 'IMPORT' | 'EXPORT') => {
    const copy: volume[] = JSON.parse(JSON.stringify(deliveryVolume));
    const newDeliveryVolume = copy.map((volume) => {
      if (type === 'IMPORT') {
        volume.import = 0;
      } else if (type === 'EXPORT') {
        volume.export = 0;
      }
      return volume;
    });

    setValue('delivery_volume', newDeliveryVolume);
  };

  const save = (path?: string) => {
    const values = getValues();
    let payload: any;

    if (id) {
      payload = getDirtyObject(dirtyFields, values);
      switch (true) {
        case !control._formState.isDirty:
          if (path) navigate(path + id);
          return;
        case control._formState.isDirty && JSON.stringify(payload) === '{}':
          if (path) navigate(path + id);
          return;
        default:
          break;
      }
    } else {
      payload = values;
    }
    removeKeys(dirtyFields, ['customer', 'updated_at']);

    setPathToNavigate(path);
    setTimeout(() => {
      id ? updateMutation.mutate(payload) : createMutation.mutate(payload);
    }, 10);
  };

  const onPlusClick = (dest: string) => {
    if (!control._formState.isDirty && id) {
      navigate(dest + id);
      return;
    }
    popupConfirmSaveBeforeNavigate({ onConfirm: () => save(dest) });
  };

  const onVolumeChange = (event: any, type: 'import' | 'export', index: number) => {
    const val = event.target.value;

    const isNumber = /^[０-９0-9]+$/.test(val);

    if (!isNumber) return;

    let copy: volume[] = JSON.parse(JSON.stringify(deliveryVolume));
    copy[index][type] = parseInt(convertToHalfWidth(val));
    setValue('delivery_volume', copy, {
      shouldDirty: true,
    });
  };

  const onDelete = () => {
    popupConfirmDelete(() => {
      deleteMutation.mutate(id as number);
    });
  };

  useBlockNavigation({ shouldBlock: control._formState.isDirty });

  useEffect(() => {
    if (id) uiStore.showLoading();
  }, []);

  useEffect(() => {
    if (customerId) customerQuery.refetch();
    else setValue('customer', undefined);
  }, [customerId]);

  return (
    <>
      <div className={styles.form}>
        <div className={styles.group1}>
          <div className={styles.group11}>
            <div className={styles.deliveryCode}>
              <TextFilter label="配送番号" control={control} keys={['delivery_code']} disabled />
            </div>

            <div className={styles.updatedAt}>
              <div>
                <Controller
                  control={control}
                  name="updated_at"
                  render={({ field: { value } }) => (
                    <CustomFilter
                      label="修正日時"
                      content={value && moment(new Date(value)).format(DATETIME_FORMAT)}
                    />
                  )}
                />
              </div>
              {!complete && (
                <button className="secondary-btn" disabled={!isValid} onClick={() => save()}>
                  登録
                </button>
              )}
            </div>
          </div>

          <div className={styles.group12}>
            <div className={styles.deliveryDate}>
              <DateFilter
                label="配送日"
                control={control}
                keys={['delivery_date']}
                required
                disabled={!!complete}
              />
            </div>

            <div className={styles.hour}>
              <HourRangeFilter
                disabled={!!complete}
                label="配送時間"
                onHourFromChange={(value) => {
                  setValue('hour_from', value ? value.value : null, { shouldDirty: true });
                }}
                onHourToChange={(value) => {
                  setValue('hour_to', value ? value.value : null, { shouldDirty: true });
                }}
                defaultFrom={hourFrom || undefined}
                defaultTo={hourTo || undefined}
              />
            </div>

            <div className={styles.timeOther}>
              <TextFilter control={control} keys={['delivery_time_other']} disabled={!!complete} />
            </div>
          </div>

          <div className={styles.group13}>
            <div className={styles.deliveryType}>
              <StaticDropdownFilter
                label="配送種別"
                control={control}
                setValue={setValue}
                isClearable={false}
                options={DELIVERY_TYPES}
                keys={['delivery_type']}
                placeholder="選択"
                disabled={!!complete}
              />
            </div>

            <div className={styles.sort}>
              <TextFilter
                label="並び順"
                control={control}
                keys={['sort']}
                disabled={!!complete}
              />
            </div>
          </div>

          <div className={styles.group14}>
            <div className={styles.targetType}>
              <RadioButtonFilter
                label="配送対象"
                options={DELIVERY_TARGET_TYPES}
                keys={['target_type']}
                control={control}
                defaultValue={targetType}
                disabled={!!complete}
                sideEffect={() => {
                  // put sideEffect here instead of useEffect because
                  // in development mode, React will mount a component twice
                  // which will break this logic if there is a target prop.
                  setValue('target_id', undefined, { shouldDirty: true });
                  setValue('target2_id', undefined, { shouldDirty: true });
                  setValue('delivery_volume_note', undefined);
                  if (!id) setValue('delivery_volume', DEFAULT_DELIVERY_VOLUMES);
                }}
              />
            </div>
          </div>

          <div className={styles.group15}>
            <div className={styles.targetId1}>
              {targetType && targetType !== 'OTHER' && (
                <DropdownSelectOptions
                  label="対象伝票番号1"
                  targetType={targetType}
                  setInfo={setInfo}
                  openTargetDetailScreen={openTargetDetailScreen}
                  onChange={(data: any) => {
                    setValue('target_id', data ? data.id : null, { shouldDirty: true });
                    if (targetType === 'IMPORT' || targetType === 'IMPORT_EXPORT') {
                      clearVolume('IMPORT');
                    }
                    if (targetType === 'EXPORT') clearVolume('EXPORT');
                  }}
                  disabled={!!complete || !canChangeTarget1}
                  targetId={targetId}
                  settingBtnDisabled={!!complete}
                  baseDate={deliveryDate}
                />
              )}
            </div>

            <div className={styles.targetId2}>
              {targetType === 'IMPORT_EXPORT' && (
                <DropdownSelectOptions
                  label="対象伝票番号2"
                  targetType="EXPORT"
                  setInfo={setInfo}
                  openTargetDetailScreen={openTargetDetailScreen}
                  onChange={(data: any) => {
                    setValue('target2_id', data ? data.id : null, { shouldDirty: true });
                    clearVolume('EXPORT');
                  }}
                  disabled={!!complete || !canChangeTarget2}
                  targetId={target2Id}
                  settingBtnDisabled={!!complete}
                  baseDate={deliveryDate}
                />
              )}
            </div>
          </div>
        </div>

        <div className={styles.group2}>
          <div className={styles.deliveryDetail}>
            <TextAreaFilter
              label="備考"
              value={deliveryDetail || ''}
              onChange={(event) => {
                setValue('delivery_detail', event.target.value, { shouldDirty: true });
              }}
              disabled={!!complete}
              rows={5}
            />
          </div>
        </div>

        <div className={styles.group9}>
          <div className={styles.fee}>
            <TextFilter
              label="運賃"
              control={control}
              keys={['amount_fee']}
              type="signed-number"
              disabled={!!complete}
            />
          </div>

          <div className={styles.extraFee}>
            <TextFilter
              label="追加運賃"
              control={control}
              keys={['amount_fee_extra']}
              type="signed-number"
              disabled={!!complete}
            />
          </div>

          <div className={styles.sales}>
            {shouldShowSale && (
              <Controller
                control={control}
                name="sales"
                render={({ field: { value } }) => {
                  const sale = value && value.length > 0 ? value[0] : undefined;
                  return (
                    <CustomFilter
                      label="売上"
                      plus={canCreateSale}
                      onPlusClick={() => {
                        onPlusClick(`/sales/create?target_type=DELIVERY&target_id=`);
                      }}
                      content={
                        <div className="d-flex align-items-center h-100" style={{ flex: 1 }}>
                          <div
                            className="d-flex align-items-center h-100"
                            style={{ borderRight: '1px solid darkgray', flex: 1 }}
                          >
                            <Link to={sale ? `/sales/${sale.id}` : ''} target="_blank">
                              {sale && sale.sale_code}
                            </Link>
                          </div>
                          <div className="d-flex align-items-center ps-2" style={{ flex: 1 }}>
                            {sale &&
                              sale.sale_details
                                .reduce(
                                  (acc: number, curr: any) =>
                                    acc + curr.product_quantity * curr.purchase_unit_price,
                                  0
                                )
                                .toLocaleString('en-US')}
                          </div>
                        </div>
                      }
                    />
                  );
                }}
              />
            )}
          </div>
        </div>

        <div className={styles.group3}>
          <div className={styles.group31}>
            <div className={styles.customerId}>
              <CustomerFilter
                control={control}
                label="顧客"
                keys={['customer_id']}
                required
                fallbackValue={customer}
                disabled={!!complete}
              />
            </div>
          </div>

          <div className={styles.group32}>
            <div className={styles.address}>
              <TextFilter label="住所" control={control} keys={['customer.address']} disabled />
            </div>

            <div className={styles.picNameHallName}>
              <TextFilter
                label="顧客担当者"
                control={control}
                keys={['customer.pic_name']}
                disabled
              />
              {/* <Controller
                control={control}
                name="customer.hall_name"
                render={({ field }) => (
                  <input
                    className="px-2"
                    disabled
                    value={field.value ? field.value : ''}
                    onBlur={field.onBlur}
                    onChange={field.onChange}
                    style={{
                      height: '30px',
                      width: 'fit-content',
                      flex: 2,
                      border: '1px solid lightgray',
                    }}
                    readOnly
                  />
                )}
              /> */}
            </div>
          </div>
          <div className={styles.group33}>
            <div className={styles.tel}>
              <TextFilter label="TEL" control={control} keys={['customer.tel']} disabled />
            </div>

            <div className={styles.fax}>
              <TextFilter label="FAX" control={control} keys={['customer.fax']} disabled />
            </div>
          </div>
        </div>

        <div className={styles.group4}>
          <div className={styles.userPicId}>
            <DynamicDropdownFilter
              label="担当者"
              control={control}
              keys={['user_pic_id']}
              dataProvider={picDataProvider}
              fallbackValue={picUser ? { label: picUser.name, value: picUser.id } : undefined}
              disabled={!!complete}
            />
          </div>

          <div className={styles.userInputId}>
            <DynamicDropdownFilter
              label="入力者"
              control={control}
              keys={['user_input_id']}
              dataProvider={inputUserDataProvider}
              fallbackValue={inputUser ? { label: inputUser.name, value: inputUser.id } : undefined}
              required
              disabled={!!complete}
            />
          </div>

          <div className={styles.userConfirmId}>
            <DynamicDropdownFilter
              label="確認者"
              control={control}
              keys={['user_confirm_id']}
              dataProvider={confirmUserDataProvider}
              fallbackValue={
                confirmUser ? { label: confirmUser.name, value: confirmUser.id } : undefined
              }
              disabled={!!complete}
            />
          </div>

          <div className={styles.complete}>
            <Controller
              control={control}
              name="complete"
              render={({ field: { value } }) => (
                <>
                  <div style={{ flex: 1 }}>
                    <CustomFilter
                      label="完了"
                      content={value === 1 ? '完了' : value === 0 ? '未完了' : null}
                    />
                  </div>
                  {id && (
                    <button
                      type="button"
                      className="secondary-btn ms-2"
                      onClick={() =>
                        value === 0 ? completeMutation.mutate() : revertMutation.mutate()
                      }
                    >
                      {value === 0 ? '完了' : '巻き戻し'}
                    </button>
                  )}
                </>
              )}
            />
          </div>
        </div>

        <div className={styles.group5}>
          <div className={styles.expwayCharge}>
            <TextFilter
              label="高速代"
              control={control}
              keys={['expway_charge']}
              type="signed-number"
              disabled={!!complete}
            />
          </div>

          <div className={styles.expwaySection}>
            <TextAreaFilter
              rows={3}
              label="高速区間"
              value={expwaySection || ''}
              onChange={(event) => {
                setValue('expway_section', event.target.value, { shouldDirty: true });
              }}
              disabled={!!complete}
            />
          </div>
        </div>

        <div className={styles.group6}>
          <div className={styles.volumeTable}>
            <div className={styles.tableWrapper}>
              <Controller
                control={control}
                name="delivery_volume"
                render={({ field: { value } }) => (
                  <div className="d-flex">
                    <div className={styles.tableLabel}>入出庫数</div>
                    <Table bordered>
                      <thead>
                        <tr>
                          {(value as volume[]).map((volume, index) => (
                            <td key={index}>{volume.device_type_code}</td>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        <tr className={styles.import}>
                          {(value as volume[]).map((volume, index) => (
                            <td key={volume.device_type_code}>
                              <input
                                value={volume.import}
                                onChange={(event) => {
                                  onVolumeChange(event, 'import', index);
                                }}
                                disabled={!canChangeDeliveryVolume}
                              />
                            </td>
                          ))}
                        </tr>
                        <tr className={styles.export}>
                          {(value as volume[]).map((volume, index) => (
                            <td key={volume.device_type_code}>
                              <input
                                value={volume.export}
                                onChange={(event) => {
                                  onVolumeChange(event, 'export', index);
                                }}
                                disabled={!canChangeDeliveryVolume}
                              />
                            </td>
                          ))}
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                )}
              />

              <div className="d-flex">
                <div className={styles.tableLabel}>その他</div>
                <Controller
                  control={control}
                  name="delivery_volume_note"
                  render={({ field }) => (
                    <textarea
                      aria-label="delivery_volume_note"
                      value={field.value || ''}
                      onChange={(event: any) => field.onChange(event.target.value)}
                      disabled={!canChangeDeliveryVolume}
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </div>

        <div className={styles.group7}>
          <div className={styles.truckId}>
            <DynamicDropdownFilter
              label="トラック"
              control={control}
              keys={['truck_id']}
              dataProvider={truckDropdownDataProvider}
              fallbackValue={truck ? { label: truck.truck_name, value: truck.id } : undefined}
              disabled={!!complete}
            />
          </div>

          <div className={styles.driverId}>
            <DynamicDropdownFilter
              label="運転手"
              control={control}
              keys={['driver_id']}
              dataProvider={driverDropdownDataProvider}
              fallbackValue={driver ? { label: driver.driver_name, value: driver.id } : undefined}
              disabled={!!complete}
            />
          </div>

          <div className={styles.assistant1}>
            <DynamicDropdownFilter
              label="助手"
              control={control}
              keys={['assistant1_id']}
              dataProvider={driverDropdownDataProvider}
              fallbackValue={
                assistant1 ? { label: assistant1.driver_name, value: assistant1.id } : undefined
              }
              disabled={!!complete}
            />
          </div>

          <div className={styles.assistant2}>
            <DynamicDropdownFilter
              label="助手"
              control={control}
              keys={['assistant2_id']}
              dataProvider={driverDropdownDataProvider}
              fallbackValue={
                assistant2 ? { label: assistant2.driver_name, value: assistant2.id } : undefined
              }
              disabled={!!complete}
            />
          </div>
        </div>

        <div className={styles.group8}>
          <div className={styles.journeyStart}>
            <TextFilter
              label="デジタコ開始"
              control={control}
              keys={['journey_start']}
              disabled={!canEditJourney}
            />
          </div>

          <div className={styles.journeyEnd}>
            <TextFilter
              label="デジタコ終了"
              control={control}
              keys={['journey_end']}
              disabled={!canEditJourney}
            />
          </div>

          <div className={styles.dist}>
            <CustomFilter label="走行距離" content={derivedDistance} disabled />
          </div>

          <div className={styles.fuel}>
            <TextFilter
              label="燃料(L)"
              control={control}
              keys={['journey_fuel']}
              disabled={!canEditJourney}
            />
          </div>

          <div className={styles.distance}>
            <TextFilter
              label="Bメーター(km)"
              control={control}
              keys={['journey_distance']}
              disabled={!canEditJourney}
            />
          </div>

          <div className={styles.adblue}>
            <TextFilter
              label="アドブルー(L)"
              control={control}
              keys={['journey_adblue']}
              disabled={!canEditJourney}
            />
          </div>

          <div className={styles.enterLater}>
            <Checkbox
              label="未提出"
              control={control}
              name="journey_enter_later"
              onChange={(isChecked: boolean) => {
                setValue('journey_enter_later', isChecked ? 1 : 0, { shouldDirty: true });
              }}
              disabled={!!complete}
            />
          </div>
        </div>
      </div>

      {id && (
        <div className="d-flex justify-content-end">
          <button className="primary-btn--delete" onClick={onDelete}>
            削除
          </button>
        </div>
      )}
    </>
  );
});

export default DeliveryDetailContainer;
