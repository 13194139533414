// Built-in
import { useEffect, useState } from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { Link } from 'react-router-dom';

// Component
import DeliveryDetailContainer from 'shared/containers/delivery-details';

// Hook
import { useQueryParams } from 'hooks/use-query-params';

const CreateDeliveryScreen = () => {
  const [loaded, setLoaded] = useState(false);
  const [target, setTarget] = useState<{ [key: string]: any } | undefined>(undefined);

  const query = useQueryParams();
  let params: any = {};
  for (let value of query.keys()) {
    params[value] = query.get(value);
  }

  useEffect(() => {
    if (params.target_type && params.target_id) {
      let target: any = {
        targetType: params.target_type,
        targetId: +params.target_id,
      };

      setTarget(target);
    }

    if (!params.id) setLoaded(true);
  }, []);

  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/home' }}>
          TOP
        </Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/deliveries' }}>
          配送
        </Breadcrumb.Item>
        <Breadcrumb.Item active>配送詳細</Breadcrumb.Item>
      </Breadcrumb>

      {loaded && <DeliveryDetailContainer target={target} />}
    </div>
  );
};

export default CreateDeliveryScreen;
