// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_tableWrapper__YiASC table tbody tr td {\n  text-align: center;\n}", "",{"version":3,"sources":["webpack://./src/screens/consumption-tax/styles.module.scss"],"names":[],"mappings":"AAEI;EACE,kBAAA;AADN","sourcesContent":[".tableWrapper {\n  table tbody tr {\n    td {\n      text-align: center;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableWrapper": "styles_tableWrapper__YiASC"
};
export default ___CSS_LOADER_EXPORT___;
