import React from 'react';
import ReactDOM from 'react-dom/client';
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import App from './App';
import { ja } from 'date-fns/locale/ja';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import './assets/styles/index.scss';
import 'react-datepicker/dist/react-datepicker.css';

registerLocale('ja',ja);
console.log(React. version);
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

