import { Table } from 'react-bootstrap';
import { SelectDataProvider } from 'shared/data-providers/select-data-provider';
import { IStockExportFilter, StockExportItem } from 'types/stock-export';
import { Spinner } from 'react-bootstrap';
import styles from './styles.module.scss';
import { ExportDetail } from 'types/export';
import { observer } from 'mobx-react';
import UIStore from 'stores/ui';
import useStores from 'hooks/use-stores';

interface IProps {
  dataProvider: SelectDataProvider<IStockExportFilter>;
  exportDate?: string;
}

const StockTable = observer(({ dataProvider, exportDate }: IProps) => {
  const uiStore: UIStore = useStores().uiStore;
  const headers = [
    { label: '種別' },
    { label: 'メーカー' },
    { label: '型式名' },
    { label: '全出' },
    { label: '枠番号', colspan: 2 },
    { label: 'セル番号', colspan: 2 },
    { label: '基盤番号' },
    { label: '入庫日' },
    { label: '入庫元' },
    { label: '在庫場所' },
    { label: '備考' },
    { label: '詳細' },
    { label: '納品先' },
    { label: 'メモ' },
    { label: '枠色' },
  ];

  const showErrorSelectPopup = () => {
    uiStore.showAlertBox({
      title: '選択不可',
      content: '以前に部分出庫した機器よりも前の出庫日では出庫できません。',
      type: 'error',
      onClose: () => {
        uiStore.hideAlertBox();
      },
    });
  };

  const isSelected = (id: number, buttonName: 'exportFull' | 'exportFrame' | 'exportBaseCell') => {
    let selectedStockType = '';
    // we need to know if the stock is selected
    // is it full or frame or base-cell
    const selected = dataProvider.selectedList.some((item) => {
      if (item.stock_id === id) {
        selectedStockType = item.export_stock_type;
        return true;
      }
      return false;
    });
    if (!selected) {
      return false;
    }

    switch (buttonName) {
      case 'exportFull':
        // if frame or base_cell has been selected, hide 'Full' button anyway
        return true;
      case 'exportFrame':
        return selectedStockType === 'FRAME' ? true : false;
      case 'exportBaseCell':
        return selectedStockType === 'BASE_CELL' ? true : false;
    }
  };

  // by color
  const isTracked = (item: any, type: 'FRAME' | 'BASE_CELL' | 'FULL') => {
    return dataProvider.colorTrackers.some(
      (tracker) => tracker.id === item.id && tracker.type === type
    );
  };

  // flag = 1 ~ not exported, 2 ~ exported frame, 3 ~ exported base-cell
  // in case current device type equals: PW, SW, SC, PC, only show frame/base-cell
  // buttons if the base device type code is one of [P, S].
  const canSelectFrame = (item: StockExportItem) => {
    const hasQR = !!item.qr;
    const isCurrentDeviceTypeValid = ['P', 'PH', 'PP', 'S', 'PW', 'SW'].includes(
      item.current_device_type_code
    );
    let isAvailable = false;
    switch (item.export_flag) {
      case 1:
        if (['PW', 'SW'].includes(item.current_device_type_code) || (['PP', 'PH'].includes(item.current_device_type_code) && (!item.frame_number || (!item.base_number && !item.cell_number)))) {
          isAvailable = false;
        } else {
          isAvailable = true;
        }
        break;
      case 3:
        isAvailable = true;
        break;
      case 2:
        isAvailable = false;
        break;
      default:
        isAvailable = false;
        break;
    }

    return hasQR && isCurrentDeviceTypeValid && isAvailable;
  };

  const canSelectBaseCell = (item: StockExportItem) => {
    const hasQR = !!item.qr;
    const isCurrentDeviceTypeValid = ['P', 'PH', 'PP', 'S', 'PC', 'SC'].includes(
      item.current_device_type_code
    );
    let isAvailable = false;
    switch (item.export_flag) {
      case 1:
        if (['PC', 'SC'].includes(item.current_device_type_code) || (['PP', 'PH'].includes(item.current_device_type_code) && (!item.frame_number || (!item.base_number && !item.cell_number)))) {
          isAvailable = false;
        } else {
          isAvailable = true;
        }
        break;
      case 2:
        isAvailable = true;
        break;
      case 3:
        isAvailable = false;
        break;
      default:
        isAvailable = false;
        break;
    }

    return hasQR && isCurrentDeviceTypeValid && isAvailable;
  };

  const selectStock = (item: StockExportItem, type: 'FULL' | 'FRAME' | 'BASE_CELL') => {
    let export_date = exportDate? new Date(exportDate) : null;
    let exported_date = item.export_details[0]?.export?.export_date? new Date(item.export_details[0].export.export_date) : null;
    if (export_date && exported_date && export_date < exported_date) {
      showErrorSelectPopup();
    } else {
      let deviceTypeCode = '';
      switch (type) {
        case 'FULL':
          deviceTypeCode = item.current_device_type_code;
          break;
        case 'FRAME':
          if (item.current_device_type_code == 'PH' || item.current_device_type_code == 'PP') {
            deviceTypeCode = item.current_device_type_code;
          } else if (item.current_device_type_code.includes('P')) {
            deviceTypeCode = 'PW';
          } else {
            deviceTypeCode = 'SW';
          }
          break;
        case 'BASE_CELL':
          if (item.current_device_type_code.includes('P')) {
            deviceTypeCode = 'PC';
          } else {
            deviceTypeCode = 'SC';
          }
          break;
      }

      // deviceTypeCode here is not the final value
      // there will be more checking in selectItem()
      let stockCopy: Partial<ExportDetail> = {
        stock_id: item.id,
        export_stock_type: type,
        device_type_code: deviceTypeCode,
        product_id: item.product_id,
        product: item.product,
        qr: item.qr,
        export_detail_note: item.stock_note,
        export_detail_detail: item.stock_detail,
        export_detail_memo: item.stock_memo,
        destination: item.destination,
        frame_color: item.frame_color,
        frame_number: item.frame_number,
        frame_code: item.frame_code,
        cell_number: item.cell_number,
        cell_code: item.cell_code,
        base_number: item.base_number,
        base_code: item.base_code,
        pallet_code: item.pallet_code,
      };
      dataProvider.selectItem(stockCopy, item.export_flag);
    }
  };

  const frameNotAvail = (item: StockExportItem) => {
    return isTracked(item, 'FRAME') || item.export_flag === 2;
  };

  const baseCellNotAvail = (item: StockExportItem) => {
    return isTracked(item, 'BASE_CELL') || item.export_flag === 3;
  };

  return (
    <Table bordered hover className={styles.stockTable}>
      <thead>
        <tr>
          <th>
            <div>
              入庫
              <br />
              種別
            </div>
          </th>
          {headers.map((header, index) => (
            <th key={index} colSpan={header.colspan}>
              <div>{header.label}</div>
            </th>
          ))}
        </tr>
      </thead>

      <tbody>
        {dataProvider.loading ? (
          <tr>
            <td colSpan={100} className="text-center">
              <Spinner animation="border" />
            </td>
          </tr>
        ) : (
          dataProvider.data.map((item: StockExportItem, index) => (
            <tr key={index}>
              <td className={styles.onelineStyle}>{item.import_device_type_code}</td>
              <td className={styles.onelineStyle}>{item.current_device_type_code}</td>
              <td>{item.product?.maker?.maker_name}</td>
              <td>{item.product?.model_name}</td>
              <td>
                {item.export_flag === 1 && !isSelected(item.id, 'exportFull') && (
                  <button onClick={() => selectStock(item, 'FULL')} className={styles.btn_outline}>
                    全
                  </button>
                )}
              </td>
              <td>
                {canSelectFrame(item) && !isSelected(item.id, 'exportFrame') && (
                  <button onClick={() => selectStock(item, 'FRAME')} className={styles.btn_outline}>
                    枠
                  </button>
                )}
              </td>
              <td className={`${frameNotAvail(item) && styles.notAvailable}`}>
                {item.frame_number}
              </td>
              <td>
                {canSelectBaseCell(item) && !isSelected(item.id, 'exportBaseCell') && (
                  <button
                    onClick={() => selectStock(item, 'BASE_CELL')}
                    className={styles.btn_outline}
                  >
                    セ
                  </button>
                )}
              </td>
              <td className={`${baseCellNotAvail(item) && styles.notAvailable}`}>
                {item.cell_number}
              </td>
              <td className={`${baseCellNotAvail(item) && styles.notAvailable}`}>
                {item.base_number}
              </td>
              <td>{item.stock_date}</td>
              <td>
                {item.customer?.hall_name ? item.customer?.hall_name : item.customer?.customer_name}
              </td>
              <td>{item.pallet_code}</td>
              <td>{item.stock_note}</td>
              <td>{item.stock_detail}</td>
              <td>{item.destination}</td>
              <td>{item.stock_memo}</td>
              <td>{item.frame_color}</td>
            </tr>
          ))
        )}
      </tbody>
    </Table>
  );
});

export default StockTable;
