// Built-ins
import { Control, Controller } from 'react-hook-form';
import moment from 'moment';

// Components
import FilterItem from '../filter-item';
import ReactDatePickerWrapper from '../datepicker-wrapper';

// Styles
import styles from './styles.module.scss';
import './styles.scss';

interface IProps {
  label: string;
  keys: [string];
  control: Control<any, any>;
  required?: boolean;
  disabled?: boolean;
  isClearable?: boolean;
}

const DateFilter = ({
  label,
  keys,
  control,
  required = false,
  disabled,
  isClearable = false,
}: IProps) => {
  return (
    <div className={styles.container}>
      <FilterItem label={label} required={required} />
      <Controller
        name={keys[0]}
        control={control}
        defaultValue=""
        rules={{ required }}
        render={({ field }) => (
          <ReactDatePickerWrapper
            selected={
              ['', undefined, null].includes(field.value)
                ? undefined
                : moment(field.value, 'YYYY-MM-DD').toDate()
            }
            onChange={(date: Date) => {
              if (date) field.onChange(moment(date).format('YYYY-MM-DD'));
              else field.onChange(undefined);
            }}
            // onChangeRaw={(e) => e.preventDefault()}
            disabled={disabled}
            className={styles.datePicker}
            dateFormat="yyyy/MM/dd"
            isClearable={isClearable}
          />
        )}
      />
    </div>
  );
};

export default DateFilter;
