// Built-in
import { useEffect } from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';

// Component
import DeliveryDetailContainer from 'shared/containers/delivery-details';

const DeliveryDetailScreen = () => {
  const params = useParams();
  const id = params.id ? (isNaN(parseInt(params.id)) ? undefined : parseInt(params.id)) : undefined;

  const navigate = useNavigate();

  useEffect(() => {
    if (!id) navigate(-1);
  }, []);

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/home' }}>
          TOP
        </Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/deliveries' }}>
          配送
        </Breadcrumb.Item>
        <Breadcrumb.Item active>配送詳細</Breadcrumb.Item>
      </Breadcrumb>
      <DeliveryDetailContainer id={id} />
    </>
  );
};

export default DeliveryDetailScreen;
