import { useEffect, useState } from 'react';
import { AsyncPaginate } from 'react-select-async-paginate';
import { observer } from 'mobx-react';

import { IDropdownDataProvider } from 'shared/data-providers/dropdown-data-provider';

import styles from './styles.module.scss';

interface IProps {
  dataProvider: IDropdownDataProvider<{ label: string; value: any }>;
  isClearable?: boolean;
  fallbackValue?: { label: string; value: string | number };
  required?: boolean;
  value?: string | number;
  onChange?: (newValue?: any) => void;
  disabled?: boolean;
  name?: string;
  className?: string;
}

const DynamicDropdown = observer(
  ({
    dataProvider,
    isClearable = true,
    fallbackValue,
    required = false,
    value,
    onChange,
    disabled,
    name,
    className,
  }: IProps) => {
    const [option, setOption] = useState<any>(null);

    const loadOptions = async () => {
      const val = await dataProvider.load();

      switch (name) {
        case 'device-type-code':
          const unSelectOption = {
            label: '未選択',
            value: undefined,
          };
          val.options.unshift(unSelectOption);
          break;
        default:
          break;
      }

      return val;
    };

    const onSelect = (newValue: any) => {
      onChange && onChange(newValue?.value);
    };

    const loadingMessage = () => <div>Loading</div>;

    useEffect(() => {
      if (value) {
        const filteredList = dataProvider.data.filter((item: any) => item.value === value);
        const opt = filteredList.length > 0 ? filteredList[0] : fallbackValue;
        setOption(opt);
      } else {
        setOption(null);
      }
    }, [value, dataProvider.data]);

    return (
      <AsyncPaginate
        className={className ? className : styles.dropdown}
        loadingMessage={loadingMessage}
        isSearchable={false}
        isClearable={isClearable}
        defaultOptions
        loadOptions={loadOptions}
        onChange={onSelect}
        value={option}
        placeholder="選択"
        menuPortalTarget={document.getElementById('dynamic-dropdown-menu-portal')?document.getElementById('dynamic-dropdown-menu-portal'):document.body}
        isDisabled={disabled}
      />
    );
  }
);

export default DynamicDropdown;
