// Built-in
import { useMemo, useRef, useState } from 'react';
import { Form, Breadcrumb, BreadcrumbItem } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useMutation } from 'react-query';
import moment from 'moment';

// Component
import DataTable from 'shared/components/data-table';
import TextFilter from 'shared/components/text-filter';
import DateFilter from 'shared/components/date-filter';
import DynamicDropdownFilter from 'shared/components/dynamic-dropdown-filter';
import EditModal from './edit-modal';

// Type
import { Product, IProductFilter, DEFAULT_PRODUCT_FILTER } from 'types/product';

// Store
import { DataProvider } from 'shared/data-providers/table-data-provider';
import { DropdownDataProvider } from 'shared/data-providers/dropdown-data-provider';
import UIStore from 'stores/ui';
import useStores from 'hooks/use-stores';

// Service
import { exportForm } from 'services/export-form';
import { createProduct, deleteProduct, updateProduct } from 'services/product';

// Hook
import { useFormError } from 'hooks/use-form-error';

// Style
import styles from './styles.module.scss';
import RadioButtonFilter from 'shared/components/radio-button-filter';
import { MdClose } from 'react-icons/md';

const ProductScreen = () => {
  const uiStore: UIStore = useStores().uiStore;

  const [editModal, setEditModal] = useState(false);
  const [selected, setSelected] = useState<any>(undefined);

  const dataProvider = useRef(
    new DataProvider<IProductFilter>({
      dataSource: 'products',
      localStorageKey: 'product_filters',
      defaultFilters: DEFAULT_PRODUCT_FILTER,
    })
  );
  const deviceTypeDataProvider = useRef(
    new DropdownDataProvider('device-types/dropdown', (device) => ({
      label: device.device_type_code,
      value: device.device_type_code,
    }))
  ).current;
  const makerDataProvider = useRef(
    new DropdownDataProvider('makers/dropdown', (maker) => ({
      label: `${maker.maker_code} | ${maker.maker_name}`,
      value: maker.id,
    }))
  ).current;

  const { control, handleSubmit, reset, setValue } = useForm<IProductFilter>({
    defaultValues: dataProvider.current.filters,
  });

  const before = useWatch({ control, name: 'before' });
  const after = useWatch({ control, name: 'after' });

  const { showFormError } = useFormError();

  const createMutation = useMutation((data) => createProduct(data), {
    onSuccess: () => {
      setEditModal(false);
      dataProvider.current.load();
    },
    onError: (err) => showFormError(err),
  });

  const saveMutation = useMutation((data: any) => updateProduct(selected!.id, data), {
    onSuccess: () => {
      setEditModal(false);
      dataProvider.current.load();
    },
    onError: (err) => showFormError(err),
  });

  const deleteMutation = useMutation((id: number) => deleteProduct(id), {
    onSuccess: () => dataProvider.current.load(),
  });

  const headers = useMemo(() => {
    return [
      { label: '商品CD', key: 'id' },
      { label: '種別' },
      { label: 'メーカー' },
      { label: '品名', key: 'product_name' },
      { label: '型式名' },
      { label: 'スペック' },
      { label: 'フリガナ' },
      { label: '導入開始日', oneLine: true, key: 'intro_start_date' },
      { label: 'メモ' },
      { label: '使用中止' },
      { label: '' },
    ];
  }, []);

  const delegate = (item: any) => {
    const itemId = item.id;
    const deviceTypeCode = item.device_type_code;
    const makerName = item.maker?.maker_name;
    const productName = item.product_name;
    const modelName = item.model_name;
    const spec = item.spec;
    const productKana = item.product_kana;
    const introStartDate =
      item.intro_start_date && moment(item.intro_start_date).format('YYYY/MM/DD');
    const productMemo = item.product_memo;
    const status = item.status === 1 ? '' : <MdClose className="status-icon" />;
    const editBtn = (item.id < 1 || item.id > 10) && (
      <button
        className="edit"
        onClick={() => {
          setSelected(item);
          setEditModal(true);
        }}
      >
        修
      </button>
    );

    return [
      itemId,
      deviceTypeCode,
      makerName,
      productName,
      modelName,
      spec,
      productKana,
      introStartDate,
      productMemo,
      status,
      editBtn,
    ];
  };

  const resetFilter = () => {
    reset(DEFAULT_PRODUCT_FILTER);
    dataProvider.current.clearFilters(['limit']);
  };

  const exportProductQRToPDF = async () => {
    const { device_type_code, maker_id } = dataProvider.current.filters;
    const narrowed = device_type_code && maker_id;

    if (!narrowed) {
      uiStore.showConfirmBox({
        title: 'メーカーと種別で絞り込んでもう一度試してください。',
        confirmText: 'OK',
        onConfirm: () => uiStore.hideConfirmBox(),
      });
      return;
    }

    try {
      uiStore.showLoading();
      const payload = JSON.parse(JSON.stringify(control._formValues));
      for (const [key, value] of Object.entries(payload)) {
        if ([undefined, null, ''].includes(value as any)) {
          payload[key] = undefined;
        }
      }
      payload.form_type = "R2401";
      await exportForm(`products/export-list`, payload);
    } catch (error: any) {
      uiStore.showAlertBox({
        title: 'エラー',
        type: 'error',
        content: error.message,
      });
    } finally {
      uiStore.hideLoading();
    }
  };

  const exportProductToExcel = async () => {
    try {
      uiStore.showLoading();
      const payload = JSON.parse(JSON.stringify(control._formValues));
      for (const [key, value] of Object.entries(payload)) {
        if ([undefined, null, ''].includes(value as any)) {
          payload[key] = undefined;
        }
      }
      payload.form_type = "R2402";
      await exportForm(`products/export-list`, payload);
    } catch (error: any) {
      uiStore.showAlertBox({
        title: 'エラー',
        type: 'error',
        content: error.message,
      });
    } finally {
      uiStore.hideLoading();
    }
  };

  const openCreateProductModal = () => {
    setSelected(undefined);
    setEditModal(true);
  };

  const showConfirmDeletePopup = () => {
    uiStore.showConfirmBox({
      title: 'このレコードを削除しますか？',
      confirmText: '削除する',
      cancelText: ' キャンセル',
      onConfirm: () => {
        uiStore.hideConfirmBox();
        deleteMutation.mutate(selected.id);
      },
    });
  };

  const onClose = () => {
    setEditModal(false);
  };

  const onSave = (data: any) => {
    saveMutation.mutate(data);
  };

  const onCreate = (data: any) => {
    createMutation.mutate(data);
  };

  const onDelete = () => {
    setEditModal(false);
    showConfirmDeletePopup();
  };

  return (
    <>
      <div className="list-screen">
        <Breadcrumb>
          <BreadcrumbItem linkAs={Link} linkProps={{ to: '/home' }}>
            TOP
          </BreadcrumbItem>
          <BreadcrumbItem active>マスター</BreadcrumbItem>
          <BreadcrumbItem active>商品</BreadcrumbItem>
        </Breadcrumb>

        <div className={styles.filterContainer}>
          <div className={styles.productId}>
            <TextFilter
              label="商品CD"
              control={control}
              keys={['product_id']}
              exceptChars={['.', ',']}
            />
          </div>

          <div className={styles.productKana}>
            <TextFilter label="フリガナ" control={control} keys={['product_kana']} />
          </div>

          <div className={styles.spec}>
            <TextFilter label="スペック" control={control} keys={['spec']} />
          </div>

          <div className={styles.productName}>
            <TextFilter label="品名・型式名" control={control} keys={['product_name']} />
          </div>

          <div className={styles.buttonGroup}>
            <button
              className="action-btn"
              onClick={handleSubmit((values) => {
                const newFilter = {
                  ...values,
                  sort: dataProvider.current.filters.sort,
                  sort_type: dataProvider.current.filters.sort_type,
                };

                dataProvider.current.setFilters(newFilter);
              })}
            >
              検索
            </button>
            <button className="action-btn" onClick={resetFilter}>
              クリア
            </button>
          </div>

          <div className={styles.deviceTypeCode}>
            <DynamicDropdownFilter
              label="種別"
              dataProvider={deviceTypeDataProvider}
              control={control}
              keys={['device_type_code']}
              setValue={setValue}
              placeholder="選択"
            />
          </div>

          <div className={styles.makerId}>
            <DynamicDropdownFilter
              label="メーカー"
              dataProvider={makerDataProvider}
              control={control}
              keys={['maker_id']}
              setValue={setValue}
              placeholder="選択"
            />
          </div>

          <div className={styles.introStartDate}>
            <DateFilter label="導入開始日" control={control} keys={['intro_start_date']} />
          </div>

          <div className={styles.checkbox}>
            <div
              className="d-flex align-items-center"
              style={{ minWidth: 'max-content', marginRight: '1rem' }}
            >
              <Controller
                control={control}
                name="before"
                render={({ field: { value, onChange } }) => (
                  <Form.Check
                    className="custom-checkbox"
                    checked={!!value}
                    defaultChecked={false}
                    onChange={(event) => {
                      onChange(event.target.checked ? 1 : 0);
                    }}
                  />
                )}
              />
              <div
                style={{ marginLeft: '0.25rem' }}
                onClick={() => {
                  setValue('before', before === 0 ? 1 : 0);
                }}
              >
                以前
              </div>
            </div>
            <div className="d-flex align-items-center" style={{ minWidth: 'max-content' }}>
              <Controller
                control={control}
                name="after"
                render={({ field: { value, onChange } }) => (
                  <Form.Check
                    className="custom-checkbox"
                    checked={!!value}
                    defaultChecked={true}
                    onChange={(event) => {
                      onChange(event.target.checked ? 1 : 0);
                    }}
                  />
                )}
              />
              <div
                style={{ marginLeft: '0.25rem' }}
                onClick={() => {
                  setValue('after', after === 0 ? 1 : 0);
                }}
              >
                以降
              </div>
            </div>
          </div>

          <div className={styles.status}>
            <RadioButtonFilter
              label={'使用中止'}
              control={control}
              keys={['status']}
              options={[
                { label: '全て', value: null },
                { label: '使用', value: 1 },
                { label: '使用中止', value: 0 },
              ]}
            />
          </div>
        </div>

        <div className="d-flex justify-content-end">
          <button className="create" onClick={openCreateProductModal}>
            作成
          </button>
        </div>

        <div className={styles.tableWrapper}>
          <DataTable<Product, IProductFilter>
            dataProvider={dataProvider.current}
            headers={headers}
            delegate={delegate}
          />
        </div>

        <div className={styles.container}>
          <div className={styles.buttonGroup}>
            <button className="primary-btn" onClick={exportProductQRToPDF}>
              機種QR印刷
            </button>
            <button className="primary-btn" onClick={exportProductToExcel}>
              エクセル出力
            </button>
          </div>
        </div>
      </div>

      {editModal && (
        <EditModal
          initialValues={selected}
          onClose={onClose}
          onSave={onSave}
          onCreate={onCreate}
          onDelete={onDelete}
        />
      )}
    </>
  );
};

export default ProductScreen;
