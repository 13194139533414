// Built-in
import { Breadcrumb } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';

// Component
import SaleDetailsContainer from 'shared/containers/sale-details';

const SaleDetailScreen = () => {
  const params = useParams();
  const id = params.id ? (isNaN(parseInt(params.id)) ? undefined : parseInt(params.id)) : undefined;

  return (
    <div id="sale-details-screen">
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/home' }}>
          TOP
        </Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/sales' }}>
          売上
        </Breadcrumb.Item>
        <Breadcrumb.Item active>売上詳細</Breadcrumb.Item>
      </Breadcrumb>
      <SaleDetailsContainer id={id} />
      <div id="dynamic-dropdown-menu-portal"></div>
    </div>
  );
};

export default SaleDetailScreen;
