// Built-in
import { useEffect, useRef, useState } from 'react';
import { Breadcrumb, Col, Row, Table } from 'react-bootstrap';
import { useForm, useWatch } from 'react-hook-form';
import { IoCloseCircleOutline } from 'react-icons/io5';
import { useMutation, useQuery } from 'react-query';
import { Link, useNavigate, useParams } from 'react-router-dom';

// Type
import TextFilter from 'shared/components/text-filter';

// Store
import UIStore from 'stores/ui';
import useStores from 'hooks/use-stores';

// Service
import { getExportById, registExportQRDetail, uploadExportQRFile } from 'services/export';

// Hook
import { useCallbackPrompt } from 'hooks/use-callback-prompt';

// Style
import styles from './styles.module.scss';

// Util
import { mappingLeftRightQrData } from 'utils/qr-handle';
import { toast } from 'react-toastify';
import useBlockNavigation from 'hooks/use-block-navigation';

const ExportQRFileScreen = () => {
  const navigate = useNavigate();

  const uiStore: UIStore = useStores().uiStore;

  const params = useParams();
  const id = params.id ? (isNaN(parseInt(params.id)) ? undefined : parseInt(params.id)) : undefined;

  const [leftProducts, setLeftProducts] = useState<Array<any>>([]);
  const [rightProducts, setRightProducts] = useState<Array<any>>([]);
  const [isDirty, setIsDirty] = useState(false);
  const loadedRef = useRef(false);

  const inputRef = useRef<HTMLInputElement | null>(null);

  const { control, reset } = useForm();
  const completed = useWatch({ control, name: 'complete' });

  useBlockNavigation({ shouldBlock: isDirty });

  const { refetch: _ } = useQuery(['export', id], () => getExportById(id as number), {
    enabled: !!id,
    onSuccess: (data) => {
      reset(data);
      const tempRight = data.export_details
        .filter((item: any) => item.qr === 1)
        .map((item: any) => item.export_qr_detail);
      setLeftProducts(data.export_details.filter((item: any) => item.qr === 1) || []);
      setRightProducts(tempRight);
      setTimeout(() => (loadedRef.current = true), 100);
    },
  });

  const uploadQRMutation = useMutation((file: File) => uploadExportQRFile(id as number, file), {
    onMutate: () => uiStore.showLoading(),
    onSettled: () => uiStore.hideLoading(),
    onSuccess: (data) => {
      const [left, right] = mappingLeftRightQrData(data);

      setLeftProducts(left);
      setRightProducts(right);
    },
  });

  const saveMutation = useMutation((data: any) => registExportQRDetail(id as number, data), {
    onMutate: () => uiStore.showLoading(),
    onSettled: () => uiStore.hideLoading(),
    onSuccess: (_data) => {
      // refetch();
      // cancel block navigation after mutating data.
      setIsDirty(false);
      // loadedRef.current = false;
      toast.success('登録しました');

      setTimeout(() => {
        navigate(`/exports/${id as number}`);
      }, 10);
    },
  });

  useEffect(() => {
    if (!id) navigate('/');
  }, []);

  useEffect(() => {
    if (loadedRef.current) setIsDirty(true);
  }, [leftProducts, rightProducts]);

  const checkFieldMatch = (item: any, key?: string) => {
    // if there is key then check the device_type_code
    // else, check the numbers
    if (key) {
      if (item?.check_match?.[key]) return styles.matched;
    } else {
      const isAnyMatched = ['frame_number', 'base_number', 'cell_number'].some(
        (code) => item?.check_match?.[code]
      );
      if (isAnyMatched) return styles.matched;
    }

    return styles.unmatched;
  };

  const removeRightRow = (index: number) => {
    const tempLeft = [...leftProducts];
    const tempRight = [...rightProducts];

    if (!!leftProducts[index]) {
      tempRight.splice(index, 1, null);
    } else {
      tempLeft.splice(index, 1);
      tempRight.splice(index, 1);
    }

    setLeftProducts(tempLeft);
    setRightProducts(tempRight);
  };

  const saveChanges = () => {
    let payload: Array<any> = [];
    for (let i = 0; i < leftProducts.length; i++) {
      if (leftProducts[i]) {
        let item: any = { id: leftProducts[i].id };
        item.export_qr_detail = rightProducts[i];
        payload.push(item);
      }
    }
    saveMutation.mutate(payload);
  };

  const headers = [
    '種別',
    '機種CD',
    '型式名',
    'セル番号',
    '枠番号',
    '基盤番号',
    '種別',
    'セル番号',
    '枠番号',
    '基盤番号',
    'セルコード',
    '枠コード',
    '基盤コード',
  ];
  if (!completed) headers.push('');

  return (
    <div>
      <div>
        <Breadcrumb>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/home' }}>
            TOP
          </Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/exports' }}>
            出庫
          </Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/exports/${id}` }}>
            出庫詳細
          </Breadcrumb.Item>
          <Breadcrumb.Item active>出庫QRファイル</Breadcrumb.Item>
        </Breadcrumb>

        <Row className="mb-4">
          <Col className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <TextFilter control={control} label="出庫番号" keys={['export_code']} disabled />
              {!completed && (
                <button className="primary-btn ms-2" onClick={() => inputRef.current?.click()}>
                  QRファイル
                </button>
              )}
              <input
                type="file"
                accept=".csv, .xls, .xlsx"
                className="d-none"
                ref={inputRef}
                onChange={(event) => {
                  if (event.target.files && event.target.files?.length > 0) {
                    const file = event.target.files[0];
                    uploadQRMutation.mutate(file);
                    event.target.value = '';
                  }
                }}
              />
            </div>
            {!completed && (
              <button className="primary-btn" onClick={saveChanges}>
                登録
              </button>
            )}
          </Col>
        </Row>

        <div style={{ overflowX: 'scroll' }}>
          <Table bordered hover className={styles.tableContainer}>
            <thead>
              <tr>
                <th colSpan={6} className={styles.orange}>
                  出庫詳細
                </th>
                <th colSpan={8} className={styles.purple}>
                  QRファイル
                </th>
              </tr>
              <tr>
                {headers.map((item, index) => (
                  <th key={index}>
                    <div>{item}</div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {leftProducts.map((item: any, index: number) => (
                <tr key={index}>
                  <td>{item?.device_type_code}</td>
                  <td>{item?.product_id}</td>
                  <td>
                    <div style={{ width: 'max-content' }}>{item?.product?.model_name}</div>
                  </td>
                  <td>{item?.cell_number}</td>
                  <td>{item?.frame_number}</td>
                  <td>{item?.base_number}</td>
                  <td className={checkFieldMatch(item, 'device_type_code')}>
                    {rightProducts[index]?.device_type_code}
                  </td>
                  <td className={checkFieldMatch(item)}>{rightProducts[index]?.cell_number}</td>
                  <td className={checkFieldMatch(item)}>{rightProducts[index]?.frame_number}</td>
                  <td className={checkFieldMatch(item)}>{rightProducts[index]?.base_number}</td>
                  <td>
                    <div style={{ width: 'max-content' }}>{rightProducts[index]?.cell_code}</div>
                  </td>

                  <td>
                    <div style={{ width: 'max-content' }}>{rightProducts[index]?.frame_code}</div>
                  </td>

                  <td>
                    <div style={{ width: 'max-content' }}>{rightProducts[index]?.base_code}</div>
                  </td>
                  {!completed && (
                    <td style={{ textAlign: 'center' }}>
                      <IoCloseCircleOutline
                        size={25}
                        className="delete-icon"
                        onClick={() => removeRightRow(index)}
                      />
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>

        <div className="d-flex justify-content-end">
          <button className={`primary-btn ${styles.backBtn}`} onClick={() => navigate(-1)}>
            キャンセル
          </button>
        </div>
      </div>
    </div>
  );
};

export default ExportQRFileScreen;
