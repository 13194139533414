import { del, post } from '.';

export const updateManufacturer = (id: number, data: any) => {
  return post('makers', [{ id, ...data }]);
};

export const createManufacturer = (data: any) => {
  return post('makers', [data]);
};

export const deleteManufacturer = (id: number) => {
  return del(`makers/${id}`);
};
