import moment from 'moment';

import { Customer } from './customer';
import { CustomerGroup } from './customer-group';
import { Product } from './product';
import { User } from './user';

export type Export = {
  id: number;
  export_code: string;
  export_type: 1 | 2;
  updated_at: string;
  input_date: string;
  export_date: string;
  group_id: number;
  customer_id: number;
  user_pic_id: number;
  user_input_id: number;
  user_confirm_id: number;
  used_body_quantity?: number;
  used_cell_quantity?: number;
  new_body_quantity?: number;
  new_cell_quantity?: number;
  total_body_cell_price: number;
  labor_cost: number;
  export_fee: number;
  export_detail: string;
  business_contact: string;
  export_memo: string;
  complete: 0 | 1;
  export_type_text?: string;
  count_deliveries: number;
  deliveries: Array<any>;
  customer: Customer;
  stocks: Array<any>;
  sales: Array<any>;
  export_details: Array<ExportDetail>;
};

export type ExportDetail = {
  id?: number;
  stock_id?: number;
  export_stock_type: string;
  device_type_code: string;
  product_id: number;
  qr: 0 | 1;
  frame_number: string | null;
  frame_code: string | null;
  cell_number: string | null;
  cell_code: string | null;
  base_number: string | null;
  base_code: string | null;
  pallet_code: string | null;
  export_detail_note: string;
  export_detail_detail: string;
  destination: string;
  export_detail_memo: string;
  frame_color: string;

  check_match?: { [key: string]: boolean };
  export_qr_detail?: { [key: string]: any };
  product?: Partial<Product>;
};

export interface IExportFilter {
  input_date_from: string | null;
  input_date_to: string | null;
  export_date_from: string | null;
  export_date_to: string | null;
  export_code: string | null;
  customer_id: number | null;
  user_input_id: string | null;
  group_id: number | null;
  created_delivery: 0 | 1 | null;
  complete: 0 | 1 | null;
  sort: string | null;
  sort_type: 'asc' | 'desc';
}

const now = new Date();
const date = now.getDate();
const month = now.getMonth();
const year = now.getFullYear();

export const DEFAULT_EXPORT_FILTER: IExportFilter = {
  input_date_from: moment(new Date(year, month - 1, date)).format('YYYY-MM-DD'),
  input_date_to: moment(now).format('YYYY-MM-DD'),
  export_date_from: null,
  export_date_to: null,
  export_code: null,
  customer_id: null,
  user_input_id: null,
  group_id: null,
  created_delivery: null,
  complete: null,
  sort: 'export_code',
  sort_type: 'asc',
};
