import { del, get, post, put } from 'services';

export const getProductById = (id: number) => get(`products/${id}`, {});

export const createProduct = (data: any) => post('products', data);

export const updateProduct = (id: number, data: any) => put(`products/${id}`, data);

export const deleteProduct = (id: number) => del(`products/${id}`);

export const getProductFilters = () => {
  const data = localStorage.getItem('product_filters');
  if (data) return JSON.parse(data);
  return null;
};

export const saveProductFilters = (filters: any) =>
  localStorage.setItem('product_filters', JSON.stringify(filters));

export const clearProductFilters = () => localStorage.removeItem('product_filters');
