import { Customer } from 'types/customer';
import { del, get, post, put } from '.';

export const getCustomersByGroup = (groupId: number) =>
  get('customers', { perPage: 500, group_id: groupId });

export const getCustomerByCode = (customerCode: string) =>
  get(`customers/bycode/${customerCode}`, {});

export const saveCustomer = (id: number, data: Omit<Customer, any>) => put(`customers/${id}`, data);

export const createCustomer = (data: any) => post('customers', data);

export const deleteCustomer = (id: number) => del(`customers/${id}`);

export const getCustomerDetails = (id: number) => get(`customers/${id}`, {});

export const getActiveCustomerByCode = (customerCode: string) =>
  get(`customers/bycode/active/${customerCode}`, {});
