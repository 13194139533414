import { Product } from 'types/product';

export const calculateProductPrice = (product: Partial<Product>, device_type_code?: string) => {
  if(product.lcd1?.lcd_code == "XX1") return "";  //set base_unit_price to undefined (not zero) when lcd_code is XX1.
  if(device_type_code && ['PW','SW'].includes(device_type_code)) return 0;
  return (
    (product.lcd1?.base_unit_price || 0) +
    (product.lcd2?.base_unit_price || 0) +
    (product.lcd3?.base_unit_price || 0)
  );
};

export const getProductLcds = (product: Partial<Product>) => {
  const arr = [];
  if (product.lcd1) arr.push(product.lcd1.lcd_code);
  if (product.lcd2) arr.push(product.lcd2.lcd_code);
  if (product.lcd3) arr.push(product.lcd3.lcd_code);
  return arr.join(', ');
};
