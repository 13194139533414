import classNames from 'classnames';
import { AiOutlinePlusCircle } from 'react-icons/ai';

import FilterItem from 'shared/components/filter-item';

interface IProps {
  label: string;
  plus?: boolean;
  onPlusClick?: () => void;
  buttonName?: any;
  onButtonClick?: () => void;
  content: any;
  className?: any;
  disabled?: boolean;
  noPadding?: boolean;
  required?: boolean;
}

const CustomFilter = ({
  label,
  plus = false,
  onPlusClick,
  buttonName = null,
  onButtonClick,
  content,
  className,
  disabled = true,
  noPadding = false,
  required = false,
}: IProps) => {
  return (
    <div className={classNames('d-flex', className)}>
      <FilterItem
        required={required}
        label={label}
        labelComponent={
          <div>
            {plus && (
              <button onClick={onPlusClick} type="button">
                <AiOutlinePlusCircle className="ms-2" />
              </button>
            )}
          </div>
        }
      />
      <div
        className="d-flex flex-row align-items-center"
        style={{
          border: '1px solid lightgray',
          flex: 1,
          padding: `${noPadding ? '0' : '0 0.5rem'}`,
          backgroundColor: disabled ? '#dedede' : 'white',
          overflowX: 'auto',
        }}
      >
        {content}
      </div>
      <div>
      {buttonName && (
        <button className="secondary-btn" onClick={onButtonClick}>
            {buttonName}
        </button>
        )}
      </div>
    </div>
  );
};

export default CustomFilter;
