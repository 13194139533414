// Built-in
import { useRef, useState } from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { MdOutlineCircle } from 'react-icons/md';
import Select from 'react-select';

// Component
import TimeRangeFilter from 'shared/components/time-range-filter';
import DataTable from 'shared/components/data-table';
import DynamicDropdownFilter from 'shared/components/dynamic-dropdown-filter';
import CustomerFilter from 'shared/components/customer-filter';
import TextFilter from 'shared/components/text-filter';
import RadioButtonFilter from 'shared/components/radio-button-filter';

// Type
import { DEFAULT_EXPORT_FILTER, Export, IExportFilter } from 'types/export';

// Store
import { DataProvider } from 'shared/data-providers/table-data-provider';
import { DropdownDataProvider } from 'shared/data-providers/dropdown-data-provider';

// Style
import styles from './styles.module.scss';

// Const

const groupDataProvider = new DropdownDataProvider('groups/dropdown', (item) => ({
  label: item.group_name,
  value: item.id,
}));

const ExportScreen = () => {
  const navigate = useNavigate();

  const dataProvider = useRef(
    new DataProvider<IExportFilter>({
      dataSource: 'exports',
      localStorageKey: 'export_filters',
      defaultFilters: DEFAULT_EXPORT_FILTER,
    })
  );
  const inputUserDataProvider = useRef(
    new DropdownDataProvider('users/dropdown/input_person', (item) => ({
      label: item.name,
      value: item.id,
    }))
  ).current;

  const { control, setValue, handleSubmit, reset } = useForm<IExportFilter>({
    defaultValues: dataProvider.current.filters,
  });

  const headers = [
    { label: '', oneLine: true, centered: true },
    { label: '出庫番号', key: 'export_code', oneLine: true },
    { label: '顧客' },
    { label: '入力日', key: 'input_date', oneLine: true },
    { label: '出庫日', key: 'export_date', oneLine: true },
    { label: '配送', oneLine: true, centered: true },
    { label: '詳細' },
    { label: '完了', centered: true },
    { label: '在庫', oneLine: true, centered: true },
  ];

  const [exportType, setExportType] = useState<number>(1);

  const resetFilters = () => {
    reset(DEFAULT_EXPORT_FILTER);
    dataProvider.current.clearFilters();
  };

  const search = handleSubmit((values) => {
    const newFilter = {
      ...values,
      sort: dataProvider.current.filters.sort,
      sort_type: dataProvider.current.filters.sort_type,
    };
    dataProvider.current.setFilters(newFilter);
  });

  return (
    <div className="list-screen">
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/home' }}>
          TOP
        </Breadcrumb.Item>
        <Breadcrumb.Item active>出庫</Breadcrumb.Item>
      </Breadcrumb>

      <div className={styles.filterContainer}>
        <div className={styles.inputDate}>
          <TimeRangeFilter
            label="入力日"
            control={control}
            keys={['input_date_from', 'input_date_to']}
          />
        </div>

        <div className={styles.exportDate}>
          <TimeRangeFilter
            label="出庫日"
            control={control}
            keys={['export_date_from', 'export_date_to']}
          />
        </div>

        <div className={styles.exportCode}>
          <TextFilter label="出庫番号" control={control} keys={['export_code']} />
        </div>

        <div className={styles.buttonGroup}>
          <button className="action-btn" onClick={search}>
            検索
          </button>
          <button className="action-btn" onClick={resetFilters}>
            クリア
          </button>
        </div>

        <div className={styles.wrap}></div>

        <div className={styles.groupId}>
          <DynamicDropdownFilter
            label="グループ"
            dataProvider={groupDataProvider}
            control={control}
            keys={['group_id']}
            setValue={setValue}
          />
        </div>

        <div className={styles.customerId}>
          <CustomerFilter label="顧客" control={control} keys={['customer_id']} />
        </div>

        <div className={styles.userInputId}>
          <DynamicDropdownFilter
            label="入力者"
            dataProvider={inputUserDataProvider}
            control={control}
            keys={['user_input_id']}
            setValue={setValue}
          />
        </div>

        <div className={styles.wrap}></div>

        <div className={styles.delivery}>
          <RadioButtonFilter
            label="配送"
            control={control}
            keys={['created_delivery']}
            options={[
              { label: '全て', value: null },
              { label: '作成済', value: 1 },
              { label: '未作成', value: 0 },
            ]}
          />
        </div>

        <div className={styles.complete}>
          <RadioButtonFilter
            label="完了"
            control={control}
            keys={['complete']}
            options={[
              { label: '全て', value: null },
              { label: '完了', value: 1 },
              { label: '未完了', value: 0 },
            ]}
          />
        </div>
      </div>

      <div className={`d-flex justify-content-end ${styles.createPart}`}>
        <div className={styles.dropdownView}>
          <Select
            options={[
              { label: '預かり', value: '1' },
              { label: '返却', value: '2' },
            ]}
            onChange={(newItem) => {
              if (newItem?.value) setExportType(parseInt(newItem.value));
            }}
            defaultValue={{ label: '預かり', value: '1' }}
          />
        </div>
        <button className="create" onClick={() => navigate('/exports/create?type=' + exportType)}>
          新しい出庫
        </button>
      </div>

      <div className={styles.tableWrapper}>
        <DataTable<Export, IExportFilter>
          dataProvider={dataProvider.current}
          headers={headers}
          delegate={(item) => [
            <button onClick={() => navigate(`/exports/${item.id}?type=${item.export_type}`)}>
              修正
            </button>,
            <Link to={`/exports/${item.id}?type=${item.export_type}`}>{item.export_code}</Link>,
            `${item.customer?.customer_name}${
              item.customer?.hall_name ? '　' + item.customer?.hall_name : ''
            }`,
            item.input_date,
            item.export_date,
            item.deliveries?.length > 0 ? (
              <div>
                {item.deliveries.map((d, index) => (
                  <>
                    <Link to={`/deliveries/${d.id}`} target="_blank" style={{ display: 'block' }}>
                      {d.delivery_code}
                    </Link>
                  </>
                ))}
              </div>
            ) : (
              item.complete === 0 && (
                <button
                  onClick={() =>
                    navigate(`/deliveries/create?target_type=EXPORT&target_id=${item.id}`)
                  }
                >
                  作成
                </button>
              )
            ),
            item.export_detail,
            item.complete === 1 && <MdOutlineCircle className="status-icon" />,
            item.stocks?.length > 0 ? (
              <MdOutlineCircle className="status-icon" />
            ) : (
              <button onClick={() => navigate(`/inventories?export_code=${item.export_code}`)}>
                在庫
              </button>
            ),
          ]}
        />
      </div>
    </div>
  );
};

export default ExportScreen;
