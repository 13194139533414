import UIStore from 'stores/ui';
import useStores from './use-stores';

export const useFormError = () => {
  const uiStore: UIStore = useStores().uiStore;

  const showFormError = (err: any) => {
    let errArr = [];
    if ((err.code == "ValidationException" || !err.code) && err.errors) {
      if (err.message) {
        errArr.push(err.message);
      }
      for (let key of Object.keys(err.errors)) {
        errArr.push(err.errors[key][0]);
      }
      uiStore.showAlertBox({ title: 'エラー', content: `${errArr.join('\n')}` });
    } else uiStore.showAlertBox({ title: 'エラー', content: err.message });
  };

  return { showFormError };
};
