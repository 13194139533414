import moment from 'moment';
import { useRef, useState } from 'react';
import { Modal, Table } from 'react-bootstrap';
import { AiOutlineClose } from 'react-icons/ai';
import { BsPlusCircle } from 'react-icons/bs';
import { IoCloseCircleOutline } from 'react-icons/io5';
import { NumericFormat } from 'react-number-format';
import { useQuery } from 'react-query';
import Select from 'react-select';
import { v4 } from 'uuid';

import useStores from 'hooks/use-stores';
import { getWithdrawalInfo } from 'services/withdrawal';
import ReactDatePickerWrapper from 'shared/components/datepicker-wrapper';
import UIStore from 'stores/ui';
import { Withdrawal, WithdrawalCustomer, withdrawalTypes } from 'types/withdrawal';
import { popupConfirmBack, popupConfirmDelete } from 'utils/modals';

import styles from './styles.module.scss';

interface IProps {
  withdrawalCustomer: WithdrawalCustomer;
  date: {
    year: number;
    month: number;
  };
  onClose: any;
  onUpdate: (payload: any) => void;
  completed: boolean;
}

const EditModal = ({ withdrawalCustomer, date, onClose, onUpdate, completed }: IProps) => {
  const formattedDate =
    date.year + '/' + (`${date.month}`.length === 1 ? `0${date.month}` : `${date.month}`);

  const [withdrawalList, setWithdrawalList] = useState<Array<Partial<Withdrawal>>>([]);

  const updateWithdrawal = (index: number, payload: any) => {
    const newList = [...withdrawalList];
    newList.splice(index, 1, { ...withdrawalList[index], ...payload });

    setWithdrawalList(newList);
  };
  const initialValues = useRef<any>();
  const uiStore: UIStore = useStores().uiStore;

  const removeWithdrawal = (index: number) => {
    const newList = [...withdrawalList];
    newList.splice(index, 1);

    setWithdrawalList(newList);
  };

  const onRegisterClick = (_event: any) => {
    let formattedList = withdrawalList.map((withdrawal) => {
      if (typeof withdrawal.id === 'string') {
        return {
          ...withdrawal,
          id: undefined,
        };
      }

      return withdrawal;
    });
    const isMissingDate = formattedList.some((withdrawal) => !withdrawal.withdrawal_date);
    const withdrawalSum = formattedList.reduce((acc, curr) => {
      return acc + (curr.out_amount || 0);
    }, 0);

    if (withdrawalSum > withdrawalCustomer.last_request_balance) {
      uiStore.showAlertBox({
        title: 'エラー',
        content: '出金額が前回買掛額より高くすることはできません。',
      });
      return;
    }
    if (isMissingDate) {
      uiStore.showAlertBox({
        title: 'エラー',
        content: ' 出金日は、必ず指定してください。',
      });
      return;
    }

    let payload = {
      month: date.month,
      year: date.year,
      customerId: withdrawalCustomer.customer.id,
      data: {
        withdrawals: formattedList,
      },
    };
    onUpdate(payload);
  };

  const onBackClick = (_event: any) => {
    let isDirty = JSON.stringify(initialValues.current) !== JSON.stringify(withdrawalList);

    popupConfirmBack(isDirty, onClose);
  };

  const CustomerInfoTable = () => (
    <Table bordered className={styles.customerInfoTable}>
      <thead>
        <tr>
          <th>処理年月</th>
          <th>顧客CD</th>
          <th>顧客</th>
          <th>前回買掛額</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <span>{formattedDate}</span>
          </td>
          <td>
            <span>{withdrawalCustomer.customer.customer_code}</span>
          </td>
          <td>
            <span>
              {`${withdrawalCustomer.customer.customer_name} ${
                withdrawalCustomer.customer.hall_name || ''
              }`}
            </span>
          </td>
          <td>
            <span>{withdrawalCustomer.total_amount_request.toLocaleString('en-US')}</span>
          </td>
        </tr>
      </tbody>
    </Table>
  );

  const queryData = useQuery(
    ['withdrawal', date.month, date.year, withdrawalCustomer.customer.id],
    () =>
      getWithdrawalInfo({
        year: date.year,
        month: date.month,
        customerId: withdrawalCustomer.customer.id,
      }),
    {
      onSuccess: (data: any) => {
        setWithdrawalList(data.withdrawals);
        initialValues.current = data.withdrawals;
      },
    }
  );

  return (
    <Modal show centered onBackdropClick={onClose} dialogClassName={styles.dialog}>
      <div className={styles.content}>
        <div className={styles.head}>
          <h3>出金登録モーダル</h3>
          <button onClick={onBackClick}>
            <AiOutlineClose />
          </button>
        </div>
        <CustomerInfoTable />
        <Table bordered className={styles.withdrawalTable}>
          <thead>
            <tr>
              <th>出金日</th>
              <th>出金方法</th>
              <th>出金額</th>
              {!completed && (
                <th>
                  <button
                    onClick={(_event: any) => {
                      setWithdrawalList([
                        ...withdrawalList,
                        {
                          withdrawal_date: '',
                          withdrawal_type: 1,
                          out_amount: 0,
                          id: v4(),
                        },
                      ]);
                    }}
                  >
                    <BsPlusCircle />
                  </button>
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {withdrawalList.map((withdrawal, index) => (
              <tr key={withdrawal.id}>
                <td>
                  <ReactDatePickerWrapper
                    className={styles.dateInput}
                    selected={
                      withdrawal.withdrawal_date
                        ? moment(withdrawal.withdrawal_date, 'YYYY-MM-DD').toDate()
                        : null
                    }
                    dateFormat="yyyy/MM/dd"
                    onChange={(date) => {
                      updateWithdrawal(index, {
                        withdrawal_date: moment(date).format('YYYY-MM-DD'),
                      });
                    }}
                    disabled={completed}
                  />
                </td>
                <td>
                  <Select
                    className={styles.dropdown}
                    value={withdrawalTypes[(withdrawal.withdrawal_type as number) - 1]}
                    options={withdrawalTypes}
                    isClearable={false}
                    isSearchable={false}
                    onChange={(newValue) => {
                      updateWithdrawal(index, {
                        withdrawal_type: newValue.value,
                      });
                    }}
                    isDisabled={completed}
                  />
                </td>
                <td>
                  <NumericFormat
                    type="text"
                    value={withdrawal.out_amount}
                    decimalScale={0}
                    thousandSeparator=","
                    allowNegative={true}
                    allowLeadingZeros={false}
                    allowedDecimalSeparators={[]}
                    onValueChange={(values, sourceInfo) => {
                      updateWithdrawal(index, {
                        out_amount: values.floatValue,
                      });
                    }}
                    disabled={completed}
                  />
                </td>
                {!completed && (
                  <td>
                    <div className={styles.deleteBtnWrapper}>
                      <IoCloseCircleOutline
                        size={20}
                        aria-label="remove-row"
                        className="delete-icon"
                        onClick={(_event: any) => popupConfirmDelete(() => removeWithdrawal(index))}
                      />
                    </div>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </Table>

        {!completed && (
          <button aria-label="register" className="secondary-btn" onClick={onRegisterClick}>
            登録
          </button>
        )}
      </div>
    </Modal>
  );
};

export default EditModal;
