// Built-ins
import { useRef, useState } from 'react';
import { Modal, Table } from 'react-bootstrap';
import { AiOutlineClose } from 'react-icons/ai';
import { useQuery } from 'react-query';
import { NumericFormat } from 'react-number-format';
import { v4 } from 'uuid';
import { BsPlusCircle } from 'react-icons/bs';
import { IoCloseCircleOutline } from 'react-icons/io5';
import moment from 'moment';

// Components
import Select from 'react-select';
import ReactDatePickerWrapper from 'shared/components/datepicker-wrapper';

// Services
import { getDepositInfo } from 'services/deposit';

// Stores
import UIStore from 'stores/ui';
import useStores from 'hooks/use-stores';

// Types
import { Deposit, DepositCustomer, depositTypes } from 'types/deposit';
import { popupConfirmBack, popupConfirmDelete } from 'utils/modals';

// Styles
import styles from './styles.module.scss';

interface IProps {
  depositCustomer: DepositCustomer;
  date: {
    year: number;
    month: number;
  };
  onClose: any;
  onUpdate: (payload: any) => void;
  completed: boolean;
}

const EditModal = ({ depositCustomer, date, onClose, onUpdate, completed }: IProps) => {
  const formattedDate =
    date.year + '/' + (`${date.month}`.length === 1 ? `0${date.month}` : `${date.month}`);

  const [depositList, setDepositList] = useState<Array<Partial<Deposit>>>([]);
  const uiStore: UIStore = useStores().uiStore;

  const updateDeposit = (index: number, payload: any) => {
    const newList = [...depositList];
    newList.splice(index, 1, { ...depositList[index], ...payload });

    setDepositList(newList);
  };
  const initialValues = useRef<any>();

  const removeDeposit = (index: number) => {
    const newList = [...depositList];
    newList.splice(index, 1);

    setDepositList(newList);
  };

  const onRegisterClick = (_event: any) => {
    let formattedList = depositList.map((withdrawal) => {
      if (typeof withdrawal.id === 'string') {
        return {
          ...withdrawal,
          id: undefined,
        };
      }
      withdrawal.in_amount = withdrawal.in_amount ? withdrawal.in_amount : 0;
      return withdrawal;
    });
    let isMissingDate = formattedList.some((deposit) => !deposit.deposit_date);

    const depositSum = formattedList.reduce((acc, curr) => {
      return acc + (curr.in_amount || 0);
    }, 0);

    if (depositSum > depositCustomer.last_request_balance) {
      uiStore.showAlertBox({
        title: 'エラー',
        content: '入金額が前回請求額より高くすることはできません。',
      });
      return;
    }
    if (isMissingDate) {
      uiStore.showAlertBox({
        title: 'エラー',
        content: '入金日は、必ず指定してください。',
      });
      return;
    }

    let payload = {
      month: date.month,
      year: date.year,
      customerId: depositCustomer.customer.id,
      data: {
        deposits: formattedList,
      },
    };
    onUpdate(payload);
  };

  const onBackClick = (_event: any) => {
    let isDirty = JSON.stringify(initialValues.current) !== JSON.stringify(depositList);

    popupConfirmBack(isDirty, onClose);
  };

  const CustomerInfoTable = () => (
    <Table bordered className={styles.customerInfoTable}>
      <thead>
        <tr>
          <th>処理年月</th>
          <th>顧客CD</th>
          <th>顧客</th>
          <th>前回請求額</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <span>{formattedDate}</span>
          </td>
          <td>
            <span>{depositCustomer.customer.customer_code}</span>
          </td>
          <td>
            <span>
              {`${depositCustomer.customer.customer_name} ${
                depositCustomer.customer.hall_name || ''
              }`}
            </span>
          </td>
          <td>
            <span>{depositCustomer.total_amount_request.toLocaleString('en-US')}</span>
          </td>
        </tr>
      </tbody>
    </Table>
  );

  const queryData = useQuery(
    ['deposit', date.month, date.year, depositCustomer.customer.id],
    () =>
      getDepositInfo({
        year: date.year,
        month: date.month,
        customerId: depositCustomer.customer.id,
      }),
    {
      onSuccess: (data: any) => {
        setDepositList(data.deposits);
        initialValues.current = data.deposits;
      },
    }
  );

  return (
    <Modal show centered onBackdropClick={onClose} dialogClassName={styles.dialog}>
      <div className={styles.content}>
        <div className={styles.head}>
          <h3>入金登録モーダル</h3>
          <button onClick={onBackClick}>
            <AiOutlineClose />
          </button>
        </div>
        <CustomerInfoTable />
        <Table bordered className={styles.withdrawalTable}>
          <thead>
            <tr>
              <th>入金日</th>
              <th>入金方法</th>
              <th>入金額</th>
              {!completed && (
                <th>
                  <button
                    onClick={(_event: any) => {
                      setDepositList([
                        ...depositList,
                        {
                          deposit_date: '',
                          deposit_type: 1,
                          in_amount: 0,
                          id: v4(),
                        },
                      ]);
                    }}
                  >
                    <BsPlusCircle />
                  </button>
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {depositList.map((deposit, index) => (
              <tr key={deposit.id}>
                <td>
                  <ReactDatePickerWrapper
                    className={styles.dateInput}
                    selected={
                      deposit.deposit_date
                        ? moment(deposit.deposit_date, 'YYYY-MM-DD').toDate()
                        : null
                    }
                    dateFormat="yyyy/MM/dd"
                    onChange={(date) => {
                      updateDeposit(index, {
                        deposit_date: moment(date).format('YYYY-MM-DD'),
                      });
                    }}
                    disabled={completed}
                  />
                </td>
                <td>
                  <Select
                    className={styles.dropdown}
                    value={depositTypes.find(type => type.value === deposit.deposit_type)}
                    options={depositTypes}
                    isClearable={false}
                    isSearchable={false}
                    onChange={(newValue) => {
                      updateDeposit(index, {
                        deposit_type: newValue.value,
                      });
                    }}
                    isDisabled={completed}
                  />
                </td>
                <td>
                  <NumericFormat
                    type="text"
                    value={deposit.in_amount}
                    decimalScale={0}
                    thousandSeparator=","
                    allowNegative={true}
                    allowLeadingZeros={false}
                    allowedDecimalSeparators={[]}
                    onValueChange={(values, sourceInfo) => {
                      updateDeposit(index, {
                        in_amount: values.floatValue,
                      });
                    }}
                    disabled={completed}
                  />
                </td>
                {!completed && (
                  <td>
                    <div className={styles.deleteBtnWrapper}>
                      <IoCloseCircleOutline
                        size={20}
                        aria-label="remove-row"
                        className="delete-icon"
                        onClick={(_event: any) => popupConfirmDelete(() => removeDeposit(index))}
                      />
                    </div>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </Table>

        {!completed && (
          <button aria-label="register" className="secondary-btn" onClick={onRegisterClick}>
            登録
          </button>
        )}
      </div>
    </Modal>
  );
};

export default EditModal;
