export const deliveryTargetTypePipe = (type: string | null | undefined) => {
  if (!type) {
    return '';
  }

  const arr: {
    [key: string]: any;
  } = {
    SALE: '売上',
    OTHER: 'その他',
    PURCHASE: '買取',
    IMPORT: '入庫',
    EXPORT: '出庫',
    IMPORT_EXPORT: '入出庫',
  };
  return arr[type];
};

export const deliveryTypePipe = (type: number) => {
  const arr: {
    [key: string]: any;
  } = {
    1: '常用', // standard
    2: '午前', // morning
    3: '午後', // afternoon
    4: '夜間', // evening
    5: 'リサイクル', // recycle
    6: '来社', // company
    7: 'その他', // other
  };

  return arr[`${type}`];
};

export const hourFromToPipe = (from: string | null | undefined, to: string | null | undefined) => {
  let returnValue: string;
  if (!from && !to) {
    returnValue = '';
  } else {
    returnValue = (from?.replace(/:00$/, '') || '') + ' ~ ' + (to?.replace(/:00$/, '') || '');
  }
  return returnValue;
};
