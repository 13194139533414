import { del, get, post, put } from 'services';

export const createDelivery = (payload: any) => post(`deliveries`, payload);

export const getDeliveryById = (id: number | string) => get(`deliveries/${id}`, {});

export const updateDelivery = (id: number | string, payload: any) =>
  put(`deliveries/${id}`, payload);

export const deleteDelivery = (id: number | string) => del(`deliveries/${id}`);

export const completeDelivery = (id: number | string) => post(`deliveries/completion/${id}`, {});

export const revertCompleteDelivery = (id: number | string) => post(`deliveries/revert/${id}`, {});

export const duplicateDeliveries = (payload: { ids: string; date: string }) => {
  const { ids, date } = payload;
  return post(`deliveries/duplicate/${ids}/${date}`, {});
};
