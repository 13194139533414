import { useEffect, useMemo, useRef, useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { useForm, Controller, useWatch } from 'react-hook-form';
import { MdClose } from 'react-icons/md';
import { AsyncPaginate } from 'react-select-async-paginate';
import moment from 'moment';

import { get } from 'services';
import { Lcd } from 'types/lcd';
import { Product } from 'types/product';
import { convertFileToBase64, removeKeys } from 'utils';
import { API_URL } from 'configs';

import styles from './styles.module.scss';
import images from 'assets/images/index';
import { observer } from 'mobx-react';
import UIStore from 'stores/ui';
import useStores from 'hooks/use-stores';
import { popupConfirmBack } from 'utils/modals';
import { mbStrWidth } from 'utils/validator';
import ReactDatePickerWrapper from 'shared/components/datepicker-wrapper';

interface IProps {
  initialValues?: Product;
  onClose: () => void;
  onSave: (data: any) => void;
  onCreate: (data: any) => void;
  onDelete: () => void;
}

const EditModal = observer(({ initialValues, onClose, onSave, onCreate, onDelete }: IProps) => {
  const [file, setFile] = useState<File | string | null>(initialValues?.cover_image as string);
  const [qrProductNameWidth, setQRProductNameWidth] = useState<number>(
    initialValues ? mbStrWidth(initialValues.qr_product_name || '') : 0
  );
  const inputRef = useRef<HTMLInputElement | null>(null);
  const uiStore: UIStore = useStores().uiStore;

  const initialFormValues = useMemo(() => {
    let values: any = {};
    if (initialValues) {
      values = initialValues;
      return values;
    }
    values = {
      qr_output: 1,
      count_target: 1,
      status: 1,
      cover_delete: undefined,
      device_type_code: null,
      intro_start_date: null,
    };
    return values;
  }, []);

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { isDirty, isValid },
  } = useForm<Product>({
    defaultValues: initialFormValues,
    mode: 'onChange',
  });

  const qrProductName = useWatch({ control, name: 'qr_product_name' });

  const modalTitle = useMemo(() => {
    return !initialValues ? '商品作成モーダル' : '商品編集モーダル';
  }, [initialValues]);

  const onSubmit = (data: any) => {
    removeKeys(data, ['maker', 'lcd1', 'lcd2', 'lcd3']);

    // cover_image returned from API is not the same format as one when created.
    // API only accepts string base64. If the user doesn't change the image,
    // we can safely remove it from the data object before sending it to the
    // server.
    if (data.cover_image) {
      if ((data.cover_image as string).includes('/storage')) delete data.cover_image;
    }

    initialValues ? onSave(data) : onCreate(data);
  };

  const onErrors = (errors: any) => {
    console.log('Errors: ', errors);
  };

  const selectFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files?.length > 0) {
      setFile(event.target.files[0]);
      convertFileToBase64(event.target.files[0], (result) => {
        setValue('cover_image', result, { shouldDirty: true });
        setValue('cover_delete', undefined);
      });
    }
  };

  useEffect(() => {
    let width = mbStrWidth(qrProductName || '');
    setQRProductNameWidth(width);
  }, [qrProductName]);

  useEffect(() => {
    register('cover_image');
    register('cover_delete');
  }, []);

  return (
    <Modal show backdrop="static" centered size="lg">
      <div className="edit-modal">
        <div className="edit-modal--title">
          {modalTitle}
          <button
            onClick={(event: any) => {
              event.preventDefault();
              popupConfirmBack(isDirty, () => onClose());
            }}
          >
            <MdClose />
          </button>
        </div>
        <Form onSubmit={handleSubmit(onSubmit, onErrors)}>
          {initialValues && (
            <Form.Group className={styles.group}>
              <Form.Label>商品CD</Form.Label>
              <Form.Control
                readOnly={!!initialValues?.id}
                {...register('id', { required: true })}
              />
            </Form.Group>
          )}

          <Form.Group className={styles.group}>
            <Form.Label className="required">種別</Form.Label>
            <Controller
              control={control}
              name="device_type_code"
              render={() => (
                  <AsyncPaginate
                    className={styles.dropdown}
                    loadingMessage={() => <div>Loading</div>}
                    isSearchable={false}
                    placeholder="選択"
                    defaultValue={
                      initialValues?.device_type
                        ? {
                            label: `${initialValues.device_type.device_type_code} | ${initialValues.device_type.device_type_name}`,
                            value: initialValues.device_type_code,
                          }
                          : undefined
                    }
                    loadOptions={async () => {
                      try {
                        const data = await get('device-types/dropdown', {});
                        return {
                          options: data.map((item: any) => ({
                            label: `${item.device_type_code} | ${item.device_type_name}`,
                            value: item.device_type_code,
                          })),
                        };
                      } catch (err) {
                        return { options: [] };
                      }
                    }}
                    onChange={(value: any) => {
                      setValue('device_type_code', value ? value.value : null, {
                        shouldDirty: true,
                      });
                    }}
                  />
                )}
            />
          </Form.Group>

          <Form.Group className={styles.group}>
            <Form.Label>メーカー</Form.Label>
              <Controller
                control={control}
                name="maker_id"
                render={() => (
                  <AsyncPaginate
                    className={styles.dropdown}
                    loadingMessage={() => <div>Loading</div>}
                    isSearchable={false}
                    isClearable
                    placeholder="選択"
                    defaultValue={
                      initialValues?.maker
                        ? {
                            label: `${initialValues.maker.maker_code} | ${initialValues.maker.maker_name}`,
                            value: initialValues.maker.id,
                            name: initialValues.maker.maker_name,
                          }
                        : undefined
                    }
                    loadOptions={async () => {
                      try {
                        const data = await get('makers/dropdown', {});
                        return {
                          options: data.map((item: any) => ({
                            label: `${item.maker_code} | ${item.maker_name}`,
                            value: item.id,
                            maker_name: item.maker_name,
                          })),
                        };
                      } catch (err) {
                        return { options: [] };
                      }
                    }}
                    onChange={(value: any) => {
                      setValue('maker', value ? value : null, { shouldDirty: true });
                      setValue('maker_id', value ? value.value : null, {
                        shouldDirty: true,
                      });
                      setValue('maker.maker_name', value ? value.maker_name : '', {
                        shouldDirty: true,
                      });
                    }}
                  />
                )}
              />
              
          </Form.Group>

          <Form.Group className={styles.group}>
            <Form.Label className="required">品名</Form.Label>
            <Form.Control {...register('product_name', { required: true })} />
          </Form.Group>

          <Form.Group className={styles.group}>
            <Form.Label>型式名</Form.Label>
            <Form.Control {...register('model_name')} />
          </Form.Group>

          <Form.Group className={styles.group}>
            <Form.Label className="required">フリガナ</Form.Label>
            <Form.Control {...register('product_kana', { required: true })} />
          </Form.Group>

          <Form.Group className={styles.group}>
            <Form.Label>スペック</Form.Label>
            <Form.Control {...register('spec')} />
          </Form.Group>

          <Form.Group className="d-flex align-items-center">
            <Form.Label>導入開始日</Form.Label>
            <div style={{ width: '85%', margin: '0 0 0 auto' }}>
              <Controller
                control={control}
                name="intro_start_date"
                render={({ field }) => {
                  let date: Date | null = null;
                  if (field.value) {
                    date = moment(field.value).toDate();
                  }
                  return (
                    <ReactDatePickerWrapper
                      selected={date}
                      onChange={(selectedDate: Date) => {
                        if (selectedDate) {
                          field.onChange(moment(selectedDate).format('YYYY-MM-DD'));
                        } else {
                          field.onChange(null);
                        }
                      }}
                      className={styles.datePicker}
                      dateFormat="yyyy/MM/dd"
                    />
                  );
                }}
              />
            </div>
          </Form.Group>

          {(['lcd1', 'lcd2', 'lcd3'] as Array<'lcd1' | 'lcd2' | 'lcd3'>).map((item, index) => (
            <Form.Group className={styles.group} key={item}>
              <Form.Label>液晶{index + 1}</Form.Label>
                <Controller
                  control={control}
                  name={item}
                  render={() => {
                    let defaultValue: any = undefined;
                    if (initialValues && initialValues[item]) {
                      let label = initialValues[item]?.lcd_code;
                      if (initialValues[item]?.maker_name && initialValues[item]?.lcd_model) {
                        label = (label as string)
                          .concat(` | ${initialValues[item]?.maker_name}`)
                          .concat(` | ${initialValues[item]?.lcd_model}`);
                      }

                      defaultValue = {
                        label,
                        value: initialValues[item]?.id,
                        maker_name: initialValues[item]?.maker_name,
                        model: initialValues[item]?.lcd_model,
                      };
                    }

                    return (
                      <AsyncPaginate
                        className={styles.dropdown}
                        loadingMessage={() => <div>Loading</div>}
                        isSearchable={false}
                        isClearable
                        placeholder="選択"
                        defaultValue={defaultValue}
                        loadOptions={async () => {
                          try {
                            const data = await get('lcds/dropdown', {});
                            const options = data.map((item: Lcd) => {
                              let label = item.lcd_code;
                              const value = item.id;
                              const maker_name = item.maker_name || '';
                              const model = item.lcd_model || '';
                              if (item.maker_name && item.lcd_model) {
                                label = label
                                  .concat(` | ${item.maker_name}`)
                                  .concat(` | ${item.lcd_model}`);
                              }

                              return { label, value, maker_name, model };
                            });
                            return { options };
                          } catch (err) {
                            return { options: [] };
                          }
                        }}
                        onChange={(value: any) => {
                          setValue(`${item}.maker_name`, value ? value.maker_name : '', {
                            shouldDirty: true,
                          });
                          setValue(`${item}.lcd_model`, value ? value.model : '', {
                            shouldDirty: true,
                          });
                          setValue(`${item}_id`, value ? value.value : null, {
                            shouldDirty: true,
                          });
                        }}
                      />
                    );
                  }}
                />
            </Form.Group>
          ))}

          <Form.Group className={styles.group}>
            <Form.Label>メモ</Form.Label>
            <Form.Control {...register('product_memo')} />
          </Form.Group>

          <Form.Group className={styles.group}>
            <Form.Label>画像</Form.Label>
            <div className={styles.productImageContainer}>
              {file ? (
                <img
                  src={
                    file instanceof File
                      ? URL.createObjectURL(file)
                      : `${API_URL.slice(0, API_URL.length - 4)}${file}`
                  }
                  alt=""
                />
              ) : (
                <img src={images.defaultProductImage} alt="default" />
              )}

              <div className={styles.btnContainer}>
                <button
                  onClick={(event) => {
                    event.preventDefault();
                    inputRef.current?.click();
                  }}
                >
                  ファイル選択
                </button>
                <button
                  onClick={(event) => {
                    event.preventDefault();
                    setFile(null);
                    setValue('cover_image', null, { shouldDirty: true });
                    setValue('cover_delete', true);
                  }}
                  disabled={!file}
                >
                  削除
                </button>
                <input
                  className="d-none"
                  ref={inputRef}
                  type="file"
                  accept="image/png, image/gif, image/jpeg"
                  onChange={selectFile}
                />
              </div>
            </div>
          </Form.Group>

          <Form.Group className="d-flex align-items-center" style={{ margin: '0.5em 0' }}>
            <Form.Label style={{ margin: 0 }}>使用中止</Form.Label>
            <div style={{ width: '85%', margin: '0 0 0 auto' }}>
              <Controller
                control={control}
                name="status"
                render={({ field: { onChange, value } }) => (
                  <div className={styles.checkboxContainer}>
                    <Form.Check
                      className="custom-checkbox"
                      // when creating a new product, auto-check all checkboxes.
                      defaultChecked={!initialValues ? true : !!initialValues.status}
                      checked={value === 1 ? false : true}
                      onChange={(event) => {
                        // active => unchecked
                        // inactive => checked

                        const checked = event.target.checked;
                        onChange(checked ? 0 : 1);
                      }}
                    />
                  </div>
                )}
              />
            </div>
          </Form.Group>

          <Form.Group className="d-flex align-items-center" style={{ margin: '0.5em 0' }}>
            <Form.Label style={{ margin: 0, width: '15%' }}>機種QR冊子</Form.Label>
            <div className={styles.qrGroup}>
              <div className={styles.qrItem}>
                <Form.Label>出力</Form.Label>
                <Controller
                  control={control}
                  name="qr_output"
                  render={({ field: { onChange, value } }) => (
                    <div className={styles.checkboxContainer}>
                      <Form.Check
                        className="custom-checkbox"
                        checked={!!value}
                        onChange={(event) => onChange(event.target.checked ? 1 : 0)}
                      />
                      <span>有効</span>
                    </div>
                  )}
                />
              </div>
              <div className={styles.qrItem}>
                <Form.Label>品名(半角14文字）</Form.Label>
                <Form.Control {...register('qr_product_name')} />
              </div>
              {qrProductNameWidth > 14 && (
                <p style={{ color: 'red', whiteSpace: 'pre' }}>
                  {
                    '機種QR冊子の品名は半角14文字以内にしてください。\n※全角文字は半角文字の2文字とみなされます'
                  }
                </p>
              )}
              <div className={styles.qrItem}>
                <Form.Label>フリナガ</Form.Label>
                <Form.Control {...register('qr_product_kana')} />
              </div>
            </div>
          </Form.Group>

          <Form.Group className="d-flex align-items-center" style={{ margin: '0.5em 0' }}>
            <Form.Label style={{ margin: 0, width: '15%' }}>数量計上</Form.Label>
            <Controller
              control={control}
              name="count_target"
              render={({ field: { onChange, value } }) => (
                <div className={styles.checkboxContainer}>
                  <Form.Check
                    className="custom-checkbox"
                    checked={!!value}
                    onChange={(event) => onChange(event.target.checked ? 1 : 0)}
                  />
                  <span>有効</span>
                </div>
              )}
            />
          </Form.Group>

          <Form.Group className={styles.group}>
            <Form.Label>付属品CD</Form.Label>
            <Form.Control {...register('accessory_product_id')} />
          </Form.Group>

          <div className="d-flex justify-content-end">
            {initialValues && (
              <button
                className="edit-modal--delete"
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  onDelete();
                }}
              >
                削除
              </button>
            )}
            <button
              className="edit-modal--save"
              type="submit"
              disabled={!isValid || qrProductNameWidth > 14}
            >
              登録
            </button>
          </div>
        </Form>
      </div>

      {isDirty && uiStore.confirmBox.visible && <div className="overlappedBackdrop"></div>}
    </Modal>
  );
});

export default EditModal;
