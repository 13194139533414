import { useEffect, useMemo, useRef, useState } from 'react';
import { Form, Modal, Spinner, Table } from 'react-bootstrap';
import { useForm, Controller, useWatch } from 'react-hook-form';
import { FiPlusCircle } from 'react-icons/fi';
import { MdClose } from 'react-icons/md';
import { useQuery } from 'react-query';
import { observer } from 'mobx-react';

import { getCustomersByGroup } from 'services/customer';
import { GroupCustomer } from 'types/customer';
import UIStore from 'stores/ui';
import useStores from 'hooks/use-stores';
import TableRow from './row';
import { popupConfirmBack } from 'utils/modals';

import styles from './styles.module.scss';
import { getDeviceTypeDropdown } from 'services/device-type';
import { DeviceType } from 'types/device-type';
import { removeKeys } from 'utils';
import { v1 } from 'uuid';

interface IProps {
  initialValues?: any;
  onClose: () => void;
  onCreate: (data: any) => void;
  onSave: (data: any) => void;
  onDelete: () => void;
}

const EditModal = observer(({ initialValues, onClose, onCreate, onSave, onDelete }: IProps) => {
  const uiStore: UIStore = useStores().uiStore;
  const [deviceTypes, setDeviceTypes] = useState<Array<any>>([]);
  const didMount = useRef<boolean>(false);

  const modalTitle = useMemo(() => {
    return !initialValues ? 'グループ作成モーダル' : 'グループ編集モーダル';
  }, [initialValues]);

  const initialFormValues = useMemo(() => {
    let values: any = {};
    if (initialValues) {
      values = initialValues;
      return values;
    }

    // customers is used for UI purpose only.
    // When sending the request, it turns into customer_ids
    values = {
      status: 1,
      id: undefined,
      group_code: '',
      group_name: '',
      group_note: '',
      deposit_unit_price: [],
      customers: [],
    };
    return values;
  }, []);

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { isDirty, isValid },
  } = useForm({
    defaultValues: initialFormValues,
    mode: 'onChange',
  });

  const customers = useWatch({ control, name: 'customers' });

  const _ = useQuery(['device-type'], async () => await getDeviceTypeDropdown(2), {
    onSuccess: (data: Array<Partial<DeviceType>>) => {
      const temp = data.map((device) => ({
        code: device.device_type_code,
        unit_price: 0,
      }));

      if (initialValues?.deposit_unit_price) {
        initialValues.deposit_unit_price.forEach((unit: { code: string; unit_price: number }) => {
          temp.forEach((device) => {
            if (device.code === unit.code) {
              device.unit_price = unit.unit_price;
            }
          });
        });
      }
      setDeviceTypes(temp);
    },
  });

  const { isLoading } = useQuery(
    ['customers', initialValues?.id],
    () => getCustomersByGroup(initialValues?.id || 0),
    {
      enabled: !!initialValues,
      onSuccess: (data) => {
        let temp = data.data || [];
        temp = temp.map((customer: any) => {
          return { ...customer, key: v1() };
        });
        setValue('customers', temp);
      },
    }
  );

  const addNewCustomerToGroup = () => {
    setValue('customers', [...customers, { key: v1() }]);
  };

  const removeCustomerFromGroup = (index: number) => {
    const temp = JSON.parse(JSON.stringify(customers));
    temp.splice(index, 1);
    setValue('customers', temp, { shouldDirty: true });
  };

  const changeCustomerRow = (customer: Partial<GroupCustomer>, index: number) => {
    const temp = JSON.parse(JSON.stringify(customers));

    temp.splice(index, 1, customer);
    setValue('customers', temp, { shouldDirty: true });
  };

  const submit = handleSubmit((values) => {
    const func = initialValues ? onSave : onCreate;

    // extract only valid id from customers
    const payload = JSON.parse(JSON.stringify(values));
    payload.customer_ids = payload.customers
      .filter((customer: Partial<GroupCustomer>) => typeof customer.id === 'number')
      .map((customer: Partial<GroupCustomer>) => customer.id);
    removeKeys(payload, ['customers']);

    func(payload);
  });

  useEffect(() => {
    if (didMount.current) {
      const temp = deviceTypes.filter((device) => !!device.unit_price === true);
      setValue('deposit_unit_price', temp, { shouldDirty: didMount.current });
    } else {
      didMount.current = true;
    }
  }, [deviceTypes]);

  return (
    <Modal show backdrop="static" centered size="lg">
      <div className="edit-modal">
        <div className="edit-modal--title">
          {modalTitle}
          <button
            onClick={(event: any) => {
              event.preventDefault();
              popupConfirmBack(isDirty, () => onClose());
            }}
          >
            <MdClose />
          </button>
        </div>
        <Form>
          {initialValues && (
            <Form.Group className={styles.group}>
              <Form.Label>グループCD</Form.Label>
              <Form.Control {...register('group_code', { required: true })} disabled />
            </Form.Group>
          )}
          <Form.Group className={styles.group}>
            <Form.Label className="required">グループ名</Form.Label>
            <Form.Control {...register('group_name', { required: true })} />
          </Form.Group>
          <Form.Group className={styles.group}>
            <Form.Label>備考</Form.Label>
            <Form.Control {...register('group_note')} />
          </Form.Group>
          <Form.Group className="d-flex align-items-center mb-4">
            <Form.Label style={{ width: '15%', margin: 0 }}>使用中止</Form.Label>
            <Controller
              control={control}
              name="status"
              render={({ field: { value, onChange } }) => (
                <Form.Check
                  style={{ transform: 'scale(1.2)' }}
                  className="custom-checkbox"
                  defaultChecked={value === 0 ? true : false}
                  onChange={(event) => {
                    // status: 1 (active - unchecked)
                    // status: 0 (inactive - checked)
                    const checked = event.target.checked;
                    const newStatus = checked ? 0 : 1;
                    onChange(newStatus);
                  }}
                />
              )}
            />
          </Form.Group>
        </Form>

        <div className={styles.customerTableWrapper}>
          <h4>所属顧客</h4>
          <Table
            bordered
            hover
            className={styles.tableContainer}
            style={{ boxShadow: '0 0 20px rgba(0, 0, 0, 0.15)', backgroundColor: 'white' }}
          >
            <thead>
              <tr>
                <th>顧客CD</th>
                <th></th>
                <th>顧客名</th>
                <th>ホール名</th>
                <th>使用中止</th>
                <th>
                  <button onClick={addNewCustomerToGroup}>
                    <FiPlusCircle />
                  </button>
                </th>
              </tr>
            </thead>
            <tbody>
              {isLoading && (
                <tr>
                  <td colSpan={5} className="text-center">
                    <Spinner animation="border" />
                  </td>
                </tr>
              )}
              {customers?.map((customer: GroupCustomer, index: number) => (
                <TableRow
                  key={customer.key}
                  item={customer}
                  onRemove={() => removeCustomerFromGroup(index)}
                  setRow={(customer) => changeCustomerRow(customer, index)}
                  rows={customers}
                />
              ))}
            </tbody>
          </Table>
        </div>

        <div className={styles.deviceTypeWrapper}>
          <h4>預かり単価</h4>
          <div className={styles.deviceTypeContainer}>
            {deviceTypes.map((device, index) => (
              <div key={index} className={styles.deviceItem}>
                <p>{device.code}</p>
                <input
                  value={device.unit_price}
                  onChange={(event: any) => {
                    if (/^[0-9]*$/.test(event.target.value)) {
                      const newDevice = {
                        code: device.code,
                        unit_price: event.target.value,
                      };
                      const temp = [...deviceTypes];
                      temp.splice(index, 1, newDevice);
                      setDeviceTypes(temp);
                    }
                  }}
                />
              </div>
            ))}
          </div>
        </div>

        <div className="d-flex justify-content-end">
          {initialValues && (
            <button className="edit-modal--delete" onClick={() => onDelete()}>
              削除
            </button>
          )}
          <button className="edit-modal--save" onClick={submit} disabled={!isValid}>
            登録
          </button>
        </div>
      </div>

      {isDirty && uiStore.confirmBox.visible && <div className="overlappedBackdrop"></div>}
    </Modal>
  );
});

export default EditModal;
