// Built-in
import { useRef, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { Breadcrumb, Form, Modal } from 'react-bootstrap';
import { MdOutlineCircle } from 'react-icons/md';
import { AiOutlineClose } from 'react-icons/ai';
import { Link, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import moment from 'moment';
import { useMutation } from 'react-query';

// Component
import TextFilter from 'shared/components/text-filter';
import CustomerFilter from 'shared/components/customer-filter';
import StaticDropdownFilter from 'shared/components/static-dropdown-filter';
import RadioButtonFilter from 'shared/components/radio-button-filter';
import TimeRangeFilter from 'shared/components/time-range-filter';
import DataTable from 'shared/components/data-table';
import FilterItem from 'shared/components/filter-item';
import PopupPrint from 'shared/components/popup-print';
import ReactDatePickerWrapper from 'shared/components/datepicker-wrapper';

// Type
import { DEFAULT_DELIVERY_FILTER, Delivery, IDeliveryFilter } from 'types/delivery';
import { DataProvider } from 'shared/data-providers/table-data-provider';
import { deliveryTargetTypePipe, deliveryTypePipe, hourFromToPipe } from 'utils/pipe';

// Store
import UIStore from 'stores/ui';
import useStores from 'hooks/use-stores';

// Service
import { duplicateDeliveries } from 'services/delivery';
import { exportForm } from 'services/export-form';

// Style
import styles from './styles.module.scss';

// Const
import { exportDeliveryOptions } from 'consts/export-options';
import { removeKeys } from 'utils';

const DeliveryListScreen = () => {
  const navigate = useNavigate();
  const uiStore: UIStore = useStores().uiStore;

  const dataProvider = useRef(
    new DataProvider<IDeliveryFilter>({
      dataSource: 'deliveries',
      localStorageKey: 'delivery-filter',
      defaultFilters: DEFAULT_DELIVERY_FILTER,
    })
  );

  const { control, handleSubmit, setValue, reset } = useForm<
    IDeliveryFilter & { selectedDate: string }
  >({
    defaultValues: { ...dataProvider.current.filters, selectedDate: 'TODAY' },
  });

  const selectedDate = useWatch({ control, name: 'selectedDate' });

  const [selectedDeliveries, setSelectedDeliveries] = useState<Array<number>>([]);
  const [userOption, setUserOption] = useState<string>('PRINT');
  const [dateToDuplicate, setDateToDuplicate] = useState<Date | null>(new Date());
  const [duplicateModal, setDuplicateModal] = useState<boolean>(false);
  const [showPrintModal, setShowPrintModal] = useState<boolean>(false);

  const duplicateDeliveryMutation = useMutation(
    (payload: { ids: string; date: string }) => duplicateDeliveries(payload),
    {
      onMutate: () => uiStore.showLoading(),
      onSettled: () => uiStore.hideLoading(),
      onSuccess: (_data) => {
        dataProvider.current.load();
        uiStore.showConfirmBox({
          title: ' 複製が成功しました。',
          confirmText: 'OK',
          onConfirm: () => {
            uiStore.hideConfirmBox();
          },
        });
      },
    }
  );

  const headers = [
    { label: '', oneLine: true, centered: true },
    { label: '配送番号', key: 'delivery_code' },
    { label: '配送日', key: 'delivery_date', oneLine: true },
    { label: '配送時間', oneLine: true },
    { label: '配送種別', oneLine: true },
    { label: '並順', oneLine: true },
    { label: '配送対象', oneLine: true },
    { label: '顧客' },
    { label: 'トラック' },
    { label: '乗員' },
    { label: '備考' },
    { label: '完了', centered: true },
    { label: '売上', centered: true },
    { label: '実績', centered: true },
    {
      label: (
        <Form.Check
          className={styles.checkbox}
          onChange={(event: any) => {
            if (event.target.checked) {
              const temp = dataProvider.current.data.map((delivery) => delivery.id);
              setSelectedDeliveries(temp);
            } else {
              setSelectedDeliveries([]);
            }
          }}
        />
      ),
      centered: true,
    },
  ];

  const delegate = (item: Partial<Delivery>) => {
    let editBtn: JSX.Element = (
      <button className="secondary-btn" onClick={() => navigate(`/deliveries/${item.id}`)}>
        修正
      </button>
    );
    let deliveryCode = (
      <Link to={{ pathname: `/deliveries/${item.id}` }}>{item.delivery_code}</Link>
    );

    let targetType;
    if (item.target_type == 'IMPORT')
      targetType = item.import ? (
        <Link to={{ pathname: `/imports/${item.import.id}` }} target="_blank">
          {deliveryTargetTypePipe(item.target_type)}
        </Link>
      ) : (
        deliveryTargetTypePipe(item.target_type)
      );
    else if (item.target_type == 'EXPORT')
      targetType = item.export ? (
        <Link to={{ pathname: `/exports/${item.export.id}` }} target="_blank">
          {deliveryTargetTypePipe(item.target_type)}
        </Link>
      ) : (
        deliveryTargetTypePipe(item.target_type)
      );
    else if (item.target_type == 'IMPORT_EXPORT') {
      targetType = (item.import && item.export) ?(
        <div>
          <Link to={{ pathname: `/imports/${item.import.id}` }} target="_blank">入庫</Link><br/>
          <Link to={{ pathname: `/exports/${item.export.id}` }} target="_blank">出庫</Link>
        </div>
      ) : ((item.import) ? (
        <div>
          <Link to={{ pathname: `/imports/${item.import.id}` }} target="_blank">入庫</Link><br/>出庫
        </div>
      ) : ((item.export) ? (
        <div>
          入庫<br/><Link to={{ pathname: `/exports/${item.export.id}` }} target="_blank">出庫</Link>
        </div>
      ) : (
          deliveryTargetTypePipe('IMPORT')
      )));
    }
    else if (item.target_type == 'PURCHASE')
      targetType = item.purchase ? (
        <Link to={{ pathname: `/purchases/${item.purchase.id}` }} target="_blank">
          {deliveryTargetTypePipe(item.target_type)}
        </Link>
      ) : (
        deliveryTargetTypePipe(item.target_type)
      );
    else if (item.target_type == 'SALE')
      targetType = item.sale ? (
        <Link to={{ pathname: `/sales/${item.sale.id}` }} target="_blank">
          {deliveryTargetTypePipe(item.target_type)}
        </Link>
      ) : (
        deliveryTargetTypePipe(item.target_type)
      );
    else if (item.target_type == 'OTHER') targetType = deliveryTargetTypePipe(item.target_type);
    else targetType = deliveryTargetTypePipe(item.target_type);

    let deliveryDate = item.delivery_date ? item.delivery_date : '';
    let hourFromTo = item.delivery_time_other
      ? item.delivery_time_other
      : hourFromToPipe(item.hour_from, item.hour_to);
    let type = deliveryTypePipe(item.delivery_type as number);
    let sort = item.sort ? item.sort : '';
    let customer =
      item.customer?.customer_name +
      (item.customer?.hall_name ? '　' + item.customer?.hall_name : '');
    let truck = item.truck ? item.truck.truck_name : '';
    let driverName = (() => {
      const arr = [item.driver, item.assistant1, item.assistant2];
      let names = '';
      arr.forEach((driver) => {
        if (!driver) {
          return;
        }
        if (names.length === 0) {
          names = names.concat(driver.driver_name);
        } else {
          names = names.concat(`、 ${driver.driver_name}`);
        }
      });

      return names;
    })();
    let deliveryDetail = item.delivery_detail ? item.delivery_detail : '';
    let complete = item.complete ? (
      <div className={styles['flex-center']}>
        <MdOutlineCircle className="status-icon" />
      </div>
    ) : (
      ''
    );

    let saleList = Array();
    if (item.import?.sales?.length) saleList = item.import.sales;
    if (item.export?.sales?.length) saleList = item.export.sales;
    if (item.purchase?.sales?.length) saleList = item.purchase.sales;
    if (item.sale) saleList = Array(item.sale);
    if (item.sales?.length) saleList = item.sales;
    let saleStatus =
      saleList.length > 0 && saleList[0].id ? (
        <Link to={{ pathname: `/sales/${saleList[0].id}` }} target="_blank">
          {saleList[0].sale_code}
        </Link>
      ) : (
        ''
      );

    let journey = (() => {
      const X = (
        <div className={styles['flex-center']}>
          <AiOutlineClose />
        </div>
      );
      const O = (
        <div className={styles['flex-center']}>
          <MdOutlineCircle className="status-icon" />
        </div>
      );

      switch (true) {
        case item.journey_enter_later === 1:
          return X;
        case (!!item.journey_start || item.journey_start == 0) &&
          (!!item.journey_end || item.journey_end == 0):
          return O;
        default:
          return '';
      }
    })();

    let checkbox = (
      <div className={styles['flex-center']}>
        <Form.Check
          className={styles.checkbox}
          checked={selectedDeliveries.includes(item.id as number)}
          onChange={(event: any) => {
            if (event.target.checked) {
              const temp = [...selectedDeliveries, item.id as number];
              setSelectedDeliveries(temp);
            } else {
              const temp = selectedDeliveries.filter((id) => id !== item.id);
              setSelectedDeliveries(temp);
            }
          }}
        />
      </div>
    );

    return [
      editBtn,
      deliveryCode,
      deliveryDate,
      hourFromTo,
      type,
      sort,
      targetType,
      customer,
      truck,
      driverName,
      deliveryDetail,
      complete,
      saleStatus,
      journey,
      checkbox,
    ];
  };

  const resetFilter = (_event: any) => {
    reset(DEFAULT_DELIVERY_FILTER);
    setValue('selectedDate', 'TODAY');
    dataProvider.current.clearFilters();
  };

  const applyDate = (_event: any) => {
    const today = new Date();
    const data = selectedDate;
    const setDeliveryDate = (from: Date, to: Date) => {
      setValue('delivery_date_from', moment(from).format('YYYY/MM/DD'));
      setValue('delivery_date_to', moment(to).format('YYYY/MM/DD'));
    };

    switch (data) {
      case 'TODAY': {
        setDeliveryDate(today, today);
        return;
      }
      case 'TOMORROW': {
        const tomorrow = new Date(today);
        tomorrow.setDate(today.getDate() + 1);
        setDeliveryDate(tomorrow, tomorrow);
        return;
      }
      case 'YESTERDAY': {
        const yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1);
        setDeliveryDate(yesterday, yesterday);
        return;
      }
      case 'THIS_WEEK': {
        const week = new Date(today);
        week.setDate(today.getDate() + 6);
        setDeliveryDate(today, week);
        return;
      }
      default: {
        setDeliveryDate(today, today);
        return;
      }
    }
  };

  const onValid = (values: IDeliveryFilter & { selectedDate: string }) => {
    removeKeys(values, ['selectedDate']);
    const newFilter = {
      ...values,
      sort: dataProvider.current.filters.sort,
      sort_type: dataProvider.current.filters.sort_type,
    };

    dataProvider.current.setFilters(newFilter);
  };

  const createNewDelivery = (_event: any) => {
    navigate(`/deliveries/create`);
  };

  const onPrintOrDuplicate = (_event: any) => {
    if (userOption === 'PRINT') {
      setShowPrintModal(true);
    } else {
      setDuplicateModal(true);
    }
  };

  const exportDeliveryListToPDF = async (type: string | number) => {
    try {
      uiStore.showLoading();
      const path = `deliveries/export-list/${selectedDeliveries.join(',')}`;
      const payload = {
        form_type: type,
      };
      await exportForm(path, payload);
    } catch (error: any) {
      uiStore.showAlertBox({
        title: 'エラー',
        type: 'error',
        content: error.message,
      });
    } finally {
      uiStore.hideLoading();
    }
  };

  const duplicate = () => {
    const payload = {
      ids: selectedDeliveries.join(','),
      date: moment(dateToDuplicate).format('YYYY/MM/DD').replace(/\//g, '-'),
    };
    setDuplicateModal(false);

    duplicateDeliveryMutation.mutate(payload);
  };

  return (
    <div className="list-screen">
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/home' }}>
          TOP
        </Breadcrumb.Item>
        <Breadcrumb.Item active>配送</Breadcrumb.Item>
      </Breadcrumb>

      <Form onSubmit={handleSubmit(onValid)} className={styles.filterContainer}>
        <div className={styles.deliveryDate}>
          <TimeRangeFilter
            control={control}
            label="配送日"
            keys={['delivery_date_from', 'delivery_date_to']}
          />
        </div>

        <div className={styles.selectedDate}>
          <StaticDropdownFilter
            isClearable={false}
            control={control}
            options={[
              { label: '本日', value: 'TODAY' },
              { label: '翌日', value: 'TOMORROW' },
              { label: '昨日', value: 'YESTERDAY' },
              { label: '今週', value: 'THIS_WEEK' },
            ]}
            placeholder="選択"
            keys={['selectedDate']}
          />
          <button type="button" onClick={applyDate} className="action-btn">
            設定
          </button>
        </div>

        <div className={styles.deliveryCode}>
          <TextFilter label="配送番号" control={control} keys={['delivery_code']} />
        </div>

        <div className={styles.buttonGroup}>
          <button aria-label="filter-btn" type="submit" className="action-btn">
            検索
          </button>
          <button aria-label="reset-btn" type="button" className="action-btn" onClick={resetFilter}>
            クリア
          </button>
        </div>

        <div className={styles.customerId}>
          <CustomerFilter control={control} label="顧客" keys={['customer_id']} />
        </div>

        <div className={styles.complete}>
          <RadioButtonFilter
            label="完了"
            control={control}
            keys={['complete']}
            options={[
              { label: '全て', value: null },
              { label: '完了 ', value: 1 },
              { label: '未完了', value: 0 },
            ]}
          />
        </div>

        <div className={styles.saleStatus}>
          <RadioButtonFilter
            label="売上"
            control={control}
            keys={['sale_status']}
            options={[
              { label: '全て', value: null }, // all
              { label: '作成済 ', value: 1 }, // created
              { label: '未作成', value: 0 }, // not created
            ]}
          />
        </div>

        <div className={styles.journey}>
          <RadioButtonFilter
            label="実績"
            control={control}
            keys={['journey_enter_later']}
            options={[
              { label: '全て', value: null },
              { label: '提出済', value: 0 },
              { label: '未提出', value: 1 },
            ]}
          />
        </div>
      </Form>

      <div className={styles.options}>
        <button
          aria-label="create-new-delivery"
          className="primary-btn"
          onClick={createNewDelivery}
        >
          新しい配送
        </button>
        <Select
          className={styles.dropdown}
          isClearable={false}
          isSearchable={false}
          options={[
            { label: '印刷', value: 'PRINT' },
            { label: '複製', value: 'DUPLICATE' },
          ]}
          onChange={(value) => {
            if (value) setUserOption(value.value);
          }}
          defaultValue={{ label: '印刷', value: 'PRINT' }}
        />
        <button
          aria-label="duplicate-or-print"
          className="primary-btn"
          onClick={onPrintOrDuplicate}
          disabled={!selectedDeliveries.length}
        >
          一括処理
        </button>
      </div>

      <div className={styles.tableWrapper}>
        <DataTable dataProvider={dataProvider.current} headers={headers} delegate={delegate} />
      </div>

      <Modal
        centered
        backdrop
        show={duplicateModal}
        className={styles.duplicateModal}
        dialogClassName={styles.duplicateDialog}
      >
        <div className={styles.content}>
          <h2 aria-label="title">複製モーダル</h2>
          <div className={styles.datePickerWrapper}>
            <FilterItem label="複製先" />
            <ReactDatePickerWrapper
              className={styles.datePicker}
              onChange={(date) => setDateToDuplicate(date)}
              dateFormat="yyyy/MM/dd"
              selected={dateToDuplicate}
            />
          </div>
          <div className={styles.actions}>
            <button
              className="secondary-btn"
              aria-label="close"
              onClick={(_event: any) => setDuplicateModal(false)}
            >
              閉じる
            </button>
            <button className="secondary-btn" aria-label="duplicate" onClick={duplicate}>
              複製
            </button>
          </div>
        </div>
      </Modal>

      {showPrintModal && (
        <PopupPrint
          title="印刷選択モーダル"
          options={exportDeliveryOptions}
          actions={[
            { label: '閉じる', callback: () => setShowPrintModal(false) },
            {
              label: '印刷',
              callback: (option: string | number) => {
                setShowPrintModal(false);
                exportDeliveryListToPDF(option);
              },
            },
          ]}
        />
      )}
    </div>
  );
};

export default DeliveryListScreen;
