// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_dropdown__dvYL5 {\n  border: 1px solid lightgray;\n  flex: 1 1;\n}\n.styles_dropdown__dvYL5:has(input:disabled) {\n  border: none;\n}", "",{"version":3,"sources":["webpack://./src/shared/components/dynamic-dropdown-filter/styles.module.scss"],"names":[],"mappings":"AAGA;EACE,2BAAA;EACA,SAAA;AAFF;AAIE;EACE,YAAA;AAFJ","sourcesContent":[".container {\n}\n\n.dropdown {\n  border: 1px solid lightgray;\n  flex: 1;\n\n  &:has(input:disabled) {\n    border: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropdown": "styles_dropdown__dvYL5"
};
export default ___CSS_LOADER_EXPORT___;
