import moment from 'moment';

import { Product } from './product';
import { User } from './user';

export type Quotation = {
  id: number;
  quotation_code: string;
  quotation_type: 1 | 2;
  input_date: string;
  scheduled_date: string;
  customer_id: number;
  user_pic_id?: number;
  user_input_id?: number;
  user_confirm_id?: number;
  quotation_detail?: string;
  business_contact?: string;
  quotation_memo?: string;
  quotation_details?: Array<QuotationProduct>;
  updated_at: string;
  purchase_posted: 0 | 1;

  // customer: Customer;
  customer: any;
  user_person_in_charge?: User;
  user_input_person?: User;
  user_confirm_person?: User;
};

export type QuotationProduct = {
  id: number | string;
  quotation_id?: number;
  device_type_code?: string;
  product_id?: number;
  product_quantity?: number;
  purchase_unit_price?: number;
  quotation_detail_note?: string;
  sort?: number;
  store?: string;
  product?: Product;
};

export interface IQuotationFilter {
  input_date_from?: string;
  input_date_to?: string;
  quotation_code?: string | null;
  customer_id?: number | null;
  purchase_posted?: 0 | 1 | null;
  user_input_id?: number | null;
  sort?: string;
  sort_type?: 'asc' | 'desc';
}

const now = new Date();
const date = now.getDate();
const month = now.getMonth();
const year = now.getFullYear();

export const DEFAULT_QUOTATION_FILTER: IQuotationFilter = {
  input_date_from: moment(new Date(year, month - 1, date)).format('YYYY-MM-DD'),
  input_date_to: moment(now).format('YYYY-MM-DD'),
  quotation_code: null,
  customer_id: null,
  purchase_posted: null,
  user_input_id: null,
  sort: 'quotation_code',
  sort_type: 'asc',
};
