// Built-in
import { Breadcrumb } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';

// Component
import PurchaseDetailsContainer from 'shared/containers/purchase-details';

const PurchaseDetailScreen = () => {
  const params = useParams();
  const id = params.id ? parseInt(params.id) : undefined;

  return (
    <div id="purchase-details-screen">
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/home' }}>
          TOP
        </Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/purchases' }}>
          買取
        </Breadcrumb.Item>
        <Breadcrumb.Item active>買取詳細</Breadcrumb.Item>
      </Breadcrumb>
      <PurchaseDetailsContainer id={id} />
      <div id="dynamic-dropdown-menu-portal"></div>
    </div>
  );
};

export default PurchaseDetailScreen;
