import { API_URL } from 'configs';
import axios from 'axios';

/**
 * @param path API endpoint to invoke.
 * @param payload if the method is 'GET', then payload is query params, else
 * the payload is body'data.
 * @param method if method is not specific, default to 'GET'.
 */
export const exportForm = async (path: string, payload: any, method: 'GET' | 'POST' = 'GET') => {
  const token = localStorage.getItem('access_token');

  let apiPath = `${API_URL}/${path}`;
  const options: any = {
    method: method ? method : 'GET',
    headers: {
      Accept: '*/*',
      Authorization: `Bearer ${token}`,
    },
  };

  // GET by default
  if (method === 'GET') {
    const queryData: {
      [key: string]: any;
    } = {};
    for (const [key, value] of Object.entries(payload)) {
      if (value !== undefined) {
        queryData[key] = value;
      }
    }
    apiPath = apiPath + '?' + new URLSearchParams(queryData);
  } else {
    options.body = JSON.stringify(payload);
  }

  await fetch(apiPath, options).then(async (response) => {
    if (response.ok) {
      const resHeaders = response.headers;
      let fileName = resHeaders.get('content-disposition')!.replace(/(^.*=)|(")/g, '');
      const url = window.URL.createObjectURL(await response.blob());
      const link = document.createElement('a');

      link.href = url;
      link.setAttribute('download', decodeURIComponent(fileName));
      document.body.appendChild(link);
      link.click();
      link.remove();
    } else {
      const error = await response.json();
      throw error;
    }
  });
};

// Normal Post request in fetch causes the pdf data to be damaged
export const exportFormPost = async (path: string, params: any) => {
  const token = localStorage.getItem('access_token');
  const options: {
    [key: string]: any;
  } = {
    responseType: 'arraybuffer',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/*',
      Authorization: `Bearer ${token}`,
    },
  };

  await axios
    .post(`${API_URL}/${path}`, params, options)
    .then((response) => {
      const resHeaders = response.headers;
      let fileName = resHeaders['content-disposition']!.replace(/(^.*=)|(")/g, '');

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');

      link.href = url;
      link.setAttribute('download', decodeURIComponent(fileName));
      document.body.appendChild(link);
      link.click();
      link.remove();
    })
    .catch((_error) => {
      // axios only accepts 'arraybuffer' as responseType but
      // the server returns json if an error happens. So we lose
      // the error message from the server and have to create one.
      const errorObj = { message: 'something went wrong, please try again.' };
      throw errorObj;
    });
};
