import { get, post } from './index';

export const getDepositInfo = (payload: any) => {
  const { year, month, customerId } = payload;
  return get(`deposits/${year}/${month}/${customerId}`, {});
};

export const updateDeposit = (payload: any) => {
  const { year, month, customerId, data } = payload;
  return post(`deposits/${year}/${month}/${customerId}`, data);
};

export const updateUnrestrictedDeposit = (payload: any) => {
  const { year, month, customerId, data } = payload;
  return post(`deposits/unrestricted/${year}/${month}/${customerId}`, data);
};
