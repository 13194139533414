import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AiFillCaretDown, AiFillCaretRight } from 'react-icons/ai';
import { Modal } from 'react-bootstrap';
import { useMutation } from 'react-query';
import { observer } from 'mobx-react';

import SubMenu from './submenu';
import useStores from 'hooks/use-stores';
import { logout } from 'services/auth';
import UIStore from 'stores/ui';
import SessionStore from 'stores/session';

import styles from './styles.module.scss';

const menuItems = [
  {
    group: '買取・受注業務',
    item: [
      { name: '見積', path: '/quotations', key: 'QUOTATION' },
      { name: '買取', path: '/purchases', key: 'PURCHASE' },
      { name: '売上', path: '/sales', key: 'SALE' },
    ],
  },
  {
    group: '預かり業務',
    item: [
      { name: '入庫', path: '/imports', key: 'IMPORT' },
      { name: '出庫', path: '/exports', key: 'EXPORT' },
      { name: '在庫', path: '/inventories', key: 'STOCK' },
    ],
  },
  {
    group: '共通業務',
    item: [
      { name: '配送', path: '/deliveries', key: 'DELIVERY' },
      { name: '車両', path: '/vehicle-cost', key: 'VEHICLE' },
      {
        name: '集計',
        subMenus: [
          { name: ' 精算', path: '/settlement' },
          { name: ' 帳票出力', path: '/form-report' },
        ],
        iconClosed: <AiFillCaretRight />,
        iconOpened: <AiFillCaretDown />,
        key: 'REPORT',
      },
      {
        name: '入出金',
        subMenus: [
          { name: '入金', path: '/deposit' },
          { name: '出金', path: '/withdrawal' },
        ],
        iconClosed: <AiFillCaretRight />,
        iconOpened: <AiFillCaretDown />,
        key: 'DEPOSIT_WITHDRAWAL',
      },
      {
        name: 'マスター',
        subMenus: [
          { name: '顧客', path: '/master/customers' },
          { name: 'グループ', path: '/master/customer-groups' },
          { name: '液晶', path: '/master/lcds' },
          { name: 'メーカー', path: '/master/makers' },
          { name: '商品', path: '/master/products' },
          { name: 'トラック', path: '/master/vehicles' },
          { name: '運転手', path: '/master/drivers' },
          { name: '種別', path: '/master/device-types' },
          { name: '消費税率', path: '/master/consumption-taxes' },
          // { name: 'ユーザー', path: '/master/users' },
        ],
        iconClosed: <AiFillCaretRight />,
        iconOpened: <AiFillCaretDown />,
        key: 'MASTER',
      },
      {
        name: 'ユーザー',
        path: '/users',
        key: 'USER',
      },
    ],
  },
];

const SideBar = observer(() => {
  const navigate = useNavigate();

  const uiStore: UIStore = useStores().uiStore;
  const sessionStore: SessionStore = useStores().sessionStore;

  const [showModal, setShowModal] = useState(false);

  const mutation = useMutation(() => logout(), {
    onMutate: () => {
      sessionStore.setIsLoggingOut(true);
      uiStore.showLoading();
    },
    onSettled: () => uiStore.hideLoading(),
    onSuccess: () => {
      localStorage.clear();
      sessionStore.clearSession();
      navigate('/');
    },
  });

  const onLogout = async () => {
    setShowModal(false);
    mutation.mutate();
  };

  return (
    <div className={styles.container}>
      <div className={styles.sidebar}>
        <div className={styles.sidebar__title}>
          <p className={styles.sidebar__title_center} onClick={() => navigate('/home')}>
            昌栄業務システム
          </p>
        </div>

        <div className={styles.user}>
          <p className={styles.user__info}>{sessionStore.profile?.name}</p>
          <button className={styles.user__logout} onClick={() => setShowModal(true)}>
            ログアウト
          </button>
        </div>

        {menuItems.map((menu, index) => (
          <div className={styles.wrapper_sidebar} key={index}>
            <p className={styles.wrapper_sidebar_title}>{menu.group}</p>
            <ul className={styles.wrapper_sidebar_menu}>
              {menu.item.map((option: any, index: number) => {
                if (
                  sessionStore.profile?.is_admin ||
                  sessionStore.profile?.user_authorities[option.key]
                )
                  return <SubMenu option={option} key={index} />;
                return null;
              })}
            </ul>
          </div>
        ))}
      </div>
      <Modal show={showModal}>
        <Modal.Header>
          <Modal.Title>ログアウトしますか？</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <button className={styles.btn} onClick={onLogout}>
            ログアウト
          </button>
          <button className={styles.btn_close} onClick={() => setShowModal(false)}>
            キャンセル
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
});

export default SideBar;
