// Built-in
import { useMemo, useRef } from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { BsCircle } from 'react-icons/bs';
import { MdRemove } from 'react-icons/md';

// Component
import TimeRangeFilter from 'shared/components/time-range-filter';
import TextFilter from 'shared/components/text-filter';
import CustomerFilter from 'shared/components/customer-filter';
import RadioButtonFilter from 'shared/components/radio-button-filter';
import DynamicDropdownFilter from 'shared/components/dynamic-dropdown-filter';

// Type
import { DEFAULT_PURCHASE_FILTER, IPurchaseFilter, Purchase } from 'types/purchase';

// Store
import { DataProvider } from 'shared/data-providers/table-data-provider';
import DataTable from 'shared/components/data-table';

// Style
import styles from './styles.module.scss';
import { DropdownDataProvider } from 'shared/data-providers/dropdown-data-provider';

const PurchaseScreen = () => {
  const navigate = useNavigate();

  const dataProvider = useRef(
    new DataProvider<IPurchaseFilter>({
      dataSource: 'purchases',
      localStorageKey: 'purchase_filters',
      defaultFilters: DEFAULT_PURCHASE_FILTER,
    })
  );
  const inputUserDataProvider = useRef(
    new DropdownDataProvider('users/dropdown/input_person', (item) => ({
      label: item.name,
      value: item.id,
    }))
  ).current;
  const { control, handleSubmit, setValue, reset } = useForm<IPurchaseFilter>({
    defaultValues: dataProvider.current.filters,
  });

  const headers = useMemo(() => {
    return [
      { label: '', oneLine: true, centered: true },
      { label: '買取番号', key: 'purchase_code' },
      { label: '顧客' },
      { label: '入力日', key: 'input_date', oneLine: true },
      { label: '買取日', key: 'purchase_date', oneLine: true },
      { label: '入庫', oneLine: true, centered: true },
      { label: '配送', oneLine: true, centered: true },
      { label: '完了', centered: true },
      { label: '集計日', key: 'aggregation_date', oneLine: true },
      { label: '詳細' },
    ];
  }, []);

  const delegate = (item: Purchase) => {
    const purchaseCode = <Link to={`/purchases/${item.id}`}>{item.purchase_code}</Link>;
    const goToPurchaseBtn = <button onClick={() => navigate(`/purchases/${item.id}`)}>修正</button>;
    const name = `${
      item.customer?.customer_name +
      (item.customer?.hall_name ? '　' + item.customer?.hall_name : '')
    }`;
    const customerName = (
      (item.different_billing == 1) ? (<div className="alert-cell">{name}</div>): name
    );
    const inputDate = item.input_date;
    const purchaseDate = item.purchase_date;
    const importState = (() => {
      if (item.purchase_type !== 1) return <MdRemove />;
      if (item.imports?.length > 0)
        return (
          <div>
            {item.imports.map((d, index) => (
              <>
                <Link to={`/imports/${d.id}`} target="_blank" style={{ display: 'block' }}>
                  {d.import_code}
                </Link>
              </>
            ))}
          </div>
        );
      if (item.complete === 0)
        return (
          <button onClick={() => navigate(`/imports/create?purchase_id=${item.id}`)}>作成</button>
        );
      return null;
    })();
    const deliveryState = (() => {
      if (item.deliveries?.length > 0)
        return (
          <div>
            {item.deliveries.map((d, index) => (
              <>
                <Link to={`/deliveries/${d.id}`} target="_blank" style={{ display: 'block' }}>
                  {d.delivery_code}
                </Link>
              </>
            ))}
          </div>
        );
      if (item.complete === 0)
        return (
          <button
            onClick={() => navigate(`/deliveries/create?target_type=PURCHASE&target_id=${item.id}`)}
          >
            作成
          </button>
        );
      return null;
    })();
    const completed = item.complete === 1 ? <BsCircle /> : '';
    const aggregationDate = (
      (item.purchase_date != item.aggregation_date) ? (
        <div className="alert-cell">
          {item.aggregation_date}
        </div>
      ): item.aggregation_date
    );
    const purchaseDetail = item.purchase_detail;

    return [
      goToPurchaseBtn,
      purchaseCode,
      customerName,
      inputDate,
      purchaseDate,
      importState,
      deliveryState,
      completed,
      aggregationDate,
      purchaseDetail,
    ];
  };

  const resetForm = () => {
    reset(DEFAULT_PURCHASE_FILTER);
    dataProvider.current.clearFilters();
  };

  const search = handleSubmit((values) => {
    const newFilter = {
      ...values,
      sort: dataProvider.current.filters.sort,
      sort_type: dataProvider.current.filters.sort_type,
    };
    dataProvider.current.setFilters(newFilter);
  });

  return (
    <div className="list-screen">
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/home' }}>
          TOP
        </Breadcrumb.Item>
        <Breadcrumb.Item active>買取</Breadcrumb.Item>
      </Breadcrumb>

      <div className={styles.filterContainer}>
        <div className={styles.inputDate}>
          <TimeRangeFilter
            control={control}
            label="入力日"
            keys={['input_date_from', 'input_date_to']}
          />
        </div>
        <div className={styles.purchaseDate}>
          <TimeRangeFilter
            control={control}
            label="買取日"
            keys={['purchase_date_from', 'purchase_date_to']}
          />
        </div>
        <div className={styles.purchaseCode}>
          <TextFilter control={control} label="買取番号" keys={['purchase_code']} />
        </div>

        <div className={styles.userInputId}>
          <DynamicDropdownFilter
            label="入力者"
            control={control}
            keys={['user_input_id']}
            dataProvider={inputUserDataProvider}
            setValue={setValue}
          />
        </div>

        <div className={styles.buttonGroup}>
          <button className="action-btn" onClick={search}>
            検索
          </button>
          <button className="action-btn" onClick={resetForm}>
            クリア
          </button>
        </div>

        <div className={styles.wrap}></div>

        <div className={styles.customerId}>
          <CustomerFilter label="顧客" control={control} keys={['customer_id']} />
        </div>
        <div className={styles.customerIdInDetail}>
          <CustomerFilter label="明細顧客" control={control} keys={['customer_id_in_detail']} />
        </div>

        <div className={styles.wrap}></div>

        <div className={styles.delivery}>
          <RadioButtonFilter
            control={control}
            label="配送"
            options={[
              { label: '全て', value: null },
              { label: '作成済', value: 1 },
              { label: '未作成', value: 0 },
            ]}
            keys={['delivery']}
          />
        </div>
        <div className={styles.complete}>
          <RadioButtonFilter
            control={control}
            label="完了"
            options={[
              { label: '全て', value: null },
              { label: '完了', value: 1 },
              { label: '未完了', value: 0 },
            ]}
            keys={['complete']}
          />
        </div>
      </div>

      <div className="d-flex justify-content-end">
        <button className="create" onClick={() => navigate('/purchases/create')}>
          新しい買取
        </button>
      </div>

      <div className={styles.tableWrapper}>
        <DataTable<Purchase, IPurchaseFilter>
          dataProvider={dataProvider.current}
          headers={headers}
          delegate={delegate}
        />
      </div>
    </div>
  );
};

export default PurchaseScreen;
