import { useEffect } from 'react';
import { Control, Controller } from 'react-hook-form';
import Select from 'react-select';
import { AsyncPaginate } from 'react-select-async-paginate';
import { IDropdownDataProvider } from 'shared/data-providers/dropdown-data-provider';
import { observer } from 'mobx-react';

import FilterItem from '../filter-item';

import styles from './styles.module.scss';

interface IProps {
  label: string;
  keys: [string];
  control: Control<any, any>;
  options?: Array<{ label: string; value: string | number }>;
  dataProvider?: IDropdownDataProvider<{ label: string; value: string | number }>;
}

const DropdownFilter = observer(({ label, keys, control, options, dataProvider }: IProps) => {
  useEffect(() => {}, [dataProvider?.data]);

  if (!dataProvider && options)
    return (
      <div className="d-flex align-items-center w-100">
        <FilterItem label={label} />
        <Controller
          control={control}
          name={keys[0]}
          render={({ field: { onChange, value, onBlur } }) => (
            <Select
              className={styles.dropdown}
              isSearchable={false}
              isClearable
              placeholder="選択"
              options={options}
              onBlur={onBlur}
              onChange={(newValue) => onChange(newValue?.value || null)}
              value={options.find((item) => item.value === value) || null}
            />
          )}
        />
      </div>
    );

  return (
    <div className="d-flex align-items-center w-100">
      <FilterItem label={label} />
      <Controller
        name={keys[0]}
        control={control}
        render={({ field: { onChange, value, onBlur } }) => (
          <AsyncPaginate
            className={styles.dropdown}
            loadingMessage={() => <div>Loading</div>}
            isSearchable={false}
            isClearable
            placeholder="選択"
            loadOptions={() => {
              const options = dataProvider!.load();
              return options;
            }}
            onBlur={onBlur}
            onChange={(newValue: any) => {
              onChange(newValue?.value || null);
            }}
            value={dataProvider?.data.find((item) => item.value === value) || null}
            defaultOptions
          />
        )}
      />
    </div>
  );
});

export default DropdownFilter;
