// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_container__WPIg3 {\n  display: flex;\n}", "",{"version":3,"sources":["webpack://./src/shared/components/universal-dropdown/styles.module.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;AADF","sourcesContent":["@use '../../../assets/styles/variables' as *;\n\n.container {\n  display: flex;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "styles_container__WPIg3"
};
export default ___CSS_LOADER_EXPORT___;
