export type DepositCustomer = {
  id: number;
  sale_year: number;
  sale_month: number;
  customer_id: number;
  start_date: string;
  end_date: string;
  last_request_balance: number;
  total_amount_request: number;
  sale_amount: number;
  deposit_amount: number;
  adjust_amount: number;
  request_balance: number;
  created_at: string | null;
  updated_at: string | null;
  deleted_at: string | null;
  customer: {
    id: number;
    customer_code: string;
    customer_name: string;
    customer_kana: string | null;
    hall_name: string | null;
    hall_kana: string | null;
  };
};

export type Deposit = {
  id: number | string;
  deposit_date: string;
  deposit_type: number;
  in_amount: number;
};

export const depositTypes: Array<{
  [key: string]: any;
}> = [
  {
    label: '振込', // transfer
    value: 1,
  },
  {
    label: '手数料', // commission
    value: 6,
  },
  {
    label: '現金', // cash
    value: 2,
  },
  {
    label: '値引', // discount
    value: 3,
  },
  {
    label: '相殺', // offset
    value: 4,
  },
  {
    label: '調整', // adjust
    value: 5,
  },
];
