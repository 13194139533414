import { useEffect, useState } from 'react';
import Select from 'react-select';
import FilterItem from '../filter-item';
import styles from './styles.module.scss';

interface IProps {
  label?: string;
  onHourFromChange?: (value: any) => void;
  onHourToChange?: (value: any) => void;
  defaultFrom?: string;
  defaultTo?: string;
  disabled?: boolean;
}

const HourRangeFilter = ({
  label,
  onHourFromChange,
  onHourToChange,
  defaultFrom,
  defaultTo,
  disabled = false,
}: IProps) => {
  const options = [
    { label: '05:00', value: '05:00' },
    { label: '05:30', value: '05:30' },

    { label: '06:00', value: '06:00' },
    { label: '06:30', value: '06:30' },

    { label: '07:00', value: '07:00' },
    { label: '07:30', value: '07:30' },

    { label: '08:00', value: '08:00' },
    { label: '08:30', value: '08:30' },

    { label: '09:00', value: '09:00' },
    { label: '09:30', value: '09:30' },

    { label: '10:00', value: '10:00' },
    { label: '10:30', value: '10:30' },

    { label: '11:00', value: '11:00' },
    { label: '11:30', value: '11:30' },

    { label: '12:00', value: '12:00' },
    { label: '12:30', value: '12:30' },

    { label: '13:00', value: '13:00' },
    { label: '13:30', value: '13:30' },

    { label: '14:00', value: '14:00' },
    { label: '14:30', value: '14:30' },

    { label: '15:00', value: '15:00' },
    { label: '15:30', value: '15:30' },

    { label: '16:00', value: '16:00' },
    { label: '16:30', value: '16:30' },

    { label: '17:00', value: '17:00' },
    { label: '17:30', value: '17:30' },

    { label: '18:00', value: '18:00' },
    { label: '18:30', value: '18:30' },

    { label: '19:00', value: '19:00' },
    { label: '19:30', value: '19:30' },

    { label: '20:00', value: '20:00' },
    { label: '20:30', value: '20:30' },

    { label: '21:00', value: '21:00' },
    { label: '21:30', value: '21:30' },

    { label: '22:00', value: '22:00' },
    { label: '22:30', value: '22:30' },

    { label: '23:00', value: '23:00' },
    { label: '23:15', value: '23:15' },
    { label: '23:30', value: '23:30' },
    { label: '23:45', value: '23:45' },

    { label: '24:00', value: '24:00' },
    { label: '24:15', value: '24:15' },
    { label: '24:30', value: '24:30' },
    { label: '24:45', value: '24:45' },

    { label: '25:00', value: '25:00' },
    { label: '25:15', value: '25:15' },
    { label: '25:30', value: '25:30' },
    { label: '25:45', value: '25:45' },

    { label: '26:00', value: '26:00' },
    { label: '26:15', value: '26:15' },
    { label: '26:30', value: '26:30' },
    { label: '26:45', value: '26:45' },

    { label: '27:00', value: '27:00' },
  ];

  const [from, setFrom] = useState<any>(undefined);
  const [to, setTo] = useState<any>(undefined);

  useEffect(() => {
    if (defaultFrom) setFrom({ label: defaultFrom, value: defaultFrom });
    if (defaultTo) setTo({ label: defaultTo, value: defaultTo });
  }, []);

  useEffect(() => {
    if (defaultFrom) {
      if (defaultFrom.length === 8)
        setFrom({ label: defaultFrom.replace(/:00$/, ''), value: defaultFrom.replace(/:00$/, '') });
      else setFrom({ label: defaultFrom, value: defaultFrom });
    }
    if (defaultTo) {
      if (defaultTo.length === 8)
        setTo({ label: defaultTo.replace(/:00$/, ''), value: defaultTo.replace(/:00$/, '') });
      else setTo({ label: defaultTo, value: defaultTo });
    }
  }, [defaultFrom, defaultTo]);

  return (
    <div className={styles.container}>
      {label && <FilterItem label={label} />}
      <Select
        className={styles.dropdown}
        isClearable={true}
        isSearchable={false}
        onChange={(newValue) => {
          setFrom(newValue);
          onHourFromChange && onHourFromChange(newValue);
        }}
        options={options}
        placeholder="選択"
        value={from}
        isDisabled={disabled}
      />
      <span className={styles.tilde}></span>
      <Select
        className={styles.dropdown}
        isClearable={true}
        isSearchable={false}
        onChange={(newValue) => {
          setTo(newValue);
          onHourToChange && onHourToChange(newValue);
        }}
        options={options}
        placeholder="選択"
        value={to}
        isDisabled={disabled}
      />
    </div>
  );
};

export default HourRangeFilter;
