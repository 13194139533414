import { del, get, post, put } from 'services';
import { Purchase } from 'types/purchase';

export const getPurchaseById = (id: number) => get(`purchases/${id}`, {});

export const createPurchase = (data: Partial<Purchase>, quotationId: number | string = '') =>
  post(`purchases/${quotationId}`, data);

export const updatePurchase = (id: number, data: Partial<Purchase>) => put(`purchases/${id}`, data);

export const deletePurchase = (id: number) => del(`purchases/${id}`);
