import { useEffect, useMemo, useState } from 'react';
import { useForm, Controller, useWatch } from 'react-hook-form';
import { Form } from 'react-bootstrap';
import Select from 'react-select';
import { AsyncPaginate } from 'react-select-async-paginate';
import { useQuery } from 'react-query';

import { DEADLINE_DAYS } from 'consts';
import { get } from 'services';
import { Customer } from 'types/customer';
import CustomerFilter from 'shared/components/customer-filter';
import { getCustomerDetails } from 'services/customer';

import styles from './styles.module.scss';

interface IProps {
  initialValues?: Customer;
  onSave: (data: any) => void;
  onCreate: (data: any) => void;
  onDelete: () => void;
  setIsDirty: (arg: boolean) => void;
}

const EditForm = ({ initialValues, onCreate, onDelete, onSave, setIsDirty }: IProps) => {
  const [billingName, setBillingName] = useState(initialValues?.billing_by?.customer_name);

  const initialFormValues = useMemo(() => {
    let values: any = {};
    if (initialValues) {
      values = initialValues;
      return values;
    }

    values = {
      deadline_day: 0,
      tel: '',
      fax: '',
      postal_code: '',
      status: 1,
    };
    return values;
  }, []);

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { isValid },
  } = useForm({
    defaultValues: initialFormValues,
    mode: 'onChange',
  });

  const tel = useWatch({ control, name: 'tel' });
  const fax = useWatch({ control, name: 'fax' });
  const postalCode = useWatch({ control, name: 'postal_code' });
  const billingBy = useWatch({ control, name: 'billing_by' });
  const billingId = useWatch({ control, name: 'billing_id' });
  const customer_memo = useWatch({ control, name: 'customer_memo' });

  const customerQuery = useQuery(['billing', billingId], () => getCustomerDetails(billingId), {
    enabled: false,
    keepPreviousData: true,
    onSuccess: (customer) => {
      setValue('billing_by', customer);
    },
  });

  useEffect(() => {
    if (billingId) {
      customerQuery.refetch();
    } else {
      setValue('billing_by', undefined);
    }
  }, [billingId]);

  return (
    <Form
      onChange={() => {
        let isDirty = control._formState.isDirty;
        setIsDirty(isDirty);
      }}
    >
      {initialValues && (
        <Form.Group className={styles.group}>
          <Form.Label>顧客CD</Form.Label>
          <Form.Control {...register('customer_code', { required: true })} disabled />
        </Form.Group>
      )}
      <Form.Group className={styles.group}>
        <Form.Label className="required">顧客名</Form.Label>
        <Form.Control {...register('customer_name', { required: true })} />
      </Form.Group>
      <Form.Group className={styles.group}>
        <Form.Label className="required">顧客カナ</Form.Label>
        <Form.Control {...register('customer_kana', { required: true })} />
      </Form.Group>
      <Form.Group className={styles.group}>
        <Form.Label>ホール名</Form.Label>
        <Form.Control {...register('hall_name')} />
      </Form.Group>
      <Form.Group className={styles.group}>
        <Form.Label>ホールカナ</Form.Label>
        <Form.Control {...register('hall_kana')} />
      </Form.Group>
      <Form.Group className={styles.group}>
        <Form.Label style={{ width: '15%' }}>請求先CD</Form.Label>
        <div style={{ width: '85%' }}>
          <CustomerFilter
            inputClassName={styles.billingInput}
            control={control}
            keys={['billing_id']}
            fallbackValue={billingBy}
            allowModal={false}
          />
        </div>
      </Form.Group>
      <Form.Group className={styles.group}>
        <Form.Label style={{ width: '15%' }}>締日</Form.Label>
        <Controller
          control={control}
          name="deadline_day"
          render={({ field }) => (
            <Select
              options={DEADLINE_DAYS}
              defaultValue={
                initialValues
                  ? initialValues.deadline_day
                    ? { label: initialValues.deadline_day, value: initialValues.deadline_day }
                    : DEADLINE_DAYS[0]
                  : DEADLINE_DAYS[0]
              }
              className={styles.dropdown}
              onChange={(newValue) => {
                field.onChange(newValue?.value);
              }}
            />
          )}
        />
      </Form.Group>
      <Form.Group className={styles.group}>
        <Form.Label>TEL</Form.Label>
        <Form.Control
          value={tel}
          onChange={(event: any) => {
            if (/^[0-9-]*$/.test(event.target.value)) {
              setValue('tel', event.target.value, { shouldDirty: true });
            }
          }}
        />
      </Form.Group>
      <Form.Group className={styles.group}>
        <Form.Label>FAX</Form.Label>
        <Form.Control
          value={fax}
          onChange={(event: any) => {
            if (/^[0-9-]*$/.test(event.target.value)) {
              setValue('fax', event.target.value, { shouldDirty: true });
            }
          }}
        />
      </Form.Group>
      <Form.Group className={styles.group}>
        <Form.Label style={{ width: '15%' }}>担当者名</Form.Label>
        <div className="d-flex" style={{ flex: 1 }}>
          <Controller
            control={control}
            name="pic_name"
            render={({ field }) => (
              <>
                <AsyncPaginate
                  className={styles.dropdown}
                  loadingMessage={() => <div>Loading</div>}
                  isSearchable={false}
                  isClearable
                  placeholder=""
                  defaultValue={
                    !!initialValues?.pic_name
                      ? {
                          label: initialValues.pic_name,
                          value: initialValues.pic_name,
                        }
                      : undefined
                  }
                  loadOptions={async () => {
                    try {
                      const data = await get('users/dropdown/person_in_charge', {});
                      const options = data.map(
                        (item: { id: number; username: string; name: string }) => ({
                          label: item.name,
                          value: item.name,
                          id: item.id,
                        })
                      );

                      return { options };
                    } catch (err) {
                      return { options: [] };
                    }
                  }}
                  onChange={(option: any) => {
                    field.onChange(option ? option.value : null);
                    setValue('pic_id', option ? option.id : null);
                  }}
                />
              </>
            )}
          />
        </div>
      </Form.Group>
      <Form.Group className={styles.group}>
        <Form.Label>〒</Form.Label>
        <Form.Control
          value={postalCode}
          onChange={(event: any) => {
            if (/^[0-9-]*$/.test(event.target.value)) {
              setValue('postal_code', event.target.value, { shouldDirty: true });
            }
          }}
        />
      </Form.Group>
      <Form.Group className={styles.group}>
        <Form.Label>住所</Form.Label>
        <Form.Control {...register('address')} />
      </Form.Group>
      <Form.Group className={styles.group}>
        <Form.Label>振込口座</Form.Label>
        <Form.Control {...register('pay_info')} />
      </Form.Group>
      <Form.Group className={styles.group}>
        <Form.Label>備考</Form.Label>
        <Form.Control {...register('customer_note')} />
      </Form.Group>
      <Form.Group className={styles.group}>
        <Form.Label>メモ</Form.Label>
        <Form.Control
          as="textarea"
          value={customer_memo}
          onChange={(event) => {
            setValue('customer_memo', event.target.value, { shouldDirty: true });
          }}
          rows={4}
        />
      </Form.Group>
      <Form.Group className="d-flex align-items-center">
        <Form.Label style={{ width: '15%' }}>使用中止</Form.Label>
        <Controller
          control={control}
          name="status"
          render={({ field }) => (
            <Form.Check
              className="custom-checkbox"
              // active = unchecked = O = 1 (DB), inactive = checked = X = 0 (DB)
              defaultChecked={field.value === 0 ? true : false}
              onChange={(event) => {
                // status: 1 (active - unchecked)
                // status: 0 (inactive - checked)
                const checked = event.target.checked;
                const newStatus = checked ? 0 : 1;
                field.onChange(newStatus);
              }}
            />
          )}
        />
      </Form.Group>
      <div className="d-flex justify-content-end">
        {initialValues && (
          <button className="edit-modal--delete" onClick={onDelete}>
            削除
          </button>
        )}
        <button
          className="edit-modal--save"
          disabled={!isValid}
          onClick={handleSubmit((values) => {
            initialValues ? onSave(values) : onCreate(values);
          })}
        >
          登録
        </button>
      </div>
    </Form>
  );
};

export default EditForm;
