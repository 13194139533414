import { observer } from 'mobx-react-lite';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import DynamicDropdownFilter from 'shared/components/dynamic-dropdown-filter';
import TextFilter from 'shared/components/text-filter';
import { DropdownDataProvider } from 'shared/data-providers/dropdown-data-provider';
import { SelectDataProvider } from 'shared/data-providers/select-data-provider';
import { IStockExportFilter } from 'types/stock-export';
import styles from './styles.module.scss';

interface IProps {
  dataProvider: SelectDataProvider<IStockExportFilter>;
  groupId?: number;
}

const StockFilter = observer(({ dataProvider, groupId }: IProps) => {
  const { control, handleSubmit, setValue, reset } = useForm<IStockExportFilter>();

  // DynamicDropdownFilter needs initial value for the dropdown
  // so customerDataProvider cannot be null.
  // force DynamicDropdownFilter to remount if groupId changes in export-details
  const [customerKey, setCustomerKey] = useState<number>(0);

  // initial value for customer dropdown
  // if there is a groupId, this will change.
  const customerDataProvider = useRef<any>(
    new DropdownDataProvider('customers/dropdown', (customer) => ({
      label: `${customer.customer_name}${customer.hall_name? '　'+customer.hall_name : ''}`,
      value: customer.id,
    }))
  );

  const groupDataProvider = useRef<any>(
    new DropdownDataProvider('groups/dropdown', (group) => ({
      label: group.group_name,
      value: group.id,
    }))
  );

  const deviceTypeDataProvider = useRef<any>(
    new DropdownDataProvider('device-types/dropdown', (deviceType) => ({
      label: deviceType.device_type_code,
      value: deviceType.device_type_code,
    }))
  );

  const makerDataProvider = useRef<any>(
    new DropdownDataProvider('makers/dropdown', (maker) => ({
      label: maker.maker_code + ' - ' + maker.maker_name,
      value: maker.id,
    }))
  );

  const resetFilters = () => {
    reset({ group_id: groupId });
    dataProvider.setFilters({ group_id: groupId });
  };

  useEffect(() => {
    if (groupId) {
      customerDataProvider.current = new DropdownDataProvider(
        `customers/dropdown?group_id=${groupId}`,
        (customer) => ({
          label: customer.hall_name? customer.hall_name : customer.customer_name,
          value: customer.id,
        })
      );
      setCustomerKey(Math.random());
    }
  }, [groupId]);

  useEffect(() => {
    setValue('group_id', groupId);
  }, [groupId]);

  return (
    <>
      <div className={styles.filterContainer}>
        <div>
          <DynamicDropdownFilter
            label="グループ"
            control={control}
            dataProvider={groupDataProvider.current}
            keys={['group_id']}
            disabled={true}
          />
        </div>

        <div>
          <DynamicDropdownFilter
            label="種別"
            control={control}
            dataProvider={deviceTypeDataProvider.current}
            keys={['device_type_code']}
            isClearable={true}
          />
        </div>

        <div>
          <DynamicDropdownFilter
            label="メーカー"
            control={control}
            dataProvider={makerDataProvider.current}
            keys={['maker_id']}
            isClearable={true}
          />
        </div>

        <div>
          <TextFilter label="型式名" control={control} keys={['model_name']} />
        </div>

        <div>
          <TextFilter label="枠番号" control={control} keys={['frame_number']} />
        </div>

        <div>
          <TextFilter label="セル番号" control={control} keys={['cell_number']} />
        </div>

        <div>
          <TextFilter label="基盤番号" control={control} keys={['base_number']} />
        </div>

        <div>
          <TextFilter label="在庫場所" control={control} keys={['pallet_code']} />
        </div>

        <div>
          <DynamicDropdownFilter
            key={customerKey}
            label="入庫元"
            control={control}
            keys={['import_customer_id']}
            dataProvider={customerDataProvider.current}
            isClearable={true}
          />
        </div>

        <div>
          <TextFilter label="備考" control={control} keys={['stock_note']} />
        </div>
        <div>
          <TextFilter label="詳細" control={control} keys={['stock_detail']} />
        </div>
        <div>
          <TextFilter label="納品先" control={control} keys={['destination']} />
        </div>
        <div>
          <TextFilter label="メモ" control={control} keys={['stock_memo']} />
        </div>
        <div>
          <TextFilter label="枠色 " control={control} keys={['frame_color']} />
        </div>
      </div>

      <div className={styles.buttonGroup}>
        <button
          className="secondary-btn"
          onClick={handleSubmit((values) => dataProvider.setFilters(values))}
        >
          検索
        </button>
        <button className="secondary-btn" onClick={resetFilters}>
          クリア
        </button>
      </div>
    </>
  );
});

export default StockFilter;
