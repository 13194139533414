// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_dropdown__tw0vy {\n  border: 1px solid lightgray;\n  min-width: 10rem;\n  border-radius: 0.25rem;\n  width: 85%;\n  margin: 0 0 0 auto;\n}\n\n.styles_datePicker__YDgev {\n  border: 1px solid lightgray;\n  border-radius: 0.25rem;\n  font-size: 1rem;\n  padding: 0.375rem 0.75rem;\n  width: 100%;\n}\n\n.styles_group__zVw7h {\n  display: flex;\n  align-items: center;\n  margin: 0.5em 0;\n}\n.styles_group__zVw7h label {\n  margin: 0 0 0 0;\n}\n.styles_group__zVw7h input {\n  width: 85%;\n  margin: 0 0 0 auto;\n}", "",{"version":3,"sources":["webpack://./src/screens/vehicle-cost/edit-modal/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,2BAAA;EACA,gBAAA;EACA,sBAAA;EACA,UAAA;EACA,kBAAA;AACF;;AAEA;EACE,2BAAA;EACA,sBAAA;EACA,eAAA;EACA,yBAAA;EACA,WAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,eAAA;AACF;AACE;EACE,eAAA;AACJ;AAEE;EACE,UAAA;EACA,kBAAA;AAAJ","sourcesContent":[".dropdown {\n  border: 1px solid lightgray;\n  min-width: 10rem;\n  border-radius: 0.25rem;\n  width: 85%;\n  margin: 0 0 0 auto;\n}\n\n.datePicker {\n  border: 1px solid lightgray;\n  border-radius: 0.25rem;\n  font-size: 1rem;\n  padding: 0.375rem 0.75rem;\n  width: 100%;\n}\n\n.group {\n  display: flex;\n  align-items: center;\n  margin: 0.5em 0;\n\n  label {\n    margin: 0 0 0 0;\n  }\n\n  input {\n    width: 85%;\n    margin: 0 0 0 auto;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropdown": "styles_dropdown__tw0vy",
	"datePicker": "styles_datePicker__YDgev",
	"group": "styles_group__zVw7h"
};
export default ___CSS_LOADER_EXPORT___;
