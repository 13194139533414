import { useEffect, useRef, useState } from 'react';
import { convertToHalfWidth } from 'utils/validator';
import TooltipTrigger from '../tooltip-trigger';

interface IProps {
  disabled?: boolean;
  customer: any;
  onUpdate: (code: string) => void;
  allowFullWidth?: boolean;
}

const CustomerTooltip = ({ disabled, customer, onUpdate, allowFullWidth }: IProps) => {
  const [val, setVal] = useState<string>('');
  const [tooltip, setTooltip] = useState<string>('');
  const debounce = useRef<any>(null);

  const onChange = (event: any) => {
    if (debounce.current) clearTimeout(debounce.current);
    let val = event.target.value;
    setVal(convertToHalfWidth(val));

    debounce.current = setTimeout(() => {
      onUpdate(convertToHalfWidth(val));
      debounce.current = null;
    }, 400);
  };

  useEffect(() => {
    if (customer) {
      const value = customer.customer_code;
      const tooltipString = `${customer.customer_name || ''} ${customer.customer_kana || ''}`;
      setVal(value);
      setTooltip(tooltipString);
    } else {
      setTooltip('');
    }
  }, [customer]);

  return (
    <TooltipTrigger tooltip={tooltip}>
      <input value={val} onChange={onChange} disabled={disabled} />
    </TooltipTrigger>
  );
};

export default CustomerTooltip;
