import { useEffect, useMemo } from 'react';
import { useMutation, useQuery } from 'react-query';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import moment from 'moment';

import {
  createQuotation,
  deleteQuotation,
  getQuotationById,
  updateQuotation,
} from 'services/quotation';
import QuotationDetailsForm from './form';
import UIStore from 'stores/ui';
import useStores from 'hooks/use-stores';
import { Quotation, QuotationProduct } from 'types/quotation';
import { getDirtyObject, removeKeys } from 'utils';
import QuotationDetailsTable from './table';
import { exportForm } from 'services/export-form';
import { useFormError } from 'hooks/use-form-error';

import styles from './styles.module.scss';
import { toast } from 'react-toastify';
import useBlockNavigation from 'hooks/use-block-navigation';
import SessionStore from 'stores/session';

interface IProps {
  id?: number; // to edit
  initialValues?: Partial<Quotation>; // to duplicate
}

const QuotationDetailContainer = observer(({ id, initialValues }: IProps) => {
  const navigate = useNavigate();
  const uiStore: UIStore = useStores().uiStore;
  const sessionStore: SessionStore = useStores().sessionStore;

  const initialFormValues = useMemo(() => {
    let values: any = {};
    const profile = sessionStore.profile;
    // duplicate
    if (initialValues) {
      values = initialValues;
    } else {
      values = {
        input_date: moment(new Date()).format('YYYY-MM-DD'),
        quotation_type: 1,
        quotation_details: [],
      };

      const canBeUserInput = profile?.status === 1 && profile?.user_types?.INPUT_PERSON === 1;

      // set currently logged-in user as user input of this form.
      if (canBeUserInput) values.user_input_id = profile.id;
    }

    return values;
  }, []);

  const {
    control,
    reset,
    setValue,
    getValues,
    formState: { isDirty, dirtyFields },
  } = useForm<Partial<Quotation>>({
    defaultValues: initialFormValues,
    mode: 'onChange',
  });

  const purchasePosted = useWatch({ control, name: 'purchase_posted' });

  const { showFormError } = useFormError();
  useBlockNavigation({ shouldBlock: control._formState.isDirty });

  const { refetch } = useQuery<Quotation>(['quotation', id], () => getQuotationById(id as number), {
    enabled: !!id,
    onSettled: () => uiStore.hideLoading(),
    onSuccess: (data) => {
      reset(data);
    },
  });

  const saveMutation = useMutation((data: any) => updateQuotation(id as number, data), {
    onMutate: () => uiStore.showLoading(),
    onSettled: () => uiStore.hideLoading(),
    onSuccess: () => {
      control._formState.isDirty = false;
      toast.success('登録しました');
      refetch();
    },
    onError: (err) => showFormError(err),
  });

  const createMutation = useMutation((data: any) => createQuotation(data), {
    onMutate: () => uiStore.showLoading(),
    onSettled: () => uiStore.hideLoading(),
    onError: (err) => showFormError(err),
    onSuccess: (data) => {
      control._formState.isDirty = false;
      toast.success('登録しました');
      setTimeout(() => {
        navigate(`/quotations/${data.id}`, { replace: true });
      }, 10);
    },
  });

  const deleteMutation = useMutation(() => deleteQuotation(id as number), {
    onMutate: () => uiStore.showLoading(),
    onSettled: () => uiStore.hideLoading(),
    onSuccess: (_data) => {
      toast.success('登録しました');
      control._formState.isDirty = false;
      setTimeout(() => {
        navigate(`/quotations`, { replace: true });
      }, 10);
    },
  });

  const print = async (option: string | number) => {
    if (!id) return;

    try {
      uiStore.showLoading();
      await exportForm(`quotations/export-details/${id}`, {
        form_type: option as string,
      });
    } catch (error: any) {
      uiStore.showAlertBox({
        title: 'エラー',
        type: 'error',
        content: error.message,
      });
    }
    uiStore.hideLoading();
  };

  const save = () => {
    const values = getValues();
    let payload: any;

    if (id) {
      payload = getDirtyObject(dirtyFields, values);
      switch (true) {
        case !isDirty:
          return;
        case isDirty && JSON.stringify(payload) === '{}':
          return;
        default:
          break;
      }
    } else {
      payload = values;
    }

    if (payload.quotation_details) {
      payload.quotation_details = payload.quotation_details.map((item: QuotationProduct) => ({
        ...item,
        id: typeof item.id === 'string' ? undefined : item.id,
      }));
    }

    removeKeys(payload, [
      'customer',
      'user_person_in_charge',
      'user_input_person',
      'user_confirm_person',
    ]);

    id ? saveMutation.mutate(payload) : createMutation.mutate(payload);
  };

  const showConfirmDeletePopup = () => {
    uiStore.showConfirmBox({
      title: 'このレコードを削除しますか？',
      confirmText: '削除する',
      cancelText: ' キャンセル',
      onConfirm: () => {
        uiStore.hideConfirmBox();
        deleteMutation.mutate();
      },
    });
  };

  useEffect(() => {
    if (id) uiStore.showLoading();
  }, []);

  return (
    <div className={styles.container}>
      <QuotationDetailsForm control={control} onSave={save} setValue={setValue} onPrint={print} />
      <Controller
        control={control}
        name="quotation_details"
        render={({ field: { value } }) => (
          <QuotationDetailsTable initialRows={value as []} control={control} setValue={setValue} />
        )}
      />
      {id && (
        <div className={styles.buttonGroup}>
          <button className="primary-btn--delete" onClick={showConfirmDeletePopup}>
            削除
          </button>
          <button
            className="primary-btn"
            onClick={() => window.open(`${window.location.origin}/quotations/create?id=${id}`)}
          >
            複製
          </button>
          {purchasePosted === 0 && (
            <button
              className="primary-btn"
              onClick={() => navigate(`/purchases/create?quotation_id=${id}`)}
            >
              買取転記
            </button>
          )}
        </div>
      )}
    </div>
  );
});

export default QuotationDetailContainer;
