// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_container__Bmx78 {\n  display: flex;\n  flex-direction: column;\n}\n.styles_container__Bmx78 textarea {\n  resize: none;\n  border: 1px solid #ddd;\n  padding: 0.25em;\n}\n.styles_container__Bmx78 textarea:focus {\n  outline: none;\n}", "",{"version":3,"sources":["webpack://./src/shared/components/textarea-filter/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;AACF;AACE;EACE,YAAA;EACA,sBAAA;EACA,eAAA;AACJ;AACI;EACE,aAAA;AACN","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n\n  textarea {\n    resize: none;\n    border: 1px solid #ddd;\n    padding: 0.25em;\n\n    &:focus {\n      outline: none;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "styles_container__Bmx78"
};
export default ___CSS_LOADER_EXPORT___;
