import { useEffect, useMemo } from 'react';
import { Table } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { useForm, useWatch } from 'react-hook-form';
import { observer } from 'mobx-react';
import { MdOutlineCircle } from 'react-icons/md';

import {
  createInventoryItem,
  deleteInventoryItems,
  getInventoryItemById,
  updateInventoryItem,
} from 'services/inventory';
import { InventoryItem } from 'types/inventory';
import UIStore from 'stores/ui';
import useStores from 'hooks/use-stores';
import { useFormError } from 'hooks/use-form-error';
import InventoryDetailsForm from './form';
import { getDirtyObject } from 'utils';

import styles from './styles.module.scss';
import { toast } from 'react-toastify';
import useBlockNavigation from 'hooks/use-block-navigation';

interface IProps {
  id?: number;
}

const InventoryDetailsContainer = observer(({ id }: IProps) => {
  const navigate = useNavigate();

  const uiStore: UIStore = useStores().uiStore;

  const initialFormValues = useMemo(() => {
    return { stock_type: 1 };
  }, []);

  const {
    control,
    reset,
    setValue,
    getValues,
    register,
    formState: { isDirty, dirtyFields },
  } = useForm<InventoryItem>({
    defaultValues: initialFormValues,
    mode: 'onChange',
  });
  const data = useWatch({ control });

  const { refetch } = useQuery<InventoryItem>(
    ['inventory-item', id],
    () => getInventoryItemById(id as number),
    {
      enabled: !!id,
      onSettled: () => uiStore.hideLoading(),
      onSuccess: (data) => reset(data),
    }
  );

  const { showFormError } = useFormError();

  const createMutation = useMutation((data) => createInventoryItem(data), {
    onMutate: () => uiStore.showLoading(),
    onSettled: () => uiStore.hideLoading(),
    onError: (err) => showFormError(err),
    onSuccess: (data) => {
      toast.success('登録しました');
      control._formState.isDirty = false;

      setTimeout(() => {
        navigate(`/inventories/${data.id}`, { replace: true });
      }, 10);
    },
  });

  const updateMutation = useMutation((data) => updateInventoryItem(id as number, data), {
    onMutate: () => uiStore.showLoading(),
    onSettled: () => uiStore.hideLoading(),
    onError: (err) => showFormError(err),
    onSuccess: () => {
      toast.success('登録しました');
      refetch();
    },
  });

  const deleteMutation = useMutation(() => deleteInventoryItems([id as number]), {
    onMutate: () => uiStore.showLoading(),
    onSettled: () => uiStore.hideLoading(),
  });

  const save = () => {
    const values = getValues();
    const payload = id ? getDirtyObject(dirtyFields, values) : values;

    if (!isDirty) return;
    if (isDirty && JSON.stringify(payload) === '{}') return;

    id ? updateMutation.mutate(payload) : createMutation.mutate(payload);
  };

  const showConfirmDeletePopup = () => {
    uiStore.showConfirmBox({
      title: 'このレコードを削除しますか？',
      confirmText: '削除する',
      cancelText: ' キャンセル',
      onConfirm: () => {
        uiStore.hideConfirmBox();
        deleteMutation.mutate();
      },
    });
  };

  useBlockNavigation({ shouldBlock: control._formState.isDirty });

  useEffect(() => {
    if (id) uiStore.showLoading();
  }, []);

  return (
    <div className={styles.container}>
      <InventoryDetailsForm control={control} setValue={setValue} onSave={save} />
      <Table hover bordered className="mt-4">
        <thead>
          <tr>
            {['', '番号', 'QRコード', '出庫番号', '出庫日', '出庫先', '返却'].map(
              (header, index) => (
                <th key={index} colSpan={index === 5 ? 3 : 1}>
                  {header}
                </th>
              )
            )}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className={styles.header}>枠番号・その他</td>
            <td>
              <input {...register('frame_number')} disabled={!!data?.frame_export} />
            </td>
            <td>
              <input {...register('frame_code')} disabled={!!data?.frame_export} />
            </td>
            <td className={styles.disabled}>
              {data?.frame_export && (
                <Link
                  to={`/exports/${data.frame_export.export?.id}?type=${data.frame_export.export?.export_type}`}
                  target="_blank"
                >
                  {data.frame_export.export?.export_code}
                </Link>
              )}
            </td>
            <td className={styles.disabled}>{data?.frame_export?.export?.export_date}</td>
            <td className={styles.disabled}>
              {data?.frame_export?.export?.customer?.customer_code}
            </td>
            <td className={styles.disabled}>
              {data?.frame_export?.export?.customer?.customer_name}
            </td>
            <td className={styles.disabled}>{data?.frame_export?.export?.customer?.hall_name}</td>
            <td className={styles.disabled}>
              {data?.frame_export?.export.export_type === 2 && <MdOutlineCircle />}
            </td>
          </tr>
          <tr>
            <td className={styles.header}>セル番号</td>
            <td>
              <input {...register('cell_number')} disabled={!!data?.base_cell_export} />
            </td>
            <td>
              <input {...register('cell_code')} disabled={!!data?.base_cell_export} />
            </td>
            <td className={styles.disabled}>
              {data?.base_cell_export && (
                <Link
                  to={`/exports/${data.base_cell_export.export.id}?type=${data.base_cell_export.export.export_type}`}
                  target="_blank"
                >
                  {data.base_cell_export.export.export_code}
                </Link>
              )}
            </td>
            <td className={styles.disabled}>{data.base_cell_export?.export.export_date}</td>
            <td className={styles.disabled}>
              {data.base_cell_export?.export.customer?.customer_code}
            </td>
            <td className={styles.disabled}>
              {data.base_cell_export?.export.customer?.customer_name}
            </td>
            <td className={styles.disabled}>{data.base_cell_export?.export.customer?.hall_name}</td>
            <td className={styles.disabled}>
              {data.base_cell_export?.export.export_type === 2 && <MdOutlineCircle />}
            </td>
          </tr>
          <tr>
            <td className={styles.header}>基盤番号</td>
            <td>
              <input {...register('base_number')} disabled={!!data.base_cell_export} />
            </td>
            <td>
              <input {...register('base_code')} disabled={!!data.base_cell_export} />
            </td>
            <td className={styles.disabled}>
              {data.base_cell_export && (
                <Link
                  to={`/exports/${data.base_cell_export.export.id}?type=${data.base_cell_export?.export.export_type}`}
                  target="_blank"
                >
                  {data.base_cell_export?.export.export_code}
                </Link>
              )}
            </td>
            <td className={styles.disabled}>{data.base_cell_export?.export?.export_date}</td>
            <td className={styles.disabled}>
              {data.base_cell_export?.export.customer?.customer_code}
            </td>
            <td className={styles.disabled}>
              {data.base_cell_export?.export.customer?.customer_name}
            </td>
            <td className={styles.disabled}>{data.base_cell_export?.export.customer?.hall_name}</td>
            <td className={styles.disabled}>
              {data.base_cell_export?.export.export_type === 2 && <MdOutlineCircle />}
            </td>
          </tr>
        </tbody>
      </Table>
      <div className="d-flex justify-content-between">
        <button className="primary-btn" onClick={() => navigate(-1)}>
          戻る
        </button>
        {id && (
          <button className="primary-btn--delete" onClick={showConfirmDeletePopup}>
            削除
          </button>
        )}
      </div>
    </div>
  );
});

export default InventoryDetailsContainer;
