// Built-in
import { useMemo, useRef, useState } from 'react';
import { Breadcrumb, BreadcrumbItem } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useMutation } from 'react-query';
import moment from 'moment';

// Component
import DataTable from 'shared/components/data-table';
import EditModal from './edit-modal';
import YearMonthSelect from 'shared/components/year-month-select';

// Type
import { DEFAULT_VEHICLE_COST_FILTER, IVehicleCostFilter, VehicleCost } from 'types/vehicle-cost';

// Store
import { DataProvider } from 'shared/data-providers/table-data-provider';
import UIStore from 'stores/ui';
import useStores from 'hooks/use-stores';

// Service
import { createVehicleCost, deleteVehicleCost, updateVehicleCost } from 'services/vehicle-cost';
import { exportForm } from 'services/export-form';

// Hook
import { useFormError } from 'hooks/use-form-error';

// Style
import styles from './styles.module.scss';

// Const
import { MONTHS, currentMonthOption, currentYearOption, YEARS } from 'consts';

const VehicleCostScreen = () => {
  const uiStore: UIStore = useStores().uiStore;

  const [editModal, setEditModal] = useState(false);
  const [selected, setSelected] = useState<any>(undefined);

  const dataProvider = useRef(
    new DataProvider<IVehicleCostFilter>({
      dataSource: 'vehicle-costs',
      defaultFilters: DEFAULT_VEHICLE_COST_FILTER,
    })
  );

  const [month, setMonth] = useState<any>(currentMonthOption);
  const [year, setYear] = useState<any>(currentYearOption);

  const { showFormError } = useFormError();

  const createMutation = useMutation((data) => createVehicleCost(data), {
    onMutate: () => setEditModal(false),
    onSuccess: () => dataProvider.current.load(),
    onError: (err) => showFormError(err),
  });

  const saveMutation = useMutation((data) => updateVehicleCost(selected!.id, data), {
    onMutate: () => setEditModal(false),
    onSuccess: () => dataProvider.current.load(),
    onError: (err) => showFormError(err),
  });

  const deleteMutation = useMutation(() => deleteVehicleCost(selected!.id), {
    onSuccess: () => dataProvider.current.load(),
  });

  const exportVehicleCostForm = async () => {
    try {
      uiStore.showLoading();
      const { month, year } = dataProvider.current.filters as any;
      await exportForm(`vehicle-costs/export-list`, { month, year });
    } catch (error: any) {
      uiStore.showAlertBox({
        title: 'エラー',
        type: 'error',
        content: error.message,
      });
    } finally {
      uiStore.hideLoading();
    }
  };

  const submit = () => {
    const newFilter = {
      ...dataProvider.current.filters,
      month: month.value,
      year: year.value,
    };

    dataProvider.current.setFilters(newFilter);
  };

  const headers = useMemo(() => {
    return [
      { label: '日付', key: 'effective_date', oneLine: true },
      { label: 'トラック', key: 'truck_name' },
      { label: '経費名' },
      { label: '金額' },
      { label: 'メモ' },
      { label: '' },
    ];
  }, []);

  const delegate = (item: any) => {
    const effectiveDate = moment(item.effective_date).format('YYYY/MM/DD');
    const truckName = item.truck?.truck_name;
    const costName = item.cost_name;
    const amount = item.amount?.toLocaleString('en-US');
    const memo = item.cost_memo;
    const editBtn = (
      <button
        className="edit"
        onClick={() => {
          setSelected(item);
          setEditModal(true);
        }}
      >
        修
      </button>
    );

    return [effectiveDate, truckName, costName, amount, memo, editBtn];
  };

  const showConfirmDeletePopup = () => {
    uiStore.showConfirmBox({
      title: 'このレコードを削除しますか？',
      confirmText: '削除する',
      cancelText: ' キャンセル',
      onConfirm: () => {
        uiStore.hideConfirmBox();
        deleteMutation.mutate(selected!.id);
      },
    });
  };

  const onClose = () => {
    setEditModal(false);
  };

  const onCreate = (data: any) => {
    createMutation.mutate(data);
  };

  const onSave = (data: any) => {
    saveMutation.mutate(data);
  };

  const onDelete = () => {
    setEditModal(false);
    showConfirmDeletePopup();
  };

  return (
    <div className="list-screen">
      <Breadcrumb>
        <BreadcrumbItem linkAs={Link} linkProps={{ to: '/home' }}>
          TOP
        </BreadcrumbItem>
        <BreadcrumbItem active>車両</BreadcrumbItem>
      </Breadcrumb>

      <div className={styles.filter}>
        <YearMonthSelect
          label="入力年月"
          monthOptions={MONTHS}
          yearOptions={YEARS}
          monthValue={month}
          yearValue={year}
          onMonthChange={(newValue: any) => setMonth(newValue)}
          onYearChange={(newValue: any) => setYear(newValue)}
          maxMenuHeight={{ year: 500, month: 500 }}
        />
        <div className={styles.buttonGroup}>
          <button className="action-btn" onClick={submit}>
            検索
          </button>
          <button className="action-btn" onClick={exportVehicleCostForm}>
            出力
          </button>
        </div>
      </div>

      <div className="d-flex justify-content-end" style={{ margin: '1em 0 0 0' }}>
        <button
          className="create"
          onClick={() => {
            setSelected(undefined);
            setEditModal(true);
          }}
        >
          作成
        </button>
      </div>

      <div className={styles.tableWrapper}>
        <DataTable<VehicleCost, IVehicleCostFilter>
          dataProvider={dataProvider.current}
          headers={headers}
          delegate={delegate}
        />
      </div>

      {editModal && (
        <EditModal
          initialValues={selected}
          onClose={onClose}
          onCreate={onCreate}
          onSave={onSave}
          onDelete={onDelete}
        />
      )}
    </div>
  );
};

export default VehicleCostScreen;
