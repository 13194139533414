export const mappingLeftRightQrData = (data: {
  export_details: Array<any>;
  export_qr_details: Array<any>;
  id: number;
}) => {
  // split a row into 2 parts: left and right
  // left: export_details, right: export_qr_details
  // if there are 2 lefts and 10 rights,
  // then the remaining 8 lefts will be filled with null.
  let left = data.export_details;
  let right = data.export_details.map((item: any) => {
    if (item.export_qr_detail !== null) {
      return item.export_qr_detail;
    }
    return null;
  });
  if (data.export_qr_details) {
    right.push(...data.export_qr_details);
  }

  // these rows will only have right values.
  let numberOfEmptyRows = right.length - left.length;
  for (let i = 0; i < numberOfEmptyRows; i++) {
    left.push(null);
  }

  return [left, right];
};
