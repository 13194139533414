// Built-in
import { observer } from 'mobx-react';
import { Outlet } from 'react-router-dom';

// Component
import Header from '../header';
import SideBar from '../sidebar';

// Style
import styles from './styles.module.scss';

interface IProps {
  children: any;
  className?: string;
  showHeader?: boolean;
  showSideBar?: boolean;
}

export const DefaultLayout = observer(
  ({ children, showHeader, showSideBar, className }: IProps) => {
    return (
      <div className={styles.container}>
        {showHeader && <Header />}

        <div className={styles.body}>
          {showSideBar && <SideBar />}
          <div
            className={`${styles.content} ${className}`}
            style={{ marginLeft: showSideBar ? '15rem' : 0 }}
          >
            {children}
          </div>
        </div>
      </div>
    );
  }
);

export const LayoutWithSideBar = () => {
  return (
    <div className={styles.container}>
      <div className={styles.body}>
        <SideBar />
        <div className={`${styles.content}`} style={{ marginLeft: '15rem' }}>
          <Outlet />
        </div>
      </div>
    </div>
  );
};
