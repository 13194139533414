// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_container__McykM {\n  padding: 2rem 3rem;\n}\n.styles_container__McykM input[type=text]:focus {\n  outline: none;\n}", "",{"version":3,"sources":["webpack://./src/shared/containers/sale-confirm-modal/styles.module.scss"],"names":[],"mappings":"AAEA;EACE,kBAAA;AADF;AAII;EACE,aAAA;AAFN","sourcesContent":["@import \"../../../assets/styles/variables\";\n\n.container {\n  padding: 2rem 3rem;\n\n  input[type=\"text\"] {\n    &:focus {\n      outline: none;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "styles_container__McykM"
};
export default ___CSS_LOADER_EXPORT___;
