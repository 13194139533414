// Built-in
import { Breadcrumb } from 'react-bootstrap';
import { Link } from 'react-router-dom';

// Component
import InventoryDetailsContainer from 'shared/containers/inventory-details';

const CreateInventoryItemScreen = () => {
  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/home' }}>
          TOP
        </Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/inventories' }}>
          在庫
        </Breadcrumb.Item>
        <Breadcrumb.Item active>在庫詳細</Breadcrumb.Item>
      </Breadcrumb>

      <InventoryDetailsContainer />
    </div>
  );
};

export default CreateInventoryItemScreen;
