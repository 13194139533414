// Built-in
import { useEffect, useState } from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';
import moment from 'moment';

// Component
import PurchaseDetailsContainer from 'shared/containers/purchase-details';

// Type
import { Purchase } from 'types/purchase';

// Service
import { getPurchaseById } from 'services/purchase';
import { getQuotationById } from 'services/quotation';

// Store
import UIStore from 'stores/ui';
import useStores from 'hooks/use-stores';

// Hook
import { useQueryParams } from 'hooks/use-query-params';
import SessionStore from 'stores/session';
import { removeKeys } from 'utils';
import { v1 } from 'uuid';

const CreatePurchaseScreen = () => {
  const navigate = useNavigate();

  const uiStore: UIStore = useStores().uiStore;
  const sessionStore: SessionStore = useStores().sessionStore;

  const query = useQueryParams();
  const duplicateIdStr = query.get('id');
  const quotationIdStr = query.get('quotation_id');
  const duplicateId = (() => {
    if (!duplicateIdStr) return undefined;
    if (isNaN(parseInt(duplicateIdStr))) return undefined;
    return parseInt(duplicateIdStr);
  })();

  const quotationId = (() => {
    if (!quotationIdStr) return undefined;
    if (isNaN(parseInt(quotationIdStr))) return undefined;
    return parseInt(quotationIdStr);
  })();

  const [initialValues, setInitialValues] = useState<Partial<Purchase> | undefined>(undefined);
  const [loaded, setLoaded] = useState(false);

  useQuery(['purchase', duplicateId], () => getPurchaseById(duplicateId as number), {
    enabled: !!duplicateId,
    onSuccess: (data) => {
      const newPurchase = JSON.parse(JSON.stringify(data));
      newPurchase.purchase_details = data.purchase_details.map((old: any) => {
        const newPurchaseDetail = JSON.parse(JSON.stringify(old));
        newPurchaseDetail.id = v1();
        removeKeys(newPurchaseDetail, ['updated_at', 'created_at', 'deleted_at', 'purchase_id']);
        return newPurchaseDetail;
      });

      removeKeys(newPurchase, [
        'id',
        'purchase_code',
        'imports',
        'deliveries',
        'created_at',
        'updated_at',
        'deleted_at',
        'quotation_id',
      ]);

      setInitialValues(newPurchase);
    },
  });

  useQuery(['quotation', quotationId], () => getQuotationById(quotationId as number), {
    enabled: !!quotationId,
    onSuccess: (quotation) => {
      if (quotation.purchase_posted === 0) {
        const purchase_details = quotation.quotation_details.map((quotationDetail: any) => {
          const purchaseDetail = JSON.parse(JSON.stringify(quotationDetail));
          purchaseDetail.customer_id = quotation.customer_id;
          purchaseDetail.id = v1();
          purchaseDetail.purchase_detail_note = quotationDetail.quotation_detail_note;

          // remove redundant fields from quotation details
          // since we are creating a new purchase, we don't need these.
          removeKeys(purchaseDetail, [
            'updated_at',
            'created_at',
            'deleted_at',
            'quotation_id',
            'quotation_detail_note',
          ]);
          return purchaseDetail;
        });

        const values = {
          ...quotation,
          input_date: moment(new Date()).format('YYYY-MM-DD'),
          purchase_type: quotation.quotation_type,
          purchase_detail: quotation.quotation_detail,
          purchase_memo: quotation.quotation_memo,
          user_input_id: sessionStore.profile?.id, // current logged-in user
          user_input_person: null,
          user_confirm_id: null,
          user_confirm_person: null,
          purchase_details,
          quotation_id: quotationId,
          purchase_date: quotation.scheduled_date,
        };


        // remove redundant fields from quotation
        // since we are creating a new purchase, we don't need these.
        removeKeys(values, [
          'purchase_posted',
          'purchase_posted_text',
          'updated_at',
          'created_at',
          'deleted_at',
          'id',
          'quotation_id',
          'quotation_type',
          'quotation_type_text',
          'quotation_code',
          'quotation_detail',
          'quotation_memo',
          'quotation_details',
          'scheduled_date',
        ]);
        setInitialValues(values);
      } else {
        uiStore.showAlertBox({
          title: 'Error',
          content: 'Da tao purchase tu quotation nay roi',
          onClose: () => {
            uiStore.hideAlertBox();
            navigate(-1);
          },
        });
      }
    },
  });

  useEffect(() => {
    if (initialValues) setLoaded(true);
  }, [initialValues]);

  useEffect(() => {
    // if there is no duplicateId or quotationId, load component immediately
    // else wait for data.
    if (!duplicateId && !quotationId) setLoaded(true);
  }, []);

  return (
    <div id="purchase-details-screen">
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/home' }}>
          TOP
        </Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/purchases' }}>
          買取
        </Breadcrumb.Item>
        <Breadcrumb.Item active>買取詳細</Breadcrumb.Item>
      </Breadcrumb>
      {loaded && (
        <PurchaseDetailsContainer initialValues={initialValues} quotationId={quotationId} />
      )}
      <div id="dynamic-dropdown-menu-portal"></div>
    </div>
  );
};

export default CreatePurchaseScreen;
